import { AvField, AvForm } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_green.css"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Card,
  CardBody, Col,
  Container, Row
} from "reactstrap"
import { addTeacher } from "../../store/actions"


//Import Breadcrumb

toast.configure()
const AddUser = () => {
  const dispatch = useDispatch()
  let history = useHistory()
  const [errok, setErrOk] = useState(false)

  const { token } = useSelector(state => state.Login)
  const { message, error } = useSelector(state => state.TeacherAdmin)

  useEffect(() => {
    if (message) {
      toast.success(message)
    }
    if (error) {
      toast.error(error)
    }
  }, [error, message])

  //check email
  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  function handleValidSubmit(event, values) {
    console.log("values email")

    if (!validateEmail(values.email)) {
      toast("Email is not valid")
    }
    dispatch(addTeacher(values, history, token))
    //
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Game || Add Teacher Data</title>
        </MetaTags>

        <div className="account-pages  ">
          <Container>
            <Row>
              {/*  */}
              <CardBody>
                <Button
                  color="primary"
                  outline
                  className="btn-xr waves-effect waves-light"
                  onClick={() => history.goBack()}
                >
                  <i className=" ti-angle-double-left"></i>
                </Button>
              </CardBody>
            </Row>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={4}>
                <Card className="overflow-hidden p-3 rounded-5">
                  <h3 className="text-center">Create Teacher</h3>

                  <CardBody className="p-4">
                    <div className="p-3">
                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            required
                          />
                        </div>

                        <Row className="mb-3">
                          <Col sm={12} className="text-end">
                            <Button
                              color="success"
                              outline
                              className="waves-effect waves-light"
                            >
                              Register Teacher
                            </Button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddUser
