import {
  ADD_FEATURE_BY_ADMIN,
  ADD_FEATURE_BY_ADMIN_FAIL,
  ADD_FEATURE_BY_ADMIN_SUCCESS,
  GET_FEATURE_BY_ADMIN,
  GET_FEATURE_BY_ADMIN_FAIL,
  GET_FEATURE_BY_ADMIN_SUCCESS,
  MAKE_FEATURE_AVAILABLE_BY_ADMIN,
  MAKE_FEATURE_AVAILABLE_BY_ADMIN_FAIL,
  MAKE_FEATURE_AVAILABLE_BY_ADMIN_SUCCESS,
} from "./actionTypes"

const initialState = {
  featureData: [],
  loading: false,
  message: false,
  error: "",
}

const adminFeature = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FEATURE_BY_ADMIN:
      state = { ...state, loading: true }
      break
    case ADD_FEATURE_BY_ADMIN_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case ADD_FEATURE_BY_ADMIN_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break

    case GET_FEATURE_BY_ADMIN:
      state = { ...state, loading: true }
      break
    case GET_FEATURE_BY_ADMIN_SUCCESS:
      state = { ...state, loading: false, featureData: action.payload.data }
      break
    case MAKE_FEATURE_AVAILABLE_BY_ADMIN:
      state = { ...state, loading: true }
      break
    case MAKE_FEATURE_AVAILABLE_BY_ADMIN_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case MAKE_FEATURE_AVAILABLE_BY_ADMIN_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    default:
      state = state
      break
  }
  return state
}

export default adminFeature
