
import { axiosDemandData, axiosGetDemandData, axiosMarketShareData, axiosUpdateDemand, axiosUpdateDemandData } from "helpers/api_helper";
import { getData } from "helpers/Custom_Helper";
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";

import { toaster } from "../../helpers/custom/Toast";
import { getDemandData, getDemandDataFail, getDemandDataSuccess, getMarketShareFail, getMarketShareSuccess, postMarketShareFail, postMarketShareSuccess, postTotalDemandFail, postTotalDemandSuccess, storeData, updateMarketShareFail, updateMarketShareSuccess, updateTotalDemandFail, updateTotalDemandSuccess } from "./actions";
import { GET_MARKET_SHARE, GET_TOTAL_DEMAND, POST_MARKET_SHARE, POST_TOTAL_DEMAND, UPDATE_MARKET_SHARE, UPDATE_TOTAL_DEMAND } from "./actionTypes";


//for main


function* onGetDemandData({ payload: { token, course, region } }) {
  
  try {
   
    const response = yield call(axiosGetDemandData, token, course, region);
    
    yield put(getDemandDataSuccess(response?.data?.getData));
    yield put(storeData('modifiedDemand', response?.data?.getData?.demandAndRound));
    console.log('response?.data?.getData', response?.data?.getData);
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed";
    toaster("error", message);
    yield put(getDemandDataFail(message));
  }
}


function* onPostTotalDemand({ payload: { values, history, token } }) {
  try {
    const response = yield call(axiosDemandData, values, token)
    console.log("hi")
    console.log(response)
    yield put(postTotalDemandSuccess(response))
    toast("🦄 Demand added successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Demand added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(postTotalDemandFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}
function* onPostMarketShare({ payload: { values, history, token } }) {
  try {
    const response = yield call(axiosMarketShareData, values, token)
    console.log("hi")
    console.log(response)
    yield put(postMarketShareSuccess(response))
    toast("🦄 Market Share added successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Market Share added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(postMarketShareFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}
function* onUpdateTotalDemand({ payload: { values, history, token, courseInfo, regionInfo, groupID, singleId } }) {
  try {
    const response = yield call(axiosUpdateDemand, values, token, groupID, singleId)
    console.log("hi")
    console.log(response)
    yield put(updateTotalDemandSuccess(response))
    yield put(getDemandData(token, courseInfo, regionInfo))
    toast("🦄 Demand updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Demand updated successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(updateTotalDemandFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}

function* onUpdateMarketShare({ payload: { values, history, token, courseInfo, regionInfo } }) {
  try {
    const response = yield call(axiosUpdateDemand, values, token, courseInfo, regionInfo)
    console.log("hi")
    console.log(response)
    yield put(updateMarketShareSuccess(response))
    toast("🦄 Market share updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Market share successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(updateMarketShareFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}

function* onGetMarketShare({ payload: { token, course, region } }) {
  try {
    const url = `market-share/${course}/${region}`;
    const response = yield call(getData, url, token);
    yield put(getMarketShareSuccess(response?.getData));
    yield put(storeData("modifiedMarketShare",response?.getData?.marketShareAndRound));
  } catch (error) {
    const message = error.response?.data?.message || "get banner failed";
    yield put(getMarketShareFail(message));
    toaster("error", message);
  }
}
function* DemandSaga() {
  yield takeEvery(POST_TOTAL_DEMAND, onPostTotalDemand);
  yield takeEvery(GET_TOTAL_DEMAND, onGetDemandData);
  yield takeEvery(UPDATE_TOTAL_DEMAND, onUpdateTotalDemand);
  yield takeEvery(POST_MARKET_SHARE, onPostMarketShare);
  yield takeEvery(UPDATE_MARKET_SHARE, onUpdateMarketShare);
  yield takeEvery(GET_MARKET_SHARE, onGetMarketShare);
}
export default DemandSaga;
