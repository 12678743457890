import React from "react"
import "./header.css"
import HeaderMainNav from "./HeaderMainNav"

const MainHeader = () => {
  return (
    <div>
      {/* <HeaderTop /> */}
      <HeaderMainNav />
    </div>
  )
}

export default MainHeader
