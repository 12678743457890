import {
  ADD_MARKET_OUTLOOK,
  ADD_MARKET_OUTLOOK_FAIL,
  ADD_MARKET_OUTLOOK_SUCCESS,
  GET_MARKET_OUTLOOKS,
  GET_MARKET_OUTLOOKS_SUCCESS,
  GET_SINGLE_MARKET_OUTLOOK,
  GET_SINGLE_MARKET_OUTLOOK_FAIL,
  GET_SINGLE_MARKET_OUTLOOK_SUCCESS,
  UPDATE_MARKET_OUTLOOK,
  UPDATE_MARKET_OUTLOOK_FAIL,
  UPDATE_MARKET_OUTLOOK_SUCCESS,
  STUDENT_GET_SINGLE_MARKET_OUTLOOK,
  STUDENT_GET_SINGLE_MARKET_OUTLOOK_SUCCESS,
  STUDENT_GET_SINGLE_MARKET_OUTLOOK_FAIL,
} from "./actionTypes"

const initialState = {
  marketOutlooks: [],
  singleMarketOutlook: {},
  studentSingleMarketOutlook: {},
  loading: false,
  message: "",
  error: "",
}

const marketOutlook = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MARKET_OUTLOOK:
      state = { ...state, loading: true }

      break
    case ADD_MARKET_OUTLOOK_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case ADD_MARKET_OUTLOOK_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case GET_MARKET_OUTLOOKS:
      state = { ...state, loading: true }
      break
    case GET_MARKET_OUTLOOKS_SUCCESS:
      state = { ...state, loading: false, marketOutlooks: action.payload.datas }
      break
    case GET_SINGLE_MARKET_OUTLOOK:
      state = { ...state, loading: true }
      break
    case GET_SINGLE_MARKET_OUTLOOK_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleMarketOutlook: action.payload.data,
      }
      break
    case GET_SINGLE_MARKET_OUTLOOK_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case UPDATE_MARKET_OUTLOOK:
      state = { ...state, loading: true }
      break
    case UPDATE_MARKET_OUTLOOK_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case UPDATE_MARKET_OUTLOOK_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case STUDENT_GET_SINGLE_MARKET_OUTLOOK:
      state = { ...state, loading: true }
      break
    case STUDENT_GET_SINGLE_MARKET_OUTLOOK_SUCCESS:
      state = {
        ...state,
        loading: false,
        studentSingleMarketOutlook: action.payload.data,
      }
      break
    default:
      state = state
      break
  }
  return state
}

export default marketOutlook
