import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"

import {
  GET_UNAVAILABLE_FEATURE,
  GET_TOTAL_OWN_FEATURE_NUMBER,
  GET_FEATURE_PURCHASE_PRICE,
  GET_FEATURE_UPGRADE_PRICE,
  GET_TECH_PURCHASE_PRICE,
  GET_TECH_UPGRADE_PRICE,
} from "./actionTypes"

import {
  getUnavailableFeatureSuccess,
  getTotalOwnFeatureSuccess,
  getFeaturePurchasePriceSuccess,
  getTechPurchasePriceSuccess,
  getTechUpgradePriceSuccess,
  getFeatureUpgradePriceSuccess,
} from "./actions"

import {
  axiosGetUnavailableFeature,
  axiosGetOwnFeature,
  axiosTechPurchasePrice,
  axiosTechUpgradePrice,
  axiosFeatureUpgradePrice,
  axiosFeaturePurchasePrice,
} from "../../helpers/api_helper"

const asyncGetUnavailableTech = async token => {
  try {
    const response = await axiosGetUnavailableFeature(token)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetUnavailableFeature({ payload: { token } }) {
  try {
    const response = yield call(asyncGetUnavailableTech, token)
    yield put(getUnavailableFeatureSuccess(response.unavailabeFeature))
  } catch (error) {
    console.log("error data")
  }
}

const asyncGetTotalOwnFeature = async (token, teamId) => {
  try {
    const response = await axiosGetOwnFeature(token, teamId)
    return response.data
  } catch (error) {
    return error.resposne.data
  }
}
function* workerGetTotalOwnFeatureNumber({ payload: { token, teamId } }) {
  try {
    const response = yield call(asyncGetTotalOwnFeature, token, teamId)
    yield put(getTotalOwnFeatureSuccess(response.getData))
  } catch (error) {}
}

//tech purchase
const asyncTechPurchasePrice = async (token, universeId) => {
  try {
    const response = await axiosTechPurchasePrice(token, universeId)
    return response.data
  } catch (error) {}
}
function* workerGetTechPurchasePrice({ payload: { token, universeId } }) {
  try {
    const response = yield call(asyncTechPurchasePrice, token, universeId)
    yield put(getTechPurchasePriceSuccess(response.getData))
  } catch (error) {}
}

//tech purchase
const asyncTechUpgradePrice = async (token, universeId) => {
  try {
    const response = await axiosTechUpgradePrice(token, universeId)
    return response.data
  } catch (error) {}
}
function* workerGetTechUpgradePrice({ payload: { token, universeId } }) {
  try {
    const response = yield call(asyncTechUpgradePrice, token, universeId)
    yield put(getTechUpgradePriceSuccess(response.getData))
  } catch (error) {}
}

//feature purchase
const asyncFeatureUpgradePrice = async (token, universeId) => {
  try {
    console.log("token data ", token)
    const response = await axiosFeatureUpgradePrice(token, universeId)
    return response.data
  } catch (error) {}
}
function* workerGetFeaturePurchasePrice({ payload: { token, universeId } }) {
  try {
    const response = yield call(asyncFeaturePurchasePrice, token, universeId)
    console.log("response data here", response)
    yield put(getFeaturePurchasePriceSuccess(response.getData))
  } catch (error) {}
}

//feature upgrade
const asyncFeaturePurchasePrice = async (token, universeId) => {
  try {
    const response = await axiosFeaturePurchasePrice(token, universeId)
    return response.data
  } catch (error) {}
}
function* workerGetFeatureUpgraePrice({ payload: { token, universeId } }) {
  try {
    const response = yield call(asyncFeatureUpgradePrice, token, universeId)
    yield put(getFeatureUpgradePriceSuccess(response.getData))
  } catch (error) {}
}
function* featureSaga() {
  yield takeEvery(GET_UNAVAILABLE_FEATURE, workerGetUnavailableFeature)
  yield takeEvery(GET_TOTAL_OWN_FEATURE_NUMBER, workerGetTotalOwnFeatureNumber)
  yield takeEvery(GET_TECH_PURCHASE_PRICE, workerGetTechPurchasePrice)
  yield takeEvery(GET_TECH_UPGRADE_PRICE, workerGetTechUpgradePrice)
  yield takeEvery(GET_FEATURE_PURCHASE_PRICE, workerGetFeaturePurchasePrice)
  yield takeEvery(GET_FEATURE_UPGRADE_PRICE, workerGetFeatureUpgraePrice)
}

export default featureSaga
