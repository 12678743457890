import {
  ADD_MARKET_OUTLOOK,
  ADD_MARKET_OUTLOOK_FAIL,
  ADD_MARKET_OUTLOOK_SUCCESS,
  GET_MARKET_OUTLOOKS,
  GET_MARKET_OUTLOOKS_SUCCESS,
  GET_SINGLE_MARKET_OUTLOOK,
  GET_SINGLE_MARKET_OUTLOOK_FAIL,
  GET_SINGLE_MARKET_OUTLOOK_SUCCESS,
  UPDATE_MARKET_OUTLOOK,
  UPDATE_MARKET_OUTLOOK_FAIL,
  UPDATE_MARKET_OUTLOOK_SUCCESS,
  STUDENT_GET_SINGLE_MARKET_OUTLOOK,
  STUDENT_GET_SINGLE_MARKET_OUTLOOK_FAIL,
  STUDENT_GET_SINGLE_MARKET_OUTLOOK_SUCCESS,
} from "./actionTypes"

export const addMarketOutlook = (value, token) => {
  return {
    type: ADD_MARKET_OUTLOOK,
    payload: { value, token },
  }
}

export const addMarketOutlookSuccess = message => {
  return {
    type: ADD_MARKET_OUTLOOK_SUCCESS,
    payload: { message },
  }
}

export const addMarketOutlookFail = message => {
  return {
    type: ADD_MARKET_OUTLOOK_FAIL,
    payload: { message },
  }
}

export const getMarketOutlooks = token => {
  return {
    type: GET_MARKET_OUTLOOKS,
    payload: token,
  }
}
export const getMarketOutlooksSuccess = datas => {
  return {
    type: GET_MARKET_OUTLOOKS_SUCCESS,
    payload: { datas },
  }
}

export const getSingleMarketOutlook = (marketOutlokId, token) => {
  return {
    type: GET_SINGLE_MARKET_OUTLOOK,
    payload: { marketOutlokId, token },
  }
}

export const getSingleMarketOutlookSuccess = data => {
  return {
    type: GET_SINGLE_MARKET_OUTLOOK_SUCCESS,
    payload: { data },
  }
}

export const getSingleMarketOutlookFail = message => {
  return {
    type: GET_SINGLE_MARKET_OUTLOOK_FAIL,
    payload: { message },
  }
}

export const updateSingeMarketOutlook = (values, token) => {
  return {
    type: UPDATE_MARKET_OUTLOOK,
    payload: { values, token },
  }
}

export const updateSingleMarketOutlookSuccess = message => {
  return {
    type: UPDATE_MARKET_OUTLOOK_SUCCESS,
    payload: { message },
  }
}
export const updateSingleMarketOutlookFail = message => {
  return {
    type: UPDATE_MARKET_OUTLOOK_FAIL,
    payload: { message },
  }
}

export const studentGetSingleMarketOutlook = (roundIndex, token) => {
  return {
    type: STUDENT_GET_SINGLE_MARKET_OUTLOOK,
    payload: { roundIndex, token },
  }
}

export const studentGetSingleMarketOutlookSuccess = data => {
  return {
    type: STUDENT_GET_SINGLE_MARKET_OUTLOOK_SUCCESS,
    payload: { data },
  }
}
