import {
  GET_FINANCIAL_OUTCOME_SUCCESS,
  GET_FINANCIAL_OUTCOME,
  SUBMIT_FINANCIAL_OUTCOME,
  SUBMIT_FINANCIAL_OUTCOME_FAIL,
  SUBMIT_FINANCIAL_OUTCOME_SUCCESS,
  GET_WHO_SUBMIT,
  GET_WHO_SUBMIT_SUCCESS,
  GET_VERIFY_SUBMISSION,
  GET_VERIFY_SUBMISSION_SUCCESS,
} from "./actionTypes"

const initial_state = {
  financialData: {},
  whoSubmit: {},
  verifySubmissionData: {},
  loading: false,
  verifySubmissionDataLoading: false,
  message: "",
  error: "",
}

const financialOutComeData = (state = initial_state, action) => {
  switch (action.type) {
    case GET_FINANCIAL_OUTCOME:
      state = { ...state, loading: true }
      break
    case GET_FINANCIAL_OUTCOME_SUCCESS:
      state = {
        ...state,
        loading: false,
        financialData: action.payload.data,
      }
      break

    case SUBMIT_FINANCIAL_OUTCOME:
      state = { ...state, loading: true }
      break
    case SUBMIT_FINANCIAL_OUTCOME_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case SUBMIT_FINANCIAL_OUTCOME_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case GET_WHO_SUBMIT:
      state = { ...state, loading: true }
      break
    case GET_WHO_SUBMIT_SUCCESS:
      state = { ...state, loading: false }
      break

    case GET_VERIFY_SUBMISSION:
      state = { ...state, verifySubmissionDataLoading: true }
      break
    case GET_VERIFY_SUBMISSION_SUCCESS:
      state = {
        ...state,
        verifySubmissionDataLoading: false,
        verifySubmissionData: action.payload.data,
      }
      break
    default:
      state = state
      break
  }
  return state
}

export default financialOutComeData
