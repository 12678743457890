import {
  ADD_OR_UPDATE_TILL_SET_PRICE,
  ADD_OR_UPDATE_TILL_SET_PRICE_FAIL,
  ADD_OR_UPDATE_TILL_SET_PRICE_SUCCESS,
  GET_TILL_PRICE_SUCCESS,
  GET_TILL_PRICE,
  GET_STUDENT_MARKET_DATA_ON_TECH_SUCCESS,
  GET_STUDENT_MARKET_DATA_ON_TECH,
  GET_MARKETING_PRIORITIES_DATA_SUCCESS,
  GET_MARKETING_PRIORITIES_DATA,
  SET_PRIORITIES_FOR_MARKETING_FAIL,
  SET_PRIORITIES_FOR_MARKETING_SUCCESS,
  SET_PRIORITIES_FOR_MARKETING,
  GET_TAXION_DATA,
  GET_TAXION_DATA_SUCCESS,
  CREATE_OR_UPDATE_MARKET_DATA_LAST_PART_SUCCESS,
  CREATE_OR_UPDATE_MARKET_DATA_LAST_PART_FAIL,
  CREATE_OR_UPDATE_MARKET_DATA_LAST_PART,
  GET_MARKETING_REGIONS_IN_INCOME_STATEMENT_SUCCESS,
  GET_MARKETING_REGIONS_IN_INCOME_STATEMENT,
  GO_TO_DECISION_CHECKLIST_PAGE,
  GO_TO_DECISION_CHECKLIST_PAGE_SUCCESS,
  GET_MARKETING_PRIORITIES_INDEX_SUCCESS,
  GET_MARKETING_PRIORITIES_INDEX,
  GO_TO_INCOME_STATEMENT,
  GO_TO_INCOME_STATEMENT_SUCCESS,
  DISABLE_TECH_BUTTON,
  DISABLE_TECH_BUTTON_SUCCESS,
  GET_MARKET_SHARE_OF_EACH,
  GET_MARKET_SHARE_OF_EACH_SUCCESS,
  NEW_SET_PRIORITIES_FOR_MARKETING,
  NEW_SET_PRIORITIES_FOR_MARKETING_SUCCESS,
  NEW_SET_PRIORITIES_FOR_MARKETING_FAIL,
  GET_PRIORITIES_PERCENTAGE_DATA,
  GET_PRIORITIES_PERCENTAGE_DATA_SUCCESS,
  GET_PRICING_MATRIX,
  GET_PRICING_MATRIX_SUCCESS,
} from "./actionTypes"

const initial_State = {
  marketingTillSetPrice: {},
  studetnMarketDataOnTech: {},
  marketingPrioritiesData: [],
  incomeStatementData: {},
  incomeStatementDataLast: {},
  incomeStatementLoader: false,
  taxionData: {},
  loading: false,
  prioritiesLoading: false,
  taxionLoading: false,
  loadingMarketLastPart: false,
  message: "",
  error: "",
  check: false,
  marketingIndex: {},
  marketingIndexLoading: false,
  incomeCheck: false,
  disbaleTechButton: false,
  marketShareData: {},
  prioritiesPercentage: {},
  pricingMatrixData: {},
  pricingMatrixLoading: false
}

const marketingData = (state = initial_State, action) => {
  switch (action.type) {
    case ADD_OR_UPDATE_TILL_SET_PRICE:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_OR_UPDATE_TILL_SET_PRICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case ADD_OR_UPDATE_TILL_SET_PRICE_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case GET_TILL_PRICE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_TILL_PRICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        marketingTillSetPrice: action.payload.data,
      }
      break
    case GET_STUDENT_MARKET_DATA_ON_TECH:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_STUDENT_MARKET_DATA_ON_TECH_SUCCESS:
      state = {
        ...state,
        loading: false,
        studetnMarketDataOnTech: action.payload.data,
      }
      break
    case GET_MARKETING_PRIORITIES_DATA:
      state = {
        ...state,
        loading: false,
      }
      break
    case GET_MARKETING_PRIORITIES_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        marketingPrioritiesData: action.payload.data,
      }
      break
    case SET_PRIORITIES_FOR_MARKETING:
      state = {
        ...state,
        prioritiesLoading: true,
      }
      break
    case SET_PRIORITIES_FOR_MARKETING_SUCCESS:
      state = {
        ...state,
        prioritiesLoading: false,
        message: action.payload.message,
      }
      break
    case SET_PRIORITIES_FOR_MARKETING_FAIL:
      state = {
        ...state,
        prioritiesLoading: false,
        error: action.payload.message,
      }
      break
    case GET_TAXION_DATA:
      state = {
        ...state,
        taxionLoading: true,
      }
      break
    case GET_TAXION_DATA_SUCCESS:
      state = {
        ...state,
        taxionLoading: false,
        taxionData: action.payload.data,
      }
      break
    case CREATE_OR_UPDATE_MARKET_DATA_LAST_PART:
      state = {
        ...state,
        loadingMarketLastPart: true,
      }
      break
    case CREATE_OR_UPDATE_MARKET_DATA_LAST_PART_SUCCESS:
      state = {
        ...state,
        loadingMarketLastPart: false,
        message: action.payload.message,
      }
      break
    case CREATE_OR_UPDATE_MARKET_DATA_LAST_PART_FAIL:
      state = {
        ...state,
        loadingMarketLastPart: false,
        error: action.payload.message,
      }
      break
    case GET_MARKETING_REGIONS_IN_INCOME_STATEMENT:
      state = {
        ...state,
        incomeStatementLoader: true,
      }
      break
    case GET_MARKETING_REGIONS_IN_INCOME_STATEMENT_SUCCESS:
      state = {
        ...state,
        incomeStatementLoader: false,
        incomeStatementData: action.payload.data.obj,
        incomeStatementDataLast: action.payload.data.objLast,
      }
      break
    case GO_TO_DECISION_CHECKLIST_PAGE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GO_TO_DECISION_CHECKLIST_PAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        check: action.payload.data,
      }

      break
    case GET_MARKETING_PRIORITIES_INDEX:
      state = {
        ...state,
        marketingIndexLoading: true,
      }
      break
    case GET_MARKETING_PRIORITIES_INDEX_SUCCESS:
      state = {
        ...state,
        marketingIndexLoading: false,

        marketingIndex: action.payload.data,
      }
      break
    case GO_TO_INCOME_STATEMENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GO_TO_INCOME_STATEMENT_SUCCESS:
      state = {
        ...state,
        incomeCheck: action.payload.data,
      }
      break

    case DISABLE_TECH_BUTTON:
      state = {
        ...state,
        loading: true,
      }
      break
    case DISABLE_TECH_BUTTON_SUCCESS:
      state = {
        ...state,
        loading: false,
        disbaleTechButton: action.payload.data,
      }
      break

    case GET_MARKET_SHARE_OF_EACH:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_MARKET_SHARE_OF_EACH_SUCCESS:
      state = {
        ...state,
        loading: false,
        marketShareData: action.payload.data,
      }
      break
      case GET_PRICING_MATRIX:
        state = {
          ...state,
          pricingMatrixLoading: true,
        }
        break
      case GET_PRICING_MATRIX_SUCCESS:
        state = {
          ...state,
          pricingMatrixLoading: false,
          pricingMatrixData: action.payload.data,
        }
        break
    case NEW_SET_PRIORITIES_FOR_MARKETING:
      state = {
        ...state,
        prioritiesLoading: true,
      }
      break
    case NEW_SET_PRIORITIES_FOR_MARKETING_SUCCESS:
      state = {
        ...state,
        prioritiesLoading: false,
        message: action.payload.message,
      }
      break
    case NEW_SET_PRIORITIES_FOR_MARKETING_FAIL:
      state = {
        ...state,
        prioritiesLoading: false,
        error: action.payload.message,
      }
      break
    case GET_PRIORITIES_PERCENTAGE_DATA:
      state = {
        ...state,
        prioritiesLoading: true,
      }
      break
    case GET_PRIORITIES_PERCENTAGE_DATA_SUCCESS:
      state = {
        ...state,
        prioritiesLoading: false,
        prioritiesPercentage: action.payload.data,
      }
      break
    default:
      state = state
      break
  }
  return state
}

export default marketingData
