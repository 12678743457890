import {
  LOGIN_USER_ERROR,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  STORE_LOGIN_DATA
} from "./actionTypes"

/*
object take user (email and pass) and history
*/
export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

/*
user : user name
token : jwt token to verify user
email  : user email
userrole : take user role to push user into his own dashboard ex : if userrole "admin" will help to push into admindashboard
*/
export const loginSuccess = (user, token, email, userrole, roundLimit, lastUniverse) => {
  return {
    type: LOGIN_SUCCESS,
    payload: { user, token, email, userrole, roundLimit, lastUniverse },
  }
}
/*
to logout user only need history , will push user 
*/
export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

//logout user
export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

//will do work leter
export const loginUserError = () => ({
  type: LOGIN_USER_ERROR,
})
export const storeLoginData = (name, data) => ({
  type: STORE_LOGIN_DATA,
  payload: { name, data },
})
