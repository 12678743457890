import React from "react"
import { Col, Container, Row, Table } from "react-bootstrap"
import { CardBody } from "reactstrap"
import inputBackground from "../img/OBJECTS 1.png"
import RegionEmptyPage from "./RegionEmptyPage"
import RegionHeader from "./RegionHeader"
const NetworkCoverage = () => {
  return (
    <div className="fundamental-round-main-wrap">
      <Container>
        <RegionHeader />
        <Row>
          <RegionEmptyPage />
        </Row>
        <Row>
          <Col className="col-12">
            <div
              className="input-tech-data-area"
              // style={{ backgroundImage: `url(${})` }}
              //   style={{ backgroundImage: `url(${inputBackground})` }}
            >
              <CardBody className="p-4 ">
                <div className="p-3">
                  <label for="myfile">
                    <img src={inputBackground} alt="" />
                  </label>
                  <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    id="myfile"
                    name="UploadTechdata"
                    // onChange={techDataUpload}
                  />
                  {/* <input
                  type="file"
                  //   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={techDataUpload}
                  name="TechDataUpload"
                /> */}
                </div>
              </CardBody>
            </div>
          </Col>
        </Row>
        <div className="fundamental-items-main-area">
          <Row>
            <Col lg={12}>
              <div className="single-course-data fundamental">
                <Table borderless hover striped>
                  <thead>
                    <tr>
                      <th>Market</th>
                      <th>round</th>
                      <th>Round</th>
                      <th>Round</th>
                      <th>Round</th>
                      <th>Round</th>
                      <th>Round</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>USA</b>
                      </td>
                      <td>10</td>
                      <td>30</td>
                      <td>30</td>
                      <td>30</td>
                      <td>30</td>
                      <td>30</td>
                    </tr>{" "}
                    <tr>
                      <td>
                        <b>USA</b>
                      </td>
                      <td>10</td>
                      <td>30</td>
                      <td>30</td>
                      <td>30</td>
                      <td>30</td>
                      <td>30</td>
                    </tr>{" "}
                    <tr>
                      <td>
                        <b>USA</b>
                      </td>
                      <td>10</td>
                      <td>30</td>
                      <td>30</td>
                      <td>30</td>
                      <td>30</td>
                      <td>30</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default NetworkCoverage
