import { call, delay, put, takeEvery } from "redux-saga/effects"
//Include Both Helper File with needed methods
import {
  addUser,
  deleteUser,
  getUsers,
  getUser,
  updateUser,
} from "../../helpers/backend_helper.js"
import {
  addUserFail,
  addUserSuccess,
  deleteUserFail,
  deleteUserSuccess,
  getUsersFail,
  getUsersSuccess,
  getUserFail,
  getUserSuccess,
  updateUserFail,
  updateUserSuccess,
} from "./actions"
// Calender Redux States
import {
  ADD_NEW_USER,
  DELETE_USER,
  GET_USERS,
  GET_USER,
  UPDATE_USER,
} from "./actionTypes"

function* fetchUsers() {
  try {
    delay(500)
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUser({ payload: id }) {
  try {
    delay(500)
    const response = yield call(getUser, id)
    yield put(getUserSuccess(response))
  } catch (error) {
    yield put(getUserFail(error))
  }
}

function* onAddNewUser({ payload: { data, history } }) {
  // let adduserData = data.data
  try {
    const response = yield call(addUser, data)
    yield put(addUserSuccess(response))
    history.push("/userdata")
  } catch (error) {
    console.log(error.response)
    if (!error.response.data.message) {
      history.push("/userdata")
    }
    let message = error.response.data.message

    // console.log("error message,", error.response.data.message)
    yield put(addUserFail(message))
  }
}

function* onUpdateUser({ payload: { id, data } }) {
  try {
    const response = yield call(updateUser, id, data)
    yield put(updateUserSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: data }) {
  try {
    const response = yield call(deleteUser, data)
    yield put(deleteUserSuccess(response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* UserSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER, fetchUser)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
}

export default UserSaga
