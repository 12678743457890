import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGIN_USER_ERROR,
  STORE_LOGIN_DATA,
} from "./actionTypes"

const initialState = {
  loading: false,
  username: "",
  token: "",
  email: "",
  userrole: "",
  selectedUniverse: "",
  selectedUniverseName: "",
  roundLimit: 7,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        username: action.payload.user,
        token: action.payload.token,
        email: action.payload.email,
        userrole: action.payload.userrole,
        roundLimit: action.payload.roundLimit,
        lastUniverse: action.payload.lastUniverse,
        selectedUniverse: "",
        loading: false,
      }
      break
    case LOGIN_USER_ERROR:
      state = {
        ...state,

        loading: false,
      }
      break
    case LOGOUT_USER:
      state = {
        ...state,
        username: "",
        token: "",
        email: "",
        userrole: "",
        selectedUniverse: "",
      }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case STORE_LOGIN_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      }
      break

    default:
      state = state
      break
  }
  return state
}

export default login
