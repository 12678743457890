//add data from files
export const ADD_REGION_FROM_FILE = "ADD_REGION_FROM_FILE"
export const ADD_REGION_FROM_FILE_SUCCESS = "ADD_REGION_FROM_FILE_SUCCESS"
export const ADD_REGION_FROM_FILE_FAIL = "ADD_REGION_FROM_FILE_FAIL"

//get regions
export const GET_REGIONS = "GET_REGIONS"
export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS"
export const GET_REGIONS_FAIL = "GET_REGIONS_FAIL"

//get production regions
export const GET_PRODUCTION_REGIONS = "GET_PRODUCTION_REGIONS"
export const GET_PRODUCTION_REGIONS_SUCCESSS = "GET_PRODUCTION_REGIONS_SUCCESS"

// get plant Production Region
//get production regions
export const GET_PLANT_PRICE_REGIONS = "GET_PLANT_PRICE_REGIONS"
export const GET_PLANT_PRICE_REGIONS_SUCCESSS =
  "GET_PLANT_PRICE_REGIONS_SUCCESS"

//get only productio region
export const GET_ONLY_PRODUCTION_REGION_DATA = "GET_ONLY_PRODUCTION_REGION_DATA"
export const GET_ONLY_PRODUCTION_REGION_DATA_SUCCESS =
  "GET_ONLY_PRODUCTION_REGION_SUCCESS"
