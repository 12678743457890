import MainFooter from "components/Common/footer/MainFooter"
import MainHeader from "components/Common/header/MainHeader"
import React from "react"
import { Link } from "react-router-dom"
import checkIcon from "./img/success.svg"
import "./registration.scss"
const ResetSuccess = () => {
  return (
    <div>
      {/* <MainHeader /> */}
      <div className="success-message-main-wrap">
        <div className="success-message-inner-wrap">
          <img src={checkIcon} alt="" />
          <h4>Successful</h4>
          <p>
            A Mail has been sent in your email <br /> account. Please Check
          </p>
          <Link to="/" className="calibarX-default mt-3">
            Login
          </Link>
        </div>
      </div>
      {/* <MainFooter /> */}
    </div>
  )
}

export default ResetSuccess
