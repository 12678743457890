import { all, fork } from "redux-saga/effects"
import ForgetSaga from "./auth/forgetpwd/saga"
//public
import AuthSaga from "./auth/login/saga"
import ProfileSaga from "./auth/profile/saga"

import LayoutSaga from "./layout/saga"

// import SurveySaga from "./survey/saga"
import UserSaga from "./users/saga"

import TeacherAdminSaga from "./teachersAdmin/saga"
import UniversitySaga from "./university/saga"
import StudentSaga from "./studentsAdmin/saga"
import ResetSaga from "./auth/resetPassword/saga"
import courseAdminSaga from "./courseAdmin/saga"

//for teacher
import teacherUniverseSaga from "./universeTeacher/saga"
//course
import CourseTeacherSaga from "./teacher/teacherCourse/saga"

import teamTeacherSaga from "./teamTeacher/saga"
import dataAdminSaga from "./networkCoverage/saga"
import regionDataSaga from "./regionData/saga"
import techDataSaga from "./tech/saga"
import marketOutlookSaga from "./marketOutlook/saga"
import adminDemandSaga from "./AdminDemand/saga"
import adminMarketShareSaga from "./adminMarketShare/saga"
import roundAdminSaga from "./roundAdmin/saga"
import roundTeacherSaga from "./roundTeacher/saga"
import studentProfileSaga from "./StudentProfile/saga"
import AdminProductionSaga from "./AdminProduction/saga"
import studentCapacityData from "./studentPlanning/saga"
import studentMarketingSaga from "./studentMarketing/saga"
import adminFeatureSaga from "./AdminFeature/saga"
import studentRandDSaga from "./studentRandD/saga"
import distributionAndLogisticSaga from "./DistributionAndLogistic/saga"
import studentDemandSaga from "./studentDemand/saga"
import studentMarketShareSaga from "./studentMarketShare/saga"
import studentInitialCapitalSaga from "./studentInitialCapital/saga"
import capacityEnhancementSaga from "./studentCapacity/saga"
import featureSaga from "./feature/saga"
import financialOutcomeSaga from "./financialOutcome/saga"
import resultEvaluationSaga from "./ResultEvaluation/saga"
import profileSaga from "./auth/profile/saga"
import DemandSaga from "./Demand/saga"
import PlantUpgradeSaga from "./PlantUpgrade/saga"
import ProductionUpgradeSaga from "./ProductionCapacity/saga"
import RnDSaga from "./AdminR&D/saga"
import StrategySaga from "./Strategy/saga"
export default function* rootSaga() {
  yield all([
    //public data
    fork(AuthSaga),

    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),

    fork(UserSaga),

    fork(TeacherAdminSaga),

    fork(UniversitySaga),
    fork(StudentSaga),
    fork(ResetSaga),
    fork(courseAdminSaga),
    fork(CourseTeacherSaga),
    fork(teacherUniverseSaga),
    fork(teamTeacherSaga),
    fork(dataAdminSaga),
    fork(regionDataSaga),
    fork(techDataSaga),
    fork(marketOutlookSaga),
    fork(adminDemandSaga),
    fork(adminMarketShareSaga),
    fork(roundAdminSaga),
    fork(roundTeacherSaga),
    fork(studentProfileSaga),
    fork(AdminProductionSaga),
    fork(studentCapacityData),
    fork(studentMarketingSaga),
    fork(adminFeatureSaga),
    fork(studentRandDSaga),
    fork(distributionAndLogisticSaga),
    fork(studentDemandSaga),
    fork(studentMarketShareSaga),
    fork(studentInitialCapitalSaga),
    fork(capacityEnhancementSaga),
    fork(featureSaga),
    fork(financialOutcomeSaga),
    fork(resultEvaluationSaga),
    fork(DemandSaga),
    fork(PlantUpgradeSaga),
    fork(ProductionUpgradeSaga),
    fork(RnDSaga),
    fork(StrategySaga),
  ])
}
