import {
  ADD_DATA,
  ADD_DATA_SUCCESS,
  ADD_DATA_FAIL,
  ADD_DATA_STUDENT,
  ADD_DATA_STUDENT_FAIL,
  ADD_DATA_STUDENT_SUCCESS,
  ADD_LOAD_A_PAGE,
} from "./actionTypes"

export const addData = (value, token) => {
  return {
    type: ADD_DATA,
    payload: { value, token },
  }
}

export const addDataSuccess = message => {
  return {
    type: ADD_DATA_INTO_TEAM_SUCCESS,
    payload: { message },
  }
}
export const addDataFail = message => {
  return {
    type: ADD_DATA_INTO_TEAM_FAIL,
    payload: { message },
  }
}

export const addDataStudent = (values, universeId, teamId, token) => {
  return {
    type: ADD_DATA_STUDENT,
    payload: { values, universeId, teamId, token },
  }
}

export const addDataStudentSuccess = value => {
  return {
    type: ADD_DATA_STUDENT_SUCCESS,
    payload: { value },
  }
}

export const addLoadAPage = message => {
  return {
    type: ADD_LOAD_A_PAGE,
    payload: { message },
  }
}
