import {
  DEMAND_ROUND_VIDEO,
  INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT,
  UPDATE_VIDEO_DATA,
  GET_DEMAND_ADMIN_LAST_ROUND_DATA,
} from "./actionTypes"

import {
  deamndRoundVideoShow,
  insertExpectedDemandForThisRoundFail,
  insertExpectedDemandForThisRoundSuccess,
  updateVideoData,
  updateVideoDataSuccess,
  getDemandAdminLastRoundDataSuccess,
} from "./actions"

import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import { getTotalDemandLastRound } from "../actions"

import {
  axiosInsertOrUpdateExpectedDemand,
  demandRoundVideoShowData,
  axiosUpdateVidoeData,
  getDemandAdminLastRoundData,
} from "../../helpers/api_helper"

const asyncInsertOrUpdateExpectedDemand = async (
  token,
  regionId,
  predictedMarketGrowth,
  totalDemandLastRound,
  roundSerial,
  universeId
) => {
  try {
    const response = await axiosInsertOrUpdateExpectedDemand(
      token,
      regionId,
      predictedMarketGrowth,
      totalDemandLastRound,
      roundSerial,
      universeId
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerInsertOrUpdateExpectedDemand({
  payload: {
    token,
    regionId,
    predictedMarketGrowth,
    totalDemandLastRound,
    roundSerial,
    universeId,
  },
}) {
  try {
    const response = yield call(
      asyncInsertOrUpdateExpectedDemand,
      token,
      regionId,
      predictedMarketGrowth,
      totalDemandLastRound,
      roundSerial,
      universeId
    )

    if (response.status == "success") {
      toast.success(
        "Since you have made changes in the global market demand, please make appropriate modifications in the market share planning if needed."
      )
      let roundIndex = parseInt(roundSerial) - 1
      yield put(getTotalDemandLastRound(token, roundIndex))
      yield put(insertExpectedDemandForThisRoundSuccess(response.message))
      yield put(insertExpectedDemandForThisRoundSuccess())
    } else {
      toast.error("data not inserted")
      yield put(insertExpectedDemandForThisRoundFail(response.message))
      yield put(insertExpectedDemandForThisRoundFail())
    }
  } catch (error) {}
}
//deamand data showSidebar
const asyncDemandRoundVideoShowData = async (token, roundSerial) => {
  try {
    const response = await demandRoundVideoShowData(token, roundSerial)
    return response.data
  } catch (error) {
    return response.error.data
  }
}
function* workerDemandRoundVideo({ payload: { token, roundSerial } }) {
  try {
    const response = yield call(
      asyncDemandRoundVideoShowData,
      token,
      roundSerial
    )
    console.log("demand vidoe response", response)
    yield put(deamndRoundVideoShow(response.getData))
  } catch (error) {}
}

//updatevideoData
const asyncUpdateVideoData = async (token, roundSerial, bodyData) => {
  try {
    const response = await axiosUpdateVidoeData(token, roundSerial, bodyData)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* wokrerUpdateVideoData({ payload: { token, roundSerial, bodyData } }) {
  try {
    const response = yield call(
      asyncUpdateVideoData,
      token,
      roundSerial,
      bodyData
    )
    if (response.status == "success") {
      const res = yield call(asyncDemandRoundVideoShowData, token, roundSerial)
      console.log("demand vidoe response", res)
      yield put(deamndRoundVideoShow(res.getData))
      yield put(updateVideoDataSuccess(response.message))
    }
  } catch (error) {
    yield put(updateVideoDataSuccess(error.response.message))
  }
}

//get demand admin last round data
const asyncGetDemandAdminLastRoundData = async (token, universeId) => {
  const response = await getDemandAdminLastRoundData(token, universeId)
  return response.data
}
function* workerGetDemandAdminLastRoundData({
  payload: { token, universeId },
}) {
  try {
    const getData = yield call(
      asyncGetDemandAdminLastRoundData,
      token,
      universeId
    )

    yield put(getDemandAdminLastRoundDataSuccess(getData.getData))
  } catch (error) {}
}

function* studentDemandSaga() {
  yield takeEvery(
    INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT,
    workerInsertOrUpdateExpectedDemand
  )
  yield takeEvery(DEMAND_ROUND_VIDEO, workerDemandRoundVideo)
  yield takeEvery(UPDATE_VIDEO_DATA, wokrerUpdateVideoData)
  yield takeEvery(
    GET_DEMAND_ADMIN_LAST_ROUND_DATA,
    workerGetDemandAdminLastRoundData
  )
}

export default studentDemandSaga
