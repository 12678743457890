import {
  ADD_REGION_FROM_FILE,
  ADD_REGION_FROM_FILE_SUCCESS,
  ADD_REGION_FROM_FILE_FAIL,
  GET_REGIONS,
  GET_REGIONS_FAIL,
  GET_REGIONS_SUCCESS,
  GET_PRODUCTION_REGIONS,
  GET_PRODUCTION_REGIONS_SUCCESSS,
  GET_PLANT_PRICE_REGIONS,
  GET_PLANT_PRICE_REGIONS_SUCCESSS,
  GET_ONLY_PRODUCTION_REGION_DATA,
  GET_ONLY_PRODUCTION_REGION_DATA_SUCCESS,
} from "./actionTypes"

export const addRegionData = (value, token, history) => {
  return {
    type: ADD_REGION_FROM_FILE,
    payload: { value, token, history },
  }
}

export const addRegionDataSuccess = message => {
  return {
    type: ADD_REGION_FROM_FILE_SUCCESS,
    payload: { message },
  }
}

export const addRegionDataFail = message => {
  return {
    type: ADD_REGION_FROM_FILE_FAIL,
    payload: { message },
  }
}

export const getRegions = token => {
  return {
    type: GET_REGIONS,
    payload: { token },
  }
}

export const getRegionsSuccess = data => {
  return {
    type: GET_REGIONS_SUCCESS,
    payload: { data },
  }
}
//get prod region for admin
export const getOnlyProductionRegion = () => {
  return {
    type: GET_ONLY_PRODUCTION_REGION_DATA,
    payload: {},
  }
}

export const getOnlyProductionRegionSuccesss = data => {
  return {
    type: GET_ONLY_PRODUCTION_REGION_DATA_SUCCESS,
    payload: { data },
  }
}

export const getProductionRegions = (token, roundSerial) => {
  return {
    type: GET_PRODUCTION_REGIONS,
    payload: { token, roundSerial },
  }
}

export const getProductionRegionsSuccess = data => {
  return {
    type: GET_PRODUCTION_REGIONS_SUCCESSS,
    payload: { data },
  }
}

//get PlantPrice
export const getPlantPriceRegions = (token, universeId) => {
  return {
    type: GET_PLANT_PRICE_REGIONS,
    payload: { token, universeId },
  }
}

export const getPlantPRiceRegionsSuccess = data => {
  return {
    type: GET_PLANT_PRICE_REGIONS_SUCCESSS,
    payload: { data },
  }
}
