import {
  GET_UNAVAILABLE_FEATURE,
  GET_UNAVAILABLE_FEATURE_SUCCESS,
  GET_TOTAL_OWN_FEATURE_NUMBER,
  GET_TOTAL_OWN_FEATURE_NUMBER_SUCCESS,
  GET_FEATURE_PURCHASE_PRICE,
  GET_FEATURE_PURCHASE_PRICE_SUCCESS,
  GET_FEATURE_UPGRADE_PRICE,
  GET_FEATURE_UPGRADE_PRICE_SUCCESS,
  GET_TECH_PURCHASE_PRICE,
  GET_TECH_PURCHASE_PRICE_SUCCESS,
  GET_TECH_UPGRADE_PRICE,
  GET_TECH_UPGRADE_PRICE_SUCCESS,
} from "./actionTypes"

const Initial_State = {
  unavailableFeature: [],
  totalOwnFeatureNumber: 0,
  loading: false,
  message: "",
  error: "",
  techUpgradePrice: {},
  techPurchasePrice: {},
  featureUpgradePrice: {},
  featurePurcahsePrice: {},
}

const featureData = (state = Initial_State, action) => {
  switch (action.type) {
    case GET_UNAVAILABLE_FEATURE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_UNAVAILABLE_FEATURE_SUCCESS:
      state = {
        ...state,
        loading: false,
        unavailableFeature: action.payload.data,
      }
      break
    case GET_TOTAL_OWN_FEATURE_NUMBER:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_TOTAL_OWN_FEATURE_NUMBER_SUCCESS:
      state = {
        ...state,
        loading: false,
        totalOwnFeatureNumber: action.payload.data,
      }
      break
    case GET_TECH_PURCHASE_PRICE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_TECH_PURCHASE_PRICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        techPurchasePrice: action.payload.data,
      }
      break
    case GET_TECH_UPGRADE_PRICE:
      state = {
        ...state,
        loading: true,
      }

    case GET_TECH_UPGRADE_PRICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        techUpgradePrice: action.payload.data,
      }
      break

    case GET_FEATURE_PURCHASE_PRICE:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_FEATURE_PURCHASE_PRICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        featurePurcahsePrice: action.payload.data,
      }

    case GET_FEATURE_UPGRADE_PRICE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_FEATURE_UPGRADE_PRICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        featureUpgradePrice: action.payload.data,
      }
      break

    default:
      state = state
      break
  }
  return state
}

export default featureData
