//ADD COURSE
export const ADD_COURSE = "ADD_COURSE"
export const ADD_COURSE_SUCCESS = "ADD_COURSE_SUCCESS"
export const ADD_COURSE_FAIL = "ADD_COURSE_FAIL"

//GET Course
export const GET_COURSE = "GET_COURSE"
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS"
export const GET_COURSE_FAIL = "GET_COURSE_FAIL"

//Get COURSE_FOR_ONE For Teacher/Student
export const GET_COURSE_FOR_ONE = "GET_COURSE_FOR_ONE"
export const GET_COURSE_FOR_ONE_SUCCESS = "GET_COURSE_FOR_ONE_SUCCESS"
export const GET_COURSE_FOR_ONE_FAIL = "GET_COURSE_FOR_ONE_FAIL"

// View Course Data
export const GET_COURSE_ALL_DATA = "GET_COURSE_ALL_DATA"
export const GET_COURSE_ALL_DATA_SUCCESS = "GET_COURSE_ALL_DATA_SUCCESS"

//delete couse
export const DELETE_COURSE = "DELETE_COURSE"
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS"
export const DELETE_COURSE_FAIL = "DELETE_COURSE_FAIL"
