export const GET_TEAM_SUBMITTED_RESULT = "GET_TEAM_SUBMITTED_RESULT"
export const GET_TEAM_SUBMITTED_RESULT_SUCCESS =
  "GET_TEAM_SUBMITTED_RESULT_SUCCESS"
export const GET_TEAM_SUBMITTED_RESULT_FAIL = "GET_TEAM_SUBMITTED_RESULT_FAIL"

export const EVUALATE_SUBMITTED_RESULT = "EVUALATE_SUBMITTED_RESULT"
export const EVUALATE_SUBMITTED_RESULT_SUCCESS =
  "EVUALATE_SUBMITTED_RESULT_SUCCESS"
export const EVUALATE_SUBMITTED_RESULT_FAIL = "EVUALATE_SUBMITTED_RESULT_FAIL"

export const GET_RESULT_SUMMARY = "GET_RESULT_SUMMARY"
export const GET_RESULT_SUMMARY_SUCCESS = "GET_RESULT_SUMMARY_SUCCESS"
export const GET_RESULT_SUMMARY_FAIL = "GET_RESULT_SUMMARY_FAIL"

export const GET_UNIVERSE_ID = "GET_UNIVERSE_ID"
export const GET_UNIVERSE_ID_SUCCESS = "GET_UNIVERSE_ID_SUCCESS"

export const SUBMIT_MARK_BY_TEACHER = "SUBMIT_MARK_BY_TEACHER"
export const SUBMIT_MARK_BY_TEACHER_SUCCESS = "SUBMIT_MARK_BY_TEACHER_SUCCESS"
export const SUBMIT_MARK_BY_TEACHER_FAIL = "SUBMIT_MARK_BY_TEACHER_FAIL"

export const SHOW_TEAM_MEMBER_NAME_WISE_DATA = "SHOW_TEAM_MEMBER_NAME_WISE_DATA"
export const SHOW_TEAM_MEMBER_NAME_WISE_DATA_SUCCESS =
  "SHOW_TEAM_MEMBER_NAME_WISE_DATA_SUCCESS"
export const SHOW_TEAM_MEMBER_NAME_WISE_DATA_FAIL =
  "SHOW_TEAM_MEMBER_NAME_WISE_DATA_FAIL"
