import React from "react"
import { Container, Row } from "react-bootstrap"
import CreateOutLook from "./CreateOutLook"
import EditOutlook from "./EditOutlook"
import OutlookData from "./OutlookData"
import RoundEmptyPage from "./RoundEmptyPage"
// import RoundEmptyPage from "./MarketOutloo/RoundEmptyPage"
import RoundHeader from "./RoundHeader"
const MarketOutlook = () => {
  return (
    <div className="fundamental-round-main-wrap">
      <Container>
        <RoundHeader />
        <Row>{/* <RoundEmptyPage /> */}</Row>

        <div className="fundamental-items-main-area outlook-main-item-create">
          <CreateOutLook />
          {/* <EditOutlook /> */}
        </div>

        <OutlookData />
      </Container>
    </div>
  )
}

export default MarketOutlook
