import {
  ADD_TEAM,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FAIL,
  GET_TEAMS,
  GET_TEAMS_SUCCESS,
  GET_SINGLE_TEAM,
  GET_SINGLE_TEAM_FAIL,
  GET_SINGLE_TEAM_SUCCESS,
  ADD_STUDENT_INTO_TEAM,
  ADD_STUDENT_INTO_TEAM_FAIL,
  ADD_STUDENT_INTO_TEAM_SUCCESS,
  REMOVE_STUDENT_FROM_TEAM,
  REMOVE_STUDENT_FROM_TEAM_SUCCESS,
  REMOVE_STUDENT_FROM_TEAM_FAIL,
  MAKE_CAPTAIN_FAIL,
  MAKE_CAPTAIN_SUCCESS,
  MAKE_CAPTAIN,
} from "./actionTypes"

const initialState = {
  teams: [],
  team: {},
  loading: false,
  message: "",
  error: "",
  loadPage: false,
}

const teamTeacher = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEAM:
      state = { ...state, loading: true }
      break
    case ADD_TEAM_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case ADD_TEAM_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case GET_TEAMS:
      state = { ...state, loading: true }
      break
    case GET_TEAMS_SUCCESS:
      state = { ...state, loading: false, teams: action.payload.teams }
      break
    case GET_SINGLE_TEAM:
      state = { ...state, loading: true }
      break
    case GET_SINGLE_TEAM_SUCCESS:
      state = { ...state, loading: false, team: action.payload.team }
      break
    case GET_SINGLE_TEAM_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case ADD_STUDENT_INTO_TEAM:
      state = { ...state, loading: true }
      break
    case ADD_STUDENT_INTO_TEAM_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case ADD_STUDENT_INTO_TEAM_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
    case REMOVE_STUDENT_FROM_TEAM:
      state = { ...state, loading: true }
      break
    case REMOVE_STUDENT_FROM_TEAM_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case REMOVE_STUDENT_FROM_TEAM_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case MAKE_CAPTAIN:
      state = {
        ...state,
        loading: true,
      }
      break
    case MAKE_CAPTAIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case MAKE_CAPTAIN_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
    default:
      state = state
      break
  }
  return state
}

export default teamTeacher
