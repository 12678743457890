import {
  GET_COURSE_FOR_TEACHER,
  GET_COURSE_FOR_TEACHER_SUCCESS,
  GET_SINGLE_COURSE,
  GET_SINGLE_COURSE_FAIL,
  GET_SINGLE_COURSE_SUCCESS,
} from "./actionTypes"

export const getCourseForTeacher = token => ({
  type: GET_COURSE_FOR_TEACHER,
  payload: token,
})

export const getCourseForTeacherSuccess = courses => ({
  type: GET_COURSE_FOR_TEACHER_SUCCESS,
  payload: { courses },
})

export const getSingleCourse = (courseId, token) => ({
  type: GET_SINGLE_COURSE,
  payload: { courseId, token },
})

export const getSingleCourseSuccess = course => ({
  type: GET_SINGLE_COURSE_SUCCESS,
  payload: { course },
})
export const getSingleCourseFail = message => ({
  type: GET_SINGLE_COURSE_FAIL,
  payload: { message },
})
