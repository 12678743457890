import { call, put, takeEvery } from "redux-saga/effects"

import {
  ADD_TOTAL_DEMAND_LAST_ROUND,
  GET_TOTAL_DEMAND_LAST_ROUND,
  INSERT_INITIAL_CAPITAL,
  GET_DEMAND_GRAPH,
  ADMIN_GET_TOTAL_DEMAND_LAST_ROUND,
} from "./actionTypes"
import {
  addTotalDemandLastRoundSuccess,
  addTotalDemandLastRoundFail,
  getTotalDemandLastRoundSuccess,
  insertInitialCapitalSuccess,
  insertInitialCapitalFail,
  getDemandGraphSuccess,
  adminGetTotalDemandLastRoundSuccess,
} from "./actions"
import { toast } from "react-toastify"
import {
  axiosInsertDemandData,
  axiosGetAllDemandData,
  axiosInsertInitialCapital,
  axiosGetDemandGraph,
  axiosAdminGetTotalDemandLastRound,
} from "../../helpers/api_helper"

const asyncAdminGetTotalDemandLastRound = async token => {
  try {
    const response = await axiosAdminGetTotalDemandLastRound(token)
    return response.data
  } catch (error) {}
}
function* workerAdminGetTotalDemandLastRound({ payload: { token } }) {
  try {
    const response = yield call(asyncAdminGetTotalDemandLastRound, token)
    yield put(adminGetTotalDemandLastRoundSuccess(response.getDatas))
  } catch (error) {}
}

const asyncGetDemandGraph = async (token, roundSerial, teamId, universeId) => {
  try {
    const response = await axiosGetDemandGraph(
      token,
      roundSerial,
      teamId,
      universeId
    )
    return response.data
  } catch (error) {}
}

function* workerGetDemandGraph({
  payload: { token, roundSerial, teamId, universeId },
}) {
  try {
    const response = yield call(
      asyncGetDemandGraph,
      token,
      roundSerial,
      teamId,
      universeId
    )
    // console.log("hello res 35 lkklj", response)
    yield put(getDemandGraphSuccess(response.obj))
  } catch (error) {}
}
//async insert Itial Capital
const asyncInsertInitialCapital = async (token, initialCapital) => {
  try {
    const response = await axiosInsertInitialCapital(token, initialCapital)
    // console.log("response data")
    return response.data
  } catch (error) {
    return error.response.data
  }
}
//insert initial Capital
function* workerInsertInitialCapital({ payload: { token, initialCaptial } }) {
  try {
    console.log("initial Capital data", initialCaptial, token)
    const response = yield call(
      asyncInsertInitialCapital,
      token,
      initialCaptial
    )
    toast.success(response.message)
    // console.log("response data", response)
  } catch (error) {
    toast.error(response.message)
  }
}

const asyncGetAllDemandData = async (
  token,
  roundSerial,
  teamId,
  universeId
) => {
  try {
    console.log("round serial d0", roundSerial)
    roundSerial = roundSerial - 1
    const response = await axiosGetAllDemandData(
      token,
      roundSerial,
      teamId,
      universeId
    )
    // console.log("response from 21", response.data)

    return response.data
  } catch (error) {
    // console.log("error response", error.response.data)
    return error.response.data
  }
}
function* workerGetTotalDemandLastRound({
  payload: { token, roundSerial, teamId, universeId },
}) {
  try {
    console.log("round serial 30 ", roundSerial, teamId, universeId)
    const response = yield call(
      asyncGetAllDemandData,
      token,
      roundSerial,
      teamId,
      universeId
    )
    // console.log("response from 30", response)
    yield put(getTotalDemandLastRoundSuccess(response.findData))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncAddTotalDemandLastRound = async (
  regionId,
  totalDemandLastRound,
  token
) => {
  try {
    const response = await axiosInsertDemandData(
      token,
      regionId,
      totalDemandLastRound
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerAddTotalDemandLastRound({
  payload: { regionId, totalDemandLastRound, token },
}) {
  try {
    const response = yield call(
      asyncAddTotalDemandLastRound,
      regionId,
      totalDemandLastRound,
      token
    )
    if (response.status == "success") {
      yield put(addTotalDemandLastRoundSuccess(response.message))
      toast.success(response.message)
      yield put(addTotalDemandLastRoundSuccess())
    } else {
      toast.error(response.message)
      yield put(addTotalDemandLastRoundFail(response.message))
      yield put(addTotalDemandLastRoundFail())
    }
  } catch (error) {}
}
function* adminDemandSaga() {
  yield takeEvery(ADD_TOTAL_DEMAND_LAST_ROUND, workerAddTotalDemandLastRound)
  yield takeEvery(GET_TOTAL_DEMAND_LAST_ROUND, workerGetTotalDemandLastRound)
  yield takeEvery(INSERT_INITIAL_CAPITAL, workerInsertInitialCapital)
  yield takeEvery(GET_DEMAND_GRAPH, workerGetDemandGraph)
  yield takeEvery(
    ADMIN_GET_TOTAL_DEMAND_LAST_ROUND,
    workerAdminGetTotalDemandLastRound
  )
}
export default adminDemandSaga
