export const GET_STUDENT_MARKET_SHARE_LAST_ROUND =
  "GET_STUDENT_MARKET_SHARE_LAST_ROUND"
export const GET_STUDENT_MARKET_SHARE_LAST_ROUND_SUCCESS =
  "GET_STUDENT_MARKET_SHARE_LAST_ROUND_SUCCESS"

// export const
export const INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE =
  "INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE"
export const INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE_SUCCESS =
  "INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE_SUCCESS"
export const INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE_FAIL =
  "INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE_FAIL"

//get all market share
export const GET_DEMAND_FULL_DATA = "GET_DEMAND_FULL_DATA"
export const GET_DEMAND_FULL_DATA_SUCCESS = "GET_DEMAND_FULL_DATA_SUCCESS"

//go to next section
export const GO_TO_PRODUCTION = "GO_TO_PRODUCTION"
export const GO_TO_PRODUCTION_SUCCESS = "GO_TO_PRODUCTION_SUCCESS"
export const GO_TO_PRODUCTION_FAIL = "GO_TO_PRODUCTION_FAIL"
