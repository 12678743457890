import { call, put, takeEvery } from "redux-saga/effects"

import { getDistributionDataFromStudentSuccess } from "./actions"
import { GET_DISTRIBUTION_DATA_FROM_STUDENT } from "./actionTypes"

import { axiosGetDistributionAndLogistic } from "../../helpers/api_helper"

const asyncGetDistributionData = async (token, roundSerial) => {
  try {
    const response = await axiosGetDistributionAndLogistic(token, roundSerial)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetDistributionData({ payload: { token, roundSerial } }) {
  try {
    const response = yield call(asyncGetDistributionData, token, roundSerial)
    console.log("response dta here ", response.getData)
    yield put(getDistributionDataFromStudentSuccess(response.getData))
  } catch (error) {}
}

function* distributionAndLogisticSaga() {
  yield takeEvery(GET_DISTRIBUTION_DATA_FROM_STUDENT, workerGetDistributionData)
}

export default distributionAndLogisticSaga
