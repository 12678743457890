import React from "react"
import emptyImage from "../img/Window No Data.png"
const RegionEmptyPage = () => {
  return (
    <div className="empty-teacher-data-page">
      <div>
        <img src={emptyImage} alt="" />
        <p>There’s no Teacher yet.</p>
      </div>
    </div>
  )
}

export default RegionEmptyPage
