import {
  ADD_OR_UPDATE_CAPACITY,
  ADD_OR_UPDATE_CAPACITY_FAIL,
  ADD_OR_UPDATE_CAPACITY_SUCCESS,
  GET_CAPACITY,
  GET_CAPACITY_SUCCESS,
  ADD_OR_UPDATE_INVENTORY,
  ADD_OR_UPDATE_INVENTORY_FAIL,
  ADD_OR_UPDATE_INVENTORY_SUCCESS,
  GET_INVENTORY,
  GET_INVENTORY_SUCCESS,
  GET_ONLY_PRODUCTION_REGION,
  GET_ONLY_PRODUCTION_REGION_SUCCESS,
  ADD_CAPACITY_WITH_TECH,
  ADD_CAPACITY_WITH_TECH_FAIL,
  ADD_CAPACITY_WITH_TECH_SUCCESS,
} from "./actionTypes"

export const addOrUpdateCapacity = (
  token,
  regionId,
  totalProductionCapacity,
  history
) => ({
  type: ADD_OR_UPDATE_CAPACITY,
  payload: {
    token,
    regionId,
    totalProductionCapacity,
    history,
  },
})

export const addOrUpdateCapacitySuccess = message => ({
  type: ADD_OR_UPDATE_CAPACITY_SUCCESS,
  payload: { message },
})

export const addOrUpdateCapacityFail = message => ({
  type: ADD_OR_UPDATE_CAPACITY_FAIL,
  payload: { message },
})

export const getCapacity = token => ({
  type: GET_CAPACITY,
  payload: { token },
})

export const getCapacitySuccess = data => ({
  type: GET_CAPACITY_SUCCESS,
  payload: { data },
})

export const addOrUpdateInventory = (
  token,
  techId,
  regionId,
  roundSerial,
  amount,
  history
) => ({
  type: ADD_OR_UPDATE_INVENTORY,
  payload: { token, techId, regionId, roundSerial, amount, history },
})

export const addOrUpdateInventorySuccess = message => ({
  type: ADD_OR_UPDATE_INVENTORY_SUCCESS,
  payload: { message },
})

export const addOrUpdateInventoryFail = message => ({
  type: ADD_OR_UPDATE_INVENTORY_FAIL,
  payload: { message },
})

export const getInventory = token => ({
  type: GET_INVENTORY,
  payload: { token },
})

export const getInventorySuccess = data => ({
  type: GET_INVENTORY_SUCCESS,
  payload: { data },
})

export const getOnlyProductionRegion = token => ({
  type: GET_ONLY_PRODUCTION_REGION,
  payload: { token },
})

export const getOnlyProductionRegionSuccess = data => ({
  type: GET_ONLY_PRODUCTION_REGION_SUCCESS,
  payload: { data },
})

export const addCapacityWithTech = (token, techId, capacityId) => ({
  type: ADD_CAPACITY_WITH_TECH,
  payload: { token, techId, capacityId },
})

export const addCapacityWIthTechSuccess = message => ({
  type: ADD_CAPACITY_WITH_TECH_SUCCESS,
  payload: { message },
})
export const addCapacityWithTechFail = message => ({
  type: ADD_CAPACITY_WITH_TECH_FAIL,
  payload: { message },
})
