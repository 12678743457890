import "bootstrap/dist/css/bootstrap.min.css"
import ReactDOM from "react-dom"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { BrowserRouter } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import App from "./App"
import "./i18n"
import "./index.scss"
import "./responsive.scss"
import * as serviceWorker from "./serviceWorker"

import { Provider } from "react-redux"

import { persistor, store } from "./store"

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
