import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { postFakeProfile } from "../../../helpers/backend_helper"
import {
  profileError,
  profileSuccess,
  getProfileSuccess,
  updateProfileSuccess,
  updateProfileFail,
  updatePasswordSuccess,
  updatePasswordFail,
} from "./actions"
// Login Redux States
import {
  EDIT_PROFILE,
  GET_PROFILE,
  UPDATE_PROFILE,
  UPDATE_PASS,
} from "./actionTypes"
import { toast } from "react-toastify"

import {
  axiosUpdateProfile,
  axiosGetUserProfile,
  axiosUpdatePas,
} from "../../../helpers/api_helper"

const asyncUpdatePass = async (
  token,
  currentPassword,
  password,
  confirmPassword
) => {
  try {
    const response = await axiosUpdatePas(
      token,
      currentPassword,
      password,
      confirmPassword
    )
    console.log("Hello 41 no line here", response)
    return response.data
  } catch (error) {
    console.log("error", error.response.data)
    return error.response.data
  }
}
function* workerUpdatePass({
  payload: { token, currentPassword, password, confirmPassword },
}) {
  try {
    console.log("ami ekhan theke run hoichi")
    const response = yield call(
      asyncUpdatePass,
      token,
      currentPassword,
      password,
      confirmPassword
    )

    if (response.status == "success") {
      yield put(updatePasswordSuccess(response.message))
      toast.success(response.message)
      yield put(updatePasswordSuccess())
    } else {
      yield put(updatePasswordFail(response.message))
      toast.error(response.message)
      yield put(updatePasswordFail())
    }
  } catch (error) {}
}

const asyncGetUserProfile = async token => {
  try {
    const response = await axiosGetUserProfile(token)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetProfile({ payload: { token } }) {
  try {
    const response = yield call(asyncGetUserProfile, token)
    // console.log("hello resposne", response.getProfile)
    yield put(getProfileSuccess(response.getProfile))
  } catch (error) {}
}

const asyncUpdateProfile = async (token, firstName, lastName, phoneNumber) => {
  try {
    const response = await axiosUpdateProfile(
      token,
      firstName,
      lastName,
      phoneNumber
    )
    return response.data
  } catch (error) {}
}
function* workerUpdateProfile({
  payload: { token, firstName, lastName, phoneNumber },
}) {
  try {
    const response = yield call(
      asyncUpdateProfile,
      token,
      firstName,
      lastName,
      phoneNumber
    )
    console.log("hello response data here", response)
    yield put(updateProfileSuccess(response.message))
    yield put(getProfileSuccess(response.data.UpdateUserProfileData))
    toast.success(response.message)
    yield put(updateProfileSuccess())
  } catch (error) {}
}

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeProfile, {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    }
  } catch (error) {
    yield put(profileError(error))
  }
}
function* profileSaga() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(GET_PROFILE, workerGetProfile)
  yield takeEvery(UPDATE_PROFILE, workerUpdateProfile)
  yield takeEvery(UPDATE_PASS, workerUpdatePass)
}

// function* ProfileSaga() {
//   yield all([fork(watchProfile)])
// }

export default profileSaga
