import { ADD_STUDENT, ADD_STUDENT_FAIL, ADD_STUDENT_SUCCESS, GET_STUDENT, GET_STUDENT_SUCCESS } from "./actionTypes"

export const addStudent = (values, history, token) => ({
    type: ADD_STUDENT,
    payload: { values, history, token }
})


export const addStudentSuccess = (message) => ({
    type: ADD_STUDENT_SUCCESS,
    payload: { message }
})


export const addStudentFail = (message) => ({
    type: ADD_STUDENT_FAIL,
    payload: { message }
})

export const getStudents = (token) => ({
    type: GET_STUDENT,
    payload: token
})

export const getStudentsSuccess = (students) => ({
    type: GET_STUDENT_SUCCESS,
    payload: { students }
})