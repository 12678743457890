

//ADD teacher
export const ADD_TEACHER = "ADD_TEACHER"
export const ADD_TEACHER_SUCCESS = "ADD_TEACHER_SUCCESS"
export const ADD_TEACHER_FAIL = "ADD_TEACHER_FAIL"

//Get Teacher

export const GET_TEACHER = "GET_TEACHER"
export const GET_TEACHER_SUCCESS ="GET_TEACHER_SUCCESS"