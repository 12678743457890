import footerLogo from "../../assets/image/main-logo.png"
import "./footer.css"
import footerLogo2 from "./img/footer-logo.svg"
const MainFooter = () => {
  return (
    <div className="footer-main-area">
      <div className="container">
        <div className="footer-inner-wrap">
          <div className="row">
            <div className="col-lg-4 col-md-12 ">
              <div className="footer-single-item">
                <div className="footer-single-item-title">
                  <img src={footerLogo} alt="" />
                </div>
                <div className="footer-single-item-content">
                  <p>
                    Our SAAS based{" "}
                    <span>Simulated learning and gaming experience</span> is a
                    crucial step towards meaningful, impact driven digital
                    education targeted towards university students.
                  </p>
                  <p className="footer-mobile-hide">© CalibraX 2022</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-6 display-none-responsive-device">
              <div className="footer-single-item footer-item-get-in-touch">
                <div className="footer-single-item-title">
                  <h5>Get in Touch</h5>
                </div>
                <div className="footer-single-item-content">
                  <span>
                    <span>
                      <i className="bx bx-map"></i>
                    </span>
                    <span>
                      House 15, Road 24, <br /> Gulshan - 2 Dhaka - 1212
                    </span>
                  </span>
                  <span>
                    <span>
                      <i className="bx bx-envelope"></i>
                    </span>
                    <span>solutionsmaac@gmail.com</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="footer-single-item">
                <div className="footer-single-item-title">
                  <h5>Want some insights?</h5>
                </div>
                <div className="footer-single-item-content">
                  <a
                    href="https://courseware.calibrax.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={footerLogo2} alt="" />
                  </a>

                  {/* <label>Your Email</label>
                <input type="email" placeholder="Enter Your Email" />
                <button>Subscribe</button> */}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="mobile-copy-right">
                <p>© CalibraX </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainFooter
