//get capacity by student *
export const GET_CAPACITY_BY_STUDENT = "GET_CAPACITY_BY_STUDENT"
export const GET_CAPACITY_BY_STUDENT_SUCCESS = "GET_CAPACITY_BY_STUDENT_SUCCESS"

//get production Requirement *
export const GET_PRODUCTION_REQUIREMENT = "GET_PRODUCTION_REQUIREMENT"
export const GET_PRODUCTION_REQUIREMENT_SUCCESS =
  "GET_PRODUCTION_REQUIREMENT_SUCCESS"
//get Capacity Manufacture *
export const GET_CAPACITY_MANUFACTURE = "GET_CAPACITY_MANUFACTURE"
export const GET_CAPACITY_MANUFACTURE_SUCCESS =
  "GET_CAPACITY_MANUFACTURE_SUCCESS"
export const GET_CAPACITY_MANUFACTURE_FAIL = "GET_CAPACITY_MANUFACTURE_FAIL"
//in-house manufacture
export const ADD_IN_HOUSE_MANUFACTURE = "ADD_IN_HOUSE_MANUFACTURE"
export const ADD_IN_HOUSE_MANUFACTURE_SUCCESS =
  "ADD_IN_HOUSE_MANUFACTURE_SUCCESS"
export const ADD_IN_HOUSE_MANUFACTURE_FAIL = "ADD_IN_HOUSE_MANUFACTURE_FAIL"

//get in hosue manufacture data
export const GET_IN_HOUSE_MANUFACTURE = "GET_IN_HOUSE_MANUFACTURE"
export const GET_IN_HOUSE_MANUFACTURE_SUCCESS =
  "GET_IN_HOUSE_MANUFACTURE_SUCCESS"
export const GET_IN_HOUSE_MANUFACTURE_FAIL = "GET_IN_HOUSE_MANUFACTURE_FAIL"
//get In houseManufacture Data last part
export const GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART =
  "GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART"
export const GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART_SUCCESS =
  "GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART_SUCCESS"

//contract manufacture
export const ADD_CONTRACT_MANUFACTURE = "ADD_CONTRACT_MANUFACTRE"
export const ADD_CONTRACT_MANUFACTURE_SUCCESS =
  "ADD_CONTRACT_MANUFACTURE_SUCCESS"
export const ADD_CONTRACT_MANUFACTURE_FAIL = "ADD_CONTRACT_MANUFACTURE_FAIL"

//get contract manufacture
export const GET_CONTRACT_MANUFACTURE_LAST_PART =
  "GET_CONTRACT_MANUFACTURE-LAST_PART"
export const GET_CONTRACT_MANUFACTURE_LAST_PART_SUCCESS =
  "GET_CONTRACT_MANUFACTURE_LAST_PART_SUCCESS"

//inventory
export const GET_STUDENT_INVENTORY = "GET_STUDENT_INVENTORY"
export const GET_STUDENT_INVENOTRY_SUCCESS = "GET_STUDENT_INVENTORY_SUCCESS"
export const GET_STUDENT_INVENTORY_FAIL = "GET_STUDENT_INVENTORY_FAIL"
