import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { Col, Container, Row } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { CardBody } from "reactstrap"
import { addRegionData, getRegions } from "store/actions"
import inputBackground from "../img/OBJECTS 1.png"
import RegionEmptyPage from "./RegionEmptyPage"
import RegionHeader from "./RegionHeader"
const FundamentalRegion = () => {
  const { token, loading, regionDatas } = useSelector(state => ({
    token: state.Login.token,
    loading: state.regionData.loading,
    regionDatas: state.regionData.regions,
  }))

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getRegions(token))
  }, [])

  const regionDataUpload = async e => {
    e.preventDefault()
    const XLSX = await import("xlsx")
    var files = e.target.files,
      f = files[0]
    var reader = new FileReader()
    reader.onload = function (e) {
      var data = e.target.result
      let readedData = XLSX.read(data, { type: "binary" })
      const wsname = readedData.SheetNames[0]
      const ws = readedData.Sheets[wsname]

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 2 })
      // setDatas(dataParse)
      console.log("all data here", dataParse)
      dispatch(addRegionData(dataParse, token, history))
    }
    reader.readAsBinaryString(f)
  }
  return (
    <div className="fundamental-round-main-wrap">
      <Container>
        <RegionHeader />

        <Row>
          <Col className="col-12">
            <div className="input-tech-data-area">
              <CardBody className="p-4 ">
                <div className="p-3">
                  <label for="myfile">
                    <img src={inputBackground} alt="" />
                  </label>
                  <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    id="myfile"
                    name="UploadTechdata"
                    onChange={regionDataUpload}
                  />
                </div>
              </CardBody>
            </div>
          </Col>
        </Row>

        {!regionDatas && (
          <Row>
            <RegionEmptyPage />
          </Row>
        )}
        <div className="fundamental-items-main-area">
          <Row>
            <Col lg={12}>
              <div className="single-course-data fundamental">
                {regionDatas?.map((v, i) => (
                  <div className="technology-data-single-item-area fundamental">
                    <div className="technology-data-single-item-area-left fundamental">
                      <p>Region </p>
                    </div>
                    <div className="technology-data-single-item-area-right fundamental">
                      <p>{v.regionName}</p>
                    </div>{" "}
                    <div className="technology-data-single-item-area-right-edit-btn fundamental">
                      <NavLink to="/admin-edit-technology">
                        <p>
                          <i className="bx bx-edit-alt"></i>
                        </p>
                      </NavLink>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default FundamentalRegion
