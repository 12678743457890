import { call, put, takeEvery } from "redux-saga/effects"

import {
  ADD_TEAM,
  GET_TEAMS,
  GET_SINGLE_TEAM,
  ADD_STUDENT_INTO_TEAM,
  REMOVE_STUDENT_FROM_TEAM,
  MAKE_CAPTAIN,
} from "./actionTypes"
import {
  addTeamSuccess,
  addTeamFail,
  getTeamsSuccess,
  getSingleTeamSuccess,
  getSingleTeamFail,
  addStudentSuccess,
  addStudentFail,
  removeStudentFromTeamSuccess,
  removeStudentFromTeamFail,
  makeCaptainSuccess,
  makeCaptainFail,
} from "./actions"
import {
  axiosAddTeam,
  axiosGetAllTeam,
  axiosGetSingleTeam,
  axiosAddStudent,
  axiosRemoveStudentFromTeam,
  axiosMakeCaptain,
  axiosGetSingleUniverse,
} from "../../helpers/api_helper"
import { toast } from "react-toastify"
import { getSingleUniverseSuccess } from "./../universeTeacher/actions"

const asyncMakeCaptain = async (token, teamId, userId) => {
  try {
    const response = await axiosMakeCaptain(token, teamId, userId)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

//make captain
function* workerMakeCaptain({
  payload: { token, teamId, universeId, userId },
}) {
  try {
    const response = yield call(asyncMakeCaptain, token, teamId, userId)
    if (response.status == "success") {
      toast.success(response.message)
      yield put(makeCaptainSuccess(response.message))
      const res = yield call(asyncGetTeams, universeId, token)
      yield put(getTeamsSuccess(res.getAllTeamsData))
      yield put(makeCaptainSuccess())
    } else {
      toast.error(response.message)
      yield put(makeCaptainFail(response.message))
      yield put(makeCaptainFail())
    }
  } catch (error) {}
}

//async remove studetn
const asyncRemoveStudentFromTeam = async (
  authtoken,
  universeId,
  teamId,
  studentId
) => {
  try {
    const response = await axiosRemoveStudentFromTeam(
      authtoken,
      universeId,
      teamId,
      studentId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
//remove student info from team and team info from student
function* workerRemoveStudentFromTeam({
  payload: { token, teamId, universeId, studentId },
}) {
  try {
    const response = yield call(
      asyncRemoveStudentFromTeam,
      token,
      universeId,
      teamId,
      studentId
    )

    if (response.status == "success") {
      yield put(removeStudentFromTeamSuccess(response.message))
      yield put(removeStudentFromTeamSuccess())
      const res = yield call(asyncGetTeams, universeId, token)

      yield put(getTeamsSuccess(res.getAllTeamsData))
      toast.success(response.messagse)
    } else {
      yield put(removeStudentFromTeamFail(response.message))
      toast.error(response.message)
    }
  } catch (error) {}
}

const asyncAddStudent = async (universeId, teamId, token, email) => {
  try {
    const response = await axiosAddStudent(universeId, teamId, email, token)
    console.log("response", response.data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
//worker add Student
function* workerAddStudent({ payload: { universeId, teamId, token, email } }) {
  try {
    const response = yield call(
      asyncAddStudent,
      universeId,
      teamId,
      token,
      email
    )
    if (response.status == "Success") {
      yield put(addStudentSuccess(response.message))
      const res = yield call(asyncGetTeams, universeId, token)

      yield put(getTeamsSuccess(res.getAllTeamsData))
      toast.success(response.message)
    } else {
      yield put(addStudentFail(response.message))
      toast.error(response.message)
    }
  } catch (error) {}
}

//async get Single team call axios
const asyncGetSingleTeam = async (universId, teamId, token) => {
  try {
    const response = await axiosGetSingleTeam(universId, teamId, token)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
//worker get singleteam to get one team's data
function* workerGetSingleTeam({ payload: { universeId, teamId, token } }) {
  console.log("worker data from line 42", universeId, teamId, token)
  try {
    const response = yield call(asyncGetSingleTeam, universeId, teamId, token)

    console.log("response data from 33", response.teamData)
    yield put(getSingleTeamSuccess(response.teamData))
  } catch (error) {}
}
//get sinigle universe
const asyncGetSingleUniverse = async (universeid, token) => {
  try {
    console.log("hello universe", universeid, token)
    const response = await axiosGetSingleUniverse(token, universeid)
    console.log("response data", response.data)
    return response.data
  } catch (error) {
    console.log("error data", error)
    return error.response.data
  }
}
//asycn createTeam pass values, token
const asyncCreateTeam = async (
  authtoken,
  universeId,
  teamName,
  studentLimit
) => {
  try {
    const response = await axiosAddTeam(
      authtoken,
      universeId,
      teamName,
      studentLimit
    )
    return response.data
  } catch (error) {
    console.log("error response", error.response.data)
    return error.response.data
  }
}

//worker create Team
function* workerCreateTeam({ payload: { values, token } }) {
  console.log("values data here", values, token)
  try {
    const { universeId, teamName, studentLimit } = values
    const response = yield call(
      asyncCreateTeam,
      token,
      universeId,
      teamName,
      studentLimit
    )
    if (response.status == "success") {
      yield put(addTeamSuccess(response.message))
      const res = yield call(asyncGetTeams, universeId, token)
      const resUniverse = yield call(asyncGetSingleUniverse, universeId, token)
      yield put(getTeamsSuccess(res.getAllTeamsData))
      yield put(getSingleUniverseSuccess(resUniverse.getSingleUniverseData))
      toast.success(response.message)
    } else {
      yield put(addTeamFail(response.message))

      toast.error(response.message)
    }
  } catch (error) {
    console.log("error data", error)
  }
}

//async get Teams
const asyncGetTeams = async (universeId, authtoken) => {
  try {
    const response = await axiosGetAllTeam(universeId, authtoken)
    console.log("response data from 21", response.data)
    return response.data
  } catch (error) {
    console.log("error response data", error.response.data)
    return error.response.data
  }
}

//get Teams
function* workerGetTeams({ payload: { universeId, token } }) {
  console.log("get teams data from 19", universeId, token)
  try {
    const response = yield call(asyncGetTeams, universeId, token)
    console.log("response data", response)

    yield put(getTeamsSuccess(response.getAllTeamsData))
  } catch (error) {
    console.log("error data", error)
  }
}
function* teamTeacherSaga() {
  yield takeEvery(GET_TEAMS, workerGetTeams)
  yield takeEvery(ADD_TEAM, workerCreateTeam)
  yield takeEvery(GET_SINGLE_TEAM, workerGetSingleTeam)
  yield takeEvery(ADD_STUDENT_INTO_TEAM, workerAddStudent)
  yield takeEvery(REMOVE_STUDENT_FROM_TEAM, workerRemoveStudentFromTeam)
  yield takeEvery(MAKE_CAPTAIN, workerMakeCaptain)
}

export default teamTeacherSaga
