export const GET_FINANCIAL_OUTCOME = "GET_FINANCIAL_OUTCOME"
export const GET_FINANCIAL_OUTCOME_SUCCESS = "GET_FINANCIAL_OUTCOME_SUCCESS"

export const SUBMIT_FINANCIAL_OUTCOME = "SUBMIT_FINANCIAL_OUTCOME"
export const SUBMIT_FINANCIAL_OUTCOME_SUCCESS =
  "SUBMIT_FINANCIAL_OUTCOME_SUCCESS"
export const SUBMIT_FINANCIAL_OUTCOME_FAIL = "SUBMIT_FINANCIAL_OUTCOME_FAIL"

export const GET_WHO_SUBMIT = "GET_WHO_SUBMIT"
export const GET_WHO_SUBMIT_SUCCESS = "GET_WHO_SUBMIT_SUCCESS"

export const GET_VERIFY_SUBMISSION = "GET_VERIFY_SUBMISSION"
export const GET_VERIFY_SUBMISSION_SUCCESS = "GET_VERIFY_SUBMISSION_SUCCESS"