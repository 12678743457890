export const ADD_MARKET_SHARE_LAST_ROUND = "ADD_MARKET_SHARE_LAST_ROUND"
export const ADD_MARKET_SHARE_LAST_ROUND_SUCCESS =
  "ADD_MARKET_SHARE_LAST_ROUND_SUCCESS"
export const ADD_MARKET_SHARE_LAST_ROUND_FAIL =
  "ADD_MARKET_SHARE_LAST_ROUND_FAIL"
export const GET_MARKET_SHARE_LAST_ROUND = "GET_MARKET_SHARE_LAST_ROUND"
export const GET_MARKET_SHARE_LAST_ROUND_SUCCESS =
  "GET_MARKET_SHARE_LAST_ROUND_SUCCESS"

export const ADMIN_GET_MARKET_SHARE = "ADMIN_GET_MARKET_SHARE"
export const ADMIN_GET_MARKET_SHARE_SUCCESS = "ADMIN_GET_MARKET_SHARE_SUCCESS"
