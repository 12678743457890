import {
  GET_TEAM_SUBMITTED_RESULT,
  GET_TEAM_SUBMITTED_RESULT_SUCCESS,
  EVUALATE_SUBMITTED_RESULT,
  EVUALATE_SUBMITTED_RESULT_FAIL,
  EVUALATE_SUBMITTED_RESULT_SUCCESS,
  GET_RESULT_SUMMARY,
  GET_RESULT_SUMMARY_FAIL,
  GET_RESULT_SUMMARY_SUCCESS,
  GET_UNIVERSE_ID,
  GET_UNIVERSE_ID_SUCCESS,
  SUBMIT_MARK_BY_TEACHER,
  SUBMIT_MARK_BY_TEACHER_FAIL,
  SUBMIT_MARK_BY_TEACHER_SUCCESS,
  SHOW_TEAM_MEMBER_NAME_WISE_DATA,
  SHOW_TEAM_MEMBER_NAME_WISE_DATA_SUCCESS,
} from "./actionTypes"

export const getTeamSubmitttedResult = (token, roundSerial) => {
  return {
    type: GET_TEAM_SUBMITTED_RESULT,
    payload: { token, roundSerial },
  }
}
export const getTeamSubmittedResultSuccess = data => {
  return {
    type: GET_TEAM_SUBMITTED_RESULT_SUCCESS,
    payload: { data },
  }
}

export const evualateSubmittedResult = (
  token,
  teamId,
  universeId,
  history,
  roundSerial
) => {
  return {
    type: EVUALATE_SUBMITTED_RESULT,
    payload: { token, teamId, universeId, history, roundSerial },
  }
}

export const evualateSubmittedResultSuccess = message => {
  return {
    type: EVUALATE_SUBMITTED_RESULT_SUCCESS,
    payload: { message },
  }
}

export const evualateSubmittedResultFail = message => {
  return {
    type: EVUALATE_SUBMITTED_RESULT_FAIL,
    payload: { message },
  }
}

export const getResultSummary = (token, roundSerial, universeId) => {
  return {
    type: GET_RESULT_SUMMARY,
    payload: { token, roundSerial, universeId },
  }
}

export const getResultSummarySuccess = data => {
  return {
    type: GET_RESULT_SUMMARY_SUCCESS,
    payload: { data },
  }
}

export const getUniverseId = token => {
  return {
    type: GET_UNIVERSE_ID,
    payload: { token },
  }
}

export const getUniverseIdSuccess = data => {
  return {
    type: GET_UNIVERSE_ID_SUCCESS,
    payload: { data },
  }
}

export const submitMarkByTeacher = (token, mark, teamId, roundSerial) => {
  return {
    type: SUBMIT_MARK_BY_TEACHER,
    payload: { token, mark, teamId, roundSerial },
  }
}

export const submitMarkByTeacherSuccess = message => {
  return {
    type: SUBMIT_MARK_BY_TEACHER_SUCCESS,
    payload: { message },
  }
}

export const submitMarkByTeacherFail = message => {
  return {
    type: SUBMIT_MARK_BY_TEACHER_FAIL,
    payload: { message },
  }
}

export const showTeamMemberNameWiseData = (token, teamId, roundSerial) => {
  return {
    type: SHOW_TEAM_MEMBER_NAME_WISE_DATA,
    payload: { token, teamId, roundSerial },
  }
}

export const showTeamMemberNameWiseDataSuccess = data => {
  return {
    type: SHOW_TEAM_MEMBER_NAME_WISE_DATA_SUCCESS,
    payload: {
      data,
    },
  }
}
