export const INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT =
  "INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT"

export const INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT_SUCCESS =
  "INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT_SUCCESS"

export const INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT_FAIL =
  "INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT_FAIL"

export const DEMAND_ROUND_VIDEO = "DEMAND_ROUND_VIDEO"
export const DEMAND_ROUND_VIDEO_SHOW = "DEMAND_ROUND_VIDEO_SHOW"

export const UPDATE_VIDEO_DATA = " UPDATE_VIDEO_DATA"
export const UPDATE_VIDEO_DATA_SUCCESS = "UPDATE_VIDEO_DATA_SUCCESS"

export const GET_DEMAND_ADMIN_LAST_ROUND_DATA =
  "GET_DEMAND_ADMIN_LAST_ROUND_DATA"
export const GET_DEMAND_ADMIN_LAST_ROUND_DATA_SUCCESS =
  "GET_DEMAND_ADMIN_LAST_ROUND_DATA_SUCCESS"
