import {
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL
} from "./actionTypes"

const initialState = {
    loading: false,
    error: "",
    message: ""
}


const resetPass = (state = initialState, action)=>{
    switch(action.type){
        case RESET_PASSWORD:
            state={...state, loading : true}
            break
        case RESET_PASSWORD_SUCCESS:
            state={...state, message : action.payload.message, loading : false}
            break
        case RESET_PASSWORD_FAIL:
            state={...state, error : action.payload.message, loading :false}
            break
        default:
            state = state
            break
    }
    return state
}

export default resetPass