import {
  GET_DISTRIBUTION_DATA_FROM_STUDENT,
  GET_DISTRIBUTION_DATA_FROM_STUDENT_SUCCESS,
} from "./actionTypes"

export const getDistributionDataFromStudent = (token, roundSerial) => ({
  type: GET_DISTRIBUTION_DATA_FROM_STUDENT,
  payload: { token, roundSerial },
})

export const getDistributionDataFromStudentSuccess = data => ({
  type: GET_DISTRIBUTION_DATA_FROM_STUDENT_SUCCESS,
  payload: { data },
})
