import React, { useState, useEffect } from "react"
import { Container, FormSelect } from "react-bootstrap"
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  Row,
  Form,
  Label,
  Table,
  Input,
  Button,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  getRegions,
  addMarketShareLastRound,
  adminGetMarketShare,
  getAvailableTechAdmin,
  postTotalDemand,
} from "store/actions"
import { MDBDataTable } from "mdbreact"
import addCircle from "../../assets/images/add-circle.png"
import closeCircle from "../../assets/images/close-circle.png"
import CustomInput from "common/CustomFilter/CustomInput"
import AddCardComponent from "common/Layout/AddCardComponent"
import { useFormik } from "formik"
import { addUserSchema } from "pages/Schemas/AddUser.schema"
import { getCourse } from "../../store/actions"
import Select from "react-select"
import { toaster } from "helpers/custom/Toast"
import { postInHouseProduction } from "store/ProductionCapacity/actions"
import { getProductStrategy, getPromotionStrategy, getWeightStrategy, postWeightStrategy, storeStrategyData, updateProductStrategyData, updatePromotionStrategyData } from "store/Strategy/actions"
const initialValues = {}
const roundList = [
  {
    value: 0,
    _id: "64923741097",
  },
  {
    value: 1,
    _id: "64923748903",
  },
  {
    value: 2,
    _id: "64923744571",
  },
  {
    value: 3,
    _id: "64923709021",
  },
  {
    value: 4,
    _id: "64923701239",
  },
  {
    value: 5,
    _id: "64923782392",
  },
  {
    value: 6,
    _id: "64923785315",
  },
  {
    value: 7,
    _id: "64923787642",
  },
]
const UpdatePromotionStrategy = ({ edit }) => {
  const [regionData, setRegionData] = useState({})
  const [initialCapital, setInitialCapital] = useState()
  const [marketData, setMarketData] = useState()
  const [techData, setTechData] = useState()
  const [regionInfo, setRegionInfo] = useState("")
  const [courseInfo, setCourseInfo] = useState("")
  const [techName, setTechName] = useState("")
  const [techID, setTechID] = useState("")
  const [roundInfo, setRoundInfo] = useState({
    roundNum: "",
    roundId: "",
    weightedData: "",
    techName: "",
    techID: "",
  })
  const [singleRound, setSingleRound] = useState([])
  const {
    token,
    loading,
    regions,
   
    techs,
    promotionStrategy,
    modifiedPromotionStrategy,
    promotionStrategyLoading,
    courses,
    adding
  } = useSelector(state => ({
    token: state.Login.token,
    loading: state.regionData.loading,
    regions: state.regionData.regions,
    message: state.adminDemandData.message,
    techs: state.techData.adminAvailableTech,
    promotionStrategy: state.StrategyReducer.promotionStrategy,
    modifiedPromotionStrategy: state.StrategyReducer.modifiedPromotionStrategy,
    promotionStrategyLoading: state.StrategyReducer.promotionStrategyLoading,
    adding: state.StrategyReducer.adding,
    courses: state.courseAdmin.courses,
  }))
  console.log("promotionStrategy", promotionStrategy)
  console.log("roundInfo", roundInfo)
  const history = useHistory()
  const dispatch = useDispatch()
  let value = {}
  value.roundSerial = 0
  useEffect(() => {
    dispatch(getRegions(token))
    dispatch(getAvailableTechAdmin(token))
  }, [])
  useEffect(() => {
    dispatch(adminGetMarketShare(token))
  }, [])

  useEffect(() => {
    dispatch(getCourse(token))
  }, [])
  

  const onSubmit = values => {
    console.log("values", values)
   
  }

  const AddUser = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addUserSchema,
    onSubmit,
  })




  const handleCourse = data => {
    console.log("data", data)
    if (data) {
      setCourseInfo(data?._id)
    } else {
      setCourseInfo("")
    }
  }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      }
    },
  }
  const handleRegion = data => {
    console.log("data", data)
    if (data) {
      setRegionInfo(data?._id)
    } else {
      setRegionInfo("")
    }
  }
  const handletech = data => {
    console.log("data", data)
    if (data) {
      setTechID(data?._id)
      setTechName(data?.techName)
    }
  }
  useEffect(() => {
    console.log("courseInfo", courseInfo, regionInfo, techID)
    if (courseInfo && regionInfo && techID) {
      dispatch(getPromotionStrategy(token, courseInfo, regionInfo, techID))
    }
  }, [courseInfo, regionInfo, techID])

  const handleTotalData = (e, idx, data) => {
    const oldValue = [...modifiedPromotionStrategy]
    console.log("oldValue", oldValue)

    oldValue[idx].data = e.target.value 
    dispatch(storeStrategyData("modifiedPromotionStrategy", oldValue))
  }
  const handleUpdateData = (key, data) =>{
    console.log('data', data);
    let obj ={
      roundSerial: data?.roundSerial,
      data:  data?.data *1,
     
    }
    console.log('obj', obj);
    dispatch(updatePromotionStrategyData(obj, history, token, promotionStrategy?.[0]?._id, data?._id, courseInfo, regionInfo, techID ))

  }

  return (
    <div>
      <Container>
        <Row className="mt-4">
          <Col md="9" className="mx-auto">
          <div className="text-end mb-3">
              <Button
                color="info"
                onClick={() => history.push("/strategy-promotion")}
              >
                Add promotion Strategy
              </Button>
            </div>
            {/* add User */}
            <AddCardComponent className="">
              <h5 className="mb-5">Update Promotion Strategy</h5>
              <Form
                className="needs-validation col-md-12"
                onSubmit={AddUser.handleSubmit}
              >
                <Row>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Courses</Label>
                    <Select
                      name="course"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Course"
                      cacheOptions
                      getOptionLabel={e => e.courseName}
                      getOptionValue={e => e.value}
                      isClearable
                      options={courses}
                      styles={colourStyles}
                      onChange={handleCourse}
                    />
                  </Col>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Region</Label>
                    <Select
                      name="course"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Region"
                      cacheOptions
                      getOptionLabel={e => e.regionName}
                      getOptionValue={e => e.value}
                      isClearable
                      options={regions}
                      styles={colourStyles}
                      onChange={handleRegion}
                    />
                  </Col>

                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Tech</Label>
                    <Select
                      name="tech"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Tech"
                      cacheOptions
                      getOptionLabel={e => e.techName}
                      getOptionValue={e => e.value}
                      options={techs}
                      isClearable
                      styles={colourStyles}
                      onChange={handletech}
                    />
                  </Col>
                </Row>
           
                <Row>
                  <Table className="table table-hover mb-0 table-centered  mb-5">
                    <thead className="table-light">
                      <tr>
                        <th>No.</th>
                        <th>Round No</th>
                      
                        <th>Total Demand</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {courseInfo !== "" &&
                      regionInfo !== "" &&
                      techID !== "" ? (
                        <>
                      {promotionStrategyLoading ? (
                        <tr>
                          <td colSpan="5">
                            <h6 className="text-center">Loading...</h6>
                          </td>
                        </tr>
                      ) : (
                        (modifiedPromotionStrategy || []).map((data, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>

                              <td>{data?.roundSerial}</td>
                             
                              <td><Input
                                  type="number"
                                  style={{
                                    width: "100px",
                                    height: "38px",
                                  }}
                                  value={data?.data}
                                  onChange={e =>
                                    handleTotalData(e, key, data)
                                  }
                                ></Input></td>

<td>
                                  <Button 
                                  color="warning" 
                                  outline
                                  disabled={adding}
                                   onClick={()=> handleUpdateData(key, data)}>
                                    {adding ? "Submitting" : "Update"}
                                  </Button>
                                </td>
                            </tr>
                          )
                        })
                      )}
                       </>
                      ) : (
                        <p className="text-center mt-5">Please Select all required fields</p>
                      )}
                    </tbody>
                  </Table>
                </Row>
               
              </Form>
            </AddCardComponent>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UpdatePromotionStrategy
