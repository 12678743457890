//Get marketing data
export const GET_MARKETING_PRIORITIES_DATA = "GET_MARKETING_PRIORITIES_DATA"
export const GET_MARKETING_PRIORITIES_DATA_SUCCESS =
  "GET_MARKETING_PRIORITIES_DATA_SUCCESS"

//till set price
export const ADD_OR_UPDATE_TILL_SET_PRICE = "ADD_OR_UPDATE_TILL_SET_PRICE"
export const ADD_OR_UPDATE_TILL_SET_PRICE_SUCCESS =
  "ADD_OR_UPDATE_TILL_SET_PRICE_SUCCESS"
export const ADD_OR_UPDATE_TILL_SET_PRICE_FAIL =
  "ADD_OR_UPDATE_TILL_SET_PRICE_FAIL"

//get till price
export const GET_TILL_PRICE = "GET_TILL_PRICE"
export const GET_TILL_PRICE_SUCCESS = "GET_TILL_PRICE_SUCCESS"
export const GET_TILL_PRICE_FAIL = "GET_TILL_PRICE_FAIL"

//get market data on tech(query)
export const GET_STUDENT_MARKET_DATA_ON_TECH = "GET_SUTDENT_MARKET_DATA_ON_TECH"
export const GET_STUDENT_MARKET_DATA_ON_TECH_SUCCESS =
  "GET_SUTDENT_MARKET_DATA_ON_TECH_SUCCESS"
export const GET_STUDENT_MARKET_DATA_ON_TECH_FAIL =
  "GET_SUTDENT_MARKET_DATA_ON_TECH_FAIL"

//set priorities for every region
export const SET_PRIORITIES_FOR_MARKETING = "SET_PRIORITIES_FOR_MARKETING"
export const SET_PRIORITIES_FOR_MARKETING_SUCCESS =
  "SET_PRIORITIES_FOR_MARKETING_SUCCESS"
export const SET_PRIORITIES_FOR_MARKETING_FAIL =
  "SET_PRIORITIES_FOR_MARKETING_FAIL"

// get taxion data
export const GET_TAXION_DATA = "GET_TAXION_DATA"
export const GET_TAXION_DATA_SUCCESS = "GET_TAXION_DATA_SUCCESS"
export const GET_TAXION_DATA_FAIL = "GET_TAXION_DATA_FAIL"

//Create or Update MarketData Last Part
export const CREATE_OR_UPDATE_MARKET_DATA_LAST_PART =
  "CREATE_OR_UPDATE_MARKET_DATA_LAST_PART"
export const CREATE_OR_UPDATE_MARKET_DATA_LAST_PART_SUCCESS =
  "CREATE_OR_UPDATE_MARKET_DATA_LAST_PART_SUCCESS"
export const CREATE_OR_UPDATE_MARKET_DATA_LAST_PART_FAIL =
  "CREATE_OR_UPDATE_MARKET_DATA_LAST_PART_FAIL"

//Get marketing regions data
export const GET_MARKETING_REGIONS_IN_INCOME_STATEMENT =
  "GET_MARKETING_REGIONS_IN_INCOME_STATEMENT"

export const GET_MARKETING_REGIONS_IN_INCOME_STATEMENT_SUCCESS =
  "GET_MARKETING_REGIONS_IN_INCOME_STATEMENT_SUCCESS"

export const GO_TO_DECISION_CHECKLIST_PAGE = "GO_TO_DECISION_CHECKLIST_PAGE"
export const GO_TO_DECISION_CHECKLIST_PAGE_SUCCESS =
  "GO_TO_DECISION_CHECKLIST_PAGE_SUCCESS"
//get marketing priorities index
export const GET_MARKETING_PRIORITIES_INDEX = "GET_MARKETING_PRIORITIES_INDEX"
export const GET_MARKETING_PRIORITIES_INDEX_SUCCESS =
  "GET_MARKETING_PRIORITIES_INDEX_SUCCESS"
export const GET_MARKETING_PRIORITIES_INDEX_FAIL =
  "GET_MARKETING_PRIORITIES_INDEX_FAIL"

export const GO_TO_INCOME_STATEMENT = "GO_TO_INCOME_STATEMENT"
export const GO_TO_INCOME_STATEMENT_SUCCESS = "GO_TO_INCOME_STATEMENT_SUCCESS"

//disable tech button
export const DISABLE_TECH_BUTTON = "DISABLE_TECH_BUTTON"
export const DISABLE_TECH_BUTTON_SUCCESS = "DISABLE_TECH_BUTTON_SUCCESS"

//get marketshare of each
export const GET_MARKET_SHARE_OF_EACH = "GET_MARKET_SHARE_OF_EACH"
export const GET_MARKET_SHARE_OF_EACH_SUCCESS =
  "GET_MARKET_SHARE_OF_EACH_SUCCESS"
  // Get Pricing matrix
  export const GET_PRICING_MATRIX = "GET_PRICING_MATRIX"
  export const GET_PRICING_MATRIX_SUCCESS ="GET_PRICING_MATRIX_SUCCESS"
//SET PRIORITIES UPDATE
export const NEW_SET_PRIORITIES_FOR_MARKETING =
  "NEW_SET_PRIORITIES_FOR_MARKETING"
export const NEW_SET_PRIORITIES_FOR_MARKETING_SUCCESS =
  "NEW_SET_PRIORITIES_FOR_MARKETING_SUCCESS"
export const NEW_SET_PRIORITIES_FOR_MARKETING_FAIL =
  "NEW_SET_PRIORITIES_FOR_MARKETING_FAIL"

//GET_PRIORITIES_DATA
export const GET_PRIORITIES_PERCENTAGE_DATA = "GET_PRIORITIES_PERCENTAGE_DATA"
export const GET_PRIORITIES_PERCENTAGE_DATA_SUCCESS =
  "GET_PRIORITIES_PERCENTAGE_DATA_SUCCESS"
