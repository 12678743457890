import { GET_MARKET_SHARE, GET_MARKET_SHARE_FAIL, GET_MARKET_SHARE_SUCCESS, GET_TOTAL_DEMAND, GET_TOTAL_DEMAND_FAIL, GET_TOTAL_DEMAND_SUCCESS, POST_MARKET_SHARE, POST_MARKET_SHARE_FAIL, POST_MARKET_SHARE_SUCCESS, POST_TOTAL_DEMAND, POST_TOTAL_DEMAND_FAIL, POST_TOTAL_DEMAND_SUCCESS, STORE_DATA, UPDATE_MARKET_SHARE, UPDATE_MARKET_SHARE_FAIL, UPDATE_MARKET_SHARE_SUCCESS, UPDATE_TOTAL_DEMAND, UPDATE_TOTAL_DEMAND_FAIL, UPDATE_TOTAL_DEMAND_SUCCESS } from "./actionTypes";

export const postTotalDemand = (values, history, token) => {
    return {
      type: POST_TOTAL_DEMAND,
      payload: { values, history, token},
    };
  };
  export const postTotalDemandSuccess = (message) => {
    return {
      type: POST_TOTAL_DEMAND_SUCCESS,
      payload: { message },
    };
  };
  
  export const postTotalDemandFail = (message) => {
    return {
      type: POST_TOTAL_DEMAND_FAIL,
      payload: { message },
    };
  };

  export const postMarketShare = (values, history, token) => {
    return {
      type: POST_MARKET_SHARE,
      payload: { values, history, token},
    };
  };
  export const postMarketShareSuccess = (message) => {
    return {
      type: POST_MARKET_SHARE_SUCCESS,
      payload: { message },
    };
  };
  
  export const postMarketShareFail = (message) => {
    return {
      type: POST_MARKET_SHARE_FAIL,
      payload: { message },
    };
  };

  export const updateMarketShare = (values, history, token, courseInfo, regionInfo) => {
    return {
      type: UPDATE_MARKET_SHARE,
      payload: { values, history, token, courseInfo, regionInfo},
    };
  };
  export const updateMarketShareSuccess = (message) => {
    return {
      type: UPDATE_MARKET_SHARE_SUCCESS,
      payload: { message },
    };
  };
  
  export const updateMarketShareFail = (message) => {
    return {
      type: UPDATE_MARKET_SHARE_FAIL,
      payload: { message },
    };
  };

  export const updateTotalDemand = (values, history, token, courseInfo, regionInfo, groupID, singleId) => {
    return {
      type: UPDATE_TOTAL_DEMAND,
      payload: { values, history, token, courseInfo, regionInfo, groupID, singleId},
    };
  };
  export const updateTotalDemandSuccess = (message) => {
    return {
      type: UPDATE_TOTAL_DEMAND_SUCCESS,
      payload: { message },
    };
  };
  
  export const updateTotalDemandFail = (message) => {
    return {
      type: UPDATE_TOTAL_DEMAND_FAIL,
      payload: { message },
    };
  };
  export const getDemandData = (token, course, region) => {
    return {
      type: GET_TOTAL_DEMAND,
      payload: { token, course, region },
    };
  };
  
  export const getDemandDataSuccess = (values) => {
    return {
      type: GET_TOTAL_DEMAND_SUCCESS,
      payload: { values },
    };
  };
  
  export const getDemandDataFail = (message) => {
    return {
      type: GET_TOTAL_DEMAND_FAIL,
      payload: { message },
    };
  };
  export const getMarketShare = (token, course, region) => {
    return {
      type: GET_MARKET_SHARE,
      payload: { token, course, region },
    };
  };
  
  export const getMarketShareSuccess = (values) => {
    return {
      type: GET_MARKET_SHARE_SUCCESS,
      payload: { values },
    };
  };
  
  export const getMarketShareFail = (message) => {
    return {
      type: GET_MARKET_SHARE_FAIL,
      payload: { message },
    };
  };

  export const storeData = (name, data) => ({
    type: STORE_DATA,
    payload: { name, data },
  });