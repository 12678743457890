import { GET_PRICE_STRATEGY, GET_PRICE_STRATEGY_FAIL, GET_PRICE_STRATEGY_SUCCESS, GET_PRODUCT_STRATEGY, GET_PRODUCT_STRATEGY_FAIL, GET_PRODUCT_STRATEGY_SUCCESS, GET_PROMOTION_STRATEGY, GET_PROMOTION_STRATEGY_FAIL, GET_PROMOTION_STRATEGY_SUCCESS, GET_WEIGHT_STRATEGY, GET_WEIGHT_STRATEGY_FAIL, GET_WEIGHT_STRATEGY_SUCCESS, POST_PRICE_STRATEGY, POST_PRICE_STRATEGY_FAIL, POST_PRICE_STRATEGY_SUCCESS, POST_PRODUCT_STRATEGY, POST_PRODUCT_STRATEGY_FAIL, POST_PRODUCT_STRATEGY_SUCCESS, POST_PROMOTION_STRATEGY, POST_PROMOTION_STRATEGY_FAIL, POST_PROMOTION_STRATEGY_SUCCESS, POST_WEIGHT_STRATEGY, POST_WEIGHT_STRATEGY_FAIL, POST_WEIGHT_STRATEGY_SUCCESS, STORE_STRATEGY_DATA, UPDATE_PRICE_STRATEGY, UPDATE_PRICE_STRATEGY_FAIL, UPDATE_PRICE_STRATEGY_SUCCESS, UPDATE_PRODUCT_STRATEGY, UPDATE_PRODUCT_STRATEGY_FAIL, UPDATE_PRODUCT_STRATEGY_SUCCESS, UPDATE_PROMOTION_STRATEGY, UPDATE_PROMOTION_STRATEGY_FAIL, UPDATE_PROMOTION_STRATEGY_SUCCESS, UPDATE_WEIGHT_STRATEGY, UPDATE_WEIGHT_STRATEGY_FAIL, UPDATE_WEIGHT_STRATEGY_SUCCESS } from "./actionTypes";

export const postWeightStrategy = (values, history, token) => {
    return {
      type: POST_WEIGHT_STRATEGY,
      payload: { values, history, token},
    };
  };
  export const postWeightStrategySuccess = (message) => {
    return {
      type: POST_WEIGHT_STRATEGY_SUCCESS,
      payload: { message },
    };
  };
  
  export const postWeightStrategyFail = (message) => {
    return {
      type: POST_WEIGHT_STRATEGY_FAIL,
      payload: { message },
    };
  };



  export const postProductStrategy = (values, history, token) => {
    return {
      type: POST_PRODUCT_STRATEGY,
      payload: { values, history, token},
    };
  };
  export const postProductStrategySuccess = (message) => {
    return {
      type: POST_PRODUCT_STRATEGY_SUCCESS,
      payload: { message },
    };
  };
  
  export const postProductStrategyFail = (message) => {
    return {
      type: POST_PRODUCT_STRATEGY_FAIL,
      payload: { message },
    };
  };

  export const postPromotionStrategy = (values, history, token) => {
    return {
      type: POST_PROMOTION_STRATEGY,
      payload: { values, history, token},
    };
  };
  export const postPromotionStrategySuccess = (message) => {
    return {
      type: POST_PROMOTION_STRATEGY_SUCCESS,
      payload: { message },
    };
  };
  
  export const postPromotionStrategyFail = (message) => {
    return {
      type: POST_PROMOTION_STRATEGY_FAIL,
      payload: { message },
    };
  };

  export const postPriceStrategy = (values, history, token) => {
    return {
      type: POST_PRICE_STRATEGY,
      payload: { values, history, token},
    };
  };
  export const postPriceStrategySuccess = (message) => {
    return {
      type: POST_PRICE_STRATEGY_SUCCESS,
      payload: { message },
    };
  };
  
  export const postPriceStrategyFail = (message) => {
    return {
      type: POST_PRICE_STRATEGY_FAIL,
      payload: { message },
    };
  };

  export const getWeightStrategy = (token, course, region, techID) => {
    return {
      type: GET_WEIGHT_STRATEGY,
      payload: { token, course, region, techID },
    };
  };
  
  export const getWeightStrategySuccess = (values) => {
    return {
      type: GET_WEIGHT_STRATEGY_SUCCESS,
      payload: { values },
    };
  };
  
  export const getWeightStrategyFail = (message) => {
    return {
      type: GET_WEIGHT_STRATEGY_FAIL,
      payload: { message },
    };
  };
  
  export const getProductStrategy = (token, course, region, techID) => {
    return {
      type: GET_PRODUCT_STRATEGY,
      payload: { token, course, region, techID },
    };
  };
  
  export const getProductStrategySuccess = (values) => {
    return {
      type: GET_PRODUCT_STRATEGY_SUCCESS,
      payload: { values },
    };
  };
  
  export const getProductStrategyFail = (message) => {
    return {
      type: GET_PRODUCT_STRATEGY_FAIL,
      payload: { message },
    };
  };

  export const getPromotionStrategy = (token, course, region, techID) => {
    return {
      type: GET_PROMOTION_STRATEGY,
      payload: { token, course, region, techID },
    };
  };
  
  export const getPromotionStrategySuccess = (values) => {
    return {
      type: GET_PROMOTION_STRATEGY_SUCCESS,
      payload: { values },
    };
  };
  
  export const getPromotionStrategyFail = (message) => {
    return {
      type: GET_PROMOTION_STRATEGY_FAIL,
      payload: { message },
    };
  };

  export const getPriceStrategy = (token, course, region, techID) => {
    return {
      type: GET_PRICE_STRATEGY,
      payload: { token, course, region, techID },
    };
  };
  
  export const getPriceStrategySuccess = (values) => {
    return {
      type: GET_PRICE_STRATEGY_SUCCESS,
      payload: { values },
    };
  };
  
  export const getPriceStrategyFail = (message) => {
    return {
      type: GET_PRICE_STRATEGY_FAIL,
      payload: { message },
    };
  };

  export const updateWeightStrategy = (values, history, token, groupId, singleId, courseInfo, regionInfo, techID) => {
    return {
      type: UPDATE_WEIGHT_STRATEGY,
      payload: { values, history, token, groupId, singleId, courseInfo, regionInfo, techID},
    };
  };
  export const updateWeightStrategySuccess = (message) => {
    return {
      type: UPDATE_WEIGHT_STRATEGY_SUCCESS,
      payload: { message },
    };
  };
  
  export const updateWeightStrategyFail = (message) => {
    return {
      type: UPDATE_WEIGHT_STRATEGY_FAIL,
      payload: { message },
    };
  };

  export const updatePriceStrategyData = (values, history, token, groupId, singleId, courseInfo, regionInfo, techID) => {
    return {
      type: UPDATE_PRICE_STRATEGY,
      payload: { values, history, token, groupId, singleId, courseInfo, regionInfo, techID},
    };
  };
  export const updatePriceStrategySuccess = (message) => {
    return {
      type: UPDATE_PRICE_STRATEGY_SUCCESS,
      payload: { message },
    };
  };
  
  export const updatePriceStrategyFail = (message) => {
    return {
      type: UPDATE_PRICE_STRATEGY_FAIL,
      payload: { message },
    };
  };

  export const updatePromotionStrategyData = (values, history, token, groupId, singleId, courseInfo, regionInfo, techID) => {
    return {
      type: UPDATE_PROMOTION_STRATEGY,
      payload: { values, history, token, groupId, singleId, courseInfo, regionInfo, techID},
    };
  };
  export const updatePromotionStrategySuccess = (message) => {
    return {
      type: UPDATE_PROMOTION_STRATEGY_SUCCESS,
      payload: { message },
    };
  };
  
  export const updatePromotionStrategyFail = (message) => {
    return {
      type: UPDATE_PROMOTION_STRATEGY_FAIL,
      payload: { message },
    };
  };
  export const updateProductStrategyData = (values, history, token, groupId, singleId, courseInfo, regionInfo, techID) => {
    return {
      type: UPDATE_PRODUCT_STRATEGY,
      payload: { values, history, token, groupId, singleId, courseInfo, regionInfo, techID},
    };
  };
  export const updateProductStrategySuccess = (message) => {
    return {
      type: UPDATE_PRODUCT_STRATEGY_SUCCESS,
      payload: { message },
    };
  };
  
  export const updateProductStrategyFail = (message) => {
    return {
      type: UPDATE_PRODUCT_STRATEGY_FAIL,
      payload: { message },
    };
  };

  export const storeStrategyData = (name, data) => ({
    type: STORE_STRATEGY_DATA,
    payload: { name, data },
  });