import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_REGION_FROM_FILE,
  GET_REGIONS,
  GET_PRODUCTION_REGIONS,
  GET_PLANT_PRICE_REGIONS,
  GET_ONLY_PRODUCTION_REGION_DATA,
} from "./actionTypes"

import {
  addRegionDataSuccess,
  addRegionDataFail,
  getRegionsSuccess,
  getProductionRegionsSuccess,
  getPlantPRiceRegionsSuccess,
  getOnlyProductionRegionSuccesss,
} from "./actions"
import {
  axiosGetAllRegionsData,
  axiosRegionDataCreateByFIle,
  axiosGetProductionRegion,
  axiosGetRegionsData,
  axiosGetPlantSizeData,
  axiosGetOnlyProductionRegionData,
} from "../../helpers/api_helper"
import { toast } from "react-toastify"

const asyncGetProductionRegions = async (token, roundSerial) => {
  try {
    const response = await axiosGetProductionRegion(token, roundSerial)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetProductionRegions({ payload: { token, roundSerial } }) {
  try {
    console.log("hello from 32", token)
    const response = yield call(asyncGetProductionRegions, token, roundSerial)
    yield put(getProductionRegionsSuccess(response.getRegionData))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncGetOnlyProductionRegions = async () => {
  try {
    console.log(" 49 49")
    const response = await axiosGetOnlyProductionRegionData()
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetOnlyProductionRegion() {
  try {
    const response = yield call(asyncGetOnlyProductionRegions)
    yield put(getOnlyProductionRegionSuccesss(response.getRegionData))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncGetRegions = async token => {
  try {
    const response = await axiosGetRegionsData(token)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetRegions({ payload: { token } }) {
  try {
    const region = yield call(asyncGetRegions, token)
    yield put(getRegionsSuccess(region.getDatas))
  } catch (error) {
    console.log("regions data")
  }
}

const asyncAddRegionFromFile = async (value, token) => {
  try {
    const response = await axiosRegionDataCreateByFIle(value, token)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.log("error data", error.response.data)
  }
}
function* workerAddRegionDataFromFile({ payload: { value, token, history } }) {
  console.log("region data", value, token)
  try {
    const response = yield call(asyncAddRegionFromFile, value, token)
    console.log("response data from 20", response.message)
    if (response.status == "success") {
      yield put(addRegionDataSuccess(response.message))
      toast.success(response.message)
      history.go(0)
      yield put(addRegionDataSuccess())
    } else {
      yield put(addRegionDataFail(response.message))
      toast.error(response.message)
      yield put(addRegionDataFail())
    }
  } catch (error) {
    console.log("hello error ", error)
  }
}

const asyncGetPlantPrice = async (authtoken, universeId) => {
  try {
    const response = await axiosGetPlantSizeData(authtoken, universeId)
    console.log("response data", response)
    return response.data
  } catch (error) {
    return response.error.data
  }
}
function* workerGetPlantPriceRegion({ payload: { token, universeId } }) {
  try {
    const response = yield call(asyncGetPlantPrice, token, universeId)
    yield put(getPlantPRiceRegionsSuccess(response.getData))
  } catch (error) {
    console.log("error data", error)
  }
}
function* regionDataSaga() {
  yield takeEvery(ADD_REGION_FROM_FILE, workerAddRegionDataFromFile)
  yield takeEvery(GET_REGIONS, workerGetRegions)
  yield takeEvery(GET_PRODUCTION_REGIONS, workerGetProductionRegions)
  yield takeEvery(GET_PLANT_PRICE_REGIONS, workerGetPlantPriceRegion)
  yield takeEvery(
    GET_ONLY_PRODUCTION_REGION_DATA,
    workerGetOnlyProductionRegion
  )
}

export default regionDataSaga
