import {
  FEATURE_REMOVE_FROM_LIST,
  FEATURE_REMOVE_FROM_LIST_SUCCESS,
  GET_FEATURES_FOR_R_AND_D,
  GET_FEATURES_FOR_R_AND_D_SUCCESS,
  GET_FEATURE_ALREADY_IN_UPGRADE,
  GET_FEATURE_ALREADY_IN_UPGRADE_SUCCESS,
  GET_FEATURE_ENABLE,
  GET_FEATURE_ENABLE_SUCCESS,
  GET_IS_TECH_ENABLE,
  GET_IS_TECH_ENABLE_SUCCESS,
  GET_LATEST_TECH,
  GET_LATEST_TECH_SUCCESS,
  GET_PURCHASED_FEATURE,
  GET_PURCHASED_FEATURE_SUCCESS,
  PURCHASE_FEATURE,
  PURCHASE_FEATURE_BY_STUDENT,
  PURCHASE_FEATURE_BY_STUDENT_FAIL,
  PURCHASE_FEATURE_BY_STUDENT_SUCCESS,
  PURCHASE_FEATURE_FAIL,
  PURCHASE_FEATURE_SUCCESS,
  PURCHASE_TECH,
  PURCHASE_TECH_FAIL,
  PURCHASE_TECH_SUCCESS,
  UPGRADE_FEATURE,
  UPGRADE_FEATURE_BY_STUDENT,
  UPGRADE_FEATURE_BY_STUDENT_FAIL,
  UPGRADE_FEATURE_BY_STUDENT_SUCCESS,
  UPGRADE_FEATURE_FAIL,
  UPGRADE_FEATURE_SUCCESS,
  UPGRADE_TECH,
  UPGRADE_TECH_FAIL,
  UPGRADE_TECH_SUCCESS,
} from "./actionTypes"
//create all the obj
export const getFeatureForRandD = token => ({
  type: GET_FEATURES_FOR_R_AND_D,
  payload: { token },
})
export const getFeatureForRandDSuccess = data => ({
  type: GET_FEATURES_FOR_R_AND_D_SUCCESS,
  payload: { data },
})
//upgrade feature studetn pass roundActive feature._id cost token
export const upgradeFeatureByStudent = (
  token,
  roundActive,
  featureId,
  cost,
  roundSerial
) => ({
  type: UPGRADE_FEATURE_BY_STUDENT,
  payload: { token, roundActive, featureId, cost, roundSerial },
})
export const upgradeFeatureByStudentSuccess = message => ({
  type: UPGRADE_FEATURE_BY_STUDENT_SUCCESS,
  payload: { message },
})
export const upgradeFeatureByStudentFail = message => ({
  type: UPGRADE_FEATURE_BY_STUDENT_FAIL,
  payload: { message },
})
export const getFeatureAlreadyInUpgrade = token => ({
  type: GET_FEATURE_ALREADY_IN_UPGRADE,
  payload: { token },
})
export const getFeatureAlreadyInUpgradeSuccess = data => ({
  type: GET_FEATURE_ALREADY_IN_UPGRADE_SUCCESS,
  payload: { data },
})
export const purchaseFeatureByStudent = (
  token,
  roundActive,
  featureId,
  cost,
  roundSerial
) => ({
  type: PURCHASE_FEATURE_BY_STUDENT,
  payload: { token, roundActive, featureId, cost, roundSerial },
})
export const purchaseFeatureByStudentSuccess = message => ({
  type: PURCHASE_FEATURE_BY_STUDENT_SUCCESS,
  payload: { message },
})
export const purchaseFeatureByStudentFail = message => ({
  type: PURCHASE_FEATURE_BY_STUDENT_FAIL,
  payload: { message },
})
export const getPurchasedFeature = token => ({
  type: GET_PURCHASED_FEATURE,
  payload: { token },
})
export const getPurchasedFeatureSuccess = data => ({
  type: GET_PURCHASED_FEATURE_SUCCESS,
  payload: { data },
})
export const getLatestTech = (token, teamId) => ({
  type: GET_LATEST_TECH,
  payload: { token, teamId },
})
export const getLatestTechSuccess = data => ({
  type: GET_LATEST_TECH_SUCCESS,
  payload: { data },
})
export const getIstechEnable = (
  token,
  teamId,
  techName,
  techId,
  universeId
) => ({
  type: GET_IS_TECH_ENABLE,
  payload: { token, teamId, techName, techId, universeId },
})
export const getIsTechEnableSuccess = data => ({
  type: GET_IS_TECH_ENABLE_SUCCESS,
  payload: { data },
})
export const upgradeTech = (
  token,
  techId,
  activeRound,
  techRealPrice,
  roundSerial,
  teamId,
  universeId,
  setUpGrdBtn
) => ({
  type: UPGRADE_TECH,
  payload: {
    token,
    techId,
    activeRound,
    techRealPrice,
    roundSerial,
    teamId,
    universeId,
    setUpGrdBtn
  },
})
export const upgradeTechSuccess = message => ({
  type: UPGRADE_TECH_SUCCESS,
  payload: { message },
})
export const upgradeTechFail = message => ({
  type: UPGRADE_TECH_FAIL,
  payload: { message },
})
export const purchaseTech = (
  token,
  techId,
  activeRound,
  techRealPrice,
  roundSerial,
  teamId,
  universeId,
  setPurchaseBtn
) => ({
  type: PURCHASE_TECH,
  payload: {
    token,
    techId,
    activeRound,
    techRealPrice,
    roundSerial,
    teamId,
    universeId,
    setPurchaseBtn
  },
})
export const purchaseTechSuccess = message => ({
  type: PURCHASE_TECH_SUCCESS,
  payload: { message },
})
export const purchaseTechFail = message => ({
  type: PURCHASE_TECH_FAIL,
  payload: { message },
})
export const getFeatureEnable = (token, teamId, roundSerial, universeId) => ({
  type: GET_FEATURE_ENABLE,
  payload: { token, teamId, roundSerial, universeId },
})
export const getFeatureEnableSuccess = (data, check) => ({
  type: GET_FEATURE_ENABLE_SUCCESS,
  payload: { data, check },
})
export const upgradeFeature = (
  token,
  featureId,
  activeRound,
  featureRealPrice,
  roundSerial,
  universeId,
  setUpgradeBtn
) => ({
  type: UPGRADE_FEATURE,
  payload: {
    token,
    featureId,
    activeRound,
    featureRealPrice,
    roundSerial,
    universeId,
    setUpgradeBtn
  },
})
export const upgradeFeatureSuccess = message => ({
  type: UPGRADE_FEATURE_SUCCESS,
  payload: { message },
})
export const upgradeFeatureFail = message => ({
  type: UPGRADE_FEATURE_FAIL,
  payload: { message },
})
export const purchaseFeature = (
  token,
  featureId,
  activeRound,
  featureRealPrice,
  roundSerial,
  universeId,
  setPurchaseBtn
) => ({
  type: PURCHASE_FEATURE,
  payload: {
    token,
    featureId,
    activeRound,
    featureRealPrice,
    roundSerial,
    universeId,
    setPurchaseBtn
  },
})
export const purchaseFeatureSuccess = message => ({
  type: PURCHASE_FEATURE_SUCCESS,
  payload: { message },
})
export const purchaseFeatureFail = message => ({
  type: PURCHASE_FEATURE_FAIL,
  payload: { message },
})
export const featureRemoveFromList = (token, featureId) => ({
  type: FEATURE_REMOVE_FROM_LIST,
  payload: { token, featureId },
})
export const featureRemoveFromListSuccess = message => ({
  type: FEATURE_REMOVE_FROM_LIST_SUCCESS,
  payload: { message },
})
