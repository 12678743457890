import {
  GET_DISTRIBUTION_DATA_FROM_STUDENT_SUCCESS,
  GET_DISTRIBUTION_DATA_FROM_STUDENT,
} from "./actionTypes"

const initialState = {
  distributionData: [],
  loading: false,
  message: "",
  error: "",
}

const distributionDataStudent = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISTRIBUTION_DATA_FROM_STUDENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_DISTRIBUTION_DATA_FROM_STUDENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        distributionData: action.payload.data,
      }
      break
    default:
      state = state
      break
  }
  return state
}

export default distributionDataStudent
