import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_TECH_FROM_FILE,
  GET_TECHS,
  GET_AVAILABLE_TECH,
  GET_UNAVAILABLE_TECH,
  GET_AVAILABLE_TECH_FOR_ADMIN_SUCCESS,
  GET_AVAILABLE_TECH_FOR_ADMIN,
  GET_UNAVAILABLE_TECH_FOR_R_AND_D,
  ANY_TECH_IN_PROCESSING,
} from "./actionTypes"

import {
  addTechDataSuccess,
  addTechDataFail,
  getTechsSuccess,
  getAvailableTechSuccess,
  getUnavailableTechSuccess,
  getAvailableTechAdminSuccess,
  getUnavailableTechForRandDSuccess,
  anyTechInProcesingSuccess,
} from "./actions"
import {
  axiosGetTechsData,
  axiosTechDataCreateByFIle,
  axiosMakeRelationBetweenTechAndRegion,
  axiosGetAvailableTech,
  axiosGetUnavailableTech,
  axiosGetAvailableTechForAdmin,
  axiosGetUnavailableTechForRAndD,
  axiosAnyTechInProcessing,
} from "../../helpers/api_helper"
import { toast } from "react-toastify"

//async get available tech
const asyncGetAvailableTechForAdmin = async token => {
  try {
    const response = await axiosGetAvailableTechForAdmin(token)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

// worker get available tech
function* workerGetAvailableTechsForAdmin({ payload: { token } }) {
  try {
    const response = yield call(asyncGetAvailableTechForAdmin, token)
    yield put(getAvailableTechAdminSuccess(response.techData))
  } catch (error) {
    console.log("error data")
  }
}

const asyncGetUnavailableTech = async token => {
  try {
    const response = await axiosGetUnavailableTech(token)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetUnavailableTech({ payload: { token } }) {
  try {
    const response = yield call(asyncGetUnavailableTech, token)
    yield put(getUnavailableTechSuccess(response.techData))
  } catch (error) {
    console.log("error data here")
  }
}

//async get available tech
const asyncGetAvailableTech = async (token, teamId) => {
  try {
    const response = await axiosGetAvailableTech(token, teamId)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

// worker get available tech
function* workerGetAvailableTechs({ payload: { token, teamId } }) {
  try {
    console.log("line 35 from here print")
    const response = yield call(asyncGetAvailableTech, token, teamId)
    yield put(getAvailableTechSuccess(response.techData))
  } catch (error) {
    console.log("error data")
  }
}

const asyncGetTechs = async token => {
  try {
    const response = await axiosGetTechsData(token)
    console.log("resposne from techs", response.data)
    return response.data
  } catch (error) {
    console.log("error response from techs", error.response.data)
    return error.response.data
  }
}
function* workerGetTechs({ payload: { token } }) {
  try {
    const tech = yield call(asyncGetTechs, token)
    yield put(getTechsSuccess(tech.getDatas))
  } catch (error) {}
}

const asyncAddTechFromFile = async (value, token) => {
  try {
    const response = await axiosTechDataCreateByFIle(value, token)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.log("error data", error.response.data)
  }
}
function* workerAddTechDataFromFile({ payload: { value, token, history } }) {
  // console.log("tech data 38", value, token)
  try {
    const response = yield call(asyncAddTechFromFile, value, token)
    // console.log("response data from 20", response.message)
    if (response.status == "success") {
      yield put(addTechDataSuccess(response.message))
      toast.success(response.message)
      yield put(addTechDataSuccess())
      history.go(0)
    } else {
      yield put(addTechDataFail(response.message))
      toast.error(response.message)
      yield put(addTechDataFail())
    }
  } catch (error) {
    console.log("hello error ", error)
  }
}

//get unavailable tech for R And D
const asyncGetUnavailableTechForRandD = async (token, teamId) => {
  try {
    const response = await axiosGetUnavailableTechForRAndD(token, teamId)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetUnavailableTechForRAndD({ payload: { token, teamId } }) {
  try {
    const response = yield call(asyncGetUnavailableTechForRandD, token, teamId)
    yield put(getUnavailableTechForRandDSuccess(response.getData))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncAnyTechInProcessing = async (
  token,
  teamId,
  roundSerial,
  universeId
) => {
  try {
    const response = await axiosAnyTechInProcessing(
      token,
      teamId,
      roundSerial,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerAnyTechInProcessing({
  payload: { token, teamId, roundSerial, universeId },
}) {
  try {
    const response = yield call(
      asyncAnyTechInProcessing,
      token,
      teamId,
      roundSerial,
      universeId
    )
    yield put(anyTechInProcesingSuccess(response.getData))
  } catch (error) {}
}

function* techDataSaga() {
  yield takeEvery(ADD_TECH_FROM_FILE, workerAddTechDataFromFile)
  yield takeEvery(GET_TECHS, workerGetTechs)
  yield takeEvery(GET_AVAILABLE_TECH, workerGetAvailableTechs)
  yield takeEvery(GET_UNAVAILABLE_TECH, workerGetUnavailableTech)
  yield takeEvery(GET_AVAILABLE_TECH_FOR_ADMIN, workerGetAvailableTechsForAdmin)
  yield takeEvery(
    GET_UNAVAILABLE_TECH_FOR_R_AND_D,
    workerGetUnavailableTechForRAndD
  )
  yield takeEvery(ANY_TECH_IN_PROCESSING, workerAnyTechInProcessing)
}

export default techDataSaga
