import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_DATA, ADD_DATA_STUDENT } from "./actionTypes"
import {
  addDataSuccess,
  addDataFail,
  addDataStudentSuccess,
  addLoadAPage,
} from "./actions"
import { toast } from "react-toastify"
import {
  axiosSendNetworkCoverageData,
  axiosRegisterAllStudent,
} from "./../../helpers/api_helper"

const asyncRegisterAllStudent = async (values, universeId, teamId, token) => {
  try {
    const response = await axiosRegisterAllStudent(
      token,
      values,
      universeId,
      teamId
    )
    console.log("response data", response.data)
    return response.data
  } catch (error) {
    console.log("error response data", error.response.data)
    return error.response.data
  }
}
function* workerAddDataStudent({
  payload: { values, universeId, teamId, token },
}) {
  console.log("values data", values, universeId, teamId, token)
  try {
    const response = yield call(
      asyncRegisterAllStudent,
      values,
      universeId,
      teamId,
      token
    )
    if (response.status == "success") {
      yield put(addDataStudentSuccess(response.dataFromOtherend))
      response.dataFromOtherend.map(({ message, type }, i) => {
        toast[type](message)
      })
      // yield put(addLoadAPage(true))
      yield put(addLoadAPage("loading"))
      yield put(addLoadAPage())
      location.reload()
    }
  } catch (error) {
    console.log("error data", error)
  }
}
const asyncSendNetworkCoverageData = async (value, token) => {
  try {
    const response = await axiosSendNetworkCoverageData(token, value)
    console.log("resposne", response.data)
  } catch (error) {
    console.log("error", error.response.data)
  }
}
function* workerAddData({ payload: { value, token } }) {
  console.log("valus obj data", value, token)
  const response = yield call(asyncSendNetworkCoverageData, value, token)
}

function* dataAdminSaga() {
  yield takeEvery(ADD_DATA, workerAddData)
  yield takeEvery(ADD_DATA_STUDENT, workerAddDataStudent)
}
export default dataAdminSaga
