import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_FINANCIAL_OUTCOME,
  SUBMIT_FINANCIAL_OUTCOME,
  GET_WHO_SUBMIT,
  GET_VERIFY_SUBMISSION,
} from "./actionTypes"

import {
  getFinancialOutcomeSuccess,
  submitFinancialOutcomeSuccess,
  submitFinancialOutcomeFail,
  getWhoSubmitSuccess,
  getVerifySubmissionSuccess,
} from "./actions"
import { toast } from "react-toastify"

import {
  axiosGetFinancialOutcome,
  axiosSubmitFinancialOutcome,
  axiosGetWhoSubmit,
  axiosGetVerifySubmission,
} from "../../helpers/api_helper"

const asyncGetWhoSubmit = async (token, roundSerial, teamId) => {
  try {
    const response = await axiosGetWhoSubmit(token, roundSerial, teamId)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetWhoSubmit({ payload: { token, roundSerial, teamId } }) {
  try {
    const response = yield call(asyncGetWhoSubmit, token, roundSerial, teamId)
    console.log("response data 34 line here", response, getTeamData)
    yield put(getWhoSubmitSuccess(response.getTeamData))
  } catch (error) {}
}

const asyncSubmitFinancialOutcome = async (
  token,
  roundSerial,
  teamId,
  totalRevenue,
  operationalProfitBefore,
  operationalProfitAfter,
  profitForThisYear,
  student,

  universeId
) => {
  try {
    const response = await axiosSubmitFinancialOutcome(
      token,
      roundSerial,
      teamId,
      totalRevenue,
      operationalProfitBefore,
      operationalProfitAfter,
      profitForThisYear,
      student,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerSubmitFinancialOutcome({
  payload: {
    token,
    roundSerial,
    teamId,
    totalRevenue,
    operationalProfitBefore,
    operationalProfitAfter,
    profitForThisYear,
    student,
    history,
    universeId,
  },
}) {
  try {
    const response = yield call(
      asyncSubmitFinancialOutcome,
      token,
      roundSerial,
      teamId,
      totalRevenue,
      operationalProfitBefore,
      operationalProfitAfter,
      profitForThisYear,
      student,
      universeId
    )

    console.log("hello response")
    if (response.status == "success") {
      yield put(submitFinancialOutcomeSuccess(response.message))
      toast.success(response.message)
      yield put(submitFinancialOutcomeSuccess())
      history.push("/studentdashboard")
    } else {
      yield put(submitFinancialOutcomeFail(response.message))
      toast.error(response.message)
      yield put(submitFinancialOutcomeFail())
      history.push("/studentdashboard")
    }
    // yield put(getVerifySubmission(token, roundSerial, universeId))
  } catch (error) {}
}

const asyncGetFinancialOutcome = async (
  token,
  roundSerial,
  teamId,
  universeId
) => {
  try {
    const response = await axiosGetFinancialOutcome(
      token,
      roundSerial,
      teamId,
      universeId
    )
    return response.data
  } catch (error) {}
}

function* workerGetFinancialOutcome({
  payload: { token, roundSerial, teamId, universeId },
}) {
  try {
    console.log("token , roun", token, roundSerial)
    const response = yield call(
      asyncGetFinancialOutcome,
      token,
      roundSerial,
      teamId,
      universeId
    )
    yield put(getFinancialOutcomeSuccess(response.mainObj))
  } catch (error) {
    console.log("error data")
  }
}
const asyncGetVerifySubmit = async (token, roundSerial, universeId) => {
  try {
    const response = await axiosGetVerifySubmission(token, roundSerial, universeId)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetVerifySubmit({ payload: { token, roundSerial, universeId } }) {
  try {
    const response = yield call(asyncGetVerifySubmit, token, roundSerial, universeId)

    yield put(getVerifySubmissionSuccess(response))
  } catch (error) {}
}

function* financialOutcomeSaga() {
  yield takeEvery(GET_FINANCIAL_OUTCOME, workerGetFinancialOutcome)
  yield takeEvery(SUBMIT_FINANCIAL_OUTCOME, workerSubmitFinancialOutcome)
  yield takeEvery(GET_WHO_SUBMIT, workerGetWhoSubmit)
  yield takeEvery(GET_VERIFY_SUBMISSION, workerGetVerifySubmit)
}

export default financialOutcomeSaga
