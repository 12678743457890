import { call, put, takeEvery } from "redux-saga/effects"
import {
  axiosGetUniverses,
  axiosGetSingleUniverse,
  axiosCreateUniverse,
} from "../../helpers/api_helper"
import { ADD_UNIVERSE, GET_UNIVERSES, GET_SINGLE_UNIVERSE } from "./actionTypes"
import {
  addUniverseSuccess,
  addUniverseFail,
  getUniversSuccess,
  getSingleUniverseSuccess,
  getSingleUniverseFail,
} from "./actions"
import { toast } from "react-toastify"

//asyncGetSingleUniverse
const asyncGetSingleUniverse = async (universeid, token) => {
  try {
    console.log("hello universe", universeid, token)
    const response = await axiosGetSingleUniverse(token, universeid)
    console.log("response data", response.data)
    return response.data
  } catch (error) {
    console.log("error data", error)
    return error.response.data
  }
}
//worker getSingleUniverse
function* workerGetSingleUniverse({ payload: { universeid, token } }) {
  console.log("univese data", universeid, token)
  try {
    const response = yield call(asyncGetSingleUniverse, universeid, token)
    console.log("response data", response.getSingleUniverseData)
    yield put(getSingleUniverseSuccess(response.getSingleUniverseData))
  } catch (error) {
    yield put(response.getSingleUniverseData)
  }
}
//async worker data call by courseid , token
const asyncGetUniverses = async (courseid, authtoken) => {
  try {
    const response = await axiosGetUniverses(authtoken, courseid)
    console.log("response data", response.data)
    return response.data
  } catch (error) {
    console.log(error.response.data)
    return error.response.data
  }
}
//worker get Univers by id and token
function* workerGetUniverses({ payload: { courseid, token } }) {
  console.log("course data", courseid, token)
  try {
    const response = yield call(asyncGetUniverses, courseid, token)
    console.log("response null or value ? ", response)
    yield put(getUniversSuccess(response.getUniverses))
    // yield put(getUniversSuccess(response.getUniverses))
  } catch (error) {
    console.log("error data", error)
  }
}

//add course async func
const asyncAddUniverse = async (
  universeName,
  studentLimit,
  teamLimit,
  courseId,
  authtoken
) => {
  try {
    const response = await axiosCreateUniverse(
      universeName,
      studentLimit,
      teamLimit,
      courseId,
      authtoken
    )
    console.log("response data", response.data)
    return response.data
  } catch (error) {
    console.log("response error", error.response.data)
    return error.response.data
  }
}

//worker add universe saga
function* workerAddUniverse({ payload: { values, token } }) {
  console.log(token)
  //universeName distructure
  const { universeName, courseId, studentLimit, teamLimit } = values
  console.log(
    "universe Name data",
    universeName,
    courseId,
    studentLimit,
    teamLimit
  )
  try {
    const response = yield call(
      asyncAddUniverse,
      universeName,
      studentLimit,
      teamLimit,
      courseId,
      token
    )
    if (response.status == "success") {
      yield put(addUniverseSuccess(response.message))
      const res = yield call(asyncGetUniverses, courseId, token)
      yield put(getUniversSuccess(res.getUniverses))

      toast.success(response.message)
      toast.success()
    } else {
      yield put(addUniverseFail(response.message))
      toast.error(response.message)
    }
  } catch (error) {
    console.log("Universe not created")
  }
}
//final saga
function* teacherUniverseSaga() {
  yield takeEvery(ADD_UNIVERSE, workerAddUniverse)
  yield takeEvery(GET_UNIVERSES, workerGetUniverses)
  yield takeEvery(GET_SINGLE_UNIVERSE, workerGetSingleUniverse)
}
export default teacherUniverseSaga
