import { call, put, takeEvery } from "redux-saga/effects"

import { axiosGetSingleCourse } from "../../../helpers/api_helper"
import {
  getCourseForTeacherSuccess,
  getSingleCourseSuccess,
  getSingleCourseFail,
} from "./actions"
import { GET_COURSE_FOR_TEACHER, GET_SINGLE_COURSE } from "./actionTypes"

// const asyncGetCourseForTeacher = async token => {
//   try {
//     // const response = await axiosGetCoursesForTeacher(token)
//     console.log("teacher course", response.data.data.teacherCourseId)
//     return response.data.data.teacherCourseId
//   } catch (error) {
//     console.log("error teacher coruse", "No data found")
//   }
// }

// function* getCourseSaga({ payload: token }) {
//   console.log("token data", token)
//   try {
//     const response = yield call(asyncGetCourseForTeacher, token)
//     console.log("teacher course from 22 line alos", response)

//     yield put(getCourseForTeacherSuccess(response))
//   } catch (error) {
//     console.log("error data", error)
//   }
// }

const asyncGetSingleCourse = async (courseId, token) => {
  try {
    const response = await axiosGetSingleCourse(token, courseId)
    console.log("response data from 36", response.data)
    return response.data
  } catch (error) {
    console.log("error data", error.response.data)
  }
}
function* workerGetSingleCourse({ payload: { courseId, token } }) {
  try {
    const response = yield call(asyncGetSingleCourse, courseId, token)
    if (response.status == "success") {
      console.log("user data", response.findCourse)
      yield put(getSingleCourseSuccess(response.findCourse))
    } else {
      yield put("No data found")
    }
  } catch (error) {
    console.log("error data", error)
  }
}
function* courseTeacherSaga() {
  // yield takeEvery(GET_COURSE_FOR_TEACHER, getCourseSaga)
  yield takeEvery(GET_SINGLE_COURSE, workerGetSingleCourse)
}

export default courseTeacherSaga
