import { GET_UNIVERSITY_NAME, GET_UNIVERSITY_NAME_SUCCESS, ADD_UNIVERSITY_NAME, ADD_UNIVERSITY_NAME_SUCCESS, ADD_UNIVERSITY_NAME_ERROR } from "./actionTypes"

const initialState = {
    universityNames: [],
    loading: false,
    message: "",
    error: ""
}


const university = (state = initialState, action) => {
    switch (action.type) {
        case ADD_UNIVERSITY_NAME:
            state = {...state, loading: true }
        case ADD_UNIVERSITY_NAME_SUCCESS:
            state = {...state, message: action.payload.message, loading: false }
        case ADD_UNIVERSITY_NAME_ERROR:
            state = {...state, error: action.payload.error, loading: false }
        case GET_UNIVERSITY_NAME:
            state = {...state, loading: true }
        case GET_UNIVERSITY_NAME_SUCCESS:
            state = {...state, universityNames: action.payload.universityNames, loading: false }
        default:
            state = state
            break;

    }

    return state;
}

export default university