import {
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL
} from "./actionTypes"



export const resetPassword = (values, history) => {
    return {

        type: RESET_PASSWORD,
        payload: { values, history }
    }
}

export const resetPasswordSuccess = (message) => {

    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: {
            message
        }
    }

}

export const resetPasswordFail = (message) => {
    return {
        type: RESET_PASSWORD_FAIL,
        payload: {
            message
        }
    }
}