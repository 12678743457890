export const ADD_TOTAL_DEMAND_LAST_ROUND = "ADD_TOTAL_DEMAND_LAST_ROUND"
export const ADD_TOTAL_DEMAND_LAST_ROUND_SUCCESS =
  "ADD_TOTAL_DEMAND_LAST_ROUND_SUCCESS"
export const ADD_TOTAL_DEMAND_LAST_ROUND_FAIL =
  "ADD_TOTAL_DEMAND_LAST_ROUND_FAIL"
export const GET_TOTAL_DEMAND_LAST_ROUND = "GET_TOTAL_DEMAND_LAST_ROUND"
export const GET_TOTAL_DEMAND_LAST_ROUND_SUCCESS =
  "GET_TOTAL_DEMAND_LAST_ROUND_SUCCESS"

export const INSERT_INITIAL_CAPITAL = "INSERT_INITIAL_CAPITAL"
export const INSERT_INITIAL_CAPITAL_SUCCESS = "INSERT_INITIAL_CAPITAL_SUCCESS"
export const INSERT_INITIAL_CAPITAL_FAIL = "INSERT_INITIAL_CAPITAL_FAIL"

export const GET_DEMAND_GRAPH = "GET_DEMAND_GRAPH"
export const GET_DEMAND_GRAPH_SUCCESS = "GET_DEMAND_GRAPH_SUCCESS"

export const ADMIN_GET_TOTAL_DEMAND_LAST_ROUND =
  "ADMIN_GET_TOTAL_DEMAND_LAST_ROUND"
export const ADMIN_GET_TOTAL_DEMAND_LAST_ROUND_SUCCESS =
  "ADMIN_GET_TOTAL_DEMAND_LAST_ROUND_SUCCESS"
