import {
  ADD_OR_UPDATE_CAPACITY,
  ADD_OR_UPDATE_CAPACITY_FAIL,
  ADD_OR_UPDATE_CAPACITY_SUCCESS,
  GET_CAPACITY,
  GET_CAPACITY_SUCCESS,
  ADD_OR_UPDATE_INVENTORY_FAIL,
  ADD_OR_UPDATE_INVENTORY_SUCCESS,
  ADD_OR_UPDATE_INVENTORY,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY,
  GET_ONLY_PRODUCTION_REGION,
  GET_ONLY_PRODUCTION_REGION_SUCCESS,
  ADD_CAPACITY_WITH_TECH_FAIL,
  ADD_CAPACITY_WITH_TECH,
  ADD_CAPACITY_WITH_TECH_SUCCESS,
} from "./actionTypes"

const Initial_State = {
  capacityData: [],
  inventoryData: [],
  productionRegion: [],
  loading: false,
  message: "",
  error: "",
}

const adminProductionData = (state = Initial_State, action) => {
  switch (action.type) {
    case ADD_OR_UPDATE_CAPACITY:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_OR_UPDATE_CAPACITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case ADD_OR_UPDATE_CAPACITY_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case GET_CAPACITY:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_CAPACITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        capacityData: action.payload.data,
      }
      break

    case ADD_OR_UPDATE_INVENTORY:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_OR_UPDATE_INVENTORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case ADD_OR_UPDATE_INVENTORY_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case GET_INVENTORY:
      state = {
        ...state,
        loading: false,
      }
      break
    case GET_INVENTORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        inventoryData: action.payload.data,
      }
      break

    case GET_ONLY_PRODUCTION_REGION:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ONLY_PRODUCTION_REGION_SUCCESS:
      state = {
        ...state,
        loading: false,
        productionRegion: action.payload.data,
      }
      break

    case ADD_CAPACITY_WITH_TECH:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_CAPACITY_WITH_TECH_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case ADD_CAPACITY_WITH_TECH_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    default:
      state = state
      break
  }
  return state
}

export default adminProductionData
