//capacity
export const GET_ONLY_PRODUCTION_REGION = "GET_ONLY_PRODUCTION_REGION"
export const GET_ONLY_PRODUCTION_REGION_SUCCESS =
  "GET_ONLY_PRODUCTION_REGION_SUCCESS"

//add capacity
export const ADD_OR_UPDATE_CAPACITY = "ADD_OR_UPDATE_CAPACITY"
export const ADD_OR_UPDATE_CAPACITY_SUCCESS = "ADD_OR_UPDATE_CAPACITY_SUCCESS"
export const ADD_OR_UPDATE_CAPACITY_FAIL = "ADD_OR_UPDATE_CAPACITY_FAIL"

//get capacity
export const GET_CAPACITY = "GET_CAPACITY"
export const GET_CAPACITY_SUCCESS = "GET_CAPACITY_SUCCESS"
export const GET_CAPACITY_FAIL = "GET_CAPACITY_FAIL"

//capacity with tech
export const ADD_CAPACITY_WITH_TECH = "ADD_CAPACITY_WITH_TECH"
export const ADD_CAPACITY_WITH_TECH_SUCCESS = "ADD_CAPACITY_WITH_TECH_SUCCESS"
export const ADD_CAPACITY_WITH_TECH_FAIL = "ADD_CAPACITY_WITH_TECH_FAIL"
//inventory
//add Inventory
export const ADD_OR_UPDATE_INVENTORY = "ADD_OR_UPDATE_INVENTORY"
export const ADD_OR_UPDATE_INVENTORY_SUCCESS = "ADD_OR_UPDATE_INVENTORY_SUCCESS"
export const ADD_OR_UPDATE_INVENTORY_FAIL = "ADD_OR_UPDATE_INVENTORY_FAIL"

//get Inventory
export const GET_INVENTORY = "GET_INVENTORY"
export const GET_INVENTORY_SUCCESS = "GET_INVENTORY_SUCCESS"
export const GET_INVENTORY_FAIL = "GET_INVENTORY_FAIL"
