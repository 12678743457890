import {
  ADD_TOTAL_DEMAND_LAST_ROUND,
  ADD_TOTAL_DEMAND_LAST_ROUND_FAIL,
  ADD_TOTAL_DEMAND_LAST_ROUND_SUCCESS,
  GET_TOTAL_DEMAND_LAST_ROUND,
  GET_TOTAL_DEMAND_LAST_ROUND_SUCCESS,
  INSERT_INITIAL_CAPITAL,
  INSERT_INITIAL_CAPITAL_FAIL,
  INSERT_INITIAL_CAPITAL_SUCCESS,
  GET_DEMAND_GRAPH,
  GET_DEMAND_GRAPH_SUCCESS,
  ADMIN_GET_TOTAL_DEMAND_LAST_ROUND,
  ADMIN_GET_TOTAL_DEMAND_LAST_ROUND_SUCCESS,
} from "./actionTypes"

export const addTotalDemandLastRound = (
  regionId,
  totalDemandLastRound,
  token
) => {
  return {
    type: ADD_TOTAL_DEMAND_LAST_ROUND,
    payload: { regionId, totalDemandLastRound, token },
  }
}
export const addTotalDemandLastRoundSuccess = message => {
  return {
    type: ADD_TOTAL_DEMAND_LAST_ROUND_SUCCESS,
    payload: { message },
  }
}
export const addTotalDemandLastRoundFail = message => {
  return {
    type: ADD_TOTAL_DEMAND_LAST_ROUND_FAIL,
    payload: { message },
  }
}

export const getTotalDemandLastRound = (
  token,
  roundSerial,
  teamId,
  universeId
) => {
  return {
    type: GET_TOTAL_DEMAND_LAST_ROUND,
    payload: { token, roundSerial, teamId, universeId },
  }
}
export const getTotalDemandLastRoundSuccess = data => {
  return {
    type: GET_TOTAL_DEMAND_LAST_ROUND_SUCCESS,
    payload: { data },
  }
}

export const insertInitialCapital = (token, initialCaptial) => {
  return {
    type: INSERT_INITIAL_CAPITAL,
    payload: { token, initialCaptial },
  }
}

export const insertInitialCapitalSuccess = message => {
  return {
    type: INSERT_INITIAL_CAPITAL_SUCCESS,
    payload: { message },
  }
}

export const insertInitialCapitalFail = message => {
  return {
    type: INSERT_INITIAL_CAPITAL_FAIL,
    payload: { message },
  }
}

export const getDemandGraph = (token, roundSerial, teamId, universeId) => {
  return {
    type: GET_DEMAND_GRAPH,
    payload: { token, roundSerial, teamId, universeId },
  }
}

export const getDemandGraphSuccess = data => {
  return {
    type: GET_DEMAND_GRAPH_SUCCESS,
    payload: { data },
  }
}

export const adminGetTotalDemandLastRound = token => {
  return {
    type: ADMIN_GET_TOTAL_DEMAND_LAST_ROUND,
    payload: { token },
  }
}

export const adminGetTotalDemandLastRoundSuccess = data => {
  return {
    type: ADMIN_GET_TOTAL_DEMAND_LAST_ROUND_SUCCESS,
    payload: { data },
  }
}
