import { axiosApi } from "./api_helper";

export async function get(url, config = {}) {
    return await axiosApi
      .get(url, { ...config })
      .then((response) => response.data);
  }
  
  export async function post(url, data, config = {}) {
    return axiosApi
      .post(url, data, { ...config })
      .then((response) => response.data);
  }
  
  export async function image(url, data, authtoken) {
    return axiosApi
      .post(url, data, { headers: { Authorization: `Bearer ${authtoken}` } })
      .then((response) => response.data);
  }
  export async function patch(url, data, config) {
    return axiosApi
      .patch(url, data, { ...config })
      .then((response) => response.data);
  }
  export async function put(url, data, config = {}) {
    return axiosApi
      .put(url, { ...data }, { ...config })
      .then((response) => response.data);
  }
  export async function del(url, config = {}) {
    return await axiosApi
      .delete(url, { ...config })
      .then((response) => response.data);
  }
export const postData = (url, data, authToken) => {
    return post(url, data, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };
  export const getData = (url, authToken) =>
    get(url, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  
  export const patchData = (url, data, authToken) => {
    return patch(url, data, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };
  
  export const deleteData = (url, authToken) => {
    return del(url, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };