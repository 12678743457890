//get teams
export const GET_TEAMS = "GET_TEAMS"
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS"

//get team
export const GET_SINGLE_TEAM = "GET_SINGLE_TEAM"
export const GET_SINGLE_TEAM_SUCCESS = "GET_SINGLE_TEAM_SUCCESS"
export const GET_SINGLE_TEAM_FAIL = "GET_SINGLE_TEAM_FAIL"

//add team
export const ADD_TEAM = "ADD_TEAM"
export const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS"
export const ADD_TEAM_FAIL = "ADD_TEAM_FAIL"

//addStudent
export const ADD_STUDENT_INTO_TEAM = "ADD_STUDENT_INTO_TEAM"
export const ADD_STUDENT_INTO_TEAM_SUCCESS = "ADD_STUDENT_INTO_TEAM_SUCCESS"
export const ADD_STUDENT_INTO_TEAM_FAIL = "ADD_STUDENT_INTO_TEAM_FAIL"

//removeStudent
export const REMOVE_STUDENT_FROM_TEAM = "REMOVE_STUDENT_FROM_TEAM"
export const REMOVE_STUDENT_FROM_TEAM_SUCCESS =
  "REMOVE_STUDENT_FROM_TEAM_SUCCESS"
export const REMOVE_STUDENT_FROM_TEAM_FAIL = "REMOVE_STUDENT_FROM_TEAM_FAIL"

// maek captain
export const MAKE_CAPTAIN = "MAKE_CAPTAIN"
export const MAKE_CAPTAIN_SUCCESS = "MAKE_CAPTAIN_SUCCESS"
export const MAKE_CAPTAIN_FAIL = "MAKE_CAPTAIN_FAIL"
