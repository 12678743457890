import { call, put, takeEvery } from "redux-saga/effects"

import { axiosCreateStudent, axiosGetStudents } from "../../helpers/api_helper"
import {
  addStudentSuccess,
  addStudentFail,
  getStudentsSuccess,
} from "./actions"
import { ADD_STUDENT, GET_STUDENT } from "./actionTypes"

const asyncAddStudent = async (
  fullname,
  email,
  instituteId,
  instituteName,
  token
) => {
  console.log("hy ami jubair")
  console.log("token user", token)
  try {
    const response = await axiosCreateStudent(
      fullname,
      email,
      instituteId,
      instituteName,
      token
    )

    console.log("geeting data", response.data)
    return response.data
  } catch (err) {
    console.log("error data", err.response.data)
    return err.response.data
  }
}

function* studetnSaga({ payload: { values, history, token } }) {
  const { fullname, email, instituteId, instituteName } = values
  try {
    const response = yield call(
      asyncAddStudent,
      fullname,
      email,
      instituteId,
      instituteName,
      token
    )

    if (response.status == "success") {
      yield put(addStudentSuccess(response.message))
      history.push("/studentdata")
      yield put(addStudentSuccess(""))
    } else {
      yield put(addStudentFail(response.message))
      yield put(addStudentFail(""))
    }
  } catch (error) {
    yield put(addStudentFail(response.message))
  }
}

//axios
const asyncGetStudents = async token => {
  try {
    const response = await axiosGetStudents(token)
    return response.data
  } catch (error) {
    console.log("error from fetch", error)
    return error.response.data
  }
}

//get teacherworker
function* getStudentSaga({ payload: token }) {
  console.log("token data", token)
  try {
    const getStudents = yield call(asyncGetStudents, token)
    console.log("get students data", getStudents)
    yield put(getStudentsSuccess(getStudents.findUniverseData))
  } catch (err) {}
}

function* studentAdminSaga() {
  yield takeEvery(ADD_STUDENT, studetnSaga)
  yield takeEvery(GET_STUDENT, getStudentSaga)
}

export default studentAdminSaga
