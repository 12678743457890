import React, { useEffect, useState } from "react"
import { Container, FormSelect } from "react-bootstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Col,
  Label,
  Row,
  InputGroup,
  Spinner,
  Alert,
  CardTitle,
} from "reactstrap"

import {
  getOnlyProductionRegion,
  addOrUpdateCapacity,
  getCapacity,
} from "./../../store/AdminProduction/actions"
const Planing = () => {
  const dispatch = useDispatch()
  let history = useHistory()
  const { token, loading, productionRegion, capacityData } = useSelector(
    state => ({
      token: state.Login.token,
      loading: state.regionData.loading,
      productionRegion: state.AdminProductionReducer.productionRegion,
      capacityData: state.AdminProductionReducer.capacityData,
    })
  )
  useEffect(() => {
    dispatch(getOnlyProductionRegion(token))
    dispatch(getCapacity(token))
  }, [])

  //production capacity create
  const [regionId, setRegionId] = useState("")
  const handleRegionSelect = e => {
    console.log("prod region data", productionRegion[e.target.value]._id)
    setRegionId(productionRegion[e.target.value]._id)
  }
  const handleValidSubmit = (e, v) => {
    e.preventDefault()
    // console.log("value data", v, regionId)
    const { totalProductionCapacity } = v
    // console.log("total Production Capacity", totalProductionCapacity)
    dispatch(
      addOrUpdateCapacity(token, regionId, totalProductionCapacity, history)
    )
  }

  return (
    <div>
      <Container>
        <Col className="col-12">
          <div className="feature-data-item-area-wrapper">
            <h3>Capacity Data</h3>
            <CardBody className=" ">
              <div className="">
                <AvForm
                  className="form-horizontal mt-2"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <div className="mb-3 d-flex">
                    <h4>Plant Location : </h4>
                    <div>
                      <select
                        style={{
                          padding: "10px 60px",
                          width: "auto",
                          marginLeft: "60px",
                        }}
                        onChange={e => handleRegionSelect(e)}
                      >
                        <option value="null">Select a option</option>
                        {productionRegion.map((v, i) => (
                          <option key={i} value={i}>
                            {v.regionName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="totalProductionCapacity"
                      label="Total Production Capacity"
                      className="form-control"
                      placeholder="Enter total capacity"
                      required
                    />
                  </div>
                  <div className="form-group">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <>
                        <label className=""></label>
                        <Button
                          color="success"
                          outline
                          className="waves-effect waves-light"
                        >
                          Create Capacity
                        </Button>
                      </>
                    )}
                  </div>
                </AvForm>
              </div>
            </CardBody>
          </div>
        </Col>
        <div className="inventory-main-area">
          <div className="inventory-title">
            <h4>Planing</h4>
          </div>
          <div className="inventody-item-main-area-wrap-admin">
            <div className="inventory-item-area-admin">
              <div className="inventory-single-item-admin-1">
                <p>Total demand last round, in lac</p>
              </div>
              {capacityData?.map((v, i) => (
                <>
                  <div className="inventory-single-item-admin-2">
                    <h3 id="hdb"></h3>
                  </div>
                  <div className="inventory-single-item-admin-3">
                    <p>100</p>
                  </div>
                </>
              ))}
            </div>{" "}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Planing
