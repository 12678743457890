export const GET_IN_HOUSE_PRODUCTION = "GET_IN_HOUSE_PRODUCTION";
export const GET_IN_HOUSE_PRODUCTION_SUCCESS = "GET_IN_HOUSE_PRODUCTION_SUCCESS";
export const GET_IN_HOUSE_PRODUCTION_FAIL = "GET_IN_HOUSE_PRODUCTION_FAIL";


export const GET_CONTRACT_PRODUCTION = "GET_CONTRACT_PRODUCTION";
export const GET_CONTRACT_PRODUCTION_SUCCESS = "GET_CONTRACT_PRODUCTION_SUCCESS";
export const GET_CONTRACT_PRODUCTION_FAIL = "GET_CONTRACT_PRODUCTION_FAIL";

export const POST_IN_HOUSE_PRODUCTION = "POST_IN_HOUSE_PRODUCTION";
export const POST_IN_HOUSE_PRODUCTION_SUCCESS = "POST_IN_HOUSE_PRODUCTION_SUCCESS";
export const POST_IN_HOUSE_PRODUCTION_FAIL = "POST_IN_HOUSE_PRODUCTION_FAIL";

export const POST_CONTRACT_PRODUCTION = "POST_CONTRACT_PRODUCTION";
export const POST_CONTRACT_PRODUCTION_SUCCESS = "POST_CONTRACT_PRODUCTION_SUCCESS";
export const POST_CONTRACT_PRODUCTION_FAIL = "POST_CONTRACT_PRODUCTION_FAIL";

export const UPDATE_IN_HOUSE_PRODUCTION = "UPDATE_IN_HOUSE_PRODUCTION";
export const UPDATE_IN_HOUSE_PRODUCTION_SUCCESS = "UPDATE_IN_HOUSE_PRODUCTION_SUCCESS";
export const UPDATE_IN_HOUSE_PRODUCTION_FAIL = "UPDATE_IN_HOUSE_PRODUCTION_FAIL";

export const UPDATE_CONTRACT_PRODUCTION = "UPDATE_CONTRACT_PRODUCTION";
export const UPDATE_CONTRACT_PRODUCTION_SUCCESS = "UPDATE_CONTRACT_PRODUCTION_SUCCESS";
export const UPDATE_CONTRACT_PRODUCTION_FAIL = "UPDATE_CONTRACT_PRODUCTION_FAIL";

export const STORE_PRODUCTION_CAPACITY = "STORE_PRODUCTION_CAPACITY";