import {
  GET_FINANCIAL_OUTCOME,
  GET_FINANCIAL_OUTCOME_SUCCESS,
  GET_FINANCOAL_OUTCOME_FAIL,
  SUBMIT_FINANCIAL_OUTCOME,
  SUBMIT_FINANCIAL_OUTCOME_FAIL,
  SUBMIT_FINANCIAL_OUTCOME_SUCCESS,
  GET_WHO_SUBMIT,
  GET_WHO_SUBMIT_SUCCESS,
  GET_VERIFY_SUBMISSION,
  GET_VERIFY_SUBMISSION_SUCCESS,
} from "./actionTypes"

export const getFinancialOutcome = (token, roundSerial, teamId, universeId) => {
  return {
    type: GET_FINANCIAL_OUTCOME,
    payload: { token, roundSerial, teamId, universeId },
  }
}

export const getFinancialOutcomeSuccess = data => {
  return {
    type: GET_FINANCIAL_OUTCOME_SUCCESS,
    payload: { data },
  }
}

export const submitFinancialOutcome = (
  token,
  roundSerial,
  teamId,
  totalRevenue,
  operationalProfitBefore,
  operationalProfitAfter,
  profitForThisYear,
  student,
  history,
  universeId
) => {
  return {
    type: SUBMIT_FINANCIAL_OUTCOME,
    payload: {
      token,
      roundSerial,
      teamId,
      totalRevenue,
      operationalProfitBefore,
      operationalProfitAfter,
      profitForThisYear,
      student,
      history,
      universeId,
    },
  }
}

export const submitFinancialOutcomeSuccess = message => {
  return {
    type: SUBMIT_FINANCIAL_OUTCOME_SUCCESS,
    payload: { message },
  }
}

export const submitFinancialOutcomeFail = message => {
  return {
    type: SUBMIT_FINANCIAL_OUTCOME_FAIL,
    payload: { message },
  }
}

export const getWhoSubmit = (token, roundSerial, teamId) => {
  return {
    type: GET_WHO_SUBMIT,
    payload: { token, roundSerial, teamId },
  }
}

export const getWhoSubmitSuccess = message => {
  return {
    type: GET_WHO_SUBMIT_SUCCESS,
    payload: { message },
  }
}
export const getVerifySubmission = (token, roundSerial, universeId) => {
  return {
    type: GET_VERIFY_SUBMISSION,
    payload: { token, roundSerial, universeId },
  }
}

export const getVerifySubmissionSuccess = data => {
  return {
    type: GET_VERIFY_SUBMISSION_SUCCESS,
    payload: { data },
  }
}
