import {
  ADD_TEACHER,
  ADD_TEACHER_SUCCESS,
  ADD_TEACHER_FAIL,
  GET_TEACHER,
  GET_TEACHER_SUCCESS,
} from "./actionTypes"

const initialState = {
  teachers: [],
  teacher: {},
  loading: false,
  message: "",
  error: "",
}

const teacherAdmin = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEACHER:
      state = {
        ...state,

        loading: true,
      }
    case ADD_TEACHER_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case ADD_TEACHER_FAIL:
      state = { ...state, loading: false, error: action.payload.error }
      break
    case GET_TEACHER:
      state = { ...state, loading: true }
    case GET_TEACHER_SUCCESS:
      state = { ...state, loading: false, teachers: action.payload.teachers }

    default:
      state = state
      break
  }
  return state
}

export default teacherAdmin
