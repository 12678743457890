import {
  ENHANCE_STUDENT_CAPACITY,
  ENHANCE_STUDENT_CAPACITY_SUCCESS,
  ENHANCE_STUDENT_CAPACITY_FAIL,
  IS_CAPACITY_IN_PROCESSING,
  IS_CAPACITY_IN_PROCESSING_SUCCESS,
  CANCLE_ENHANCEMENT,
  CANCLE_ENHANCEMENT_FAIL,
  CANCLE_ENHANCEMENT_SUCCESS,
  GO_TO_RAND_D,
  GO_TO_RAND_D_SUCCESS,
} from "./actionTypes"

const initial_State = {
  loading: false,
  message: "",
  error: "",
  enhanceCapacityLoading: false,
  enhanceCapacity: [],
  capacityProcessingData: {},
  check: false,
}

const studentEnhanceCapacity = (state = initial_State, action) => {
  switch (action.type) {
    case ENHANCE_STUDENT_CAPACITY:
      state = {
        ...state,
        enhanceCapacityLoading: true,
      }
      break
    case ENHANCE_STUDENT_CAPACITY_SUCCESS:
      state = {
        ...state,
        enhanceCapacityLoading: false,
        message: action.payload.message,
      }
      break
    case ENHANCE_STUDENT_CAPACITY_FAIL:
      state = {
        ...state,
        enhanceCapacityLoading: false,
        message: action.payload.message,
      }
      break
    case IS_CAPACITY_IN_PROCESSING:
      state = {
        ...state,
        loading: true,
      }
      break
    case IS_CAPACITY_IN_PROCESSING_SUCCESS:
      state = {
        ...state,
        loading: false,
        capacityProcessingData: action.payload.data,
      }
    case CANCLE_ENHANCEMENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case CANCLE_ENHANCEMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case CANCLE_ENHANCEMENT_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case GO_TO_RAND_D:
      state = {
        ...state,
        loading: true,
      }
      break
    case GO_TO_RAND_D_SUCCESS:
      state = {
        ...state,
        loading: false,
        check: action.payload.data,
      }
      break
    default:
      state = state
      break
  }
  return state
}

export default studentEnhanceCapacity
