import {
  ADD_DATA,
  ADD_DATA_SUCCESS,
  ADD_DATA_FAIL,
  ADD_DATA_STUDENT,
  ADD_DATA_STUDENT_SUCCESS,
  ADD_DATA_STUDENT_FAIL,
  ADD_LOAD_A_PAGE,
} from "./actionTypes"

const initialState = {
  data: [],
  loading: false,
  message: "",
  error: "",
  loadAPage: false,
}

const dataAdmin = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DATA:
      state = { ...state, loading: true }
      break
    case ADD_DATA_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case ADD_DATA_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case ADD_DATA_STUDENT:
      state = { ...state, loading: true }
      break
    case ADD_DATA_STUDENT_SUCCESS:
      state = { ...state, loading: false, data: action.payload.value }
      break
    case ADD_LOAD_A_PAGE:
      {
        state = { ...state, message: action.payload.message }
      }
      break
    default:
      state = state
      break
  }
  return state
}

export default dataAdmin
