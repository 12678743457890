

//GET all users (for admin)
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"


//GET SINGLE USER

export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS =
    "GET_USER_SUCCESS"
export const GET_USER_FAIL = "GET_USER_FAIL"
//ADD
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

export const LOADING_ADD_USER = "LOADING_ADD_USER"

// Update
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS =
    "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

//Delete
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS =
    "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

//Others
