import {
  EDIT_PROFILE,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  RESET_PROFILE_FLAG,
  GET_PROFILE_SUCCESS,
  GET_PROFILE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PASS,
  UPDATE_PASS_SUCCESS,
  UPDATE_PASS_FAIL,
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  data: {},
  message: "",
  loading: false,
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break
    case GET_PROFILE:
      state = { ...state, loading: true }
      break
    case GET_PROFILE_SUCCESS:
      state = { ...state, loading: false, data: action.payload.data }
      break
    case UPDATE_PROFILE:
      state = { ...state, loading: true }
      break
    case UPDATE_PROFILE_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case UPDATE_PROFILE_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case UPDATE_PASS:
      state = { ...state, loading: true }
      break
    case UPDATE_PASS_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case UPDATE_PASS_FAIL:
      state = { ...state, loading: false, message: action.payload.message }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
