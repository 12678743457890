import { GET_MARKET_SHARE, GET_MARKET_SHARE_FAIL, GET_MARKET_SHARE_SUCCESS, GET_TOTAL_DEMAND, GET_TOTAL_DEMAND_FAIL, GET_TOTAL_DEMAND_SUCCESS, POST_MARKET_SHARE, POST_MARKET_SHARE_FAIL, POST_MARKET_SHARE_SUCCESS, POST_TOTAL_DEMAND, POST_TOTAL_DEMAND_FAIL, POST_TOTAL_DEMAND_SUCCESS, STORE_DATA, UPDATE_MARKET_SHARE, UPDATE_MARKET_SHARE_FAIL, UPDATE_MARKET_SHARE_SUCCESS, UPDATE_TOTAL_DEMAND, UPDATE_TOTAL_DEMAND_FAIL, UPDATE_TOTAL_DEMAND_SUCCESS } from "./actionTypes";

  
  const initialState = {
    adding: false,
    message: "",
    error: "",
    totalDemandData: [],
    modifiedDemand: [],

    totalMarketShare: [],
    modifiedMarketShare: [],
    totalDemandLoading: false,
    marketShareLoading: false,
   
  };
  
  const DemandReducer = (state = initialState, action) => {
    switch (action.type) {
      case POST_TOTAL_DEMAND:
        state = {
          ...state,
          adding: true,
        };
        break;
      case POST_TOTAL_DEMAND_SUCCESS:
        state = {
          ...state,
          message: action.payload.message,
          adding: false,
        };
        break;
      case POST_TOTAL_DEMAND_FAIL:
        state = {
          ...state,
          error: action.payload.message,
          adding: false,
        };
        break;
        case POST_MARKET_SHARE:
          state = {
            ...state,
            adding: true,
          };
          break;
        case POST_MARKET_SHARE_SUCCESS:
          state = {
            ...state,
            message: action.payload.message,
            adding: false,
          };
          break;
        case POST_MARKET_SHARE_FAIL:
          state = {
            ...state,
            error: action.payload.message,
            adding: false,
          };
          break;
        case UPDATE_TOTAL_DEMAND:
          state = {
            ...state,
            adding: true,
          };
          break;
        case UPDATE_TOTAL_DEMAND_SUCCESS:
          state = {
            ...state,
            message: action.payload.message,
            adding: false,
          };
          break;
        case UPDATE_TOTAL_DEMAND_FAIL:
          state = {
            ...state,
            error: action.payload.message,
            adding: false,
          };
          break;

          case UPDATE_MARKET_SHARE:
            state = {
              ...state,
              adding: true,
            };
            break;
          case UPDATE_MARKET_SHARE_SUCCESS:
            state = {
              ...state,
              message: action.payload.message,
              adding: false,
            };
            break;
          case UPDATE_MARKET_SHARE_FAIL:
            state = {
              ...state,
              error: action.payload.message,
              adding: false,
            };
            break;
        case GET_TOTAL_DEMAND:
          state = {
            ...state,
            totalDemandLoading: true,
          };
          break;
        case GET_TOTAL_DEMAND_SUCCESS:
          state = {
            ...state,
            totalDemandLoading: false,
            totalDemandData: action.payload.values,
          };
          break;
        case GET_TOTAL_DEMAND_FAIL:
          state = {
            ...state,
            totalDemandLoading: false,
            message: action.payload.message,
          };
          break;

          case GET_MARKET_SHARE:
            state = {
              ...state,
              marketShareLoading: true,
            };
            break;
          case GET_MARKET_SHARE_SUCCESS:
            state = {
              ...state,
              marketShareLoading: false,
              totalMarketShare: action.payload.values,
            };
            break;
          case GET_MARKET_SHARE_FAIL:
            state = {
              ...state,
              marketShareLoading: false,
              message: action.payload.message,
            };
            break;
          case STORE_DATA:
            return {
              ...state,
              [action.payload.name]: action.payload.data,
            };
  
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default DemandReducer;
  