import {
  GET_STUDENT_PROFILE_DATA,
  GET_STUDENT_PROFILE_DATA_SUCCESS,
  GET_STUDENT_TEAM_DATA_SUCCESS,
  GET_STUDENT_DEMAND_DATA,
  GET_STUDENT_DEMAND_DATA_FAIL,
  GET_STUDENT_DEMAND_DATA_SUCCESS,
  UPDATE_DEMAND_TABLE_ONE,
  UPDATE_DEMAND_TABLE_ONE_FAIL,
  UPDATE_DEMAND_TABLE_ONE_SUCCESS,
  GET_EXPECTED_DEMAND_DATA,
  GET_EXPECTED_DEMAND_DATA_FAIL,
  GET_EXPECTED_DEMAND_DATA_SUCCESS,
  GET_MARKET_SHARES_STUDENT,
  GET_MARKET_SHARES_STUDENT_FAIL,
  GET_MARKET_SHARES_STUDENT_SUCCESS,
  UPDATE_MARKET_SHARES_STUDENT,
  UPDATE_MARKET_SHARES_STUDENT_FAIL,
  UPDATE_MARKET_SHARES_STUDENT_SUCCESS,
  GET_EXPECTED_MARKET_DEMAND_STUDENT,
  GET_EXPECTED_MARKET_DEMAND_STUDENT_FAIL,
  GET_EXPECTED_MARKET_DEMAND_STUDENT_SUCCESS,
  GET_ACTIVITY_DATA,
  GET_ACTIVITY_DATA_SUCCESS,
  GET_ACTIVITY_DATA_FAIL,
  GET_UNIVERSE_DATA,
  GET_UNIVERSE_DATA_SUCCESS,
  GET_UNIVERSE_DATA_FAIL,
  SELECT_UNIVERSE_DATA,
  SELECT_UNIVERSE_DATA_SUCCESS,
  SELECT_UNIVERSE_DATA_FAIL,
  STORE_UNIVERSE_DATA

} from "./actionTypes"

// get student profile data
export const getStudentProfileData = token => ({
  type: GET_STUDENT_PROFILE_DATA,
  payload: { token },
})

//get student profile data success
export const getStudentProfileDataSuccess = (data, teamData, userData) => ({
  type: GET_STUDENT_PROFILE_DATA_SUCCESS,
  payload: { data, teamData, userData },
})

//get student team data success
export const getStudentTeamDataSuccess = teamData => ({
  type: GET_STUDENT_TEAM_DATA_SUCCESS,
  payload: { teamData },
})

//getStudentDemandData
export const getStudentDemandData = (
  token,
  roundSerial,

  teamId
) => ({
  type: GET_STUDENT_DEMAND_DATA,
  payload: { token, roundSerial, teamId },
})

//getStudentDemandDataSuccess
export const getStudentDemandDataSucccess = (demand, marketShare) => ({
  type: GET_STUDENT_DEMAND_DATA_SUCCESS,
  payload: { demand, marketShare },
})

//getStudentDemandDataFail
export const getStudentDemandDataFail = message => ({
  type: GET_STUDENT_DEMAND_DATA_FAIL,
  payload: { message },
})

//update Demand Table One
export const updateDemandTableOne = (
  token,
  region,
  predictedMarketGrowth,
  totalDemandLastRound,
  teamId,
  roundSerial
) => ({
  type: UPDATE_DEMAND_TABLE_ONE,
  payload: {
    token,
    region,
    predictedMarketGrowth,
    totalDemandLastRound,
    teamId,
    roundSerial,
  },
})
//update demand table one success
export const updateDemandTableOneSuccess = message => ({
  type: UPDATE_DEMAND_TABLE_ONE_SUCCESS,
  payload: { message },
})
//update demand table one fail
export const updateDemandTableOneFail = message => ({
  type: UPDATE_DEMAND_TABLE_ONE_FAIL,
  payload: { message },
})

export const getExpectedDemandData = (token, roundSerial) => ({
  type: GET_EXPECTED_DEMAND_DATA,
  payload: { token, roundSerial },
})

export const getExpectedDemandDataSuccess = data => ({
  type: GET_EXPECTED_DEMAND_DATA_SUCCESS,
  payload: { data },
})

export const getExpectedDemandDataFail = message => ({
  type: GET_EXPECTED_DEMAND_DATA_FAIL,
  payload: { message },
})

export const getMarketShareStudent = (token, teamId, roundSerial) => ({
  type: GET_MARKET_SHARES_STUDENT,
  payload: { token, teamId, roundSerial },
})

export const getMarketShareStudentSuccess = data => ({
  type: GET_MARKET_SHARES_STUDENT_SUCCESS,
  payload: { data },
})

export const getMarketShareStudentFail = message => ({
  type: GET_MARKET_SHARES_STUDENT_FAIL,
  payload: { message },
})

export const updateMarketShareStudent = (
  regionId,
  techId,
  lastRound,
  estimateMarketShare,
  forcastDemand,
  totalProductTarget,
  roundSerial,
  token
) => ({
  type: UPDATE_MARKET_SHARES_STUDENT,
  payload: {
    regionId,
    techId,
    lastRound,
    estimateMarketShare,
    forcastDemand,
    totalProductTarget,
    roundSerial,
    token,
  },
})

export const updateMarketShareStudentSuccess = message => ({
  type: UPDATE_MARKET_SHARES_STUDENT_SUCCESS,
  payload: { message },
})

export const updateMarketShareStudentFail = message => ({
  type: UPDATE_MARKET_SHARES_STUDENT_FAIL,
  payload: { message },
})

export const getExpectedMarketDemand = (token, roundSerial, teamId) => ({
  type: GET_EXPECTED_MARKET_DEMAND_STUDENT,
  payload: { token, roundSerial, teamId },
})

export const getExpectedMarketDemandSuccess = data => ({
  type: GET_EXPECTED_MARKET_DEMAND_STUDENT_SUCCESS,
  payload: { data },
})

export const getExpectedMarketDemandFail = message => ({
  type: GET_EXPECTED_MARKET_DEMAND_STUDENT_FAIL,
  payload: { message },
})

export const getActivityData = (token, universe, teamId) => ({
  type: GET_ACTIVITY_DATA,
  payload: { token, universe, teamId },
})

export const getActivityDataSuccess = data => ({
  type: GET_ACTIVITY_DATA_SUCCESS,
  payload: { data },
})

export const getActivityDataFail = message => ({
  type: GET_ACTIVITY_DATA_FAIL,
  payload: { message },
})

export const getUniverseData = (token, universe) => ({
  type: GET_UNIVERSE_DATA,
  payload: { token, universe },
})

export const getUniverseDataSuccess = data => ({
  type: GET_UNIVERSE_DATA_SUCCESS,
  payload: { data },
})

export const getUniverseDataFail = message => ({
  type: GET_UNIVERSE_DATA_FAIL,
  payload: { message },
})


export const selectUniverse = (token, universe) => ({
  type: SELECT_UNIVERSE_DATA,
  payload: { token, universe },
})


export const selectUniverseSuccess = (teamInfo, universeInfo) => ({
  type: SELECT_UNIVERSE_DATA_SUCCESS,
  payload: { teamInfo, universeInfo },
})
export const storeUniverseData = (name, data) => ({
  type: STORE_UNIVERSE_DATA,
  payload: { name, data },
})