import {
  all,
  call,
  delay,
  put,
  takeLatest,
  takeEvery,
} from "redux-saga/effects"

import {
  ACTIVE_ROUND_BY_TEACHER,
  DEACTIVATE_ROUND_BY_TEACHER,
  UPDATE_ROUND_BY_TEACHER
} from "./actionTypes"

import {
  activeRoundByTeacherSuccess,
  activeRoundByTeacherFail,
  deactivateRoundByTeacherFail,
  deactivateRoundByTeacherSuccess,
  updateRoundByTeacherSuccess,
  updateRoundByTeacherFail
} from "./actions"

import {
  axiosActiveRound,
  axiosDeactivateRoundByTeacher,
  axiosUpdateRound
} from "../../helpers/api_helper"
import { toast } from "react-toastify"
const asyncDeactiveRoundByTeacher = async (
  token,
  universeId,
  roundSerial,
  teamIds
) => {
  try {
    const response = await axiosDeactivateRoundByTeacher(
      token,
      universeId,
      roundSerial,
      teamIds
    )
    return response.data
  } catch (error) {}
}
function* workerDeactivateRoundByTeacher({
  payload: { token, universeId, roundSerial, teamIds },
}) {
  try {
    const response = yield call(
      asyncDeactiveRoundByTeacher,
      token,
      universeId,
      roundSerial,
      teamIds
    )

    yield put(deactivateRoundByTeacherSuccess(response.message))
    toast.success(response.message)
    yield put(deactivateRoundByTeacherSuccess())
  } catch (error) {}
}

const asyncActiveRoundByTeacher = async (
  roundSerial,
  teamIds,
  examEndingTime,
  universeId,
  token
) => {
  try {
    const response = await axiosActiveRound(
      token,
      roundSerial,
      examEndingTime,
      universeId,
      teamIds
    )
    console.log("response data", response.data)
    return response.data
  } catch (error) {
    console.log("error data", error.response.data)
    return error.response.data
  }
}

function* workerActiveRoundByTeacher({
  payload: { roundSerial, teamIds, examEndingTime, universeId, token },
}) {
  try {
    const response = yield call(
      asyncActiveRoundByTeacher,
      roundSerial,
      teamIds,
      examEndingTime,
      universeId,
      token
    )
    if (response.status == "success") {
      yield put(activeRoundByTeacherSuccess(response.message))
      toast.success(response.message)
      location.reload()
    } else {
      yield put(activeRoundByTeacherFail(response.message))
      toast.error(response.message)
    }
  } catch (error) {}
}
function* updateRoundByTeacher({ payload: { roundSerial, teamIds, examEndingTime, universeId, token } }) {
  try {
    const response = yield call(axiosUpdateRound, roundSerial, teamIds, examEndingTime, universeId, token)
    console.log("hi")
    console.log(response)
    yield put(updateRoundByTeacherSuccess(response))
   
    toast("🦄 Update Time successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    location.reload()
  } catch (error) {
    if (!error.response) {
      toast("🦄 Update Time successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      let message = error.response.data.message
      yield put(updateRoundByTeacherFail(message))
      toast.error(message)
    }
  }
}


function* roundTeacherSaga() {
  yield takeEvery(ACTIVE_ROUND_BY_TEACHER, workerActiveRoundByTeacher)
  yield takeEvery(DEACTIVATE_ROUND_BY_TEACHER, workerDeactivateRoundByTeacher)
  yield takeEvery(UPDATE_ROUND_BY_TEACHER, updateRoundByTeacher)
}
export default roundTeacherSaga
