import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  featureRemoveFromListSuccess,
  getFeatureAlreadyInUpgradeSuccess,
  getFeatureEnableSuccess,
  getFeatureForRandDSuccess,
  getIsTechEnableSuccess,
  getLatestTechSuccess,
  getPurchasedFeatureSuccess,
  purchaseFeatureByStudentFail,
  purchaseFeatureByStudentSuccess,
  purchaseFeatureFail,
  purchaseFeatureSuccess,
  purchaseTechFail,
  purchaseTechSuccess,
  upgradeFeatureByStudentFail,
  upgradeFeatureByStudentSuccess,
  upgradeFeatureFail,
  upgradeFeatureSuccess,
  upgradeTechFail,
  upgradeTechSuccess,
} from "./actions"
import {
  FEATURE_REMOVE_FROM_LIST,
  GET_FEATURES_FOR_R_AND_D,
  GET_FEATURE_ALREADY_IN_UPGRADE,
  GET_FEATURE_ENABLE,
  GET_IS_TECH_ENABLE,
  GET_LATEST_TECH,
  GET_PURCHASED_FEATURE,
  PURCHASE_FEATURE,
  PURCHASE_FEATURE_BY_STUDENT,
  PURCHASE_TECH,
  UPGRADE_FEATURE,
  UPGRADE_FEATURE_BY_STUDENT,
  UPGRADE_TECH,
} from "./actionTypes"

import {
  axiosAnyTechInProcessing,
  axiosFeatureRemoveFromList,
  axiosGetFeatureForRandD,
  axiosGetIsTechEnable,
  axiosGetLatestTech,
  axiosGetPurchasedFeatureByStudent,
  axiosGetUpgradingFeatureByStudent,
  axiosIsFeatureEnable,
  axiosPurchaseFeature,
  axiosPurchaseFeatureByStudent,
  axiosPurchaseTech,
  axiosUpgradeFeature,
  axiosUpgradeTech,
} from "../../helpers/api_helper"
import { anyTechInProcesingSuccess } from "./../tech/actions"

const asyncPurchaseFeature = async (
  token,
  featureId,
  activeRound,
  featureRealPrice,
  roundSerial,
  universeId
) => {
  try {
    const response = await axiosPurchaseFeature(
      token,
      featureId,
      activeRound,
      featureRealPrice,
      roundSerial,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
//any tech in processing
const asyncAnyTechInProcessing = async (token, teamId, roundSerial) => {
  try {
    const response = await axiosAnyTechInProcessing(token, teamId, roundSerial)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

//cancle upgrade tech

function* workerPurchaseFeature({
  payload: {
    token,
    featureId,
    activeRound,
    featureRealPrice,
    roundSerial,
    universeId,
    setPurchaseBtn
  },
}) {
  try {
    console.log("purchase feature", roundSerial)
    const response = yield call(
      asyncPurchaseFeature,
      token,
      featureId,
      activeRound,
      featureRealPrice,
      roundSerial,
      universeId
    )
    if (response.status == "success") {
      yield put(purchaseFeatureSuccess(response.message))
      setPurchaseBtn(false)
      toast.success(response.message)
      yield put(purchaseFeatureSuccess())
    } else {
      yield put(purchaseFeatureFail(response.message))
      toast.error(response.message)
      yield put(purchaseFeatureFail())
    }
  } catch (error) {}
}

const asyncUpgradeFeature = async (
  token,
  featureId,
  activeRound,
  featureRealPrice,
  roundSerial,
  universeId
) => {
  try {
    const response = await axiosUpgradeFeature(
      token,
      featureId,
      activeRound,
      featureRealPrice,
      roundSerial,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerUpgradeFeature({
  payload: {
    token,
    featureId,
    activeRound,
    featureRealPrice,
    roundSerial,
    universeId,
    setUpgradeBtn
  },
}) {
  try {
    const response = yield call(
      asyncUpgradeFeature,
      token,
      featureId,
      activeRound,
      featureRealPrice,
      roundSerial,
      universeId
    )
    if (response.status == "success") {
      setUpgradeBtn(false)
      yield put(upgradeFeatureSuccess(response.message))
      toast.success(response.message)
      yield put(upgradeFeatureSuccess())
    } else {
      yield put(upgradeFeatureFail(response.message))
      toast.error(response.message)
      yield put(upgradeFeatureFail())
    }
  } catch (error) {}
}

const asyncGetFeatureEnable = async (
  token,
  teamId,
  roundSerial,
  universeId
) => {
  try {
    const response = await axiosIsFeatureEnable(
      token,
      teamId,
      roundSerial,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetFeatureEnable({
  payload: { token, teamId, roundSerial, universeId },
}) {
  try {
    const response = yield call(
      asyncGetFeatureEnable,
      token,
      teamId,
      roundSerial,
      universeId
    )
    console.log("response data  164 hello", response.check, response.data)
    yield put(getFeatureEnableSuccess(response.data?.obj, response.data?.check))
  } catch (error) {
    console.log("error data")
  }
}

const asyncPurchaseTech = async (
  token,
  techId,
  activeRound,
  techRealPrice,
  roundSerial,
  universeId
) => {
  try {
    const response = await axiosPurchaseTech(
      token,
      techId,
      activeRound,
      techRealPrice,
      roundSerial,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerPurchaseTech({
  payload: {
    token,
    techId,
    activeRound,
    techRealPrice,
    roundSerial,
    teamId,
    universeId,
    setPurchaseBtn
  },
}) {
  try {
    const response = yield call(
      asyncPurchaseTech,
      token,
      techId,
      activeRound,
      techRealPrice,
      roundSerial,
      universeId
    )

    if (response.status == "success") {
      yield put(purchaseTechSuccess(response.message))
      toast.success(response.message)
      const res = yield call(
        asyncAnyTechInProcessing,
        token,
        teamId,
        roundSerial,
        universeId
      )
      setPurchaseBtn(false)
      console.log("respone here kay0", res)
      yield put(anyTechInProcesingSuccess(res.getData))
      yield put(purchaseTechSuccess())
    } else {
      yield put(purchaseTechFail(response.message))
      toast.error(response.message)
      yield put(purchaseTechFail())
    }
  } catch (error) {}
}

const asycnUpgradeTech = async (
  token,
  techId,
  activeRound,
  techRealPrice,
  roundSerial,
  universeId
) => {
  try {
    const response = await axiosUpgradeTech(
      token,
      techId,
      activeRound,
      techRealPrice,
      roundSerial,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerUpgradeTech({
  payload: {
    token,
    techId,
    activeRound,
    techRealPrice,
    roundSerial,
    teamId,
    universeId,
    setUpGrdBtn
  },
}) {
  try {
    const response = yield call(
      asycnUpgradeTech,
      token,
      techId,
      activeRound,
      techRealPrice,
      roundSerial,
      universeId
    )
    if (response.status == "success") {
      yield put(upgradeTechSuccess(response.message))
      const res = yield call(
        asyncAnyTechInProcessing,
        token,
        teamId,
        roundSerial,
        universeId
      )

      yield put(anyTechInProcesingSuccess(res.getData))
      setUpGrdBtn(false)
      toast.success(response.message)
      yield put(upgradeTechSuccess())
    } else {
      yield put(upgradeTechFail(response.message))
      toast.error(response.message)
      yield put(upgradeTechFail())
    }
  } catch (error) {}
}

const asyncGetTechEnable = async (
  token,
  teamId,
  techName,
  techId,
  universeId
) => {
  try {
    const response = await axiosGetIsTechEnable(
      token,
      teamId,
      techName,
      techId,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetIsTechEnable({
  payload: { token, teamId, techName, techId, universeId },
}) {
  try {
    const response = yield call(
      asyncGetTechEnable,
      token,
      teamId,
      techName,
      techId,
      universeId
    )
    if (response.disable == true) {
      toast.error(response.message)
      yield put(getIsTechEnableSuccess(response.disable))
    } else {
      yield put(getIsTechEnableSuccess(response.disable))
    }
  } catch (error) {}
}
const asyncGetLatestTech = async (token, teamId) => {
  try {
    const response = await axiosGetLatestTech(token, teamId)
    console.log("hello response from 33", response.data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetLatestTech({ payload: { token, teamId } }) {
  try {
    const response = yield call(asyncGetLatestTech, token, teamId)
    yield put(getLatestTechSuccess(response.latestTech))
  } catch (error) {}
}

const asyncGetPurchasedFeature = async token => {
  try {
    const response = await axiosGetPurchasedFeatureByStudent(token)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetPurcasedFeature({ payload: { token } }) {
  try {
    const response = yield call(asyncGetPurchasedFeature, token)
    yield put(getPurchasedFeatureSuccess(response.getPurchasingFeature))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncPurchaseFeatureByStudent = async (
  token,
  roundActive,
  featureId,
  cost,
  roundSerial
) => {
  try {
    const response = await axiosPurchaseFeatureByStudent(
      token,
      roundActive,
      featureId,
      cost,
      roundSerial
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerPurcahseFeatureByStudent({
  payload: { token, roundActive, featureId, cost, roundSerial },
}) {
  try {
    const response = yield call(
      asyncPurchaseFeatureByStudent,
      token,
      roundActive,
      featureId,
      cost,
      roundSerial
    )
    if (response.status == "success") {
      toast.success(response.message)
      yield put(purchaseFeatureByStudentSuccess(response.message))
    } else {
      toast.error(response.message)
      yield put(purchaseFeatureByStudentFail(response.message))
    }
  } catch (error) {
    console.log(error)
  }
}

const asyncGetUpgradingFeature = async token => {
  try {
    const response = await axiosGetUpgradingFeatureByStudent(token)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetFeatureAlreadyInUpgrade({ payload: { token } }) {
  try {
    const response = yield call(asyncGetUpgradingFeature, token)
    yield put(getFeatureAlreadyInUpgradeSuccess(response.getUpgradingFeatures))
  } catch (error) {
    console.log("error data")
  }
}

const asyncUpgradeFeatureByStudent = async (
  token,
  roundActive,
  featureId,
  cost,
  roundSerial
) => {
  try {
    // const response =
    //   await axiosUpgradeFeatureByStudent(
    //   token,
    //   roundActive,
    //   featureId,
    //   cost,
    //   roundSerial
    // )
    // return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerUpgradeFeatureByStudent({
  payload: { token, roundActive, featureId, cost, roundSerial },
}) {
  try {
    const response = yield call(
      asyncUpgradeFeatureByStudent,
      token,
      roundActive,
      featureId,
      cost,
      roundSerial
    )
    if (response.status == "success") {
      toast.success(response.message)
      yield put(upgradeFeatureByStudentSuccess(response.message))
      yield put(upgradeFeatureByStudentSuccess())
    } else {
      toast.error(response.message)
      yield put(upgradeFeatureByStudentFail(response.message))
      yield put(upgradeFeatureByStudentFail())
    }
  } catch (error) {}
}

const asyncGetFeatureForRandD = async token => {
  try {
    const response = await axiosGetFeatureForRandD(token)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetFeatureForRandD({ payload: { token } }) {
  try {
    const response = yield call(asyncGetFeatureForRandD, token)
    yield put(getFeatureForRandDSuccess(response.getFeaturesData))
  } catch (error) {
    return error.response.data
  }
}
//feature list
const asyncFeatureRemoveList = async (token, featureId) => {
  try {
    const response = await axiosFeatureRemoveFromList(token, featureId)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerFeatureRemoveList({ payload: { token, featureId } }) {
  try {
    const response = yield call(asyncFeatureRemoveList, token, featureId)
    yield put(featureRemoveFromListSuccess(response.message))
    toast.success(response.message)
    yield put(featureRemoveFromListSuccess())
  } catch (error) {
    console.log("no message")
  }
}

function* studentRandDSaga() {
  yield takeEvery(GET_FEATURES_FOR_R_AND_D, workerGetFeatureForRandD)
  yield takeEvery(UPGRADE_FEATURE_BY_STUDENT, workerUpgradeFeatureByStudent)
  yield takeEvery(
    GET_FEATURE_ALREADY_IN_UPGRADE,
    workerGetFeatureAlreadyInUpgrade
  )
  yield takeEvery(PURCHASE_FEATURE_BY_STUDENT, workerPurcahseFeatureByStudent)
  yield takeEvery(GET_PURCHASED_FEATURE, workerGetPurcasedFeature)
  yield takeEvery(GET_LATEST_TECH, workerGetLatestTech)
  yield takeEvery(GET_IS_TECH_ENABLE, workerGetIsTechEnable)
  yield takeEvery(UPGRADE_TECH, workerUpgradeTech)
  yield takeEvery(PURCHASE_TECH, workerPurchaseTech)
  yield takeEvery(GET_FEATURE_ENABLE, workerGetFeatureEnable)
  yield takeEvery(UPGRADE_FEATURE, workerUpgradeFeature)
  yield takeEvery(PURCHASE_FEATURE, workerPurchaseFeature)
  yield takeEvery(FEATURE_REMOVE_FROM_LIST, workerFeatureRemoveList)
}

export default studentRandDSaga
