import {
  ADD_REGION_FROM_FILE_FAIL,
  ADD_REGION_FROM_FILE_SUCCESS,
  ADD_REGION_FROM_FILE,
  GET_REGIONS,
  GET_REGIONS_SUCCESS,
  GET_PRODUCTION_REGIONS_SUCCESSS,
  GET_PRODUCTION_REGIONS,
  GET_PLANT_PRICE_REGIONS,
  GET_PLANT_PRICE_REGIONS_SUCCESSS,
  GET_ONLY_PRODUCTION_REGION_DATA,
  GET_ONLY_PRODUCTION_REGION_DATA_SUCCESS,
} from "./actionTypes"

const initialState = {
  regions: [],
  region: {},
  productionRegion: [],
  productionRegionAdmin: [],
  loading: false,
  message: "",
  error: "",
  plantPrice: {},
}

const regionData = (state = initialState, action) => {
  switch (action.type) {
    case ADD_REGION_FROM_FILE:
      state = { ...state, loading: true }
      break
    case ADD_REGION_FROM_FILE_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case ADD_REGION_FROM_FILE_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case GET_REGIONS:
      state = { ...state, loading: true }
      break
    case GET_REGIONS_SUCCESS:
      state = { ...state, loading: false, regions: action.payload.data }
      break
    case GET_ONLY_PRODUCTION_REGION_DATA:
      state = { ...state, loading: true }
      break
    case GET_ONLY_PRODUCTION_REGION_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        productionRegionAdmin: action.payload.data,
      }
      break
    case GET_PRODUCTION_REGIONS:
      state = { ...state, loading: true }
      break

    case GET_PRODUCTION_REGIONS_SUCCESSS:
      state = {
        ...state,
        loading: false,
        productionRegion: action.payload.data,
      }
      break
    case GET_PLANT_PRICE_REGIONS:
      state = { ...state, loading: true }
      break
    case GET_PLANT_PRICE_REGIONS_SUCCESSS:
      state = {
        ...state,
        loading: false,
        plantPrice: action.payload.data,
      }
    default:
      state = state
      break
  }
  return state
}
export default regionData
