
import { axiosGetPlantUpgrade, axiosPlantUpgrade, axiosUpdatePlantUpgrade } from "helpers/api_helper";
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";

import { toaster } from "../../helpers/custom/Toast";
import { getPlantUpgrade, getPlantUpgradeFail, getPlantUpgradeSuccess, postPlantUpgradeFail, postPlantUpgradeSuccess, storePlantUpgradeData, updatePlantUpgradeFail, updatePlantUpgradeSuccess } from "./actions";
import { GET_PLANT_UPGRADE, POST_PLANT_UPGRADE, UPDATE_PLANT_UPGRADE } from "./actionTypes";



//for main


function* onGetPlantUpgrade({ payload: { token, course, region } }) {
  console.log('hello');
  console.log("type", course, region);
  try {
   
    const response = yield call(axiosGetPlantUpgrade, token, course, region);
    
    yield put(getPlantUpgradeSuccess(response?.data?.getData));
    console.log('response?.data?.getData', response?.data?.getData);
    yield put(storePlantUpgradeData('modifiedPlant', response?.data?.getData?.plantPrice));
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed";
    toaster("error", message);
    yield put(getPlantUpgradeFail(message));
  }
}
function* onPostPlantUpgrade({ payload: { values, history, token } }) {
  try {
    console.log('values', values);
    const response = yield call(axiosPlantUpgrade, values, token)
    console.log("hi")
    console.log(response)
    yield put(postPlantUpgradeSuccess(response))
    yield put(getPlantUpgrade(token, values?.course, values?.region))
    toast("🦄 Plant Upgraded Successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Plant Upgraded  successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(postPlantUpgradeFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}

function* onUpdatePlantUpgrade({ payload: { values, history, token, groupId, singleId, courseInfo, regionInfo } }) {
  try {
    const response = yield call(axiosUpdatePlantUpgrade, values, token, groupId, singleId)
    console.log("hi")
    console.log(response)
    yield put(updatePlantUpgradeSuccess(response))
    yield put(getPlantUpgrade(token, courseInfo, regionInfo))
    toast("🦄 plant upgrade updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 plant upgrade updated successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(updatePlantUpgradeFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}


function* PlantUpgradeSaga() {
  yield takeEvery(POST_PLANT_UPGRADE, onPostPlantUpgrade);
  yield takeEvery(GET_PLANT_UPGRADE, onGetPlantUpgrade);
  yield takeEvery(UPDATE_PLANT_UPGRADE, onUpdatePlantUpgrade);
}
export default PlantUpgradeSaga;
