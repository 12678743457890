//get latest tech
export const GET_LATEST_TECH = "GET_LATEST_TECH"
export const GET_LATEST_TECH_SUCCESS = "GET_LATEST_TECH_SUCCESS"

//1 get Features
export const GET_FEATURES_FOR_R_AND_D = "GET_FEATURES_FOR_R_AND_D"
export const GET_FEATURES_FOR_R_AND_D_SUCCESS =
  "GET_FEATURES_FOR_R_AND_D_SUCCESS"
export const GET_FEATURES_FOR_R_AND_D_FAIL = "GET_FEATURES_FOR_R_AND_D_FAIL"

//2 feature start to processing
//purchase
export const PURCHASE_FEATURE_BY_STUDENT = "PURCHASE_FEATURE_BY_STUDENT"
export const PURCHASE_FEATURE_BY_STUDENT_SUCCESS =
  "PURCHASE_FEATURE_BY_STUDENT_SUCCESS"
export const PURCHASE_FEATURE_BY_STUDENT_FAIL =
  "PURCHASE_FEATURE_BY_STUDENT_FAIL"
//upgrade
export const UPGRADE_FEATURE_BY_STUDENT = "UPGRADE_FEATURE_BY_STUDENT"
export const UPGRADE_FEATURE_BY_STUDENT_SUCCESS =
  "UPGRADE_FEATURE_BY_STUDENT_SUCCESS"
export const UPGRADE_FEATURE_BY_STUDENT_FAIL = "UPGRADE_FEATURE_BY_STUDENT_FAIL"

//3 get Feature Already in processing
//purchase
export const GET_PURCHASED_FEATURE = "GET_PURCHASED_FEATURE"
export const GET_PURCHASED_FEATURE_SUCCESS = "GET_PURCHASED_FEATURE_SUCCESS"
//upgrade
export const GET_FEATURE_ALREADY_IN_UPGRADE = "GET_FEATURE_ALREADY_IN_UPGRADE"
export const GET_FEATURE_ALREADY_IN_UPGRADE_SUCCESS =
  "GET_FEATURE_ALREADY_IN_UPGRADE_SUCCESS"

//from new
export const GET_IS_TECH_ENABLE = "GET_IS_TECH_ENABLE"
export const GET_IS_TECH_ENABLE_SUCCESS = "GET_IS_TECH_ENABLE_SUCCESS"

//upgrade Tech
export const UPGRADE_TECH = "UPGRADE_TECH"
export const UPGRADE_TECH_SUCCESS = "UPGRADE_TECH_SUCCESS"
export const UPGRADE_TECH_FAIL = "UPGRADE_TECH_FAIL"

//purchase tech
export const PURCHASE_TECH = "PURCHASE_TECH"
export const PURCHASE_TECH_SUCCESS = "PURCHASE_TECH_SUCCESS"
export const PURCHASE_TECH_FAIL = "PURCHASE_TECH_FAIL"

//get feature is enable or not
export const GET_FEATURE_ENABLE = "GET_FEATURE_ENABLE"
export const GET_FEATURE_ENABLE_SUCCESS = "GET_FEATURE_ENABLE_SUCCESS"
export const GET_FEATURE_ENABLE_FAIL = "GET_FEATURE_ENABLE_FAIL"

//upgrade feature
export const UPGRADE_FEATURE = "UPGRADE_FEATURE"
export const UPGRADE_FEATURE_SUCCESS = "UPGRADE_FEATURE_SUCCESS"
export const UPGRADE_FEATURE_FAIL = "UPGRADE_FEATURE_FAIL"

//purchase feature
export const PURCHASE_FEATURE = "PURCHASE_FEATURE"
export const PURCHASE_FEATURE_SUCCESS = "PURCHASE_FEATURE_SUCCESS"
export const PURCHASE_FEATURE_FAIL = "PURCHASE_FEATURE_FAIL"
//Remove Purchased or upgrage feature
export const FEATURE_REMOVE_FROM_LIST = "FEATURE_REMOVE_FROM_LIST"
export const FEATURE_REMOVE_FROM_LIST_SUCCESS =
  "FEATURE_REMOVE_FROM_LIST_SUCCESS"
