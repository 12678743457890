import {
  ADD_MARKET_SHARE_LAST_ROUND,
  ADD_MARKET_SHARE_LAST_ROUND_FAIL,
  ADD_MARKET_SHARE_LAST_ROUND_SUCCESS,
  GET_MARKET_SHARE_LAST_ROUND,
  GET_MARKET_SHARE_LAST_ROUND_SUCCESS,
  ADMIN_GET_MARKET_SHARE,
  ADMIN_GET_MARKET_SHARE_SUCCESS,
} from "./actionTypes"

const initialState = {
  marketShares: [],
  adminMarketShare: [],
  loading: false,
  message: "",
  error: "",
}

const AdminMarketShares = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MARKET_SHARE_LAST_ROUND:
      state = { ...state, loading: true }
      break
    case ADD_MARKET_SHARE_LAST_ROUND_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case ADD_MARKET_SHARE_LAST_ROUND_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case GET_MARKET_SHARE_LAST_ROUND:
      state = { ...state, loading: true }
      break
    case GET_MARKET_SHARE_LAST_ROUND_SUCCESS:
      state = { ...state, loading: false, marketShares: action.payload.data }
      break

    case ADMIN_GET_MARKET_SHARE:
      state = { ...state, loading: true }
      break
    case ADMIN_GET_MARKET_SHARE_SUCCESS:
      state = {
        ...state,
        loading: false,
        adminMarketShare: action.payload.data,
      }
      break
    default:
      state = state
      break
  }
  return state
}

export default AdminMarketShares
