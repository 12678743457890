

export const POST_WEIGHT_STRATEGY = "POST_WEIGHT_STRATEGY";
export const POST_WEIGHT_STRATEGY_SUCCESS = "POST_WEIGHT_STRATEGY_SUCCESS";
export const POST_WEIGHT_STRATEGY_FAIL = "POST_WEIGHT_STRATEGY_FAIL";

export const POST_PRODUCT_STRATEGY = "POST_PRODUCT_STRATEGY";
export const POST_PRODUCT_STRATEGY_SUCCESS = "POST_PRODUCT_STRATEGY_SUCCESS";
export const POST_PRODUCT_STRATEGY_FAIL = "POST_PRODUCT_STRATEGY_FAIL";

export const POST_PROMOTION_STRATEGY = "POST_PROMOTION_STRATEGY";
export const POST_PROMOTION_STRATEGY_SUCCESS = "POST_PROMOTION_STRATEGY_SUCCESS";
export const POST_PROMOTION_STRATEGY_FAIL = "POST_PROMOTION_STRATEGY_FAIL";


export const POST_PRICE_STRATEGY = "POST_PRICE_STRATEGY";
export const POST_PRICE_STRATEGY_SUCCESS = "POST_PRICE_STRATEGY_SUCCESS";
export const POST_PRICE_STRATEGY_FAIL = "POST_PRICE_STRATEGY_FAIL";


export const GET_WEIGHT_STRATEGY = "GET_WEIGHT_STRATEGY";
export const GET_WEIGHT_STRATEGY_SUCCESS = "GET_WEIGHT_STRATEGY_SUCCESS";
export const GET_WEIGHT_STRATEGY_FAIL = "GET_WEIGHT_STRATEGY_FAIL";

export const GET_PRODUCT_STRATEGY = "GET_PRODUCT_STRATEGY";
export const GET_PRODUCT_STRATEGY_SUCCESS = "GET_PRODUCT_STRATEGY_SUCCESS";
export const GET_PRODUCT_STRATEGY_FAIL = "GET_PRODUCT_STRATEGY_FAIL";

export const GET_PROMOTION_STRATEGY = "GET_PROMOTION_STRATEGY";
export const GET_PROMOTION_STRATEGY_SUCCESS = "GET_PROMOTION_STRATEGY_SUCCESS";
export const GET_PROMOTION_STRATEGY_FAIL = "GET_PROMOTION_STRATEGY_FAIL";

export const GET_PRICE_STRATEGY = "GET_PRICE_STRATEGY";
export const GET_PRICE_STRATEGY_SUCCESS = "GET_PRICE_STRATEGY_SUCCESS";
export const GET_PRICE_STRATEGY_FAIL = "GET_PRICE_STRATEGY_FAIL";


export const UPDATE_WEIGHT_STRATEGY = "UPDATE_WEIGHT_STRATEGY";
export const UPDATE_WEIGHT_STRATEGY_SUCCESS = "UPDATE_WEIGHT_STRATEGY_SUCCESS";
export const UPDATE_WEIGHT_STRATEGY_FAIL = "UPDATE_WEIGHT_STRATEGY_FAIL";

export const UPDATE_PRICE_STRATEGY = "UPDATE_PRICE_STRATEGY";
export const UPDATE_PRICE_STRATEGY_SUCCESS = "UPDATE_PRICE_STRATEGY_SUCCESS";
export const UPDATE_PRICE_STRATEGY_FAIL = "UPDATE_PRICE_STRATEGY_FAIL";

export const UPDATE_PROMOTION_STRATEGY = "UPDATE_PROMOTION_STRATEGY";
export const UPDATE_PROMOTION_STRATEGY_SUCCESS = "UPDATE_PROMOTION_STRATEGY_SUCCESS";
export const UPDATE_PROMOTION_STRATEGY_FAIL = "UPDATE_PROMOTION_STRATEGY_FAIL";


export const UPDATE_PRODUCT_STRATEGY = "UPDATE_PRODUCT_STRATEGY";
export const UPDATE_PRODUCT_STRATEGY_SUCCESS = "UPDATE_PRODUCT_STRATEGY_SUCCESS";
export const UPDATE_PRODUCT_STRATEGY_FAIL = "UPDATE_PRODUCT_STRATEGY_FAIL";

export const STORE_STRATEGY_DATA = "STORE_STRATEGY_DATA";