//add data from files
export const ADD_TECH_FROM_FILE = "ADD_TECH_FROM_FILE"
export const ADD_TECH_FROM_FILE_SUCCESS = "ADD_TECH_FROM_FILE_SUCCESS"
export const ADD_TECH_FROM_FILE_FAIL = "ADD_TECH_FROM_FILE_FAIL"

//get tech
export const GET_TECHS = "GET_TECHS"
export const GET_TECHS_SUCCESS = "GET_TECHS_SUCCESS"
export const GET_TECHS_FAIL = "GET_TECHS_FAIL"

//get available tech
export const GET_AVAILABLE_TECH = "GET_AVAILABLE_TECH"
export const GET_AVAILABLE_TECH_SUCCESS = "GET_AVAILABLE_TECH_SUCCESS"

//get unavailable tech
export const GET_UNAVAILABLE_TECH = "GET_UNAVAILABLE_TECH"
export const GET_UNAVAILABLE_TECH_SUCCESS = "GET_UNAVAILABLE_TECH_SUCCESS"

//get unavailable tech for R And D
export const GET_UNAVAILABLE_TECH_FOR_R_AND_D =
  "GET_UNAVAILABLE_TECH_FOR_R_AND_D"
export const GET_UNAVAILABLE_TECH_FOR_R_AND_D_SUCCESS =
  "GET_UNAVAILABLE_TECH_FOR_R_AND_D_SUCCESS"

//get available tech for admin
export const GET_AVAILABLE_TECH_FOR_ADMIN = "GET_AVAILABLE_TECH_FOR_ADMIN"
export const GET_AVAILABLE_TECH_FOR_ADMIN_SUCCESS =
  "GET_AVAILABLE_TECH_FOR_ADMIN_SUCCESS"

//tech in processing
export const ANY_TECH_IN_PROCESSING = "ANY_TECH_IN_PROCESSING"
export const ANY_TECH_IN_PROCESSING_SUCCESS = "ANY_TECH_IN_PROCESSING_SUCCESS"
