import {
  GET_COURSE_FOR_TEACHER,
  GET_COURSE_FOR_TEACHER_SUCCESS,
  GET_SINGLE_COURSE_SUCCESS,
  GET_SINGLE_COURSE,
  GET_SINGLE_COURSE_FAIL,
} from "./actionTypes"

const initialState = {
  courses: [],
  loading: false,
  course: {},
  error: "",
}

const courseForTeacher = (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSE_FOR_TEACHER:
      state = { ...state, loading: true }
      break
    case GET_COURSE_FOR_TEACHER_SUCCESS:
      state = { ...state, loading: false, courses: action.payload.courses }
      break
    case GET_SINGLE_COURSE:
      state = { ...state, loading: true }
      break
    case GET_SINGLE_COURSE_SUCCESS:
      state = { ...state, loading: false, course: action.payload.course }
      break
    case GET_SINGLE_COURSE_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
    default:
      state = state
      break
  }
  return state
}

export default courseForTeacher
