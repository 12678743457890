export const GET_FEATURE_BY_ADMIN = "GET_FEATURE_BY_ADMIN"
export const GET_FEATURE_BY_ADMIN_SUCCESS = "GET_FEATURE_BY_ADMIN_SUCCESS"
export const GET_FEATURE_BY_ADMIN_FAIL = "GET_FEATURE_BY_ADMIN_FAIL"

export const ADD_FEATURE_BY_ADMIN = "ADD_FEATURE_BY_ADMIN"
export const ADD_FEATURE_BY_ADMIN_SUCCESS = "ADD_FEATURE_BY_ADMIN_SUCCESS"
export const ADD_FEATURE_BY_ADMIN_FAIL = "ADD_FEATURE_BY_ADMIN_FAIL"

export const MAKE_FEATURE_AVAILABLE_BY_ADMIN = "MAKE_FEATURE_AVAILABLE_BY_ADMIN"
export const MAKE_FEATURE_AVAILABLE_BY_ADMIN_SUCCESS =
  "MAKE_FEATURE_AVAILABLE_BY_ADMIN_SUCCESS"
export const MAKE_FEATURE_AVAILABLE_BY_ADMIN_FAIL =
  "MAKE_FEATURE_AVAILABLE_BY_ADMIN_FAIL"
