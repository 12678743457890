import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PASS,
  UPDATE_PASS_FAIL,
  UPDATE_PASS_SUCCESS,
} from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const getProfile = token => {
  return {
    type: GET_PROFILE,
    payload: { token },
  }
}

export const getProfileSuccess = data => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: { data },
  }
}

export const updateProfile = (token, firstName, lastName, phoneNumber) => {
  return {
    type: UPDATE_PROFILE,
    payload: { token, firstName, lastName, phoneNumber },
  }
}

export const updateProfileSuccess = message => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: { message },
  }
}

export const updateProfileFail = message => {
  return {
    type: UPDATE_PROFILE_FAIL,
    payload: { message },
  }
}

export const updatePassword = (
  token,
  currentPassword,
  password,
  confirmPassword
) => {
  return {
    type: UPDATE_PASS,
    payload: { token, currentPassword, password, confirmPassword },
  }
}

export const updatePasswordSuccess = message => {
  return {
    type: UPDATE_PASS_SUCCESS,
    payload: { message },
  }
}

export const updatePasswordFail = message => {
  return {
    type: UPDATE_PASS_FAIL,
    payload: { message },
  }
}
