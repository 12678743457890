import React from "react"
import { Container } from "react-bootstrap"

const AdminEditTech = () => {
  return (
    <div>
      <Container>
        <h2>hi</h2>
      </Container>
    </div>
  )
}

export default AdminEditTech
