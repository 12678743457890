import {
  ADD_COURSE,
  ADD_COURSE_SUCCESS,
  ADD_COURSE_FAIL,
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_FOR_ONE,
  GET_COURSE_FOR_ONE_SUCCESS,
  GET_COURSE_ALL_DATA,
  GET_COURSE_ALL_DATA_SUCCESS,
  DELETE_COURSE,
  DELETE_COURSE_FAIL,
  DELETE_COURSE_SUCCESS,
} from "./actionTypes"

const initialState = {
  courses: [],
  singleCoursedata: [],
  loading: false,
  message: "",
  error: "",
}

const courseAdmin = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COURSE:
      state = { ...state, loading: true }
      break
    case ADD_COURSE_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case ADD_COURSE_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case GET_COURSE:
      state = { ...state, loading: true }
      break
    case GET_COURSE_SUCCESS:
      state = { ...state, loading: false, courses: action.payload.courses }
      break
    case GET_COURSE_FOR_ONE:
      state = { ...state, loading: true }
      break
    case GET_COURSE_FOR_ONE_SUCCESS:
      state = { ...state, loading: false, courses: action.payload.courseforone }
      break

    case GET_COURSE_ALL_DATA:
      state = { ...state, loading: true }
      break
    case GET_COURSE_ALL_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleCoursedata: action.payload.data,
      }
      break
    case DELETE_COURSE:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELETE_COURSE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case DELETE_COURSE_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      }
      break
    default:
      state = state
      break
  }

  return state
}

export default courseAdmin
