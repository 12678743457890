import {
  GET_STUDENT_PROFILE_DATA,
  GET_STUDENT_PROFILE_DATA_SUCCESS,
  GET_STUDENT_TEAM_DATA_SUCCESS,
  GET_STUDENT_DEMAND_DATA_FAIL,
  GET_STUDENT_DEMAND_DATA_SUCCESS,
  GET_STUDENT_DEMAND_DATA,
  UPDATE_DEMAND_TABLE_ONE,
  UPDATE_DEMAND_TABLE_ONE_FAIL,
  UPDATE_DEMAND_TABLE_ONE_SUCCESS,
  GET_EXPECTED_DEMAND_DATA,
  GET_EXPECTED_DEMAND_DATA_FAIL,
  GET_EXPECTED_DEMAND_DATA_SUCCESS,
  GET_MARKET_SHARES_STUDENT,
  GET_MARKET_SHARES_STUDENT_FAIL,
  GET_MARKET_SHARES_STUDENT_SUCCESS,
  UPDATE_MARKET_SHARES_STUDENT_FAIL,
  UPDATE_MARKET_SHARES_STUDENT_SUCCESS,
  UPDATE_MARKET_SHARES_STUDENT,
  GET_EXPECTED_MARKET_DEMAND_STUDENT_FAIL,
  GET_EXPECTED_MARKET_DEMAND_STUDENT_SUCCESS,
  GET_EXPECTED_MARKET_DEMAND_STUDENT,
  GET_ACTIVITY_DATA_SUCCESS,
  GET_ACTIVITY_DATA_FAIL,
  GET_UNIVERSE_DATA_SUCCESS,
  GET_UNIVERSE_DATA_FAIL,
  SELECT_UNIVERSE_DATA,
  SELECT_UNIVERSE_DATA_SUCCESS,
  STORE_UNIVERSE_DATA
} from "./actionTypes"

const Initial_State = {
  profile: {},
  teamData: {},
  studentDemand: {},
  userData:{},
  teamInfo:{},
  universeInfo:{},
  studentMarketShare: {},
  studentExpectedDemand: {},
  studentProductMarket: {},
  studentExpectedMarketDemand: {},
  activityData: [],
  universeData: [],
  singleUniverse: "",
  loading: false,
  message: "",
  error: "",
  activityDataLoading: true,
  universeDataLoading: true,
  universeInfoLoading: true
}

const studentProfileData = (state = Initial_State, action) => {
  switch (action.type) {
    case GET_STUDENT_PROFILE_DATA:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_STUDENT_PROFILE_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        profile: action.payload.data,
        teamData: action.payload.teamData,
        userData: action.payload.userData,
      }
      break
      case SELECT_UNIVERSE_DATA:
        state = {
          ...state,
          universeInfoLoading: true,
        }
        break
      case SELECT_UNIVERSE_DATA_SUCCESS:
        state = {
          ...state,
          universeInfoLoading: false,
          teamInfo: action.payload.teamInfo,
          universeInfo: action.payload.universeInfo,
        }
        break
    case GET_STUDENT_DEMAND_DATA:
      state = { ...state, loading: true }
      break
    case GET_STUDENT_DEMAND_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        studentDemand: action.payload.demand,
        studentMarketShare: action.payload.marketShare,
      }
      break
    case GET_STUDENT_DEMAND_DATA_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
    case UPDATE_DEMAND_TABLE_ONE:
      state = { ...state, loading: true }
      break
    case UPDATE_DEMAND_TABLE_ONE_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case UPDATE_DEMAND_TABLE_ONE_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case GET_EXPECTED_DEMAND_DATA:
      state = { ...state, loading: true }
      break
    case GET_EXPECTED_DEMAND_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        studentExpectedDemand: action.payload.data,
      }
      break
    case GET_MARKET_SHARES_STUDENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_MARKET_SHARES_STUDENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        studentProductMarket: action.payload.data,
      }
      break
    case UPDATE_MARKET_SHARES_STUDENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_MARKET_SHARES_STUDENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case UPDATE_MARKET_SHARES_STUDENT_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case GET_EXPECTED_MARKET_DEMAND_STUDENT:
      state = {
        ...state,
        loading: false,
      }
      break
    case GET_EXPECTED_MARKET_DEMAND_STUDENT_SUCCESS:
      state = {
        ...state,
        loading: true,
        studentExpectedMarketDemand: action.payload.data,
      }
      break
    case GET_EXPECTED_MARKET_DEMAND_STUDENT_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
      case GET_ACTIVITY_DATA_SUCCESS:
        state = {
          ...state,
          activityDataLoading: false,
          activityData: action.payload,
        }
        break
      case GET_ACTIVITY_DATA_FAIL:
        state = {
          ...state,
          activityDataLoading: false,
          error: action.payload.message,
        }
        break
        case GET_UNIVERSE_DATA_SUCCESS:
          state = {
            ...state,
            universeData: false,
            universeDataLoading: action.payload,
          }
          break
        case GET_UNIVERSE_DATA_FAIL:
          state = {
            ...state,
            universeDataLoading: false,
            error: action.payload.message,
          }
          break
          case STORE_UNIVERSE_DATA:
            return {
              ...state,
              [action.payload.name]: action.payload.data,
           
            
            }
            break
    default:
      state = state
      break
  }
  return state
}

export default studentProfileData
