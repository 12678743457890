import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { postFakeForgetPwd } from "../../../helpers/backend_helper"
import {
  userForgetPasswordError,
  userForgetPasswordSuccess,
  recoverAccountFail,
  recoverAccountSuccess,
} from "./actions"
// Login Redux States
import { FORGET_PASSWORD, RECOVER_ACCOUNT } from "./actionTypes"
import { axiosRecoverAccount } from "./../../../helpers/api_helper"
import { toast } from "react-toastify"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeForgetPwd, "/fake-forget-pwd", {
        email: user.email,
      })
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

//worker recover account
const asyncRecoverAccount = async email => {
  try {
    const response = await axiosRecoverAccount(email)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerRecoverAccount({ payload: { email, history } }) {
  try {
    const response = yield call(asyncRecoverAccount, email)
    console.log("resposne here", response)
    if (response.status == "success") {
      yield put(recoverAccountSuccess(response.message))
      toast.success(response.message)
      yield put(recoverAccountSuccess())
    } else {
      yield put(recoverAccountFail(response.message))
      toast.error(response.message)
      yield put(recoverAccountFail())
    }
  } catch (error) {
    console.log("hello error")
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(RECOVER_ACCOUNT, workerRecoverAccount)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
