import {
  GET_INITIAL_CAPITAL,
  GET_INITIAL_CAPITAL_SUCCESS,
  GET_INITIAL_CAPITAL_FAIL,
} from "./actionTypes"

export const getInitialCapital = (token, teamId, roundSerial, universeId) => ({
  type: GET_INITIAL_CAPITAL,
  payload: { token, teamId, roundSerial, universeId },
})

export const getInitialCapitalSuccess = data => ({
  type: GET_INITIAL_CAPITAL_SUCCESS,
  payload: { data },
})
