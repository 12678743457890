
import { axiosContractData, axiosFeaturePurchase, axiosFeatureUpgrade, axiosGetPlantUpgrade, axiosInHouseData, axiosPlantUpgrade, axiosTechPurchase, axiosTechUpgrade, axiosUpdatePlantUpgrade } from "helpers/api_helper";
import { getData, patchData } from "helpers/Custom_Helper";
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";

import { toaster } from "../../helpers/custom/Toast";
import { getFeaturePurchase, getFeaturePurchaseFail, getFeaturePurchaseSuccess, getFeatureUpgrade, getFeatureUpgradeFail, getFeatureUpgradeSuccess, getTechPurchase, getTechPurchaseFail, getTechPurchaseSuccess, getTechUpgrade, getTechUpgradeFail, getTechUpgradeSuccess, postFeaturePurchaseFail, postFeaturePurchaseSuccess, postFeatureUpgradeFail, postFeatureUpgradeSuccess, postTechPurchaseFail, postTechPurchaseSuccess, postTechUpgradeFail, postTechUpgradeSuccess, storeRnDData, updateFeaturePurchaseFail, updateFeaturePurchaseSuccess, updateFeatureUpgradeFail, updateFeatureUpgradeSuccess, updateTechPurchaseFail, updateTechPurchaseSuccess, updateTechUpgradeFail, updateTechUpgradeSuccess } from "./actions";
import { GET_FEATURE_PURCHASE, GET_FEATURE_UPGRADE, GET_TECH_PURCHASE, GET_TECH_UPGRADE, POST_FEATURE_PURCHASE, POST_FEATURE_UPGRADE, POST_TECH_PURCHASE, POST_TECH_UPGRADE, UPDATE_FEATURE_PURCHASE, UPDATE_FEATURE_UPGRADE, UPDATE_TECH_PURCHASE, UPDATE_TECH_UPGRADE } from "./actionTypes";



//for main



function* onPostTechPurchase({ payload: { values, history, token } }) {
  try {
    const response = yield call(axiosTechPurchase, values, token)
    console.log("hi")
    console.log(response)
    yield put(postTechPurchaseSuccess(response))
    toast("🦄 Tech purchase Successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Tech purchase  successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(postTechPurchaseFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}

function* onPostTechUpgrade({ payload: { values, history, token } }) {
  try {
    const response = yield call(axiosTechUpgrade, values, token)
    console.log("hi")
    console.log(response)
    yield put(postTechUpgradeSuccess(response))
    toast("🦄 Tech Upgraded Successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Tech Upgraded  successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(postTechUpgradeFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}
function* onPostFeaturePurchase({ payload: { values, history, token } }) {
  try {
    const response = yield call(axiosFeaturePurchase, values, token)
    console.log("hi")
    console.log(response)
    yield put(postFeaturePurchaseSuccess(response))
    toast("🦄 Feature purchased Successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Feature purchased Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(postFeaturePurchaseFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}
function* onPostFeatureUpgrade({ payload: { values, history, token } }) {
  try {
    const response = yield call(axiosFeatureUpgrade, values, token)
    console.log("hi")
    console.log(response)
    yield put(postFeatureUpgradeSuccess(response))
    toast("🦄 Feature Upgraded Successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Feature Upgraded Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(postFeatureUpgradeFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}
function* onGetTechPurchase({ payload: { token, course, tech }, }) {
  try {
    const url = `tech-purchase/data-get/${course}/${tech}`;
    const response = yield call(getData, url, token);
    yield put(getTechPurchaseSuccess(response?.getData));
    yield put(storeRnDData("modifiedTechPurchase",response?.getData?.techDetails));
  } catch (error) {
    const message = error.response?.data?.message || "get banner failed";
    yield put(getTechPurchaseFail(message));
    toaster("error", message);
  }
}

function* onGetTechUpgrade({ payload: { token, course, tech }, }) {
  try {
    const url = `tech-upgradation/data-get/${course}/${tech}`;
    const response = yield call(getData, url, token);
    yield put(getTechUpgradeSuccess(response?.getData));
    yield put(storeRnDData("modifiedTechUpgrade",response?.getData?.techDetails));
  } catch (error) {
    const message = error.response?.data?.message || "get banner failed";
    yield put(getTechUpgradeFail(message));
    toaster("error", message);
  }
}

function* onGetFeatureUpgrade({ payload: { token, course, tech }, }) {
  try {
    const url = `feature-upgradation/data-get/${course}/${tech}`;
    const response = yield call(getData, url, token);
    yield put(getFeatureUpgradeSuccess(response?.getData));
    yield put(storeRnDData("modifiedFeatureUpgrade",response?.getData?.featureDetails));
  } catch (error) {
    const message = error.response?.data?.message || "get banner failed";
    yield put(getFeatureUpgradeFail(message));
    toaster("error", message);
  }
}

function* onGetFeaturePurchase({ payload: { token, course, featureID }, }) {
  try {
    const url = `feature-purchase/data-get/${course}/${featureID}`;
    const response = yield call(getData, url, token);
    yield put(getFeaturePurchaseSuccess(response?.getData));
    yield put(storeRnDData("modifiedFeaturePurchase",response?.getData?.featureDetails));
  } catch (error) {
    const message = error.response?.data?.message || "get banner failed";
    yield put(getFeaturePurchaseFail(message));
    toaster("error", message);
  }
}

function* updateTechPurchase({payload: { values, history, token, groupId, singleId, courseInfo, techID},}) {
  try {
    const url = `update-tech-purchase/${groupId}/${singleId}`;
    const response = yield call(patchData, url, values, token);
    yield put(updateTechPurchaseSuccess(response?.getData));
    yield put(getTechPurchase(token, courseInfo, techID));
    toaster("success", `Tech feature Updated`);
    // history.push(`/`);
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(updateTechPurchaseFail(message));
  }
}

function* updateTechUpgrade({payload: { values, history, token, groupId, singleId, courseInfo, techID},}) {
  try {
    const url = `update-tech-upgradation/${groupId}/${singleId}`;
    const response = yield call(patchData, url, values, token);
    yield put(updateTechUpgradeSuccess(response?.getData));
    yield put(getTechUpgrade(token, courseInfo, techID));
    toaster("success", `Tech Updated successfully`);
    // history.push(`/`);
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(updateTechUpgradeFail(message));
  }
}

function* updateFeaturePurchase({payload: { values, history, token, groupId, singleId, courseInfo, featureID},}) {
  try {
    const url = `update-feature-purchase/${groupId}/${singleId}`;
    const response = yield call(patchData, url, values, token);
    yield put(updateFeaturePurchaseSuccess(response?.getData));
    yield put(getFeaturePurchase(token, courseInfo, featureID));
    toaster("success", `Update feature purchase successfully`);
    // history.push(`/`);
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(updateFeaturePurchaseFail(message));
  }
}

function* updateFeatureUpgrade({payload: { values, history, token, groupId, singleId, courseInfo, techID},}) {
  try {
    const url = `update-feature-upgradation/${groupId}/${singleId}`;
    const response = yield call(patchData, url, values, token);
    yield put(updateFeatureUpgradeSuccess(response?.getData));
    yield put(getFeatureUpgrade(token, courseInfo, techID));
    toaster("success", `Feature updated Updated successfully`);
    // history.push(`/`);
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(updateFeatureUpgradeFail(message));
  }
}
function* RnDSaga() {

  yield takeEvery(POST_TECH_PURCHASE, onPostTechPurchase);
  yield takeEvery(POST_TECH_UPGRADE, onPostTechUpgrade);
  yield takeEvery(POST_FEATURE_PURCHASE, onPostFeaturePurchase);
  yield takeEvery(POST_FEATURE_UPGRADE, onPostFeatureUpgrade);
  yield takeEvery(GET_TECH_PURCHASE, onGetTechPurchase);
  yield takeEvery(GET_TECH_UPGRADE, onGetTechUpgrade);
  yield takeEvery(GET_FEATURE_UPGRADE, onGetFeatureUpgrade);
  yield takeEvery(GET_FEATURE_PURCHASE, onGetFeaturePurchase);
  yield takeEvery(UPDATE_TECH_PURCHASE, updateTechPurchase);
  yield takeEvery(UPDATE_TECH_UPGRADE, updateTechUpgrade);
  yield takeEvery(UPDATE_FEATURE_PURCHASE, updateFeaturePurchase);
  yield takeEvery(UPDATE_FEATURE_UPGRADE, updateFeatureUpgrade);
}

export default RnDSaga;
