import { call, put, takeEvery } from "redux-saga/effects"

import { loginSuccess } from "../login/actions"

import { resetPasswordSuccess, resetPasswordFail } from "./actions"
import { RESET_PASSWORD } from "./actionTypes"

import { axiosUserRegisterComplete } from "../../../helpers/api_helper"

const asyncResetPass = async (
  email,
  accountActivateToken,
  password,
  confirmPassword
) => {
  // console.log("from async await", email, accountActivateToken, password, confirmPassword)
  try {
    const response = await axiosUserRegisterComplete(
      accountActivateToken,
      email,
      password,
      confirmPassword
    )
    console.log("response data user", response.data)
    return response.data
  } catch (err) {
    console.log("errror data", err.response.data)
    return err.response.data
  }
}

function* resetPasswordSaga(payload) {
  const { history, values } = payload.payload
  const { email, accountActivateToken, password, confirmPassword } = values
  console.log(
    "all value from 36 reset  ",
    email,
    accountActivateToken,
    password,
    confirmPassword
  )
  try {
    const response = yield call(
      asyncResetPass,
      email,
      accountActivateToken,
      password,
      confirmPassword
    )
    console.log("response", response)
    localStorage.clear()
    sessionStorage.clear()
    localStorage.removeItem("lastUniverse")
    localStorage.removeItem("teamId")
    localStorage.removeItem("universeId")
    localStorage.removeItem("persist:primary")
    // console.log("response data what", response.status)
    if (response.status === "success") {
      //   // yield put(loginSuccess(loginUserResponse.user.fullname, loginUserResponse.token, loginUserResponse.user.email, loginUserResponse.user.role))

      yield put(
        loginSuccess(
          response.user.firstName
            ? response.user.firstName
            : response.user.email.split("@")[0],
          response.token,
          response.user.email,
          response.user.role,
          response.user?.roundLimit ? response.user?.roundLimit : 7
        )
      )
      history.push(`/${response.user.role}dashboard`)
      // if (response.user.role === "teacher") {
      //   console.log("from techer")
      //   history.push("/teacherdashboard")
      // } else if (response.user.role === "student") {
      //   console.log("from student")

      //   history.push("/studentdashboard")
      // }
      yield put(resetPasswordSuccess("Password Reset Successfully"))
    } else {
      console.log("hello error", response)
      yield put(resetPasswordFail(response.message))
      yield put(resetPasswordFail())
    }
  } catch (error) {
    yield put(resetPasswordFail(error))
  }
  // console.log("all whole values ", email, accountActivateToken, password, confirmPassword)
}

function* resetSaga() {
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga)
}

export default resetSaga
