"use strict"
import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = "https://salesx.herokuapp.com/api/v1"

const REACT_APP_API = `https://${process.env.REACT_APP_API}`
// const REACT_APP_API = `https://api.calibrax.org/api`
// const REACT_APP_API = `https://staging-api.calibrax.org/api`
// const REACT_APP_API = `http://localhost:8000/api`
// as
// const REACT_APP_API = "http://localhost:8000/api"
export const axiosApi = axios.create({
  baseURL: REACT_APP_API,
})

export const postLogin = async (email, password) =>
  await axios.post(`${REACT_APP_API}/login`, {
    email,
    password,
  })

//create Teacher
export const axiosCreateTeacher = async (
  email,

  authtoken
) =>
  await axios.post(
    `${REACT_APP_API}/admin/teacher-register`,
    { email },
    {
      headers: {
        authtoken,
      },
    }
  )

//get Teachers

export const axiosGetTeachers = async authtoken =>
  await axios.get(`${REACT_APP_API}/admin/get-teachers`, {
    headers: {
      authtoken,
    },
  })

//create University
export const axiosCreateUniversity = async (universityName, authtoken) =>
  await axios.post(
    `${REACT_APP_API}/user/university-name`,
    { universityName },
    {
      headers: {
        authtoken,
      },
    }
  )

//get University Names
export const axiosGetUniversityName = async authtoken =>
  await axios.get(`${REACT_APP_API}/user/university-name`, {
    headers: {
      authtoken,
    },
  })

//    axiosCreateStudent, axiosGetStudents
//create student
export const axiosCreateStudent = async (
  fullname,
  email,
  instituteName,
  instituteId,
  authtoken
) =>
  await axios.post(
    `${REACT_APP_API}/user/register-student`,
    { fullname, email, instituteName, instituteId },
    {
      headers: {
        authtoken,
      },
    }
  )

//get Teachers

export const axiosGetStudents = async authtoken =>
  await axios.get(`${REACT_APP_API}/user/get-students`, {
    headers: {
      authtoken,
    },
  })

// reset User

export const axiosResetPassword = async (
  email,
  accountActivateToken,
  password,
  confirmPassword
) =>
  await axios.patch(`${REACT_APP_API}/user/reset-password`, {
    email,
    accountActivateToken,
    password,
    confirmPassword,
  })

//createCourse
export const axiosCreateCourse = async (
  courseName,
  courseCode,
  studentLimit,
  courseLeader,
  authtoken
) =>
  await axios.post(
    `${REACT_APP_API}/admin/create-course`,
    { courseName, courseCode, studentLimit, courseLeader },
    {
      headers: { authtoken },
    }
  )

export const axiosGetCourses = async authtoken =>
  await axios.get(`${REACT_APP_API}/admin/get-courses`, {
    headers: {
      authtoken,
    },
  })

export const axiosCoursesForTeacher = async authtoken =>
  await axios.get(`${REACT_APP_API}/teacher/get-courses`, {
    headers: {
      authtoken,
    },
  })

//to complete further process for teacher
export const axiosUserRegisterComplete = async (
  accountActivateToken,
  email,
  password,
  confirmPassword
) =>
  await axios.patch(`${REACT_APP_API}/user/account-verify`, {
    accountActivateToken,
    email,
    password,
    confirmPassword,
  })

export const axiosGetCoursesForOne = async authtoken =>
  await axios.get(`${REACT_APP_API}/user/get-course-for-one`, {
    headers: {
      authtoken,
    },
  })

export const axiosGetSingleCourse = async (authtoken, courseId) =>
  await axios.get(`${REACT_APP_API}/teacher/course/${courseId}`, {
    headers: {
      authtoken,
    },
  })

//for universe
//create universe
export const axiosCreateUniverse = async (
  universeName,
  studentLimit,
  teamLimit,
  courseid,
  authtoken
) =>
  await axios.post(
    `${REACT_APP_API}/universe/${courseid}/create-universe`,
    {
      universeName,
      studentLimit,
      teamLimit,
    },
    {
      headers: { authtoken },
    }
  )

//get all the universe
export const axiosGetUniverses = async (authtoken, courseid) =>
  await axios.get(`${REACT_APP_API}/${courseid}/universe/get-universe`, {
    headers: { authtoken },
  })

//get-single-universe
export const axiosGetSingleUniverse = async (authtoken, universeid) =>
  await axios.get(
    `${REACT_APP_API}/universe/${universeid}/get-single-universe`,
    {
      headers: { authtoken },
    }
  )

//for teams

//addteam
export const axiosAddTeam = async (
  authtoken,
  universeId,
  teamName,
  studentLimit
) =>
  await axios.post(
    `${REACT_APP_API}/${universeId}/create-team`,
    { teamName, studentLimit },
    {
      headers: { authtoken },
    }
  )

//getallteam
export const axiosGetAllTeam = async (universeid, authtoken) =>
  await axios.get(`${REACT_APP_API}/${universeid}/get-teams`, {
    headers: { authtoken },
  })

//add student
export const axiosAddStudent = async (universeId, teamId, email, authtoken) =>
  await axios.patch(
    `${REACT_APP_API}/${universeId}/${teamId}/add-student-to-course`,
    { email },
    {
      headers: { authtoken },
    }
  )
//remove student
export const axiosRemoveStudent = async (
  universeId,
  teamId,
  studentId,
  authtoken
) =>
  await axios.patch(
    `${REACT_APP_API}/${universeId}/${teamId}/remove-student/${studentId}`,
    {},
    {
      headers: { authtoken },
    }
  )

//getTeam
export const axiosGetSingleTeam = async (universeId, teamId, authtoken) =>
  await axios.get(`${REACT_APP_API}/${universeId}/get-team/${teamId}`, {
    headers: {
      authtoken,
    },
  })

//add student to team
export const axiosAddStudentToTeam = async (
  universeId,
  teamId,
  authtoken,
  email
) =>
  await axios.patch(
    `${REACT_APP_API}/${universeId}/${teamId}/add-student-to-course`,
    {
      email,
    },
    { headers: { authtoken } }
  )

//make captain
export const axiosMakeCaptain = async (authtoken, teamId, userId) =>
  await axios.patch(
    `${REACT_APP_API}/teacher/${teamId}/make-captain`,
    { userId },
    { headers: { authtoken } }
  )
//remove student from team

//add student to team
export const axiosRemoveStudentFromTeam = async (
  authtoken,
  universeId,
  teamId,
  studentId
) =>
  await axios.patch(
    `${REACT_APP_API}/${universeId}/${teamId}/remove-student/${studentId}`,
    {},
    { headers: { authtoken } }
  )

export const axiosSendNetworkCoverageData = async (authtoken, value) =>
  await axios.post(
    `${REACT_APP_API}/admin/network-coverage`,
    { value },
    { headers: { authtoken } }
  )

export const axiosRegisterAllStudent = async (
  authtoken,
  value,
  universeId,
  teamId
) =>
  await axios.post(
    `${REACT_APP_API}/teacher/${universeId}/${teamId}/add-student-through-file`,
    { value },
    { headers: { authtoken } }
  )

export const axiosRegionDataCreateByFIle = async (value, authtoken) =>
  await axios.post(
    `${REACT_APP_API}/region/create-region-by-files`,
    { value },
    {
      headers: { authtoken },
    }
  )

export const axiosGetAllRegionsData = async authtoken =>
  await axios.get(`${REACT_APP_API}/get/all-region`, {
    headers: { authtoken },
  })
export const axiosGetRegionsData = async authtoken =>
  await axios.get(`${REACT_APP_API}/get/market-region`, {
    headers: { authtoken },
  })

//get plant Size
export const axiosGetPlantSizeData = async (authtoken, universeId) =>
  await axios.get(`${REACT_APP_API}/capacity/upgradate-cost/${universeId}`, {
    headers: { authtoken },
  })

//get production region
export const axiosGetProductionRegion = async (authtoken, roundSerial) =>
  await axios.get(`${REACT_APP_API}/get/production-region/${roundSerial}`, {
    headers: { authtoken },
  })
//get production region
export const axiosGetOnlyProductionRegionData = async () =>
  await axios.get(`${REACT_APP_API}/get/production-regions`)

//axiosTechDataCreateByFIle
//tech data insert from file Tech -1
export const axiosTechDataCreateByFIle = async (value, authtoken) =>
  await axios.post(
    `${REACT_APP_API}/tech/insert-data-by-files`,
    { value },
    {
      headers: { authtoken },
    }
  )
export const axiosGetTechsData = async authtoken =>
  await axios.get(`${REACT_APP_API}/tech/get-techs`, {
    headers: { authtoken },
  })

//add market outlook
export const axiosAddMarketOutlook = async (
  authtoken,
  demand,
  cost,
  finance,
  roundIndex
) =>
  await axios.post(
    `${REACT_APP_API}/market-outlook/create`,
    { demand, cost, finance, roundIndex },
    {
      headers: { authtoken },
    }
  )

//get market outlooks
export const axiosGetMarketOutlooks = async authtoken =>
  await axios.get(`${REACT_APP_API}/get-market-outlooks`, {
    headers: { authtoken },
  })
//get singile market outlook
export const axiosGetSingleMarketOutlook = async (authtoken, marketOutlookId) =>
  await axios.get(
    `${REACT_APP_API}/get-single-market-outlook/${marketOutlookId}`,
    {
      headers: { authtoken },
    }
  )

//update market outlook
export const axiosUpdateMarketOutlook = async (
  authtoken,
  marketOutlookId,
  demand,
  cost,
  finance
) =>
  await axios.patch(
    `${REACT_APP_API}/update-market-outlook/${marketOutlookId}`,
    {
      demand,
      cost,
      finance,
    },
    { headers: { authtoken } }
  )

//insert demand data
export const axiosInsertDemandData = async (
  authtoken,
  regionId,
  totalDemandLastRound
) =>
  await axios.post(
    `${REACT_APP_API}/admin/demand/total-demand-last-round`,
    {
      regionId,
      totalDemandLastRound,
    },
    { headers: { authtoken } }
  )

//insert or update expected demand
export const axiosInsertOrUpdateExpectedDemand = async (
  authtoken,
  regionId,
  predictedMarketGrowth,
  totalDemandLastRound,
  roundSerial,
  universeId
) =>
  await axios.patch(
    `${REACT_APP_API}/demand/student/insert-or-update-expected-demand`,
    {
      regionId,
      predictedMarketGrowth,
      totalDemandLastRound,
      roundSerial,
      universeId,
    },
    { headers: { authtoken } }
  )
//insert or update expected demand
export const demandRoundVideoShowData = async (authtoken, roundSerial) =>
  await axios.post(
    `${REACT_APP_API}/round-Design/${roundSerial}`,
    {},
    { headers: { authtoken } }
  )

//demand admin last round data
export const getDemandAdminLastRoundData = async (authtoken, universeId) =>
  await axios.get(`${REACT_APP_API}/student-get-demand-data/${universeId}`, {
    headers: { authtoken },
  })
export const axiosUpdateVidoeData = async (authtoken, roundSerial, bodyData) =>
  await axios.patch(
    `${REACT_APP_API}/round-Design/${roundSerial}`,
    { bodyData },
    { headers: { authtoken } }
  )

//get all the demand data
export const axiosGetAllDemandData = async (
  authtoken,
  roundSerial,
  teamId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/demand/get-estimate-demand/${roundSerial}/${teamId}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

//insert MarketShare data
export const axiosInsertMarketShareData = async (
  authtoken,
  regionId,
  techId,
  marketShare
) =>
  await axios.patch(
    `${REACT_APP_API}/region/${regionId}/tech/${techId}/make-relation`,
    {
      marketShare,
    },
    { headers: { authtoken } }
  )

//get all market Share data
//get all the demand data
export const axiosGetMarketShareData = async (authtoken, roundSerial) =>
  await axios.get(`${REACT_APP_API}/get-market-share/${roundSerial}`, {
    headers: { authtoken },
  })
export const axiosAdminGetMarketShareData = async (authtoken, roundSerial) =>
  await axios.get(`${REACT_APP_API}/admin/get-market-share/${roundSerial}`, {
    headers: { authtoken },
  })

//round data CRUD by admin
export const axiosAddRoundData = async (authtoken, roundName) =>
  await axios.post(
    `${REACT_APP_API}/round/create-round`,
    { roundName },
    {
      headers: { authtoken },
    }
  )

//get round
export const axiosGetRounds = async authtoken =>
  await axios.get(`${REACT_APP_API}/round/get-rounds`, {
    headers: { authtoken },
  })

//activeRound
export const axiosActiveRound = async (
  authtoken,
  roundSerial,
  examEndingTime,
  universeId,
  teamIds
) =>
  await axios.patch(
    `${REACT_APP_API}/teacher/round/activate`,
    { roundSerial, examEndingTime, universeId, teamIds },
    {
      headers: { authtoken },
    }
  )
// Update Round
export const axiosUpdateRound = async (
  roundSerial,
  teamIds,
  examEndingTime,
  universeId,
  token
) =>
  await axios.patch(
    `${REACT_APP_API}/update-current-universe/${roundSerial}`,
    { roundSerial, examEndingTime, universeId, teamIds },
    {
      headers: { authtoken: token },
    }
  )

//get me data
export const axiosGetMe = async authtoken =>
  await axios.get(`${REACT_APP_API}/get-profile-and-course`, {
    headers: { authtoken },
  })

//get Student Demand data
export const axiosGetStudentDemandData = async (
  authtoken,
  teamId,
  roundSerial
) =>
  await axios.get(`${REACT_APP_API}/student/demand/${teamId}/${roundSerial}`, {
    headers: { authtoken },
  })

//update demand table
export const axiosUpdateDemandData = async (
  authtoken,
  regionId,
  predictedMarketGrowth,
  totalDemandLastRound,
  roundSerial,
  teamId
) =>
  await axios.patch(
    `${REACT_APP_API}/update/student/demand`,
    {
      regionId,
      predictedMarketGrowth,
      totalDemandLastRound,
      roundSerial,
      teamId,
    },
    { headers: { authtoken } }
  )

export const axiosGetExpectedDemandData = async (authtoken, roundSerial) =>
  await axios.get(`${REACT_APP_API}/get-expected-demand-data/${roundSerial}`, {
    headers: { authtoken },
  })

export const axiosGetMarketDataStudent = async (
  authtoken,
  teamId,
  roundSerial
) =>
  await axios.get(
    `${REACT_APP_API}/student/get-market-share/${teamId}/${roundSerial}`,
    { headers: { authtoken } }
  )

export const axiosUpdateMarketShareStudent = async (
  regionId,
  techId,
  lastRound,
  estimateMarketShare,
  forcastDemand,
  totalProductTarget,
  roundSerial,
  authtoken
) =>
  await axios.patch(
    `${REACT_APP_API}/student/update-market-share/${regionId}/${techId}/${roundSerial}`,
    { estimateMarketShare, forcastDemand, totalProductTarget, lastRound },
    { headers: { authtoken } }
  )

export const axiosGetExpectedMarketShare = async (
  authtoken,
  roundSerial,
  teamId
) =>
  await axios.get(
    `${REACT_APP_API}/student/get-expected-market-Share/${teamId}/${roundSerial}`,
    {
      headers: { authtoken },
    }
  )

//add or upate capacity
export const axiosAddOrUpdateCapacity = async (
  authtoken,
  regionId,
  totalProductionCapacity
) =>
  await axios.patch(
    `${REACT_APP_API}/admin/create-capacity`,
    {
      regionId,
      totalProductionCapacity,
    },
    {
      headers: { authtoken },
    }
  )

//get capacity
export const axiosGetCapacity = async authtoken =>
  await axios.get(`${REACT_APP_API}/admin/get-capacity`, {
    headers: { authtoken },
  })

//add inventory
export const axiosAddOrUpdateInventory = async (
  authtoken,
  techId,
  regionId,
  roundSerial,
  amount
) =>
  await axios.patch(
    `${REACT_APP_API}/admin/create-update-inventory`,
    {
      techId,
      regionId,
      roundSerial,
      amount,
    },
    { headers: { authtoken } }
  )
//get inventory
export const axiosGetInventory = async authtoken =>
  await axios.get(`${REACT_APP_API}/admin/get-inventory`, {
    headers: { authtoken },
  })

// get capacity schemas for student
export const axiosGetCapacityData = async (
  authtoken,
  teamId,
  roundSerial,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/${teamId}/${roundSerial}/get-capacity/${universeId}`,
    {
      headers: { authtoken },
    }
  )

//add contract manufacture
export const axiosAddContractManufacture = async (
  authtoken,
  regionId,
  techId,
  techName,
  regionName,
  teamId,
  roundSerial,
  capacityAllocation,
  totalProductionCapacity,
  universeId
) =>
  await axios.patch(
    `${REACT_APP_API}/${regionId}/${teamId}/${techId}/${roundSerial}/add-contact-manufacture-data/${universeId}`,
    {
      techName,
      regionName,
      capacityAllocation,
      totalProductionCapacity,
    },
    { headers: { authtoken } }
  )

//get contract manufacture
export const axiosGetContractManufacture = async (authtoken, roundSerial) =>
  await axios.get(`${REACT_APP_API}/${roundSerial}/get-contact-manufacture`, {
    headers: { authtoken },
  })

//add or update marketing data
export const axiosAddOrUpdateMarketingData = async (
  authtoken,
  techId,
  setPrice,
  productionCostPerUnit,
  regionId,
  roundSerial,
  marketingStrategy,
  marketSize
) =>
  await axios.patch(
    `${REACT_APP_API}/${regionId}/${roundSerial}/add-or-update-market`,
    { techId, setPrice, productionCostPerUnit, marketingStrategy, marketSize },
    { headers: { authtoken } }
  )

//get Marketing data
export const axiosGetMarketingData = async (authtoken, roundSerial) =>
  await axios.get(`${REACT_APP_API}/${roundSerial}/get-market`, {
    headers: { authtoken },
  })

//student market data
export const axiosGetStudentMarketData = async (
  authtoken,
  roundSerial,
  techId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/${roundSerial}/${techId}/student-market-data/${universeId}`,
    { headers: { authtoken } }
  )

//admin create feature
export const axiosAddFeature = async (authtoken, featureName, featurePrice) =>
  await axios.post(
    `${REACT_APP_API}/admin/create-feature`,
    {
      featureName,
      featurePrice,
    },
    { headers: { authtoken } }
  )

//admin get Feature
export const axiosGetFeature = async authtoken =>
  await axios.get(`${REACT_APP_API}/admin/get-feature-list`, {
    headers: { authtoken },
  })

//admin makefeature available
export const axiosMakeFeatureAvailable = async (authtoken, featureId) =>
  await axios.patch(
    `${REACT_APP_API}/admin/make-feature-available`,
    { featureId },
    { headers: { authtoken } }
  )

//RAndD
export const axiosGetFeatureForRandD = async authtoken =>
  await axios.get(`${REACT_APP_API}/student/get-feature-list`, {
    headers: { authtoken },
  })

//get upgrade features
export const axiosGetUpgradingFeatureByStudent = async authtoken =>
  await axios.get(`${REACT_APP_API}/student/get-upgrading-feature`, {
    headers: { authtoken },
  })

//purchase license RandD
export const axiosPurchaseFeatureByStudent = async (
  authtoken,
  roundActive,
  featureId,
  cost,
  roundSerial
) =>
  await axios.patch(
    `${REACT_APP_API}/student/purchase-feature/${roundSerial}/${featureId}`,
    {
      roundActive,
      cost,
    },
    { headers: { authtoken } }
  )

//get purchased features
export const axiosGetPurchasedFeatureByStudent = async authtoken =>
  await axios.get(`${REACT_APP_API}/student/feature/get-purcahsed-feature`, {
    headers: { authtoken },
  })

//get available tech
export const axiosGetLatestTech = async (authtoken, teamId) =>
  await axios.get(`${REACT_APP_API}/get/latest-tech/${teamId}`, {
    headers: { authtoken },
  })

//get production region admin
export const axiosGetOnlyProductionRegion = async (authtoken, roundSerial) =>
  await axios.get(`${REACT_APP_API}/get/production-region/${roundSerial}`, {
    headers: { authtoken },
  })

//production Requirement
export const axiosGetProductionRequirement = async (
  authtoken,
  teamId,
  roundSerial,
  regionId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/get/production-requirement/${teamId}/${roundSerial}/${regionId}/${universeId}`,
    { headers: { authtoken } }
  )

//add Capacity WIth Tech
export const axiosAddCapacityWithTech = async (authtoken, capacityId, techId) =>
  await axios.patch(
    `${REACT_APP_API}/admin/capacity-with-tech`,
    { capacityId, techId },
    { headers: { authtoken } }
  )

// all for inHouse Manufacture
//get capacity data
export const axiosGetCapacityManufacture = async (authtoken, roundSerial) =>
  await axios.get(
    `${REACT_APP_API}/student/get-capacity-manufacture-data/${roundSerial}`,
    { headers: { authtoken } }
  )
//get inhosueSecondPart
export const axiosGetInHouseSecondPartData = async (
  authtoken,

  roundSerial,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/student/get-in-hosue-second-part/${roundSerial}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

//get contract manufacture
export const axiosGetContractManufactureLastPart = async (
  authtoken,

  roundSerial,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/get-contract-manufacture-second-last-part/${roundSerial}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

//get distribution and logistic data
export const axiosGetDistributionAndLogistic = async (authtoken, roundSerial) =>
  await axios.get(
    `${REACT_APP_API}/student/get-distribution-data-region-wise/${roundSerial}`,
    { headers: { authtoken } }
  )

//get single market outlook
export const axiosStudentGetSingleMarketOutlook = async (
  authtoken,
  roundIndex
) =>
  await axios.get(
    `${REACT_APP_API}/student/get-single-market-outlook/${roundIndex}`,
    {
      headers: { authtoken },
    }
  )

//admin
//get market region
export const axiosGetMarketRegion = async authtoken =>
  await axios.get(`${REACT_APP_API}/get/market-region`, {
    headers: { authtoken },
  })
//demand
export const axiosInsertTotalDemandLastRoundData = async (
  authtoken,
  region,
  totalDemandLastRound
) =>
  await axios.post(
    `${REACT_APP_API}/admin/demand/total-demand-last-round`,
    {
      region,
      totalDemandLastRound,
      predictedMarketGrowth,
    },
    { headers: { authtoken } }
  )

// make a relation between region and tech and insert market share
export const axiosMakeRelationBetweenTechAndRegion = async (
  authtoken,
  regionId,
  techId,
  marketShare,
  roundSerial
) =>
  await axios.patch(
    `${REACT_APP_API}/region/${regionId}/tech/${techId}/make-relation`,
    { marketShare, roundSerial },
    { headers: { authtoken } }
  )

//get available tech
export const axiosGetAvailableTech = async (authtoken, teamId) =>
  await axios.get(`${REACT_APP_API}/tech/get-available-tech/${teamId}`, {
    headers: { authtoken },
  })

//update password
export const axiosUpdatePass = async (
  authtoken,
  currentPassword,
  password,
  confirmPassword
) =>
  await axios.patch(
    `${REACT_APP_API}/update-password`,
    {
      currentPassword,
      password,
      confirmPassword,
    },
    {
      headers: { authtoken },
    }
  )
export const axiosUpdatePas = async (
  authtoken,
  currentPassword,
  password,
  confirmPassword
) =>
  await axios.patch(
    `${REACT_APP_API}/update-pass`,
    {
      currentPassword,
      password,
      confirmPassword,
    },
    {
      headers: { authtoken },
    }
  )

//get market share last round
export const axiosGetMarketShareLastRound = async (
  authtoken,

  roundSerial,
  teamId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/get-market-share/${roundSerial}/${teamId}/${universeId}`,
    { headers: { authtoken } }
  )

//insert or update market share last part of student
export const axiosInsertOrUpdateLastPartOFMarketShare = async (
  authtoken,
  regionId,
  roundSerial,
  techId,
  estimateMarketShare,
  marketShareLastRound,
  universeId
) =>
  await axios.patch(
    `${REACT_APP_API}/student/insert-or-update-market-share/at/${regionId}/of/${roundSerial}/${universeId}`,
    { techId, estimateMarketShare, marketShareLastRound },
    { headers: { authtoken } }
  )

//get market shares all data demand table 2
export const axiosGetDemandFullData = async (
  authtoken,
  roundSerial,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/student/get-demand/${roundSerial}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

//insert initial capital
export const axiosInsertInitialCapital = async (authtoken, initialCapital) =>
  await axios.post(
    `${REACT_APP_API}/insert/initial-capital`,
    { initialCapital },
    { headers: { authtoken } }
  )

//get initial capital
export const axiosGetInitialCapital = async (
  authtoken,
  teamId,
  roundSerial,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/get/initial-capital/${roundSerial}/${teamId}/${universeId}`,
    { headers: { authtoken } }
  )

//capacity enhancement by studetn
export const axiosCapacityEnhancement = async (
  authtoken,
  teamId,
  roundSerial,
  regionId,
  capacityActive,
  totalProductionCapacity,
  totalCost,
  currentRound,
  universeId
) =>
  await axios.post(
    `${REACT_APP_API}/student/capacity-enhancement/${teamId}`,
    {
      roundSerial,
      regionId,
      capacityActive,
      totalProductionCapacity,
      totalCost,
      currentRound,
      universeId,
    },
    { headers: { authtoken } }
  )

//is capacity in processing
export const axiosIsCapacityInProcessing = async (
  authtoken,
  roundSerial,
  teamId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/student/get-capacity-EnhanceMentPosition/${teamId}/${roundSerial}/${universeId}`,
    { headers: { authtoken } }
  )

//add in house manufacture
export const axiosAddInHouseManufacture = async (
  authtoken,
  regionId,
  techId,
  techName,
  regionName,
  teamId,
  roundSerial,
  capacityAllocation,
  productionLevel,
  universeId
) =>
  await axios.patch(
    `${REACT_APP_API}/${regionId}/${teamId}/${techId}/${roundSerial}/add-in-house-manufacture/${universeId}`,
    {
      techName,
      regionName,
      capacityAllocation,
      productionLevel,
    },
    {
      headers: { authtoken },
    }
  )

//get in house manufacture
export const axiosGetInHouseManufacture = async (
  authtoken,
  teamId,
  roundSerial
) =>
  await axios.get(
    `${REACT_APP_API}/${teamId}/${roundSerial}/get-in-house-manufacture-data/`,
    {
      headers: { authtoken },
    }
  )

//get unavailable tech
export const axiosGetUnavailableTech = async authtoken =>
  await axios.get(`${REACT_APP_API}/tech/get-unavailable-tech`, {
    headers: { authtoken },
  })
// get unavailable tech for r&D
export const axiosGetUnavailableTechForRAndD = async (authtoken, teamId) =>
  await axios.get(
    `${REACT_APP_API}/tech/get-unavailable-tech-for-rAnd-d/${teamId}`,
    {
      headers: { authtoken },
    }
  )
//for admin get available tech
export const axiosGetAvailableTechForAdmin = async authtoken =>
  await axios.get(`${REACT_APP_API}/admin/get-available-tech`, {
    headers: { authtoken },
  })
//for randd
//get tech enable or not
export const axiosGetIsTechEnable = async (
  authtoken,
  teamId,
  techName,
  techId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/get/is-tech-enable/${teamId}/${techName}/${techId}/${universeId}`,
    { headers: { authtoken } }
  )

//RandD
//upgrade tech
export const axiosUpgradeTech = async (
  authtoken,
  techId,
  activeRound,
  techRealPrice,
  roundSerial,
  universeId
) =>
  await axios.post(
    `${REACT_APP_API}/upgrade-tech`,
    {
      techId,
      activeRound,
      techRealPrice,
      roundSerial,
      universeId,
    },
    { headers: { authtoken } }
  )

//purchase tech
export const axiosPurchaseTech = async (
  authtoken,
  techId,
  activeRound,
  techRealPrice,
  roundSerial,
  universeId
) =>
  await axios.post(
    `${REACT_APP_API}/purchase-tech`,
    {
      techId,
      activeRound,
      techRealPrice,
      roundSerial,
      universeId,
    },
    { headers: { authtoken } }
  )

//feature
export const axiosGetUnavailableFeature = async authtoken =>
  await axios.get(`${REACT_APP_API}/get/unavailable-feature`, {
    headers: { authtoken },
  })

//get own feature
export const axiosGetOwnFeature = async (authtoken, teamId) => {
  try {
    const response = await axios.get(
      `${REACT_APP_API}/get/own-feature/${teamId}`,
      {
        headers: { authtoken },
      }
    )
    return response
  } catch (error) {
    if (error?.response?.data?.statusCode == "401") {
      Swal.fire({
        title: "Error!",
        text: error?.response?.data?.message,
        icon: "error",
        confirmButtonText: "Please Login",
      }).then(function () {
        localStorage.removeItem("persist:primary")
        window.location = "/"
      })
    }
    return error.response
  }
}

//is feature enable
export const axiosIsFeatureEnable = async (
  authtoken,
  teamId,
  roundSerial,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/get/is-feature-enable/${teamId}/${roundSerial}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

//update feature
export const axiosUpgradeFeature = async (
  authtoken,
  featureId,
  activeRound,
  featureRealPrice,
  roundSerial,
  universeId
) =>
  await axios.post(
    `${REACT_APP_API}/upgrade-feature`,
    {
      featureId,
      activeRound,
      featureRealPrice,
      roundSerial,
      universeId,
    },
    { headers: { authtoken } }
  )

//feature purchase
export const axiosPurchaseFeature = async (
  authtoken,
  featureId,
  activeRound,
  featureRealPrice,
  roundSerial,
  universeId
) =>
  await axios.post(
    `${REACT_APP_API}/purchase-feature`,
    {
      featureId,
      activeRound,
      featureRealPrice,
      roundSerial,
      universeId,
    },
    { headers: { authtoken } }
  )

//get distribution data
//marketing priorities
export const axiosGetDistributionData = async (
  authtoken,
  roundSerial,
  teamId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/student/get-distribution-data/${roundSerial}/${teamId}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

//set priorities
export const axiosNewSetPrioritiesData = async (
  authtoken,
  teamId,
  regionId,
  regionName,
  techId,
  techName,
  totalProduction,
  priorities,
  productionCostPerUnit,
  roundSerial,
  inventoryProduction,
  inventoryProductionCost,
  contractProduction,
  contractProductionCost,
  marketingRegion,
  universeId
) => {
  try {
    const response = await axios.patch(
      `${REACT_APP_API}/student/set-new-priorities`,
      {
        teamId,
        regionId,
        regionName,
        techId,
        techName,
        totalProduction,
        priorities,
        productionCostPerUnit,
        roundSerial,
        inventoryProduction,
        inventoryProductionCost,
        contractProduction,
        contractProductionCost,
        marketingRegion,
        universeId,
      },
      { headers: { authtoken } }
    )
    return response
  } catch (error) {
    if (error?.response?.data?.statusCode == "401") {
      Swal.fire({
        title: "Error!",
        text: error?.response?.data?.message,
        icon: "error",
        confirmButtonText: "Please Login",
      }).then(function () {
        localStorage.removeItem("persist:primary")
        window.location = "/"
      })
    }
    return error.response
  }
}

//marketing Priorities Data
export const axiosGetMarketingPrioritiesData = async (
  authtoken,
  teamId,
  roundSerial,
  universeId
) => {
  try {
    const response = await axios.post(
      `${REACT_APP_API}/student/get-priorities-percentage`,
      {
        teamId,
        roundSerial,
        universeId,
      },
      { headers: { authtoken } }
    )
    return response
  } catch (error) {
    if (error?.response?.data?.statusCode == "401") {
      Swal.fire({
        title: "Error!",
        text: error?.response?.data?.message,
        icon: "error",
        confirmButtonText: "Please Login",
      }).then(function () {
        localStorage.removeItem("persist:primary")
        window.location = "/"
      })
    }
    return error.response
  }
}
//get taxion data
export const axiosGetTaxionData = async (authtoken, roundSerial, universeId) =>
  await axios.get(
    `${REACT_APP_API}/get-taxion-data/${roundSerial}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

//create or update marketignData second part
export const axiosCreateOrUpdateMarketingDataLastPart = async (
  authtoken,
  roundSerial,
  techId,
  regionName,
  setPrice,
  promotion,
  estimatedSalesInUnit,
  marketingStrategyType,
  strategyPoint,
  universeId
) =>
  await axios.patch(
    `${REACT_APP_API}/create-or-update-marketing-data-last-part`,
    {
      roundSerial,
      techId,
      regionName,
      setPrice,
      promotion,
      estimatedSalesInUnit,
      marketingStrategyType,
      strategyPoint,
      universeId,
    },
    { headers: { authtoken } }
  )

export const axiosGetIncomeStatementData = async (
  authtoken,
  roundSerial,
  teamId,
  regionId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/get-income-statement-data/${roundSerial}/${regionId}/${teamId}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

export const axiosGetFinancialOutcome = async (
  authtoken,
  roundSerial,
  teamId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/get-financial-outcome/${roundSerial}/${teamId}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

export const axiosGetDemandGraph = async (
  authtoken,
  roundSerial,
  teamId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/get-demand-graph/${roundSerial}/${teamId}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

//admin totalDemand LAst Round
export const axiosAdminGetTotalDemandLastRound = async authtoken =>
  await axios.get(`${REACT_APP_API}/admin-get-total-demand`, {
    headers: { authtoken },
  })
export const axiosSubmitFinancialOutcome = async (
  authtoken,
  roundSerial,
  teamId,
  totalRevenue,
  operationalProfitBefore,
  operationalProfitAfter,
  profitForThisYear,
  student,
  universeId
) =>
  await axios.post(
    `${REACT_APP_API}/submit-financial-outcome`,
    {
      roundSerial,
      teamId,
      totalRevenue,
      operationalProfitBefore,
      operationalProfitAfter,
      profitForThisYear,
      student,
      universeId,
    },
    { headers: { authtoken } }
  )

export const axiosGetWhoSubmit = async (authtoken, roundSerial, teamId) =>
  await axios.get(`${REACT_APP_API}/get-who-submit/${roundSerial}/${teamId}`, {
    headers: { authtoken },
  })
export const axiosGetVerifySubmission = async (
  authtoken,
  roundSerial,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/user/has-income-statement-already-submitted/${universeId}/${roundSerial}`,
    {
      headers: { authtoken },
    }
  )
export const axiosGetTeamSubmittedData = async (authtoken, roundSerial) =>
  await axios.get(`${REACT_APP_API}/get_submitted_data/${roundSerial}`, {
    headers: { authtoken },
  })

export const axiosEvualateSubmitResult = async (
  authtoken,
  teamId,
  universeId,
  roundSerial
) =>
  await axios.post(
    `${REACT_APP_API}/evualate_submitted_result`,
    { teamId, universeId, roundSerial },
    {
      headers: { authtoken },
    }
  )

export const axiosGetResultSummarySuccess = async (
  authtoken,
  roundSerial,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/get-result-summary/${roundSerial}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

export const axiosGetUniverseId = async authtoken =>
  await axios.get(`${REACT_APP_API}/get-universe-id`, {
    headers: { authtoken },
  })

export const axiosDeactivateRoundByTeacher = async (
  authtoken,
  universeId,
  roundSerial,
  teamIds
) =>
  await axios.patch(
    `${REACT_APP_API}/teacher/round/deactive`,
    { universeId, roundSerial, teamIds },
    {
      headers: { authtoken },
    }
  )

export const axiosUpdateProfile = async (
  authtoken,
  firstName,
  lastName,
  phoneNumber
) =>
  await axios.patch(
    `${REACT_APP_API}/update-profile`,
    {
      firstName,
      lastName,
      phoneNumber,
    },
    { headers: { authtoken } }
  )

export const axiosGetUserProfile = async authtoken =>
  await axios.get(`${REACT_APP_API}/get-me`, { headers: { authtoken } })

//get Invenotry
export const axiosGetStudentInventory = async (
  authtoken,
  teamId,
  roundSerial
) =>
  await axios.get(
    `${REACT_APP_API}/get/student-inventory/${roundSerial}/${teamId}`,
    { headers: { authtoken } }
  )

//submit mark
export const axiosSubmitMark = async (authtoken, teamId, roundSerial, mark) =>
  await axios.post(
    `${REACT_APP_API}/submit-mark`,
    {
      teamId,
      roundSerial,
      mark,
    },
    { headers: { authtoken } }
  )

//feature remove from list
export const axiosFeatureRemoveFromList = async (authtoken, featureId) =>
  await axios.post(
    `${REACT_APP_API}/feature-remove/${featureId}`,
    {},
    { headers: { authtoken } }
  )

//recover account
export const axiosRecoverAccount = async email =>
  await axios.patch(`${REACT_APP_API}/recover-account`, { email })

export const axiosShowTeamMemberNameWiseData = async (
  authtoken,
  teamId,
  roundSerial
) =>
  await axios.get(
    `${REACT_APP_API}/show-team-member-name-wise-data/${teamId}/${roundSerial}`,
    {
      headers: { authtoken },
    }
  )

export const axiosAnyTechInProcessing = async (
  authtoken,
  teamId,
  roundSerial,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/tech-in-processing/${teamId}/${roundSerial}/${universeId}`,
    { headers: { authtoken } }
  )

export const axiosCancleEnhancement = async (
  authtoken,
  roundSerial,
  regionId,
  teamId
) =>
  await axios.patch(
    `${REACT_APP_API}/student/cancle-capacity-enhancement/${teamId}`,
    { roundSerial, regionId },
    { headers: { authtoken } }
  )

export const axiosDeleteCourse = async (authtoken, courseId) =>
  await axios.delete(`${REACT_APP_API}/admin/delete-course/${courseId}`, {
    headers: { authtoken },
  })

export const axiosGoToProduction = async (
  authtoken,
  roundSerial,
  teamId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/admin/go-to-production/${roundSerial}/${teamId}/${universeId}`,
    {
      headers: { authtoken },
    }
  )
export const axiosGoToRANDD = async (
  authtoken,
  roundSerial,
  teamId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/admin/go-to-randd/${roundSerial}/${teamId}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

export const axiosGoToDecisionCheckListPage = async (
  authtoken,
  roundSerial,
  teamId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/user/go-to-decision-checklist-page/${roundSerial}/${teamId}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

//priorities index
export const axiosGetMarketingPrioritiesIndex = async (
  authtoken,
  roundSerial,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/student/get-set-prioriteis-index/${roundSerial}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

// /user/go-to-income-statement/:roundSerial/:teamId
export const axiosGoToIncomeStatement = async (
  authtoken,
  roundSerial,
  teamId,
  universeId
) =>
  await axios.get(
    `${REACT_APP_API}/user/go-to-income-statement/${roundSerial}/${teamId}/${universeId}`,
    {
      headers: { authtoken },
    }
  )

export const axiosDisableTechButton = async (authtoken, roundSerial, teamId) =>
  await axios.get(
    `${REACT_APP_API}/user/get-disable-tech-button/${roundSerial}/${teamId}`,
    {
      headers: { authtoken },
    }
  )

export const axiosGetMarketShareOfEach = async (
  authtoken,
  roundSerial,
  teamId,
  techId
) =>
  await axios.get(
    `${REACT_APP_API}/user/getmarket-share-of-each/${teamId}/${roundSerial}/${techId}`,
    { headers: { authtoken } }
  )
export const axiosGetPricingMatrix = async (
  authtoken,
  universeId,
  roundSerial,
  techId
) =>
  await axios.get(
    `${REACT_APP_API}/user/get-price-matrix/${universeId}/${roundSerial}/${techId}`,
    { headers: { authtoken } }
  )
export const activityData = async (token, universe, teamId) =>
  await axios.get(`${REACT_APP_API}/get-notification/${universe}/${teamId}`, {
    headers: { token },
  })

export const universeData = async (token, universe, teamId) =>
  await axios.get(`${REACT_APP_API}/universe-data`, {
    headers: { token },
  })

export const axiosSelectUniverse = async (authtoken, universe) =>
  await axios.get(`${REACT_APP_API}/get-team-data-by-universe/${universe}`, {
    headers: { authtoken },
  })
export const axiosDemandData = async (
  values,

  authtoken
) =>
  await axios.post(`${REACT_APP_API}/demand-data-create`, values, {
    headers: {
      authtoken,
    },
  })
export const axiosMarketShareData = async (
  values,

  authtoken
) =>
  await axios.post(
    `${REACT_APP_API}/market-share/market-share-create`,
    values,
    {
      headers: {
        authtoken,
      },
    }
  )
export const axiosGetDemandData = async (authtoken, course, region) =>
  await axios.get(`${REACT_APP_API}/get-demand-data/${course}/${region}`, {
    headers: { authtoken },
  })

export const axiosUpdateDemand = async (
  values,

  authtoken,
  courseInfo,
  regionInfo
) =>
  await axios.patch(
    `${REACT_APP_API}/update-demand-data/${courseInfo}/${regionInfo}`,
    values,
    {
      headers: {
        authtoken,
      },
    }
  )

export const axiosGetPlantUpgrade = async (authtoken, course, region) =>
  await axios.get(
    `${REACT_APP_API}/plant-upgradation/data-get/${course}/${region}`,
    {
      headers: { authtoken },
    }
  )

export const axiosPlantUpgrade = async (
  values,

  authtoken
) =>
  await axios.post(`${REACT_APP_API}/plant-upgradation/data-create`, values, {
    headers: {
      authtoken,
    },
  })

export const axiosUpdatePlantUpgrade = async (
  values,

  authtoken,
  courseInfo,
  regionInfo
) =>
  await axios.patch(
    `${REACT_APP_API}/update-upgradation/${courseInfo}/${regionInfo}`,
    values,
    {
      headers: {
        authtoken,
      },
    }
  )
export const axiosInHouseData = async (
  values,

  authtoken
) =>
  await axios.post(`${REACT_APP_API}/in-house-production/data-create`, values, {
    headers: {
      authtoken,
    },
  })
export const axiosContractData = async (
  values,

  authtoken
) =>
  await axios.post(`${REACT_APP_API}/contract-production/data-create`, values, {
    headers: {
      authtoken,
    },
  })
export const axiosTechPurchase = async (
  values,

  authtoken
) =>
  await axios.post(`${REACT_APP_API}/tech-purchase/data-create`, values, {
    headers: {
      authtoken,
    },
  })
export const axiosTechUpgrade = async (
  values,

  authtoken
) =>
  await axios.post(`${REACT_APP_API}/tech-upgradation/data-create`, values, {
    headers: {
      authtoken,
    },
  })
export const axiosFeaturePurchase = async (
  values,

  authtoken
) =>
  await axios.post(`${REACT_APP_API}/feature-purchase/data-create`, values, {
    headers: {
      authtoken,
    },
  })

export const axiosFeatureUpgrade = async (
  values,

  authtoken
) =>
  await axios.post(`${REACT_APP_API}/feature-upgradation/data-create`, values, {
    headers: {
      authtoken,
    },
  })

export const axiosWeightStrategy = async (
  values,

  authtoken
) =>
  await axios.post(`${REACT_APP_API}/weight-strategy/data-create`, values, {
    headers: {
      authtoken,
    },
  })
export const axiosProductStrategy = async (
  values,

  authtoken
) =>
  await axios.post(`${REACT_APP_API}/weight-product/data-create`, values, {
    headers: {
      authtoken,
    },
  })

export const axiosPromotionStrategy = async (
  values,

  authtoken
) =>
  await axios.post(`${REACT_APP_API}/weight-promotion/data-create`, values, {
    headers: {
      authtoken,
    },
  })

export const axiosPriceStrategy = async (
  values,

  authtoken
) =>
  await axios.post(`${REACT_APP_API}/weight-price/data-create`, values, {
    headers: {
      authtoken,
    },
  })
export const axiosGetWeightedStrategy = async (authtoken, course, region) =>
  await axios.get(
    `${REACT_APP_API}/weight-strategy/data-get/${course}/${region}`,
    {
      headers: { authtoken },
    }
  )
export const axiosGetProductStrategy = async (authtoken, course, region) =>
  await axios.get(
    `${REACT_APP_API}/weight-product/data-get/${course}/${region}`,
    {
      headers: { authtoken },
    }
  )
export const axiosGetPromotionStrategy = async (authtoken, course, region) =>
  await axios.get(
    `${REACT_APP_API}/weight-promotion/data-get/${course}/${region}`,
    {
      headers: { authtoken },
    }
  )
export const axiosGetPriceStrategy = async (authtoken, course, region) =>
  await axios.get(
    `${REACT_APP_API}/weight-price/data-get/${course}/${region}`,
    {
      headers: { authtoken },
    }
  )

//feature purachase price

export const axiosFeaturePurchasePrice = async (authtoken, universeId) =>
  await axios.get(
    `${REACT_APP_API}/feature/get-feature-purchase-price/${universeId}`,
    {
      headers: { authtoken },
    }
  )
//feature upgrade
export const axiosFeatureUpgradePrice = async (authtoken, universeId) =>
  await axios.get(
    `${REACT_APP_API}/feature/get-feature-upgrade-price/${universeId}`,
    {
      headers: { authtoken },
    }
  )
//tech purchase price
export const axiosTechPurchasePrice = async (authtoken, universeId) =>
  await axios.get(
    `${REACT_APP_API}/tech/get-tech-purchase-price/${universeId}`,
    {
      headers: { authtoken },
    }
  )
//feature upgrade
export const axiosTechUpgradePrice = async (authtoken, universeId) =>
  await axios.get(
    `${REACT_APP_API}/tech/get-tech-upgrade-price/${universeId}`,
    {
      headers: { authtoken },
    }
  )
//feature ugpradeprice

//tech upgrade price
