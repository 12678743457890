import {
  GET_CAPACITY_BY_STUDENT,
  GET_CAPACITY_BY_STUDENT_SUCCESS,
  ADD_IN_HOUSE_MANUFACTURE,
  ADD_IN_HOUSE_MANUFACTURE_FAIL,
  ADD_IN_HOUSE_MANUFACTURE_SUCCESS,
  GET_IN_HOUSE_MANUFACTURE_SUCCESS,
  GET_IN_HOUSE_MANUFACTURE,
  ADD_CONTRACT_MANUFACTURE_FAIL,
  ADD_CONTRACT_MANUFACTURE_SUCCESS,
  ADD_CONTRACT_MANUFACTURE,
  GET_PRODUCTION_REQUIREMENT_SUCCESS,
  GET_PRODUCTION_REQUIREMENT,
  GET_CAPACITY_MANUFACTURE,
  GET_CAPACITY_MANUFACTURE_SUCCESS,
  GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART,
  GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART_SUCCESS,
  GET_CONTRACT_MANUFACTURE_LAST_PART,
  GET_CONTRACT_MANUFACTURE_LAST_PART_SUCCESS,
  GET_STUDENT_INVENTORY,
  GET_STUDENT_INVENOTRY_SUCCESS,
} from "./actionTypes"

const Initial_State = {
  capacity: [],
  inHouseManufacture: [],
  inHouseManufactureLastPart: {},
  contractManufactureLastPart: {},
  inventoryData: {},
  contractManufactureLoading: false,
  techData: [],
  totalCapacity: 0,
  totalRequirement: 0,
  capacityManufactureData: [],
  contractManufacture: {},
  productionRequirement: [],
  loading: false,
  inHouseLoading: false,
  secondLoading: false,
  inHouseLoading: false,
  thirdLoading: false,
  message: "",
  error: "",
  check: false,
}

const studentCapacityData = (state = Initial_State, action) => {
  switch (action.type) {
    case GET_CAPACITY_BY_STUDENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_CAPACITY_BY_STUDENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        capacity: action.payload.data.getData,
        totalCapacity: action.payload.data.totalCapLimit,
        totalRequirement: action.payload.data.totalProductionTarget,
      }
      break
    case ADD_IN_HOUSE_MANUFACTURE:
      state = {
        ...state,
        secondLoading: true,
      }
      break
    case ADD_IN_HOUSE_MANUFACTURE_SUCCESS:
      state = {
        ...state,
        secondLoading: false,
        message: action.payload.message,
      }
      break
    case ADD_IN_HOUSE_MANUFACTURE_FAIL:
      state = {
        ...state,
        secondLoading: false,
        error: action.payload.error,
      }
      break
    case GET_IN_HOUSE_MANUFACTURE:
      state = {
        ...state,
        inHouseLoading: true,
      }
      break
    case GET_IN_HOUSE_MANUFACTURE_SUCCESS:
      state = {
        ...state,
        inHouseLoading: false,
        techData: action.payload.data.techData,
      }
      break
    case ADD_CONTRACT_MANUFACTURE:
      state = {
        ...state,
        thirdLoading: true,
      }
      break
    case ADD_CONTRACT_MANUFACTURE_SUCCESS:
      state = {
        ...state,
        thirdLoading: false,
        message: action.payload.message,
      }
      break
    case ADD_CONTRACT_MANUFACTURE_FAIL:
      state = {
        ...state,
        thirdLoading: false,
        error: action.payload.message,
      }
      break

    case GET_PRODUCTION_REQUIREMENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_PRODUCTION_REQUIREMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        productionRequirement: action.payload.data,
      }
      break
    case GET_CAPACITY_MANUFACTURE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_CAPACITY_MANUFACTURE_SUCCESS:
      state = {
        ...state,
        loading: false,
        capacityManufactureData: action.payload.data,
      }
      break
    case GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART:
      state = {
        ...state,
        secondLoading: true,
      }
      break
    case GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART_SUCCESS:
      state = {
        ...state,
        secondLoading: false,
        inHouseManufactureLastPart: action.payload.data,
      }
      break
    case GET_CONTRACT_MANUFACTURE_LAST_PART:
      state = {
        ...state,
        contractManufactureLoading: true,
      }
      break
    case GET_CONTRACT_MANUFACTURE_LAST_PART_SUCCESS:
      state = {
        ...state,
        contractManufactureLoading: false,
        contractManufactureLastPart: action.payload.data,
      }
      break
    case GET_STUDENT_INVENTORY:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_STUDENT_INVENOTRY_SUCCESS:
      state = {
        ...state,
        loading: false,
        inventoryData: action.payload.data,
      }
      break

    default:
      state = state
      break
  }
  return state
}

export default studentCapacityData
