import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_OR_UPDATE_TILL_SET_PRICE,
  CREATE_OR_UPDATE_MARKET_DATA_LAST_PART,
  GET_MARKETING_PRIORITIES_DATA,
  GET_MARKETING_REGIONS_IN_INCOME_STATEMENT,
  GET_STUDENT_MARKET_DATA_ON_TECH,
  GET_TAXION_DATA,
  GET_TILL_PRICE,
  GO_TO_DECISION_CHECKLIST_PAGE,
  SET_PRIORITIES_FOR_MARKETING,
  GET_MARKETING_PRIORITIES_INDEX,
  GO_TO_INCOME_STATEMENT,
  DISABLE_TECH_BUTTON,
  GET_MARKET_SHARE_OF_EACH,
  NEW_SET_PRIORITIES_FOR_MARKETING,
  GET_PRIORITIES_PERCENTAGE_DATA,
  GET_PRICING_MATRIX,
} from "./actionTypes"

import {
  addOrUpdateTillSetPriceFail,
  addOrUpdateTillSetPriceSuccess,
  createOrUpdateMarketDataLastPartSuccess,
  getMarketingPrioritiesDataSuccess,
  getMarketingStatementDataInIncomeStatementSuccess,
  getStudentMarketDataOnTechSuccess,
  getTaxionDataSuccess,
  getTillPriceSuccess,
  goToDecisionCheckListPageSuccess,
  setPrioritiesForMarketingFail,
  setPrioritiesForMarketingSuccess,
  getMarketingPrioritiesIndexSuccess,
  goToIncomeStatementSuccess,
  disableTechButtonSuccess,
  getMarketShareOfEachSuccess,
  newSetPrioritiesForMarketingSuccess,
  newSetPrioritiesForMarketingFail,
  getPrioritiesPercentageDataSuccess,
  getPricingMatrixSuccess,
} from "./actions"

import {
  axiosAddOrUpdateMarketingData,
  axiosCreateOrUpdateMarketingDataLastPart,
  axiosGetDistributionData,
  axiosGetIncomeStatementData,
  axiosGetMarketingData,
  axiosGetStudentMarketData,
  axiosGetTaxionData,
  axiosGoToDecisionCheckListPage,
  axiosNewSetPrioritiesData,
  axiosGetMarketingPrioritiesIndex,
  axiosGoToIncomeStatement,
  axiosDisableTechButton,
  axiosGetMarketShareOfEach,
  axiosGetMarketingPrioritiesData,
  axiosGetPricingMatrix,
} from "../../helpers/api_helper"

const asyncGetMarketingRegionIncomeStatement = async (
  token,
  roundSerial,
  teamId,
  regionId,
  universeId
) => {
  try {
    const response = await axiosGetIncomeStatementData(
      token,
      roundSerial,
      teamId,
      regionId,
      universeId
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetMarketingRegionIncomeStatement({
  payload: { token, roundSerial, teamId, regionId, universeId },
}) {
  try {
    // console.log("hello from 60 line", token, roundSerial, regionId)
    const response = yield call(
      asyncGetMarketingRegionIncomeStatement,
      token,
      roundSerial,
      teamId,
      regionId,
      universeId
    )
    // console.log("hello response", response)
    if (response.status == "success") {
      // console.log("response data marketing income", response.obj)
      yield put(getMarketingStatementDataInIncomeStatementSuccess(response.obj))
    }
  } catch (error) {}
}

const asyncCreateOrUpdateMarketingDataLastPart = async (
  token,
  roundSerial,
  techId,
  regionName,
  setPrice,
  promotion,
  estimatedSalesInUnit,
  marketingStrategyType,
  strategyPoint,
  universeId
) => {
  try {
    console.log("strategy point data", strategyPoint)
    const response = await axiosCreateOrUpdateMarketingDataLastPart(
      token,
      roundSerial,
      techId,
      regionName,
      setPrice,
      promotion,
      estimatedSalesInUnit,
      marketingStrategyType,
      strategyPoint,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerCreateOrUpdateMarketingDataLastPart({
  payload: {
    token,
    roundSerial,
    techId,
    regionName,
    setPrice,
    promotion,
    estimatedSalesInUnit,
    marketingStrategyType,
    strategyPoint,
    universeId,
  },
}) {
  try {
    console.log("strategy data", universeId)
    console.log("strategy data 2", strategyPoint)
    const response = yield call(
      asyncCreateOrUpdateMarketingDataLastPart,
      token,
      roundSerial,
      techId,
      regionName,
      setPrice,
      promotion,
      estimatedSalesInUnit,
      marketingStrategyType,
      strategyPoint,
      universeId
    )
    if (response.status == "success") {
      // toast.success(response.message)
      let resData = yield call(
        asyncGetStudentMarketData,
        token,
        roundSerial,
        techId,
        universeId
      )
      yield put(getStudentMarketDataOnTechSuccess(resData.marketData))
      yield put(createOrUpdateMarketDataLastPartSuccess(response.message))
    } else {
    }
  } catch (error) {}
}

const asyncGetTaxionData = async (token, roundSerial, universeId) => {
  try {
    console.log("call from here")
    const response = await axiosGetTaxionData(token, roundSerial, universeId)
    console.log("hello response", response)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetTaxionData({ payload: { token, roundSerial, universeId } }) {
  try {
    const response = yield call(
      asyncGetTaxionData,
      token,
      roundSerial,
      universeId
    )
    yield put(getTaxionDataSuccess(response.objData))
  } catch (error) {}
}

const asyncNewSetPriorities = async (
  token,
  teamId,
  regionId,
  regionName,
  techId,
  techName,
  totalProduction,
  priorities,
  productionCostPerUnit,
  roundSerial,
  inventoryProduction,
  inventoryProductionCost,
  contractProduction,
  contractProductionCost,
  marketingRegion,
  universeId
) => {
  try {
    const response = await axiosNewSetPrioritiesData(
      token,
      teamId,
      regionId,
      regionName,
      techId,
      techName,
      totalProduction,
      priorities,
      productionCostPerUnit,
      roundSerial,
      inventoryProduction,
      inventoryProductionCost,
      contractProduction,
      contractProductionCost,
      marketingRegion,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerSetPriorities({
  payload: {
    token,
    teamId,
    regionId,
    regionName,
    techId,
    techName,
    totalProduction,
    priorities,
    productionCostPerUnit,
    roundSerial,
    inventoryProduction,
    inventoryProductionCost,
    contractProduction,
    contractProductionCost,
  },
}) {
  try {
    // const response = yield call(
    //   asyncSetPriorities,
    //   token,
    //   teamId,
    //   regionId,
    //   regionName,
    //   techId,
    //   techName,
    //   totalProduction,
    //   priorities,
    //   productionCostPerUnit,
    //   roundSerial,
    //   inventoryProduction,
    //   inventoryProductionCost,
    //   contractProduction,
    //   contractProductionCost
    // )

    console.log("260 line response")
    console.log("260 line response", response)
    if (response.status == "success") {
      // toast.success(response.message)

      yield put(setPrioritiesForMarketingSuccess(response.message))
      // toast.success("Priorities Data has been set, please check taxion data")
      const res = yield call(
        asyncGetMarketingPrioritiesIndex,
        token,
        roundSerial
      )

      yield put(getMarketingPrioritiesIndexSuccess(res.getData))
      const resButton = yield call(
        asyncDisableTechButton,
        token,
        roundSerial,
        teamId
      )
      yield put(disableTechButtonSuccess(resButton.check))

      // const res = yield call(asyncGetTaxionData, token, roundSerial)

      // yield put(getTaxionDataSuccess(res.objData))

      yield put(setPrioritiesForMarketingSuccess())
    } else {
      console.log("response")
      toast.error(response.message)
      yield put(setPrioritiesForMarketingFail(response.message))
      yield put(setPrioritiesForMarketingFail())
    }
  } catch (error) {}
}
//for new
function* workerNewSetPriorities({
  payload: {
    token,
    teamId,
    regionId,
    regionName,
    techId,
    techName,
    totalProduction,
    priorities,
    productionCostPerUnit,
    roundSerial,
    inventoryProduction,
    inventoryProductionCost,
    contractProduction,
    contractProductionCost,
    marketingRegion,
    universeId,
  },
}) {
  try {
    const response = yield call(
      asyncNewSetPriorities,
      token,
      teamId,
      regionId,
      regionName,
      techId,
      techName,
      totalProduction,
      priorities,
      productionCostPerUnit,
      roundSerial,
      inventoryProduction,
      inventoryProductionCost,
      contractProduction,
      contractProductionCost,
      marketingRegion,
      universeId
    )
    console.log("334 line response", response)
    console.log("335 response")
    if (response.status == "success") {
      // toast.success(response.message)

      yield put(newSetPrioritiesForMarketingSuccess(response.message))
      // toast.success("Priorities Data has been set, please check taxion data")
      const res = yield call(
        asyncGetMarketingPrioritiesIndex,
        token,
        roundSerial,
        universeId
      )

      const resData = yield call(
        asyncGetPercentageData,
        token,
        teamId,
        roundSerial,
        universeId
      )

      yield put(getPrioritiesPercentageDataSuccess(resData.getData))
      yield put(getMarketingPrioritiesIndexSuccess(res.getData))
      const resButton = yield call(
        asyncDisableTechButton,
        token,
        roundSerial,
        teamId
      )
      yield put(disableTechButtonSuccess(resButton.check))

      // const res = yield call(asyncGetTaxionData, token, roundSerial)

      // yield put(getTaxionDataSuccess(res.objData))

      yield put(newSetPrioritiesForMarketingSuccess())
    } else {
      toast.error(response.message)
      yield put(newSetPrioritiesForMarketingFail(response.message))
      yield put(newSetPrioritiesForMarketingFail())
    }
  } catch (error) {}
}

const asyncGetMarketingPrioritiesData = async (
  token,
  roundSerial,
  teamId,
  universeId
) => {
  try {
    const response = await axiosGetDistributionData(
      token,
      roundSerial,
      teamId,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetMarketingPrioritiesData({
  payload: { token, roundSerial, teamId, universeId },
}) {
  try {
    const response = yield call(
      asyncGetMarketingPrioritiesData,
      token,
      roundSerial,
      teamId,
      universeId
    )
    yield put(getMarketingPrioritiesDataSuccess(response.objData))
    // console.log("45 line data", response)
  } catch (error) {}
}

const asyncGetStudentMarketData = async (
  token,
  roundSerial,
  techId,
  universeId
) => {
  try {
    const response = await axiosGetStudentMarketData(
      token,
      roundSerial,
      techId,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* wrokerGetStudetnMarketTech({
  payload: { token, roundSerial, techId, universeId },
}) {
  console.log("print value of alkfdjs", token, roundSerial, techId, universeId)
  const response = yield call(
    asyncGetStudentMarketData,
    token,
    roundSerial,
    techId,
    universeId
  )

  yield put(getStudentMarketDataOnTechSuccess(response.marketData))
}

const asyncGetTillPrice = async (token, roundSerial) => {
  try {
    const response = await axiosGetMarketingData(token, roundSerial)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* worketGetTillPrice({ payload: { token, roundSerial } }) {
  try {
    const response = yield call(asyncGetTillPrice, token, roundSerial)
    console.log("response data of 27", response)
    yield put(getTillPriceSuccess(response.getMarketData))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncAddOrUpdateTillSetPrice = async (
  token,
  techId,
  setPrice,
  productionCostPerUnit,
  regionId,
  roundSerial,
  marketingStrategy,
  marketSize
) => {
  try {
    const response = await axiosAddOrUpdateMarketingData(
      token,
      techId,
      setPrice,
      productionCostPerUnit,
      regionId,
      roundSerial,
      marketingStrategy,
      marketSize
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerAddOrUpdateTillSetPrice({
  payload: {
    token,
    techId,
    setPrice,
    productionCostPerUnit,
    regionId,
    roundSerial,
    marketingStrategy,
    marketSize,
  },
}) {
  try {
    const response = yield call(
      asyncAddOrUpdateTillSetPrice,
      token,
      techId,
      setPrice,
      productionCostPerUnit,
      regionId,
      roundSerial,
      marketingStrategy,
      marketSize
    )
    if (response.status == "success") {
      yield put(addOrUpdateTillSetPriceSuccess(response.message))
      // toast.success(response.message)
      //here try

      const resdata = yield call(
        asyncGetStudentMarketData,
        token,
        roundSerial,
        techId
      )

      yield put(getStudentMarketDataOnTechSuccess(resdata.marketData))
      //hreer end
      yield put(addOrUpdateTillSetPriceSuccess())
    } else {
      toast.error(response.message)
      yield put(addOrUpdateTillSetPriceFail(response.message))
      yield put(addOrUpdateTillSetPriceFail())
    }
  } catch (error) {}
}

const asyncGoToDecisionCheckListPage = async (
  token,
  roundSerial,
  teamId,
  universeId
) => {
  try {
    const response = await axiosGoToDecisionCheckListPage(
      token,
      roundSerial,
      teamId,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGoToDecisionCheckListPage({
  payload: { token, roundSerial, teamId, history, universeId, goNext },
}) {
  try {
    console.log("call from here")
    const response = yield call(
      asyncGoToDecisionCheckListPage,
      token,
      roundSerial,
      teamId,
      universeId
    )

    if (response.check == false) {
      toast.error("Please fill all the input field first")

      if (goNext == 2) {
        history.push("/decisions/demand")
      }
      yield put(goToDecisionCheckListPageSuccess(response.check))
    } else {
      history.push("/decisions/decision-checklist")
    }
    yield put(goToDecisionCheckListPageSuccess(response.check))
  } catch (error) {}
}

const asyncGetMarketingPrioritiesIndex = async (
  token,
  roundSerial,
  universeId
) => {
  try {
    const response = await axiosGetMarketingPrioritiesIndex(
      token,
      roundSerial,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetMarketingPrioritiesIndex({
  payload: { token, roundSerial, universeId },
}) {
  try {
    const response = yield call(
      asyncGetMarketingPrioritiesIndex,
      token,
      roundSerial,
      universeId
    )
    yield put(getMarketingPrioritiesIndexSuccess(response.getData))
  } catch (error) {}
}

const asyncGoToIncomeStatementPage = async (
  token,
  roundSerial,
  teamId,
  universeId
) => {
  try {
    console.log("response her")
    const response = await axiosGoToIncomeStatement(
      token,
      roundSerial,
      teamId,
      universeId
    )
    console.log("response yaho", response.data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGoToIncomeStatement({
  payload: { token, roundSerial, teamId, universeId, history, goNext },
}) {
  try {
    console.log("token here 491", token)
    const response = yield call(
      asyncGoToIncomeStatementPage,
      token,
      roundSerial,
      teamId,
      universeId
    )

    console.log("call data yap")
    if (response.check == true) {
      console.log("call data yap 1")
      history.push("/decisions/income-statemant")
    } else {
      console.log("call data yap 2")
      toast.error("Teacher hasn't evulated result for this round")
      console.log("go next here", goNext)
      if (goNext == true) {
        history.push("/decisions/demand")
      }
    }
    yield put(goToIncomeStatementSuccess(response.check))
  } catch (error) {}
}

//
const asyncDisableTechButton = async (token, roundSerial, teamId) => {
  try {
    const resposne = await axiosDisableTechButton(token, roundSerial, teamId)
    return resposne.data
  } catch (error) {
    return error.response.data
  }
}
function* workerDisableTechButton({ payload: { token, roundSerial, teamId } }) {
  try {
    const response = yield call(
      asyncDisableTechButton,
      token,
      roundSerial,
      teamId
    )
    yield put(disableTechButtonSuccess(response.check))
  } catch (error) {
    console.log("error here", error)
  }
}

const asyncGetMarketShareOfEach = async (
  token,
  roundSerial,
  teamId,
  techId
) => {
  try {
    const response = await axiosGetMarketShareOfEach(
      token,
      roundSerial,
      teamId,
      techId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetMarketShareOfEach({
  payload: { token, roundSerial, teamId, techId },
}) {
  try {
    const response = yield call(
      asyncGetMarketShareOfEach,
      token,
      roundSerial,
      teamId,
      techId
    )
    yield put(getMarketShareOfEachSuccess(response.getData))
  } catch (error) {}
}
const asyncPricingMatrix = async (
  token, universeId, roundSerial, techId
) => {
  try {
    const response = await axiosGetPricingMatrix(
      token, universeId, roundSerial, techId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerPricingMatrix({
  payload: { token, universeId, roundSerial, techId },
}) {
  try {
    const response = yield call(
      asyncPricingMatrix,
      token, universeId, roundSerial, techId
    )
    // console.log('resres', response);
    yield put(getPricingMatrixSuccess(response.getData))
  } catch (error) {}
}
const asyncGetPercentageData = async (
  token,
  teamId,
  roundSerial,
  universeId
) => {
  try {
    const response = await axiosGetMarketingPrioritiesData(
      token,
      teamId,
      roundSerial,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetPrioritiesPercentageData({
  payload: { token, teamId, roundSerial, universeId },
}) {
  try {
    console.log("token here", token)
    const response = yield call(
      asyncGetPercentageData,
      token,
      teamId,
      roundSerial,
      universeId
    )
    yield put(getPrioritiesPercentageDataSuccess(response.getData))
  } catch (error) {}
}

function* studentMarketingSaga() {
  yield takeEvery(ADD_OR_UPDATE_TILL_SET_PRICE, workerAddOrUpdateTillSetPrice)
  yield takeEvery(GET_TILL_PRICE, worketGetTillPrice)
  yield takeEvery(GET_STUDENT_MARKET_DATA_ON_TECH, wrokerGetStudetnMarketTech)
  yield takeEvery(
    GET_MARKETING_PRIORITIES_DATA,
    workerGetMarketingPrioritiesData
  )

  yield takeEvery(SET_PRIORITIES_FOR_MARKETING, workerSetPriorities)
  yield takeEvery(NEW_SET_PRIORITIES_FOR_MARKETING, workerNewSetPriorities)
  yield takeEvery(GET_TAXION_DATA, workerGetTaxionData)
  yield takeEvery(
    CREATE_OR_UPDATE_MARKET_DATA_LAST_PART,
    workerCreateOrUpdateMarketingDataLastPart
  )
  yield takeEvery(
    GET_MARKETING_REGIONS_IN_INCOME_STATEMENT,
    workerGetMarketingRegionIncomeStatement
  )

  yield takeEvery(
    GO_TO_DECISION_CHECKLIST_PAGE,
    workerGoToDecisionCheckListPage
  )
  yield takeEvery(
    GET_MARKETING_PRIORITIES_INDEX,
    workerGetMarketingPrioritiesIndex
  )

  yield takeEvery(GO_TO_INCOME_STATEMENT, workerGoToIncomeStatement)
  yield takeEvery(DISABLE_TECH_BUTTON, workerDisableTechButton)
  yield takeEvery(GET_MARKET_SHARE_OF_EACH, workerGetMarketShareOfEach)
  yield takeEvery(
    GET_PRIORITIES_PERCENTAGE_DATA,
    workerGetPrioritiesPercentageData
  )
  yield takeEvery(GET_PRICING_MATRIX,workerPricingMatrix)
}

export default studentMarketingSaga
