//get rounds
export const GET_ROUNDS_BY_TEACHER = "GET_ROUNDS_BY_TEACHER"
export const GET_ROUNDS_BY_TEACHER_SUCCESS = "GET_ROUNDS_BY_TEACHER_SUCCESS"
export const GET_ROUNDS_BY_TEACHER_FAIL = "GET_ROUNDS_BY_TEACHER_FAIL"
//active round
export const ACTIVE_ROUND_BY_TEACHER = "ACTIVE_ROUND_BY_TEACHER"
export const ACTIVE_ROUND_BY_TEACHER_SUCCESS = "ACTIVE_ROUND_BY_TEACHER_SUCCESS"
export const ACTIVE_ROUND_BY_TEACHER_FAIL = "ACTIVE_ROUND_BY_TEACHER_FAIL"
//deactive round
export const DEACTIVATE_ROUND_BY_TEACHER = "DEACTIVATE_ROUND_BY_TEACHER"
export const DEACTIVATE_ROUND_BY_TEACHER_SUCCESS =
  "DEACTIVATE_ROUND_BY_TEACHER_SUCCESS"
export const DEACTIVATE_ROUND_BY_TEACHER_SUCCESS_FAIL =
  "DEACTIVATE_ROUND_BY_TEACHER_SUCCESS_FAIL"

// UPDATE ROUND

export const UPDATE_ROUND_BY_TEACHER = "UPDATE_ROUND_BY_TEACHER"
export const UPDATE_ROUND_BY_TEACHER_SUCCESS = "UPDATE_ROUND_BY_TEACHER_SUCCESS"
export const UPDATE_ROUND_BY_TEACHER_FAIL = "UPDATE_ROUND_BY_TEACHER_FAIL"
