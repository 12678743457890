
import { axiosContractData, axiosGetPlantUpgrade, axiosInHouseData, axiosPlantUpgrade, axiosUpdatePlantUpgrade } from "helpers/api_helper";
import { getData, patchData } from "helpers/Custom_Helper";
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";

import { toaster } from "../../helpers/custom/Toast";
import { getContractProduction, getContractProductionFail, getContractProductionSuccess, getInHouseProduction, getInHouseProductionFail, getInHouseProductionSuccess, getPlantUpgradeFail, getPlantUpgradeSuccess, postContractProductionFail, postContractProductionSuccess, postInHouseProductionFail, postInHouseProductionSuccess, postPlantUpgradeFail, postPlantUpgradeSuccess, storePlantUpgradeData, storeProductionCapacity, updateContractProductionFail, updateContractProductionSuccess, updateInHouseProductionFail, updateInHouseProductionSuccess } from "./actions";
import { GET_CONTRACT_PRODUCTION, GET_IN_HOUSE_PRODUCTION, GET_PLANT_UPGRADE, POST_CONTRACT_PRODUCTION, POST_IN_HOUSE_PRODUCTION, POST_PLANT_UPGRADE, UPDATE_CONTRACT_PRODUCTION, UPDATE_IN_HOUSE_PRODUCTION, UPDATE_PLANT_UPGRADE } from "./actionTypes";



//for main



function* onPostInHouseProd({ payload: { values, history, token } }) {
  try {
    const response = yield call(axiosInHouseData, values, token)
    console.log("hi")
    console.log(response)
    yield put(postInHouseProductionSuccess(response))
    toast("🦄 Production Upgraded Successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Production Upgraded  successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(postInHouseProductionFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}

function* onPostContractProd({ payload: { values, history, token } }) {
  try {
    const response = yield call(axiosContractData, values, token)
    console.log("hi")
    console.log(response)
    yield put(postContractProductionSuccess(response))
    toast("🦄 Production Upgraded Successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Production Upgraded  successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(postContractProductionFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}
function* onGetInHouseProduction({ payload: { token, course, region, tech }, }) {
  try {
    const url = `in-house-production/data-get/${course}/${region}/${tech}`;
    const response = yield call(getData, url, token);
    yield put(getInHouseProductionSuccess(response?.getData));
    yield put(storeProductionCapacity("modifiedInHouseProduction",response?.getData?.inHouseProductionCost));
  } catch (error) {
    const message = error.response?.data?.message || "get banner failed";
    yield put(getInHouseProductionFail(message));
    toaster("error", message);
  }
}
function* onGetContractProduction({ payload: { token, course, region, tech }, }) {
  try {
    const url = `contract-production/data-get/${course}/${region}/${tech}`;
    const response = yield call(getData, url, token);
    yield put(getContractProductionSuccess(response?.getData));
    yield put(storeProductionCapacity("modifiedContractProduction",response?.getData?.ContractProductionCost));
  } catch (error) {
    const message = error.response?.data?.message || "get banner failed";
    yield put(getContractProductionFail(message));
    toaster("error", message);
  }
}

function* updateInHouseProduction({payload: { values, history, token, groupId, singleId, courseInfo, regionInfo, techID}}) {
  try {
    const url = `update-in-house-production-cost/${groupId}/${singleId}`;
    const response = yield call(patchData, url, values, token);
    yield put(updateInHouseProductionSuccess(response?.getData));
    yield put(getInHouseProduction(token, courseInfo, regionInfo, techID));
    toaster("success", `In House Production Updated`);
    // history.push(`/`);
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(updateInHouseProductionFail(message));
  }
}
function* updateContractProduction({payload: { values, history, token, groupId, singleId, courseInfo, regionInfo, techID}}) {
  try {
    const url = `update-contract-production-cost/${groupId}/${singleId}`;
    const response = yield call(patchData, url, values, token);
    yield put(updateContractProductionSuccess(response?.getData));
    yield put(getContractProduction(token, courseInfo, regionInfo, techID));
    toaster("success", `Contract Production Updated`);
    // history.push(`/`);
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(updateContractProductionFail(message));
  }
}

function* ProductionUpgradeSaga() {

  yield takeEvery(POST_IN_HOUSE_PRODUCTION, onPostInHouseProd);
  yield takeEvery(POST_CONTRACT_PRODUCTION, onPostContractProd);

  yield takeEvery(GET_IN_HOUSE_PRODUCTION, onGetInHouseProduction);
  yield takeEvery(GET_CONTRACT_PRODUCTION, onGetContractProduction);
  yield takeEvery(UPDATE_IN_HOUSE_PRODUCTION, updateInHouseProduction);
  yield takeEvery(UPDATE_CONTRACT_PRODUCTION, updateContractProduction);
}

export default ProductionUpgradeSaga;
