import React from 'react';
import MaacLoaderImg from './MaacLoader.svg';
import "./MaacStyle.css";
const MaacLoader = () => {
    return (
        <div className="maac-loader-main-wrap">
            <div className="maac-loader-inner-wrap">
                <img src={MaacLoaderImg} alt="loader"/>
                
            </div>
        </div>
    );
};

export default MaacLoader;