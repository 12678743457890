import {
  ADD_UNIVERSE,
  ADD_UNIVERSE_SUCCESS,
  ADD_UNIVERSE_FAIL,
  GET_UNIVERSES,
  GET_UNIVERSES_SUCCESS,
  GET_SINGLE_UNIVERSE,
  GET_SINGLE_UNIVERSE_SUCCESS,
  GET_SINGLE_UNIVERSE_FAIL,
} from "./actionTypes"

export const addUniverse = (values, token) => {
  return {
    type: ADD_UNIVERSE,
    payload: { values, token },
  }
}

export const addUniverseSuccess = message => {
  return {
    type: ADD_UNIVERSE_SUCCESS,
    payload: { message },
  }
}

export const addUniverseFail = message => {
  return {
    type: ADD_UNIVERSE_FAIL,
    payload: { message },
  }
}

export const getUniverses = (courseid, token) => {
  return {
    type: GET_UNIVERSES,
    payload: { courseid, token },
  }
}
export const getUniversSuccess = universe => {
  return {
    type: GET_UNIVERSES_SUCCESS,
    payload: { universe },
  }
}

export const getSingleUniverse = (universeid, token) => {
  return {
    type: GET_SINGLE_UNIVERSE,
    payload: { universeid, token },
  }
}

export const getSingleUniverseSuccess = universe => {
  return {
    type: GET_SINGLE_UNIVERSE_SUCCESS,
    payload: { universe },
  }
}

export const getSingleUniverseFail = message => {
  return {
    type: GET_SINGLE_UNIVERSE_FAIL,
    payload: { message },
  }
}
