import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"

import { GET_INITIAL_CAPITAL } from "./actionTypes"

import { getInitialCapitalSuccess } from "./actions"
import { axiosGetInitialCapital } from "../../helpers/api_helper"

const asyncGetInitialCapital = async (
  token,
  roundSerial,
  teamId,
  universeId
) => {
  try {
    const response = await axiosGetInitialCapital(
      token,
      teamId,
      roundSerial,
      universeId
    )
    console.log("response data 12 no here", response.data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetInitialCapital({
  payload: { token, roundSerial, teamId, universeId },
}) {
  try {
    const response = yield call(
      asyncGetInitialCapital,
      token,
      roundSerial,
      teamId,
      universeId
    )
    console.log("hello data 26")
    console.log("capital Data here", response.CapitalData)
    yield put(getInitialCapitalSuccess(response.CapitalData))
  } catch (error) {}
}
function* studentInitialCapitalSaga() {
  yield takeEvery(GET_INITIAL_CAPITAL, workerGetInitialCapital)
}

export default studentInitialCapitalSaga
