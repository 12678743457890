import {
  ADD_COURSE,
  ADD_COURSE_SUCCESS,
  ADD_COURSE_FAIL,
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_FOR_ONE,
  GET_COURSE_FOR_ONE_SUCCESS,
  GET_COURSE_FOR_ONE_FAIL,
  GET_COURSE_ALL_DATA,
  GET_COURSE_ALL_DATA_SUCCESS,
  DELETE_COURSE_FAIL,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE,
} from "./actionTypes"

export const addCourse = (values, history, token) => ({
  type: ADD_COURSE,
  payload: { values, history, token },
})

export const addCourseSuccess = message => {
  return {
    type: ADD_COURSE_SUCCESS,
    payload: { message },
  }
}

export const addCourseFail = message => ({
  type: ADD_COURSE_FAIL,
  payload: { message },
})

export const getCourse = token => ({
  type: GET_COURSE,
  payload: token,
})

export const getCourseSuccess = courses => ({
  type: GET_COURSE_SUCCESS,
  payload: { courses },
})

export const deleteCourse = (token, courseId) => ({
  type: DELETE_COURSE,
  payload: { token, courseId },
})
export const deleteCourseSuccess = message => ({
  type: DELETE_COURSE_SUCCESS,
  payload: { message },
})

export const deleteCourseFail = error => ({
  type: DELETE_COURSE_FAIL,
  payload: { error },
})
export const getCourseForOne = token => ({
  type: GET_COURSE_FOR_ONE,
  payload: token,
})

export const getCourseForOneSuccess = courseforone => ({
  type: GET_COURSE_FOR_ONE_SUCCESS,
  payload: {
    courseforone,
  },
})

export const getCourseAllData = token => ({
  type: GET_COURSE_ALL_DATA,
  payload: { token },
})

export const getCourseAllDataSuccess = data => ({
  type: GET_COURSE_ALL_DATA_SUCCESS,
  payload: { data },
})
