import { GET_PLANT_UPGRADE, GET_PLANT_UPGRADE_FAIL, GET_PLANT_UPGRADE_SUCCESS, POST_PLANT_UPGRADE, POST_PLANT_UPGRADE_FAIL, POST_PLANT_UPGRADE_SUCCESS, STORE_PLANT_DATA, UPDATE_PLANT_UPGRADE, UPDATE_PLANT_UPGRADE_FAIL, UPDATE_PLANT_UPGRADE_SUCCESS } from "./actionTypes";

export const postPlantUpgrade = (values, history, token) => {
    return {
      type: POST_PLANT_UPGRADE,
      payload: { values, history, token},
    };
  };
  export const postPlantUpgradeSuccess = (message) => {
    return {
      type: POST_PLANT_UPGRADE_SUCCESS,
      payload: { message },
    };
  };
  
  export const postPlantUpgradeFail = (message) => {
    return {
      type: POST_PLANT_UPGRADE_FAIL,
      payload: { message },
    };
  };


  export const updatePlantUpgrade = (values, history, token, groupId, singleId, courseInfo, regionInfo) => {
    return {
      type: UPDATE_PLANT_UPGRADE,
      payload: { values, history, token, groupId, singleId, courseInfo, regionInfo},
    };
  };
  export const updatePlantUpgradeSuccess = (message) => {
    return {
      type: UPDATE_PLANT_UPGRADE_SUCCESS,
      payload: { message },
    };
  };
  
  export const updatePlantUpgradeFail = (message) => {
    return {
      type: UPDATE_PLANT_UPGRADE_FAIL,
      payload: { message },
    };
  };


  export const getPlantUpgrade = (token, course, region) => {
    return {
      type: GET_PLANT_UPGRADE,
      payload: { token, course, region },
    };
  };
  
  export const getPlantUpgradeSuccess = (values) => {
    return {
      type: GET_PLANT_UPGRADE_SUCCESS,
      payload: { values },
    };
  };
  
  export const getPlantUpgradeFail = (message) => {
    return {
      type: GET_PLANT_UPGRADE_FAIL,
      payload: { message },
    };
  };


  export const storePlantUpgradeData = (name, data) => ({
    type: STORE_PLANT_DATA,
    payload: { name, data },
  });