import React, { useState, useEffect } from "react"
import { Container, FormSelect } from "react-bootstrap"
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  Row,
  Form,
  Label,
  Table,
  Input,
  Button,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  getRegions,
  addMarketShareLastRound,
  adminGetMarketShare,
  getAvailableTechAdmin,
  postTotalDemand,
} from "store/actions"
import { MDBDataTable } from "mdbreact"
import addCircle from "../../assets/images/add-circle.png"
import closeCircle from "../../assets/images/close-circle.png"
import CustomInput from "common/CustomFilter/CustomInput"
import AddCardComponent from "common/Layout/AddCardComponent"
import { useFormik } from "formik"
import { addUserSchema } from "pages/Schemas/AddUser.schema"
import { getCourse } from "../../store/actions"
import Select from "react-select"
import { toaster } from "helpers/custom/Toast"
import {
  getInHouseProduction,
  postInHouseProduction,
  storeProductionCapacity,
  updateInHouseProduction,
} from "store/ProductionCapacity/actions"
import { updatePlantUpgrade } from "store/PlantUpgrade/actions"
const initialValues = {}
const roundList = [
  {
    value: 0,
    _id: "64923741097",
  },
  {
    value: 1,
    _id: "64923748903",
  },
  {
    value: 2,
    _id: "64923744571",
  },
  {
    value: 3,
    _id: "64923709021",
  },
  {
    value: 4,
    _id: "64923701239",
  },
  {
    value: 5,
    _id: "64923782392",
  },
  {
    value: 6,
    _id: "64923785315",
  },
  {
    value: 7,
    _id: "64923787642",
  },
]
const UpdateInHouseProduction = ({ edit }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [regionData, setRegionData] = useState({})
  const [initialCapital, setInitialCapital] = useState()
  const [marketData, setMarketData] = useState()
  const [techData, setTechData] = useState()
  const [regionInfo, setRegionInfo] = useState("")
  const [courseInfo, setCourseInfo] = useState("")
  const [techName, setTechName] = useState("")
  const [techID, setTechID] = useState("")
  const [roundInfo, setRoundInfo] = useState({
    roundNum: "",
    roundId: "",
    productionCost: "",
    techName: "",
    techID: "",
  })
  const [singleRound, setSingleRound] = useState([])
  const {
    token,
    loading,
    regions,

    techs,
    adding,
    courses,
    modifiedInHouseProduction,
    inHouseProductionLoading,
    inHouseProduction,
  } = useSelector(state => ({
    token: state.Login.token,
    loading: state.regionData.loading,
    regions: state.regionData.regions,
    message: state.adminDemandData.message,

    techs: state.techData.adminAvailableTech,
    modifiedInHouseProduction:
      state.productionCapacityReducer.modifiedInHouseProduction,
    inHouseProductionLoading:
      state.productionCapacityReducer.inHouseProductionLoading,
    courses: state.courseAdmin.courses,
    inHouseProduction: state.productionCapacityReducer.inHouseProduction,
    adding: state.productionCapacityReducer.adding,
  }))
  console.log("inHouseProduction", inHouseProduction)

  let value = {}
  value.roundSerial = 0
  useEffect(() => {
    dispatch(getRegions(token))
    dispatch(getAvailableTechAdmin(token))
  }, [])
  useEffect(() => {
    dispatch(adminGetMarketShare(token))
  }, [])

  useEffect(() => {
    dispatch(getCourse(token))
  }, [])

  const onSubmit = values => {
    console.log("values", values)
  }

  const AddUser = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addUserSchema,
    onSubmit,
  })

  const handleCourse = data => {
    console.log("data", data)
    if (data) {
      setCourseInfo(data?._id)
    } else {
      setCourseInfo("")
    }
  }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      }
    },
  }
  const handleRegion = data => {
    console.log("data", data)
    if (data) {
      setRegionInfo(data?._id)
    } else {
      setRegionInfo("")
    }
  }
  const handletech = data => {
    console.log("data", data)
    if (data) {
      setTechID(data?._id)
      setTechName(data?.techName)
    }
  }

  useEffect(() => {
    if (regionInfo && courseInfo && techID) {
      dispatch(getInHouseProduction(token, courseInfo, regionInfo, techID))
    }
  }, [courseInfo, regionInfo, techID])
  console.log("courseInfo", courseInfo)

  const handleProductionCostEdit = (e, idx, data) => {
    const oldValue = [...modifiedInHouseProduction]
    console.log("oldValue", oldValue)

    oldValue[idx].productionCost = e.target.value
    dispatch(storeProductionCapacity("modifiedInHouseProduction", oldValue))
  }

  const handleUpdateData = (key, data) => {
    console.log("data", data)
    let obj = {
      roundSerial: data?.roundSerial,
      productionCost: data?.productionCost * 1,
    }
    console.log("obj", obj)
    
    if(obj?.productionCost ===0){
      return toaster("warning", "Data should be greater than 0")
    }
    else{
      dispatch(
        updateInHouseProduction(
          obj,
          history,
          token,
          inHouseProduction?._id,
          data?._id,
          courseInfo,
          regionInfo,
          techID
        )
      )
 
  }
}
  return (
    <div>
      <Container>
        <Row className="mt-4">
          <Col md="9" className="mx-auto">
            {/* add User */}
            <div className="text-end mb-3">
              <Button
                color="info"
                className="btn btn-primary me-end"
                onClick={() => history.push("/Inhouse-Production")}
              >
                Add In House Production
              </Button>
            </div>
            <AddCardComponent className="">
              <h5 className="mb-4">Update In House production</h5>
              <Form
                className="needs-validation col-md-12"
                onSubmit={AddUser.handleSubmit}
              >
                <Row>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Courses</Label>
                    <Select
                      name="course"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Course"
                      cacheOptions
                      getOptionLabel={e => e.courseName}
                      getOptionValue={e => e.value}
                      isClearable
                      options={courses}
                      styles={colourStyles}
                      onChange={handleCourse}
                    />
                  </Col>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Region</Label>
                    <Select
                      name="course"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Region"
                      cacheOptions
                      getOptionLabel={e => e.regionName}
                      getOptionValue={e => e.value}
                      isClearable
                      options={regions}
                      styles={colourStyles}
                      onChange={handleRegion}
                    />
                  </Col>

                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Tech</Label>
                    <Select
                      name="tech"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Tech"
                      cacheOptions
                      getOptionLabel={e => e.techName}
                      getOptionValue={e => e.value}
                      options={techs}
                      isClearable
                      styles={colourStyles}
                      onChange={handletech}
                    />
                  </Col>
                </Row>

                <Row>
                  <Table className="table table-hover mb-0 table-centered  mb-5">
                    <thead className="table-light">
                      <tr>
                        <th>No.</th>
                        <th>Round No</th>

                        <th>Production Cost</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseInfo !== "" &&
                      regionInfo !== "" &&
                      techID !== "" ? (
                        <>
                          {" "}
                          {inHouseProductionLoading ? (
                            <tr>
                              <td colSpan="5">
                                <h6 className="text-center">Loading...</h6>
                              </td>
                            </tr>
                          ) : (
                            (modifiedInHouseProduction || []).map(
                              (data, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>

                                    <td>{data?.roundSerial}</td>
                                    <td>
                                      <Input
                                        type="number"
                                        style={{
                                          width: "100px",
                                          height: "38px",
                                        }}
                                        value={data?.productionCost}
                                        onChange={e =>
                                          handleProductionCostEdit(e, key, data)
                                        }
                                      ></Input>
                                    </td>

                                    <td>
                                      <Button
                                      color="warning"
                                      outline
                                        disabled={adding}
                                        onClick={() =>
                                          handleUpdateData(key, data)
                                        }
                                      >
                                        {adding ? "Submitting" : "Update"}
                                      </Button>
                                    </td>
                                  </tr>
                                )
                              }
                            )
                          )}
                        </>
                      ) : (
                        <p className="mt-3">
                          Please Select all required fields
                        </p>
                      )}
                    </tbody>
                  </Table>
                </Row>
              </Form>
            </AddCardComponent>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UpdateInHouseProduction
