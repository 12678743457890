import {
  ADD_IN_HOUSE_MANUFACTURE,
  GET_CAPACITY_BY_STUDENT,
  GET_IN_HOUSE_MANUFACTURE,
  ADD_CONTRACT_MANUFACTURE,
  GET_PRODUCTION_REQUIREMENT,
  GET_CAPACITY_MANUFACTURE,
  GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART,
  GET_CONTRACT_MANUFACTURE_LAST_PART,
  GET_STUDENT_INVENTORY,
} from "./actionTypes"
import {
  getCapacityByStudentSuccess,
  getInHouseManufactureDataSuccess,
  addInHouseManufactureSuccess,
  addInHouseManufactureFail,
  addContractManufactureFail,
  addContractManufactureSuccess,
  getContractManufactureSuccess,
  getProductionRequirement,
  getCapacityManufactureSuccess,
  getInHouseManufactureDataLastPartSuccess,
  getContractManufactureDataLastPartSuccess,
  getStudentInventorySuccess,
} from "./actions"
import {
  axiosGetCapacityData,
  axiosGetContractManufacture,
  axiosAddInHouseManufacture,
  axiosGetInHouseManufacture,
  axiosAddContractManufacture,
  axiosGetProductionRequirement,
  axiosGetCapacityManufacture,
  axiosGetInHouseSecondPartData,
  axiosGetContractManufactureLastPart,
  axiosGetStudentInventory,
} from "../../helpers/api_helper"
import { call, put, takeEvery } from "redux-saga/effects"

import { toast } from "react-toastify"
import { getProductionRequirementSuccess } from "store/actions"

const asyncGetStudentInventory = async (token, roundSerial, teamId) => {
  try {
    const response = await axiosGetStudentInventory(token, teamId, roundSerial)
    console.log("hello inventory ami respone", response.data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetStudentInventory({
  payload: { token, roundSerial, teamId },
}) {
  try {
    const response = yield call(
      asyncGetStudentInventory,
      token,
      roundSerial,
      teamId
    )
    console.log("invenotry response", response.data)
    yield put(getStudentInventorySuccess(response.data))
  } catch (error) {}
}

const asyncGetContractManufactureLastPart = async (
  token,

  roundSerial,
  universeId
) => {
  try {
    const response = await axiosGetContractManufactureLastPart(
      token,

      roundSerial,
      universeId
    )
    return response.data
  } catch (error) {}
}

function* workerGetContractManufactureLastPart({
  payload: { token, roundSerial, universeId },
}) {
  try {
    const response = yield call(
      asyncGetContractManufactureLastPart,
      token,

      roundSerial,
      universeId
    )
    yield put(getContractManufactureDataLastPartSuccess(response.objData))
  } catch (error) {}
}

const asyncGetInHouseSecondPart = async (
  token,

  roundSerial,
  universeId
) => {
  try {
    console.log("round serial for in house", roundSerial)
    const response = await axiosGetInHouseSecondPartData(
      token,

      roundSerial,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetInHosueManufactureDataLastPart({
  payload: { token, roundSerial, universeId },
}) {
  try {
    const response = yield call(
      asyncGetInHouseSecondPart,
      token,

      roundSerial,
      universeId
    )

    yield put(getInHouseManufactureDataLastPartSuccess(response.objData))
  } catch (error) {
    console.log("error data")
  }
}

const asyncGetCapacityManufacture = async (token, roundSerial) => {
  try {
    const response = await axiosGetCapacityManufacture(token, roundSerial)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetCapcityManufacture({ payload: { token, roundSerial } }) {
  try {
    const response = yield call(asyncGetCapacityManufacture, token, roundSerial)
    yield put(getCapacityManufactureSuccess(response.getCapacityData))
  } catch (error) {}
}

const asyncGetProductionRequirement = async (
  token,
  teamId,
  roundSerial,
  regionId,
  universeId
) => {
  try {
    const response = await axiosGetProductionRequirement(
      token,
      teamId,
      roundSerial,
      regionId,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetProductionRequirement({
  payload: { token, teamId, roundSerial, regionId, universeId },
}) {
  try {
    const response = yield call(
      asyncGetProductionRequirement,
      token,
      teamId,
      roundSerial,
      regionId,
      universeId
    )
    yield put(getProductionRequirementSuccess(response.allData))
  } catch (error) {}
}

const asyncGetContractManufacture = async (token, roundSerial) => {
  try {
    const response = await axiosGetContractManufacture(
      token,

      roundSerial
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGetContractManufacture({ payload: { token, roundSerial } }) {
  try {
    const response = yield call(
      asyncGetContractManufacture,
      token,

      roundSerial
    )
    yield put(getContractManufactureSuccess(response.contractData))
  } catch (error) {}
}

const asyncAddContractManufacture = async (
  token,
  regionId,
  techId,
  techName,
  regionName,
  teamId,
  roundSerial,
  capacityAllocation,
  totalProductionCapacity,
  universeId
) => {
  try {
    const response = await axiosAddContractManufacture(
      token,
      regionId,
      techId,
      techName,
      regionName,
      teamId,
      roundSerial,
      capacityAllocation,
      totalProductionCapacity,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerAddContractManufacture({
  payload: {
    token,
    regionId,
    techId,
    techName,
    regionName,
    teamId,
    roundSerial,
    capacityAllocation,
    totalProductionCapacity,
    universeId,
  },
}) {
  try {
    const response = yield call(
      asyncAddContractManufacture,
      token,
      regionId,
      techId,
      techName,
      regionName,
      teamId,
      roundSerial,
      capacityAllocation,
      totalProductionCapacity,
      universeId
    )
    if (response.status == "success") {
      yield put(addContractManufactureSuccess(response.message))
      const res = yield call(
        asyncGetContractManufactureLastPart,
        token,
        roundSerial,
        universeId
      )
      yield put(getContractManufactureDataLastPartSuccess(res.objData))
      yield put(addContractManufactureSuccess())
    } else {
      toast.error(response.message)
      yield put(addContractManufactureFail(response.message))
    }
  } catch (error) {}
}

const asyncGetInHouseManufacture = async (teamId, roundSerial, token) => {
  try {
    const response = await axiosGetInHouseManufacture(
      token,
      teamId,
      roundSerial
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetInHouseManufacture({
  payload: { teamId, roundSerial, token },
}) {
  try {
    console.log("hello data", teamId, roundSerial, token)
    const response = yield call(
      asyncGetInHouseManufacture,
      teamId,
      roundSerial,
      token
    )
    yield put(getInHouseManufactureDataSuccess(response.data))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncAddInHouseManufacture = async (
  token,
  regionId,
  techId,
  techName,
  regionName,
  teamId,
  roundSerial,
  capacityAllocation,
  productionLevel,
  universeId
) => {
  try {
    const response = await axiosAddInHouseManufacture(
      token,
      regionId,
      techId,
      techName,
      regionName,
      teamId,
      roundSerial,
      capacityAllocation,
      productionLevel,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerAddInHouseManufacture({
  payload: {
    token,
    regionId,
    techId,
    techName,
    regionName,
    teamId,
    roundSerial,
    capacityAllocation,
    productionLevel,
    universeId,
  },
}) {
  try {
    let response = yield call(
      asyncAddInHouseManufacture,
      token,
      regionId,
      techId,
      techName,
      regionName,
      teamId,
      roundSerial,
      capacityAllocation,
      productionLevel,
      universeId
    )
    if (response.status == "success") {
      yield put(addInHouseManufactureSuccess(response.message))
      yield put(addInHouseManufactureSuccess())
      let resposneData = yield call(
        asyncGetInHouseSecondPart,
        token,

        roundSerial,
        universeId
      )
      yield put(getInHouseManufactureDataLastPartSuccess(resposneData.objData))
    } else {
      toast.error(response.message)
      yield put(addInHouseManufactureFail(response.message))
    }
  } catch (error) {
    console.log("error response data", error)
  }
}
const asyncGetCapacityDataforStudent = async (
  token,
  teamId,
  roundSerial,
  universeId
) => {
  try {
    const response = await axiosGetCapacityData(
      token,
      teamId,
      roundSerial,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetCapacityDataForStudent({
  payload: { token, teamId, roundSerial, universeId },
}) {
  try {
    const response = yield call(
      asyncGetCapacityDataforStudent,
      token,
      teamId,
      roundSerial,
      universeId
    )

    yield put(getCapacityByStudentSuccess(response.getData))
  } catch (error) {}
}

function* studentPlanningSaga() {
  yield takeEvery(GET_CAPACITY_BY_STUDENT, workerGetCapacityDataForStudent)
  yield takeEvery(ADD_IN_HOUSE_MANUFACTURE, workerAddInHouseManufacture)
  yield takeEvery(GET_IN_HOUSE_MANUFACTURE, workerGetInHouseManufacture)
  yield takeEvery(ADD_CONTRACT_MANUFACTURE, workerAddContractManufacture)
  // yield takeEvery(
  //   GET_CONTRACT_MANUFACTURE_LAST_PART,
  //   workerGetContractManufacture
  // )
  yield takeEvery(GET_PRODUCTION_REQUIREMENT, workerGetProductionRequirement)
  yield takeEvery(GET_CAPACITY_MANUFACTURE, workerGetCapcityManufacture)
  yield takeEvery(
    GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART,
    workerGetInHosueManufactureDataLastPart
  )
  yield takeEvery(
    GET_CONTRACT_MANUFACTURE_LAST_PART,
    workerGetContractManufactureLastPart
  )

  yield takeEvery(GET_STUDENT_INVENTORY, workerGetStudentInventory)
}

export default studentPlanningSaga
