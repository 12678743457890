import {
  ADD_ROUND_DATA,
  ADD_ROUND_DATA_SUCCESS,
  ADD_ROUND_DATA_FAIL,
  GET_ROUND,
  GET_ROUND_FAIL,
  GET_ROUND_SUCCESS,
} from "./actionTypes"

const initialState = {
  rounds: [],

  loading: false,
  message: "",
  error: "",
}

const roundData = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ROUND_DATA:
      state = { ...state, loading: true }
      break
    case ADD_ROUND_DATA_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case ADD_ROUND_DATA_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case GET_ROUND:
      state = { ...state, loading: true }
      break
    case GET_ROUND_SUCCESS:
      state = { ...state, loading: false, rounds: action.payload.data }
      break
    default:
      state = state
      break
  }
  return state
}

export default roundData
