import {
  GET_UNAVAILABLE_FEATURE,
  GET_UNAVAILABLE_FEATURE_FAIL,
  GET_UNAVAILABLE_FEATURE_SUCCESS,
  GET_TOTAL_OWN_FEATURE_NUMBER,
  GET_TOTAL_OWN_FEATURE_NUMBER_SUCCESS,
  GET_FEATURE_PURCHASE_PRICE,
  GET_FEATURE_PURCHASE_PRICE_SUCCESS,
  GET_FEATURE_UPGRADE_PRICE_SUCCESS,
  GET_FEATURE_UPGRADE_PRICE,
  GET_TECH_PURCHASE_PRICE,
  GET_TECH_PURCHASE_PRICE_SUCCESS,
  GET_TECH_UPGRADE_PRICE,
  GET_TECH_UPGRADE_PRICE_SUCCESS,
} from "./actionTypes"

//get unavailable feature
export const getUnavailableFeature = token => ({
  type: GET_UNAVAILABLE_FEATURE,
  payload: { token },
})

//get unavailable feature success
export const getUnavailableFeatureSuccess = data => ({
  type: GET_UNAVAILABLE_FEATURE_SUCCESS,
  payload: { data },
})

export const getTotalOwnFeature = (token, teamId) => ({
  type: GET_TOTAL_OWN_FEATURE_NUMBER,
  payload: { token, teamId },
})

export const getTotalOwnFeatureSuccess = data => ({
  type: GET_TOTAL_OWN_FEATURE_NUMBER_SUCCESS,
  payload: { data },
})

export const getFeaturePurchasePrice = (token, universeId) => ({
  type: GET_FEATURE_PURCHASE_PRICE,
  payload: { token, universeId },
})
export const getFeaturePurchasePriceSuccess = data => ({
  type: GET_FEATURE_PURCHASE_PRICE_SUCCESS,
  payload: { data },
})

export const getFeatureUpgradePrice = (token, universeId) => ({
  type: GET_FEATURE_UPGRADE_PRICE,
  payload: { token, universeId },
})
export const getFeatureUpgradePriceSuccess = data => ({
  type: GET_FEATURE_UPGRADE_PRICE_SUCCESS,
  payload: { data },
})

export const getTechUpgradePrice = (token, universeId) => ({
  type: GET_TECH_UPGRADE_PRICE,
  payload: { token, universeId },
})
export const getTechUpgradePriceSuccess = data => ({
  type: GET_TECH_UPGRADE_PRICE_SUCCESS,
  payload: { data },
})

export const getTechPurchasePrice = (token, universeId) => ({
  type: GET_TECH_PURCHASE_PRICE,
  payload: { token, universeId },
})
export const getTechPurchasePriceSuccess = data => ({
  type: GET_TECH_PURCHASE_PRICE_SUCCESS,
  payload: { data },
})
