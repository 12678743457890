import {
  ADD_TECH_FROM_FILE_FAIL,
  ADD_TECH_FROM_FILE_SUCCESS,
  ADD_TECH_FROM_FILE,
  GET_TECHS,
  GET_TECHS_SUCCESS,
  GET_AVAILABLE_TECH,
  GET_AVAILABLE_TECH_SUCCESS,
  GET_UNAVAILABLE_TECH,
  GET_UNAVAILABLE_TECH_SUCCESS,
  GET_AVAILABLE_TECH_FOR_ADMIN,
  GET_AVAILABLE_TECH_FOR_ADMIN_SUCCESS,
  GET_UNAVAILABLE_TECH_FOR_R_AND_D,
  GET_UNAVAILABLE_TECH_FOR_R_AND_D_SUCCESS,
  ANY_TECH_IN_PROCESSING,
  ANY_TECH_IN_PROCESSING_SUCCESS,
} from "./actionTypes"

const initialState = {
  techs: [],
  availableTech: [],
  unavailableTech: [],
  adminAvailableTech: [],
  tech: {},
  loading: false,
  message: "",
  error: "",
  techForRAndD: [],
  processingTech: {},
}

const techData = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TECH_FROM_FILE:
      state = { ...state, loading: true }
      break
    case ADD_TECH_FROM_FILE_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case ADD_TECH_FROM_FILE_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case GET_TECHS:
      state = { ...state, loading: true }
      break
    case GET_TECHS_SUCCESS:
      state = { ...state, loading: false, techs: action.payload.data }
      break
    case GET_AVAILABLE_TECH:
      state = { ...state, loading: true }
      break
    case GET_AVAILABLE_TECH_SUCCESS:
      state = { ...state, loading: false, availableTech: action.payload.data }
      break
    case GET_UNAVAILABLE_TECH:
      state = { ...state, loading: true }
      break
    case GET_UNAVAILABLE_TECH_SUCCESS:
      state = { ...state, loading: false, unavailableTech: action.payload.data }
      break
    case GET_AVAILABLE_TECH_FOR_ADMIN:
      state = { ...state, loading: true }
      break
    case GET_AVAILABLE_TECH_FOR_ADMIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        adminAvailableTech: action.payload.data,
      }
      break
    case GET_UNAVAILABLE_TECH_FOR_R_AND_D:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_UNAVAILABLE_TECH_FOR_R_AND_D_SUCCESS:
      state = {
        ...state,
        loading: false,
        techForRAndD: action.payload.data,
      }
      break
    case ANY_TECH_IN_PROCESSING:
      state = {
        ...state,
        loading: true,
      }
      break
    case ANY_TECH_IN_PROCESSING_SUCCESS:
      state = {
        ...state,
        loading: false,
        processingTech: action.payload.data,
      }
      break
    default:
      state = state
      break
  }
  return state
}
export default techData
