import {
    ADD_STUDENT,
    ADD_STUDENT_FAIL,
    ADD_STUDENT_SUCCESS,
    GET_STUDENT,
    GET_STUDENT_SUCCESS
} from "./actionTypes"

const initialState = {
    students: [],
    loading: false,
    message: "",
    error: ""
}

const studentsAdmin = (state = initialState, action) => {
    switch (action.type) {
        case ADD_STUDENT:
            state = {
                ...state,

                loading: true,
            }
        case ADD_STUDENT_SUCCESS:
            state = {...state, loading: false, message: action.payload.message }
            break;
        case ADD_STUDENT_FAIL:
            state = {...state, loading: false, error: action.payload.error }
            break;
        case GET_STUDENT:
            state = {...state, loading: true }
        case GET_STUDENT_SUCCESS:
            state = {...state, loading: false, students: action.payload.students }

        default:
            state = state
            break;

    }
    return state;
}

export default studentsAdmin