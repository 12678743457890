import {
  GET_STUDENT_PROFILE_DATA,
  GET_STUDENT_DEMAND_DATA,
  UPDATE_DEMAND_TABLE_ONE,
  GET_EXPECTED_DEMAND_DATA,
  GET_MARKET_SHARES_STUDENT,
  UPDATE_MARKET_SHARES_STUDENT,
  GET_EXPECTED_MARKET_DEMAND_STUDENT,
  GET_ACTIVITY_DATA,
  GET_UNIVERSE_DATA,
  SELECT_UNIVERSE_DATA
} from "./actionTypes"
import {
  getStudentProfileDataSuccess,
  getStudentDemandDataSucccess,
  getStudentDemandDataFail,
  updateDemandTableOneFail,
  updateDemandTableOneSuccess,
  getExpectedDemandDataFail,
  getExpectedDemandDataSuccess,
  getMarketShareStudentSuccess,
  updateMarketShareStudentSuccess,
  updateMarketShareStudentFail,
  getExpectedMarketDemandSuccess,
  getExpectedMarketDemandFail,
  getActivityDataSuccess,
  getActivityDataFail,
  getUniverseDataSuccess,
  getUniverseDataFail,
  selectUniverseSuccess,
  selectUniverseFail
} from "./actions"
import {
  axiosGetMe,
  axiosGetStudentDemandData,
  axiosUpdateDemandData,
  axiosGetExpectedDemandData,
  axiosGetMarketDataStudent,
  axiosUpdateMarketShareStudent,
  axiosGetExpectedMarketDemand,
  axiosGetExpectedMarketShare,
  axiosSelectUniverse,
  activityData,
  universeData
} from "../../helpers/api_helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"

const asyncGetExpectedMarketDemand = async (token, roundSerial, teamId) => {
  try {
    const response = await axiosGetExpectedMarketShare(
      token,
      roundSerial,
      teamId
    )
    console.log("hello resposne", response.data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetExpectedMarketDemand({
  payload: { token, roundSerial, teamId },
}) {
  try {
    console.log("48 line print")
    const response = yield call(
      asyncGetExpectedMarketDemand,
      token,
      roundSerial,
      teamId
    )
    console.log("response data of market share", response)
    if (response.status == "success") {
      yield put(
        getExpectedMarketDemandSuccess(response.findProductAndMarketData)
      )
    } else {
      yield put(getExpectedMarketDemandFail(response.message))
    }
  } catch (error) {}
}

//asyncUpdate
const asyncUpdateMarketShareStudent = async (
  regionId,
  techId,
  lastRound,
  estimateMarketShare,
  forcastDemand,
  totalProductTarget,
  roundSerial,
  token
) => {
  try {
    const response = await axiosUpdateMarketShareStudent(
      regionId,
      techId,
      lastRound,
      estimateMarketShare,
      forcastDemand,
      totalProductTarget,
      roundSerial,
      token
    )
    console.log("response data", response.data)
    return response.data
  } catch (error) {
    console.log("error data", error.response.data)
    return error.response.data
  }
}
//worker update
function* workerUpdateMarketShareStudent({
  payload: {
    regionId,
    techId,
    lastRound,
    estimateMarketShare,
    forcastDemand,
    totalProductTarget,
    roundSerial,
    token,
  },
}) {
  try {
    console.log("round serial data", roundSerial)
    const response = yield call(
      asyncUpdateMarketShareStudent,
      regionId,
      techId,
      lastRound,
      estimateMarketShare,
      forcastDemand,
      totalProductTarget,
      roundSerial,
      token
    )
    console.log("response data", response)
    if (response.status == "success") {
      toast.success(response.message)
      yield put(updateMarketShareStudentSuccess(response.message))
      yield put(updateMarketShareStudentSuccess())
    }
    yield put(updateMarketShareStudentFail(response.message))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncGetMarketShareStudent = async (token, teamId, roundSerial) => {
  try {
    const response = await axiosGetMarketDataStudent(token, teamId, roundSerial)
    console.log("log data 31 no", response.data)
    return response.data
  } catch (error) {
    console.log("error data", error.response.data)
  }
}

//get market share student
function* workerGetMarketShareStudent({
  payload: { token, teamId, roundSerial },
}) {
  try {
    const response = yield call(
      asyncGetMarketShareStudent,
      token,
      teamId,
      roundSerial
    )
    yield put(getMarketShareStudentSuccess(response.findProductAndMarketData))
  } catch (error) {}
}

//asyncUpdate Demand
const asyncUpdateDemandTableOne = async (
  token,
  region,
  predictedMarketGrowth,
  totalDemandLastRound,
  teamId,
  roundSerial
) => {
  try {
    const response = await axiosUpdateDemandData(
      token,
      region,
      predictedMarketGrowth,
      totalDemandLastRound,
      roundSerial,
      teamId
    )
    console.log("response data", response.data)
    return response.data
  } catch (error) {
    console.log("error response data", error.response.data)
    return error.response.data
  }
}
//update demand
function* workerUpdateDemandTableOne({
  payload: {
    token,
    region,
    predictedMarketGrowth,
    totalDemandLastRound,
    teamId,
    roundSerial,
  },
}) {
  try {
    console.log(
      "worker data all",
      token,
      region,
      predictedMarketGrowth,
      totalDemandLastRound,
      teamId,
      roundSerial
    )
    const response = yield call(
      asyncUpdateDemandTableOne,
      token,
      region,
      predictedMarketGrowth,
      totalDemandLastRound,
      teamId,
      roundSerial
    )
    if (response.status == "success") {
      console.log("resposne data", response)
      // toast.success(response.message)
      yield put(updateDemandTableOneSuccess(response.message))
      yield put(updateDemandTableOneSuccess())
    } else {
      toast.error(response.message)
    }
  } catch (error) {}
}
const asyncGetStudentProfileData = async authtoken => {
  try {
    const response = await axiosGetMe(authtoken)
    console.log("log data here jdbd", response)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* worketGetStudentProfileData({ payload: { token } }) {
  try {
    const response = yield call(asyncGetStudentProfileData, token)
    yield put(
      getStudentProfileDataSuccess(
        response.findUniverseData,
        response.findTeamData,
        response.findUser

      )
    )
  } catch (error) {}
}
const asyncGetStudentDemandData = async (token, roundSerial, teamId) => {
  try {
    console.log("print vlau from 36 line", token, teamId, roundSerial)
    const response = await axiosGetStudentDemandData(token, teamId, roundSerial)
    console.log("response data", response.data)
    return response.data
  } catch (error) {
    console.log("error data", error)
    return error.response.data
  }
}
function* workerGetStudentDemandData({
  payload: { token, roundSerial, teamId },
}) {
  try {
    console.log("helllo from 38", token, roundSerial, teamId)
    const response = yield call(
      asyncGetStudentDemandData,
      token,
      roundSerial,
      teamId
    )
    console.log("response data from 56", response)
    yield put(
      getStudentDemandDataSucccess(
        response.findDemand,
        response.findProductAndMarket
      )
    )
  } catch (error) {
    console.log("error data", error)
  }
}

//axios get Expected demand data
const asyncGetExpectedDemandData = async (token, roundSerial) => {
  try {
    const response = await axiosGetExpectedDemandData(token, roundSerial)
    console.log("response data", response.data)
    return response.data
  } catch (error) {
    console.log("error data", error.response.data)
    return error.response.data
  }
}
//update get Expected demand data
function* workerGetExpectedDemandData({ payload: { token, roundSerial } }) {
  try {
    const response = yield call(asyncGetExpectedDemandData, token, roundSerial)
    console.log("resposne data from 161", response)
    yield put(getExpectedDemandDataSuccess(response.findExpectedDemand))
  } catch (error) {
    console.log("error data", error)
  }
}


function* fetchActivityData({payload: { token, universe, teamId },}) {
  try {  
    const response = yield call(activityData, token, universe, teamId);
    yield put(getActivityDataSuccess(response?.data));
  } catch (error) {
    yield put(getActivityDataFail(error));
  }
}
function* fetchUniverseData({payload: { token, universe, teamId },}) {

  try {  
    const response = yield call(universeData, token, universe, teamId);
    yield put(getUniverseDataSuccess(response?.data));
  } catch (error) {
    yield put(getUniverseDataFail(error));
  }
}



const asyncSelectUniverse = async (token, universe) => {
  try {
    const response = await axiosSelectUniverse(token, universe)
    console.log("log data here jdbd", response)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerSelectUniverse({ payload: { token, universe } }) {
  try {
    const response = yield call(asyncSelectUniverse, token, universe)
    console.log('response634567', response);
    yield put(
      selectUniverseSuccess(   
        response.findTeamData,
        response.findUniverseData,

      )
    )
  } catch (error) {}
}

function* studentProfileSaga() {
  yield takeEvery(GET_STUDENT_PROFILE_DATA, worketGetStudentProfileData)
  yield takeEvery(GET_STUDENT_DEMAND_DATA, workerGetStudentDemandData)
  yield takeEvery(UPDATE_DEMAND_TABLE_ONE, workerUpdateDemandTableOne)
  yield takeEvery(GET_EXPECTED_DEMAND_DATA, workerGetExpectedDemandData)
  yield takeEvery(GET_MARKET_SHARES_STUDENT, workerGetMarketShareStudent)
  yield takeEvery(UPDATE_MARKET_SHARES_STUDENT, workerUpdateMarketShareStudent)
  yield takeEvery(
    GET_EXPECTED_MARKET_DEMAND_STUDENT,
    workerGetExpectedMarketDemand
  )
  yield takeEvery(GET_ACTIVITY_DATA, fetchActivityData)
  yield takeEvery(GET_UNIVERSE_DATA, fetchUniverseData)
  yield takeEvery(SELECT_UNIVERSE_DATA, workerSelectUniverse)
}

export default studentProfileSaga
