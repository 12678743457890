import {
  ADD_ROUND_DATA,
  ADD_ROUND_DATA_SUCCESS,
  ADD_ROUND_DATA_FAIL,
  GET_ROUND,
  GET_ROUND_FAIL,
  GET_ROUND_SUCCESS,
} from "./actionTypes"

export const addRoundData = (value, token) => {
  return {
    type: ADD_ROUND_DATA,
    payload: { value, token },
  }
}

export const addRoundDataSuccess = message => {
  return {
    type: ADD_ROUND_DATA_SUCCESS,
    payload: { message },
  }
}

export const addRoundDataFail = message => {
  return {
    type: ADD_ROUND_DATA_FAIL,
    payload: { message },
  }
}

export const getRoundData = token => {
  return {
    type: GET_ROUND,
    payload: { token },
  }
}

export const getRoundDataSuccess = data => {
  return {
    type: GET_ROUND_SUCCESS,
    payload: { data },
  }
}
