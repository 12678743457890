export const ENHANCE_STUDENT_CAPACITY = "ENHANCE_STUDENT_CAPACITY"
export const ENHANCE_STUDENT_CAPACITY_SUCCESS =
  "ENHANCE_STUDENT_CAPACITY_SUCCESS"

export const ENHANCE_STUDENT_CAPACITY_FAIL = "ENHANCE_STUDENT_CAPACITY_FAIL"

export const IS_CAPACITY_IN_PROCESSING = "IS_CAPACITY_IN_PROCESSING"
export const IS_CAPACITY_IN_PROCESSING_SUCCESS =
  "IS_CAPACITY_IN_PROCESSING_SUCCESS"
export const CANCLE_ENHANCEMENT = "CANCLE_ENHANCEMENT"
export const CANCLE_ENHANCEMENT_SUCCESS = "CANCLE_ENHANCEMENT_SUCCESS"
export const CANCLE_ENHANCEMENT_FAIL = "CANCLE_ENHANCEMENT_FAIL"

//
export const GO_TO_RAND_D = "GO_TO_RAND_D"
export const GO_TO_RAND_D_SUCCESS = "GO_TO_RAND_D_SUCCESS"
