export const GET_TOTAL_DEMAND = "GET_TOTAL_DEMAND";
export const GET_TOTAL_DEMAND_SUCCESS = "GET_TOTAL_DEMAND_SUCCESS";
export const GET_TOTAL_DEMAND_FAIL = "GET_TOTAL_DEMAND_FAIL";

export const GET_MARKET_SHARE = "GET_MARKET_SHARE";
export const GET_MARKET_SHARE_SUCCESS = "GET_MARKET_SHARE_SUCCESS";
export const GET_MARKET_SHARE_FAIL = "GET_MARKET_SHARE_FAIL";

export const POST_TOTAL_DEMAND = "POST_TOTAL_DEMAND";
export const POST_TOTAL_DEMAND_SUCCESS = "POST_TOTAL_DEMAND_SUCCESS";
export const POST_TOTAL_DEMAND_FAIL = "POST_TOTAL_DEMAND_FAIL";

export const POST_MARKET_SHARE = "POST_MARKET_SHARE";
export const POST_MARKET_SHARE_SUCCESS = "POST_MARKET_SHARE_SUCCESS";
export const POST_MARKET_SHARE_FAIL = "POST_MARKET_SHARE_FAIL";


export const UPDATE_MARKET_SHARE = "UPDATE_MARKET_SHARE";
export const UPDATE_MARKET_SHARE_SUCCESS = "UPDATE_MARKET_SHARE_SUCCESS";
export const UPDATE_MARKET_SHARE_FAIL = "UPDATE_MARKET_SHARE_FAIL";

export const UPDATE_TOTAL_DEMAND = "UPDATE_TOTAL_DEMAND";
export const UPDATE_TOTAL_DEMAND_SUCCESS = "UPDATE_TOTAL_DEMAND_SUCCESS";
export const UPDATE_TOTAL_DEMAND_FAIL = "UPDATE_TOTAL_DEMAND_FAIL";
export const STORE_DATA = "STORE_DATA";