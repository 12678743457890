import PropTypes from "prop-types"
import { lazy, Suspense } from "react"
import "react-loading-skeleton/dist/skeleton.css"
import { Route, Switch } from "react-router-dom"

import EmailVerify from "./pages/Authentication/EmailVerify"
import ResetSuccess from "./pages/Authentication/ResetSuccess"
import AdminCourseView from "./pages/Course/AdminCourseView"
import AdminEditTech from "./pages/Dashboard/tech/AdminEditTech"
import MarketDemand from "./pages/Demand2/MarketDemand"
import MarketShare from "./pages/Demand2/MarketShare"
import NetworkCoverage from "./pages/FundamentalInfo/NetworkCoverage/NetworkCoverage"
import FundamentalRegion from "./pages/FundamentalInfo/Region/FundamentalRegion"
import FundamentalRound from "./pages/FundamentalInfo/Round/FundamentalRound"
import MarketOutlook from "./pages/MarketOutlook/MarketOutlook"
import Planing from "./pages/Production/Planing"
import Plant from "./pages/Production/Plant"
import AddUser from "./pages/Users/AddUser"
// Import scss
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import "react-toastify/dist/ReactToastify.css"
import "./assets/scss/theme.scss"
// import MaacLoader from "./Loader/MaacLoader"
import TeacherMaterial from "pages/Material/TeacherMaterial"
import StudentRequirement from "pages/Requirement/StudentRequirement"
import TeacherRequirement from "pages/Requirement/TeacherRequirement"
import AdminRoute from "./authBasedRoute/AdminRoute"
import StudentRoute from "./authBasedRoute/StudentRoute"
import TeacherRoute from "./authBasedRoute/TeacherRoute"
import "./pages/AllStudentPage/StudentHome/home.css"
import "./pages/AllTeacherPage/UniverseTeamCreate/universe.css"
import Login from "./pages/Authentication/Login2"
import "./pages/Dashboard/tech.css"
import "./pages/Home/home.css"
import "./pages/Students/StudentPages/Decisions/decision.scss"
import "./style.css"

import UpdateDemand from "pages/Demand2/UpdateDemand"
import UpdatePlantUpgrade from "pages/Production/UpdatePlantUpgrade"
import InhouseProduction from "pages/InhouseProduction/InhouseProduction"
import ContractProduction from "pages/ContractProduction/ContractProduction"
import TechPurchase from "pages/AdminR&D/TechPurchase"
import TechUpgrade from "pages/AdminR&D/TechUpgrade"
import FeaturePurchase from "pages/AdminR&D/FeaturePurchase"
import FeatureUpgrade from "pages/AdminR&D/FeatureUpgrade"
import CreateWeightStrategy from "pages/Strategy/CreateWeightStrategy"
import CreateProductStrategy from "pages/Strategy/CreateProductStrategy"
import CreatePromotiontStrategy from "pages/Strategy/CreatePromotionStrategy"
import CreatePriceStrategy from "pages/Strategy/CreatePriceStrategy"
import UpdateWeightStrategy from "pages/Strategy/UpdateWeightStrategy"
import UpdatePriceStrategy from "pages/Strategy/UpdatePriceStrategy"
import UpdatePromotionStrategy from "pages/Strategy/UpdatePromotionStrategy"
import UpdateProductStrategy from "pages/Strategy/UpdateProductStrategy"
import UpdateTechPurchase from "pages/AdminR&D/UpdateTechPurchase"
import UpdateTechUgrade from "pages/AdminR&D/UpdateTechUpgrade"
import UpdateTechUpgrade from "pages/AdminR&D/UpdateTechUpgrade"
import UpdateFeaturePurchase from "pages/AdminR&D/UpdateFeaturePurchase"
import UpdateFeatureUpgrade from "pages/AdminR&D/UpdateFeatureUpgrade"
import UpdateInHouseProduction from "pages/InhouseProduction/UpdateInHouseProduction"
import UpdateContractProduction from "pages/ContractProduction/UpdateContractProduction"
import UpdateMarketShare from "pages/Demand2/UpdateMarketShare"
toast.configure()

//= lazy(() => import( auth page
const ForgetPassword = lazy(() =>
  import("./pages/Authentication/ForgetPassword")
)
const Register = lazy(() => import("./pages/Authentication/Register2"))
const Material = lazy(() => import("./pages/Material/Material"))

//for admin
//all dashboard
//  teacher
const Dashboard = lazy(() => import("./pages/Dashboard/index"))
//all teacher
const TeaceherData = lazy(() => import("./pages/Users/Allusers"))
//register teacher
const AddTeacherData = lazy(() => import("./pages/Users/AddUser"))

//universityname
const University = lazy(() => import("./pages/University/createUniversity"))

//product Capacity
const AddProductCapacity = lazy(() =>
  import("./pages/AdminProduct/AddProductCapacity")
)
//product Inventory
const AddProductInventory = lazy(() =>
  import("./pages/AdminProduct/AddProductInventory")
)
//for students
const StudentsData = lazy(() => import("./pages/Students/AllStudents"))
const AddStudentData = lazy(() => import("./pages/Students/CreateStudent"))

//for course
const AddCourse = lazy(() => import("./pages/Course/AddCourse"))
const AllCourse = lazy(() => import("./pages/Course/AllCourse"))

//tech Pages
const AdminTechPages = lazy(() => import("./pages/Dashboard/tech/techPage"))

//round
const AdminCreateRound = lazy(() =>
  import("./pages/FundamentalInfo/Round/CreateRound")
)
//for teacherd
//teacherSingleCourse
// single course
const TeacherSingleCourse = lazy(() =>
  import("./pages/AllTeacherPage/UniverseTeamCreate/AllUniverse/EmptyUniverse")
)
// const TeacherSingleCourse = lazy(() => import( "./pages/AllTeacherPage/TeacherSingleCourse"
//teacher single unive/teachersinglecourse/
const TeacherSingleUniverse = lazy(() =>
  import("./pages/AllTeacherPage/UniverseTeamCreate/AllUniverse/ViewUniverse")
)
// const TeacherSingleUniverse = lazy(() => import( "./pages/AllTeacherPage/TeacherSingleUniverse"
const TeacherTeamUniverse = lazy(() =>
  import("./pages/AllTeacherPage/TeacherTeamUniverse")
)

//react taostify
// const { ToastContainer } = lazy(() => import( "react-toastify"

// const MaacLoader = lazy(() => import( "./Loader/MaacLoader"

//for all users
//set password
const SetPassword = lazy(() => import("./pages/Authentication/SetPassword"))

//by teacher
const TeacherDashboard = lazy(() => import("./pages/Home/HomeEmpty/HomeEmpty"))
// const TeacherDashboard = lazy(() => import( "./pages/Dashboard/TeacherDashboard"
const CourseForTeacher = lazy(() =>
  import("./pages/AllTeacherPage/TeacherCourse")
)
const XlFileUPload = lazy(() => import("./pages/Dashboard/XlfileUpload"))

//region by admin
const RegionPages = lazy(() => import("./pages/Dashboard/RegionPages"))
//tech
// const TechPages = lazy(() => import( "./pages/Dashboard/TechPages"
//market outlook
const DemandByAdmin = lazy(() => import("./pages/Demand/DemandByAdmin"))

const RoundPages = lazy(() => import("./pages/Dashboard/RoundPages"))

// const material = lazy(() => import( "./pages/Material/Material"
const result = lazy(() =>
  import("./pages/Students/StudentPages/result/ResultEvulation")
)

const ResultSummary = lazy(() => import("./pages/Result/ResultSummary"))
const schedule = lazy(() => import("./pages/Schedule/Schedule"))

//student
// student Dashboard
const MainFooter = lazy(() => import("components/Common/footer/MainFooter"))
const MainHeader = lazy(() => import("./components/Common/header/MainHeader"))
const FeatureUpgradeAndPurchase = lazy(() =>
  import("./pages/AllStudentPage/FeatureUpgradeAndPurchaseList")
)
const RandD = lazy(() => import("./pages/AllStudentPage/RandD"))
const StudentDashboard = lazy(() =>
  import("./pages/AllStudentPage/StudentDashboard")
)
const StudentDemand = lazy(() => import("./pages/AllStudentPage/StudentDemand"))
const StudentDistributionAndLogistic = lazy(() =>
  import("./pages/AllStudentPage/studentDistributionAndLogistic")
)
const StudentMarketing = lazy(() =>
  import("./pages/AllStudentPage/StudentMarketing")
)
const StudentPlanning = lazy(() =>
  import("./pages/AllStudentPage/StudentPlanning")
)
const Profile = lazy(() => import("./pages/Students/profile/Profile"))
const DecisionsNavbar = lazy(() =>
  import(
    "./pages/Students/StudentPages/Decisions/DecisionsNvabar/DecisionsNavbar"
  )
)
const Tutorial = lazy(() =>
  import("./pages/Students/StudentPages/Decisions/tutorial/Tutorial")
)

const MarketOutlookHome = lazy(() =>
  import(
    "pages/Students/StudentPages/Decisions/MarketOutlook/MarketOutlookHome"
  )
)

const DecisionChecklistHome = lazy(() =>
  import(
    "pages/Students/StudentPages/Decisions/DecisionChecklist/DecisionChecklistHome"
  )
)
const DemandHome = lazy(() =>
  import("pages/Students/StudentPages/Decisions/Demand/DemandHome")
)
const MarketingHomePage = lazy(() =>
  import("pages/Students/StudentPages/Decisions/Marketing/MarketingHomePage")
)
const ProductionHome = lazy(() =>
  import("pages/Students/StudentPages/Decisions/Productions/ProductionHome")
)
const RandDHomePage = lazy(() =>
  import("pages/Students/StudentPages/Decisions/R&D/RandDHomePage")
)

const IncomeStatement = lazy(() =>
  import(
    "./pages/Students/StudentPages/Decisions/IncomeStatement/IncomeStatemant"
  )
)

function App() {
  return (
    <Suspense
      fallback={<div className="col text-center p-5">Loading ... </div>}
    >
      {/* <ToastContainer /> */}

      <Switch>
        {/* <a href="/login" /z>  */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/email-verify" component={EmailVerify} />
        <Route exact path="/reset-password" component={SetPassword} />
        <Route exact path="/forgot-password" component={ForgetPassword} />
        <Route exact path="/reset-pass-success" component={ResetSuccess} />
        //for admin
        <AdminRoute exact path="/register" component={Register} />
        <AdminRoute
          exact
          path={["/", "/admindashboard"]}
          component={Dashboard}
        />
        <AdminRoute exact path="/teacherdata" component={TeaceherData} />
        <AdminRoute exact path="/addteacherdata" component={AddTeacherData} />
        <AdminRoute exact path="/universitydata" component={University} />
        <AdminRoute exact path="/studentdata" component={StudentsData} />
        <AdminRoute exact path="/addstudentdata" component={AddStudentData} />
        <AdminRoute exact path="/allcourse" component={AllCourse} />
        <AdminRoute exact path="/addcoursedata" component={AddCourse} />
        <AdminRoute exact path="/xlfile" component={XlFileUPload} />
        <AdminRoute exact path="/region" component={RegionPages} />
        <AdminRoute exact path="/tech-admin" component={AdminTechPages} />
        <AdminRoute
          exact
          path="/admin-create-round"
          component={AdminCreateRound}
        />
        <AdminRoute
          exact
          path="/admin-fundamental-round"
          component={FundamentalRound}
        />
        <AdminRoute
          exact
          path="/admin-fundamental-region"
          component={FundamentalRegion}
        />{" "}
        <AdminRoute
          exact
          path="/admin-fundamental-coverage"
          component={NetworkCoverage}
        />{" "}
        <AdminRoute
          exact
          path="/admin-market-outlook"
          component={MarketOutlook}
        />
        <AdminRoute exact path="/market-outlook" component={MarketOutlook} />
        <AdminRoute exact path="/demand-admin" component={DemandByAdmin} />
        <AdminRoute exact path="/round-data" component={RoundPages} />
        <TeacherRoute exact path="/result-evualation" component={result} />
        <TeacherRoute exact path="/result-summary" component={ResultSummary} />
        <AdminRoute
          exact
          path="/admin-market-demand"
          component={MarketDemand}
        />
           <AdminRoute
          exact
          path="/admin-market-demand-update"
          component={UpdateDemand}
        />
        <AdminRoute exact path="/admin-market-share" component={MarketShare} />
        <AdminRoute exact path="/admin-market-share-update" component={UpdateMarketShare} />
        <AdminRoute exact path="/admin-production-plant" component={Plant} />
        <AdminRoute exact path="/update-plant-upgrade" component={UpdatePlantUpgrade} />
        <AdminRoute exact path="/Inhouse-Production" component={InhouseProduction} />
        <AdminRoute exact path="/update-inhouse-production" component={UpdateInHouseProduction} />
        <AdminRoute exact path="/update-contract-production" component={UpdateContractProduction} />
        <AdminRoute exact path="/Contract-Production" component={ContractProduction} />
        <AdminRoute exact path="/admin-tech" component={TechPurchase} />
        <AdminRoute exact path="/admin-tech-upgrade" component={TechUpgrade} />
        <AdminRoute exact path="/feature-purchase" component={FeaturePurchase} />
        <AdminRoute exact path="/feature-upgrade" component={FeatureUpgrade} />
        <AdminRoute exact path="/strategy-weight" component={CreateWeightStrategy} />
        <AdminRoute exact path="/strategy-product" component={CreateProductStrategy} />
        <AdminRoute exact path="/strategy-promotion" component={CreatePromotiontStrategy} />
        <AdminRoute exact path="/strategy-price" component={CreatePriceStrategy} />
        <AdminRoute exact path="/update-weight-strategy" component={UpdateWeightStrategy} />
        <AdminRoute exact path="/update-price-strategy" component={UpdatePriceStrategy} />
        <AdminRoute exact path="/update-promotion-strategy" component={UpdatePromotionStrategy} />
        <AdminRoute exact path="/update-product-strategy" component={UpdateProductStrategy} />
        <AdminRoute exact path="/update-tech-purchase" component={UpdateTechPurchase} />
        <AdminRoute exact path="/update-tech-upgrade" component={UpdateTechUpgrade} />
        <AdminRoute exact path="/update-feature-purchase" component={UpdateFeaturePurchase} />
        <AdminRoute exact path="/update-feature-upgrade" component={UpdateFeatureUpgrade} />
        <AdminRoute
          exact
          path="/admin-production-capacity"
          component={Planing}
        />
        {/* <AdminRoute exact path="/admin-production" component={Planing} /> */}
        <AdminRoute
          exact
          path="/add-product-capacity"
          component={AddProductCapacity}
        />
        <AdminRoute
          exact
          path="/add-product-inventory"
          component={AddProductInventory}
        />
        <AdminRoute exact path="/admin-course-view-btn">
          <AdminCourseView />
        </AdminRoute>
        <AdminRoute exact path="/addteacherdata">
          <AddUser />
        </AdminRoute>
        <AdminRoute exact path="/admin-edit-technology">
          <AdminEditTech />
        </AdminRoute>
        <StudentRoute
          exact
          path={["/", "/studentdashboard"]}
          component={StudentDashboard}
        />
        <StudentRoute exact path="/student-demand" component={StudentDemand} />
        <StudentRoute
          exact
          path="/student-planning"
          component={StudentPlanning}
        />
        <StudentRoute
          exact
          path="/student-marketing"
          component={StudentMarketing}
        />
        <StudentRoute exact path="/schedule" component={schedule} />
        <StudentRoute exact path="/r-and-d" component={RandD} />
        <StudentRoute
          exact
          path="/feature-upgrade-purchase-list"
          component={FeatureUpgradeAndPurchase}
        />
        <StudentRoute
          path="/student/distribution-and-logistic"
          component={StudentDistributionAndLogistic}
        />
        <StudentRoute path="/requirement" component={StudentRequirement} />
        <StudentRoute path="/material" component={Material} />
        <TeacherRoute path="/teacher-material" component={TeacherMaterial} />
        <TeacherRoute
          path="/teacher-requirement"
          component={TeacherRequirement}
        />
        <TeacherRoute
          exact
          path={["/", "/teacherdashboard"]}
          component={TeacherDashboard}
        />
        <TeacherRoute
          exact
          path="/teachercourse"
          component={CourseForTeacher}
        />
        <TeacherRoute
          exact
          path="/teachersinglecourse/:courseId"
          component={TeacherSingleCourse}
        />
        <TeacherRoute
          exact
          path="/teachersingleuniverse/universe/:universeId"
          component={TeacherSingleUniverse}
        />
        <TeacherRoute
          exact
          path="/teachersingleuniverse/:courseId/universe/:universeId/team/:teamId"
          component={TeacherTeamUniverse}
        />
        <Route exact path="/profile">
          <MainHeader />
          <Profile />
          <MainFooter />
        </Route>
        <StudentRoute exact path="/decisions/tutorial">
          <DecisionsNavbar />
          <Tutorial />
        </StudentRoute>
        <StudentRoute exact path="/decisions/market-outlook">
          <DecisionsNavbar />
          <MarketOutlookHome />
        </StudentRoute>
        <StudentRoute exact path="/decisions/demand">
          <DecisionsNavbar />
          <DemandHome />
        </StudentRoute>
        <StudentRoute exact path="/decisions/production">
          <DecisionsNavbar />
          <ProductionHome />
        </StudentRoute>
        <StudentRoute exact path="/decisions/r-and-d">
          <DecisionsNavbar />
          <RandDHomePage />
        </StudentRoute>
        <StudentRoute exact path="/decisions/marketing">
          <DecisionsNavbar />
          <MarketingHomePage />
        </StudentRoute>
        <StudentRoute exact path="/decisions/decision-checklist">
          <DecisionsNavbar />
          <DecisionChecklistHome />
        </StudentRoute>
        <StudentRoute exact path="/decisions/income-statemant">
          <DecisionsNavbar />
          <IncomeStatement />
        </StudentRoute>
      </Switch>
    </Suspense>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
