import {
  GET_STUDENT_MARKET_SHARE_LAST_ROUND,
  INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE,
  GET_DEMAND_FULL_DATA,
  GO_TO_PRODUCTION,
} from "./actionTypes"
import {
  getMarketShareLastRoundSuccess,
  goToProductionPageSuccess,
  goToProductionPageFail,
} from "./actions"

import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
import {
  axiosGetMarketShareLastRound,
  axiosInsertOrUpdateLastPartOFMarketShare,
  axiosGetDemandFullData,
  axiosGoToProduction,
  axiosGetCapacityData,
} from "../../helpers/api_helper"
import {
  getStudentMarketShareLastRoundSuccess,
  insertOrUpdateLastPartOfMarketShareSuccess,
  insertOrUpdateLastPartOfMarketShareFail,
  getDemandFullDataSuccess,
} from "./actions"
import { getCapacityByStudentSuccess } from "../studentPlanning/actions"

const asyncGetDemandFullData = async (token, roundSerial, universeId) => {
  try {
    const response = await axiosGetDemandFullData(
      token,
      roundSerial,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetDemandFullData({
  payload: { token, roundSerial, universeId },
}) {
  console.log("hello jp from 33")
  try {
    console.log("hello response 33", token, roundSerial)
    const response = yield call(
      asyncGetDemandFullData,
      token,
      roundSerial,
      universeId
    )
    console.log("response data 34 37", response.findShareData)
    yield put(getDemandFullDataSuccess(response.findShareData))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncInsertOrUPdateLastPartMarketingShare = async (
  token,
  regionId,
  roundSerial,
  techId,
  estimateMarketShare,
  marketShareLastRound,
  universeId
) => {
  try {
    const response = await axiosInsertOrUpdateLastPartOFMarketShare(
      token,
      regionId,
      roundSerial,
      techId,
      estimateMarketShare,
      marketShareLastRound,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const asyncGetCapacityDataforStudent = async (token, teamId, roundSerial) => {
  try {
    const response = await axiosGetCapacityData(token, teamId, roundSerial)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerInsertOrUpdateLastPartMarketingShare({
  payload: {
    token,
    regionId,
    roundSerial,
    techId,
    estimateMarketShare,
    marketShareLastRound,
    teamId,
    universeId,
  },
}) {
  try {
    console.log("token and teamId0", token)
    const response = yield call(
      asyncInsertOrUPdateLastPartMarketingShare,
      token,
      regionId,
      roundSerial,
      techId,
      estimateMarketShare,
      marketShareLastRound,
      universeId
    )
    console.log("hello data")
    if (response.status == "success") {
      yield put(insertOrUpdateLastPartOfMarketShareSuccess(response.message))
      const responsedemand = yield call(
        asyncGetDemandFullData,
        token,
        roundSerial,
        universeId
      )

      const res = yield call(
        asyncGetCapacityDataforStudent,
        token,
        teamId,
        roundSerial
      )

      yield put(getDemandFullDataSuccess(responsedemand.findShareData))
      yield put(getCapacityByStudentSuccess(res.getData))
      yield put(insertOrUpdateLastPartOfMarketShareSuccess())
    } else {
      toast.error(response.message)
      yield put(insertOrUpdateLastPartOfMarketShareFail(response.message))
      yield put(insertOrUpdateLastPartOfMarketShareFail())
    }
  } catch (error) {}
}

const asyncGetMarketShareLastRound = async (
  token,

  roundSerial,
  teamId,
  universeId
) => {
  try {
    const response = await axiosGetMarketShareLastRound(
      token,
      roundSerial,
      teamId,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetMarketShareLastRound({
  payload: { token, roundSerial, teamId, universeId },
}) {
  try {
    console.log("hello resposne data 139", teamId)
    const response = yield call(
      asyncGetMarketShareLastRound,
      token,

      roundSerial,
      teamId,
      universeId
    )
    console.log("get data here marketing", response.getDatas)
    yield put(getStudentMarketShareLastRoundSuccess(response.getDatas))
  } catch (error) {
    console.log("log data from 53", error)
  }
}

//go to production
const asyncGoToProduction = async (token, roundSerial, teamId, universeId) => {
  try {
    let response = await axiosGoToProduction(
      token,
      roundSerial,
      teamId,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerGoToProduction({
  payload: { token, roundSerial, history, goNext, teamId, universeId },
}) {
  const response = yield call(
    asyncGoToProduction,
    token,
    roundSerial,
    teamId,
    universeId
  )
  console.log("response here", goNext)
  if (response.check == false) {
    toast.error("Please insert all the value")

    if (goNext) {
      history.push("/decisions/demand")
    }
  } else {
    history.push("/decisions/production")
  }
  yield put(goToProductionPageSuccess(response.check))
}

function* studentMarketShareSaga() {
  yield takeEvery(
    GET_STUDENT_MARKET_SHARE_LAST_ROUND,
    workerGetMarketShareLastRound
  )
  yield takeEvery(
    INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE,
    workerInsertOrUpdateLastPartMarketingShare
  )
  yield takeEvery(GET_DEMAND_FULL_DATA, workerGetDemandFullData)

  yield takeEvery(GO_TO_PRODUCTION, workerGoToProduction)
}

export default studentMarketShareSaga
