import { GET_PLANT_UPGRADE, GET_PLANT_UPGRADE_FAIL, GET_PLANT_UPGRADE_SUCCESS, POST_PLANT_UPGRADE, POST_PLANT_UPGRADE_FAIL, POST_PLANT_UPGRADE_SUCCESS, STORE_PLANT_DATA, UPDATE_PLANT_UPGRADE, UPDATE_PLANT_UPGRADE_FAIL, UPDATE_PLANT_UPGRADE_SUCCESS } from "./actionTypes";


  const initialState = {
    adding: false,
    message: "",
    error: "",
    plantUpgradeData: [],
    modifiedDemand: [],
    modifiedPlant: [],
    plantUpgradeLoading: false,
   
  };
  
  const PlantUpgradeReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_PLANT_UPGRADE:
        state = {
          ...state,
          adding: true,
        };
        break;
      case UPDATE_PLANT_UPGRADE_SUCCESS:
        state = {
          ...state,
          message: action.payload.message,
          adding: false,
        };
        break;
      case UPDATE_PLANT_UPGRADE_FAIL:
        state = {
          ...state,
          error: action.payload.message,
          adding: false,
        };
        break;
        case POST_PLANT_UPGRADE:
          state = {
            ...state,
            adding: true,
          };
          break;
        case POST_PLANT_UPGRADE_SUCCESS:
          state = {
            ...state,
            message: action.payload.message,
            adding: false,
          };
          break;
        case POST_PLANT_UPGRADE_FAIL:
          state = {
            ...state,
            error: action.payload.message,
            adding: false,
          };
          break;
       

        
        case GET_PLANT_UPGRADE:
          state = {
            ...state,
            plantUpgradeLoading: true,
          };
          break;
        case GET_PLANT_UPGRADE_SUCCESS:
          state = {
            ...state,
            plantUpgradeLoading: false,
            plantUpgradeData: action.payload.values,
          };
          break;
        case GET_PLANT_UPGRADE_FAIL:
          state = {
            ...state,
            plantUpgradeLoading: false,
            message: action.payload.message,
          };
          break;
          case STORE_PLANT_DATA:
            return {
              ...state,
              [action.payload.name]: action.payload.data,
            };
  
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default PlantUpgradeReducer;
  