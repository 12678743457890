import {  GET_FEATURE_PURCHASE, GET_FEATURE_PURCHASE_FAIL, GET_FEATURE_PURCHASE_SUCCESS, GET_FEATURE_UPGRADE, GET_FEATURE_UPGRADE_FAIL, GET_FEATURE_UPGRADE_SUCCESS, GET_TECH_PURCHASE, GET_TECH_PURCHASE_FAIL, GET_TECH_PURCHASE_SUCCESS, GET_TECH_UPGRADE, GET_TECH_UPGRADE_FAIL, GET_TECH_UPGRADE_SUCCESS, POST_FEATURE_PURCHASE, POST_FEATURE_PURCHASE_FAIL, POST_FEATURE_PURCHASE_SUCCESS, POST_FEATURE_UPGRADE, POST_FEATURE_UPGRADE_FAIL, POST_FEATURE_UPGRADE_SUCCESS, POST_TECH_PURCHASE, POST_TECH_PURCHASE_FAIL, POST_TECH_PURCHASE_SUCCESS, POST_TECH_UPGRADE, POST_TECH_UPGRADE_FAIL, POST_TECH_UPGRADE_SUCCESS, STORE_RnD_DATA, UPDATE_FEATURE_PURCHASE, UPDATE_FEATURE_PURCHASE_FAIL, UPDATE_FEATURE_PURCHASE_SUCCESS, UPDATE_FEATURE_UPGRADE, UPDATE_FEATURE_UPGRADE_FAIL, UPDATE_FEATURE_UPGRADE_SUCCESS, UPDATE_TECH_PURCHASE, UPDATE_TECH_PURCHASE_FAIL, UPDATE_TECH_PURCHASE_SUCCESS } from "./actionTypes";

  const initialState = {
    adding: false,
    message: "",
    error: "",
    techPurchase: [],
    techUpgrade: [],
    modifiedTechPurchase: [],
    modifiedTechUpgrade: [],
    modifiedFeaturePurchase: [],
    modifiedFeatureUpgrade: [],
    featureUpgrade: [],
    featurePurchase: [],
    techPurchaseLoading: false,
    techUpgradeLoading: false,
    featureUpgradeLoading: false,
    featurePurchaseLoading: false,
   
  };
  
  const RnDReducer = (state = initialState, action) => {
    switch (action.type) {
    
        case POST_TECH_PURCHASE:
          state = {
            ...state,
            adding: true,
          };
          break;
        case POST_TECH_PURCHASE_SUCCESS:
          state = {
            ...state,
            message: action.payload.message,
            adding: false,
          };
          break;
        case POST_TECH_PURCHASE_FAIL:
          state = {
            ...state,
            error: action.payload.message,
            adding: false,
          };
          break;
          case UPDATE_TECH_PURCHASE:
            state = {
              ...state,
              adding: true,
            };
            break;
          case UPDATE_TECH_PURCHASE_SUCCESS:
            state = {
              ...state,
              message: action.payload.message,
              adding: false,
            };
            break;
          case UPDATE_TECH_PURCHASE_FAIL:
            state = {
              ...state,
              error: action.payload.message,
              adding: false,
            };
            break;
          case POST_TECH_UPGRADE:
            state = {
              ...state,
              adding: true,
            };
            break;
          case POST_TECH_UPGRADE_SUCCESS:
            state = {
              ...state,
              message: action.payload.message,
              adding: false,
            };
            break;
          case POST_TECH_UPGRADE_FAIL:
            state = {
              ...state,
              error: action.payload.message,
              adding: false,
            };
            break;
            case POST_FEATURE_PURCHASE:
              state = {
                ...state,
                adding: true,
              };
              break;
            case POST_FEATURE_PURCHASE_SUCCESS:
              state = {
                ...state,
                message: action.payload.message,
                adding: false,
              };
              break;
            case POST_FEATURE_PURCHASE_FAIL:
              state = {
                ...state,
                error: action.payload.message,
                adding: false,
              };
              break;

              case UPDATE_FEATURE_PURCHASE:
                state = {
                  ...state,
                  adding: true,
                };
                break;
              case UPDATE_FEATURE_PURCHASE_SUCCESS:
                state = {
                  ...state,
                  message: action.payload.message,
                  adding: false,
                };
                break;
              case UPDATE_FEATURE_PURCHASE_FAIL:
                state = {
                  ...state,
                  error: action.payload.message,
                  adding: false,
                };
                break;
                case UPDATE_FEATURE_UPGRADE:
                  state = {
                    ...state,
                    adding: true,
                  };
                  break;
                case UPDATE_FEATURE_UPGRADE_SUCCESS:
                  state = {
                    ...state,
                    message: action.payload.message,
                    adding: false,
                  };
                  break;
                case UPDATE_FEATURE_UPGRADE_FAIL:
                  state = {
                    ...state,
                    error: action.payload.message,
                    adding: false,
                  };
                  break;
              case POST_FEATURE_UPGRADE:
                state = {
                  ...state,
                  adding: true,
                };
                break;
              case POST_FEATURE_UPGRADE_SUCCESS:
                state = {
                  ...state,
                  message: action.payload.message,
                  adding: false,
                };
                break;
              case POST_FEATURE_UPGRADE_FAIL:
                state = {
                  ...state,
                  error: action.payload.message,
                  adding: false,
                };
                break;

                case GET_TECH_UPGRADE:
                  state = {
                    ...state,
                    techUpgradeLoading: true,
                  };
                  break;
                case GET_TECH_UPGRADE_SUCCESS:
                  state = {
                    ...state,
                    techUpgradeLoading: false,
                    techUpgrade: action.payload.values,
                  };
                  break;
                case GET_TECH_UPGRADE_FAIL:
                  state = {
                    ...state,
                    techUpgradeLoading: false,
                    message: action.payload.message,
                  };
                  break;
                  case GET_TECH_PURCHASE:
                    state = {
                      ...state,
                      techPurchaseLoading: true,
                    };
                    break;
                  case GET_TECH_PURCHASE_SUCCESS:
                    state = {
                      ...state,
                      techPurchaseLoading: false,
                      techPurchase: action.payload.values,
                    };
                    break;
                  case GET_TECH_PURCHASE_FAIL:
                    state = {
                      ...state,
                      techPurchaseLoading: false,
                      message: action.payload.message,
                    };
                    break;
                    case GET_FEATURE_PURCHASE:
                      state = {
                        ...state,
                        featurePurchaseLoading: true,
                      };
                      break;
                    case GET_FEATURE_PURCHASE_SUCCESS:
                      state = {
                        ...state,
                        featurePurchaseLoading: false,
                        featurePurchase: action.payload.values,
                      };
                      break;
                    case GET_FEATURE_PURCHASE_FAIL:
                      state = {
                        ...state,
                        featurePurchaseLoading: false,
                        message: action.payload.message,
                      };
                      break;
                      case GET_FEATURE_UPGRADE:
                        state = {
                          ...state,
                          featureUpgradeLoading: true,
                        };
                        break;
                      case GET_FEATURE_UPGRADE_SUCCESS:
                        state = {
                          ...state,
                          featureUpgradeLoading: false,
                          featureUpgrade: action.payload.values,
                        };
                        break;
                      case GET_FEATURE_UPGRADE_FAIL:
                        state = {
                          ...state,
                          featureUpgradeLoading: false,
                          message: action.payload.message,
                        };
                        break;
                    case STORE_RnD_DATA:
                      return {
                        ...state,
                        [action.payload.name]: action.payload.data,
                      };
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default RnDReducer;
  