import PropTypes from "prop-types"
import React, { useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { Link, useHistory, withRouter } from "react-router-dom"
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"
// users
import user1 from "../../../components/assets/image/profile1111.jpg"
//logout from action
import { logoutUser } from "../../../store/auth/login/actions"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const dispatch = useDispatch()
  let history = useHistory()
  const { username, email } = useSelector(state => state.Login)
  let nameData = username ? username : email.split("@")[0]

  const handleLogout = () => {
    dispatch(logoutUser(history))
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect main-profile-right-area"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <b> {props.t(`${nameData}`)} </b>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link className="dropdown-item" to="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t(`${nameData}`)}{" "}
          </Link>

          <div className="dropdown-divider" />
          <a to="#" className="dropdown-item" onClick={() => handleLogout()}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span className="user-select-auto">{props.t("Logout")}</span>
          </a>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
