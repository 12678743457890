import {  GET_FEATURE_PURCHASE, GET_FEATURE_PURCHASE_FAIL, GET_FEATURE_PURCHASE_SUCCESS, GET_FEATURE_UPGRADE, GET_FEATURE_UPGRADE_FAIL, GET_FEATURE_UPGRADE_SUCCESS, GET_TECH_PURCHASE, GET_TECH_PURCHASE_FAIL, GET_TECH_PURCHASE_SUCCESS, GET_TECH_UPGRADE, GET_TECH_UPGRADE_FAIL, GET_TECH_UPGRADE_SUCCESS, POST_FEATURE_PURCHASE, POST_FEATURE_PURCHASE_FAIL, POST_FEATURE_PURCHASE_SUCCESS, POST_FEATURE_UPGRADE, POST_FEATURE_UPGRADE_FAIL, POST_FEATURE_UPGRADE_SUCCESS, POST_TECH_PURCHASE, POST_TECH_PURCHASE_FAIL, POST_TECH_PURCHASE_SUCCESS, POST_TECH_UPGRADE, POST_TECH_UPGRADE_FAIL, POST_TECH_UPGRADE_SUCCESS, STORE_RnD_DATA, UPDATE_FEATURE_PURCHASE, UPDATE_FEATURE_PURCHASE_FAIL, UPDATE_FEATURE_PURCHASE_SUCCESS, UPDATE_FEATURE_UPGRADE, UPDATE_FEATURE_UPGRADE_FAIL, UPDATE_FEATURE_UPGRADE_SUCCESS, UPDATE_TECH_PURCHASE, UPDATE_TECH_PURCHASE_FAIL, UPDATE_TECH_PURCHASE_SUCCESS, UPDATE_TECH_UPGRADE, UPDATE_TECH_UPGRADE_FAIL, UPDATE_TECH_UPGRADE_SUCCESS } from "./actionTypes";

export const postTechPurchase = (values, history, token) => {
    return {
      type: POST_TECH_PURCHASE,
      payload: { values, history, token},
    };
  };
  export const postTechPurchaseSuccess = (message) => {
    return {
      type: POST_TECH_PURCHASE_SUCCESS,
      payload: { message },
    };
  };
  
  export const postTechPurchaseFail = (message) => {
    return {
      type: POST_TECH_PURCHASE_FAIL,
      payload: { message },
    };
  };

  export const postTechUpgrade = (values, history, token) => {
    return {
      type: POST_TECH_UPGRADE,
      payload: { values, history, token},
    };
  };
  export const postTechUpgradeSuccess = (message) => {
    return {
      type: POST_TECH_UPGRADE_SUCCESS,
      payload: { message },
    };
  };
  
  export const postTechUpgradeFail = (message) => {
    return {
      type: POST_TECH_UPGRADE_FAIL,
      payload: { message },
    };
  };


  export const postFeatureUpgrade = (values, history, token) => {
    return {
      type: POST_FEATURE_UPGRADE,
      payload: { values, history, token},
    };
  };
  export const postFeatureUpgradeSuccess = (message) => {
    return {
      type: POST_FEATURE_UPGRADE_SUCCESS,
      payload: { message },
    };
  };
  
  export const postFeatureUpgradeFail = (message) => {
    return {
      type: POST_FEATURE_UPGRADE_FAIL,
      payload: { message },
    };
  };

  export const postFeaturePurchase = (values, history, token) => {
    return {
      type: POST_FEATURE_PURCHASE,
      payload: { values, history, token},
    };
  };
  export const postFeaturePurchaseSuccess = (message) => {
    return {
      type: POST_FEATURE_PURCHASE_SUCCESS,
      payload: { message },
    };
  };
  
  export const postFeaturePurchaseFail = (message) => {
    return {
      type: POST_FEATURE_PURCHASE_FAIL,
      payload: { message },
    };
  };
  export const getTechPurchase = (token, course, tech) => {
    return {
      type: GET_TECH_PURCHASE,
      payload: { token, course, tech },
    };
  };
  
  export const getTechPurchaseSuccess = (values) => {
    return {
      type: GET_TECH_PURCHASE_SUCCESS,
      payload: { values },
    };
  };
  
  export const getTechPurchaseFail = (message) => {
    return {
      type: GET_TECH_PURCHASE_FAIL,
      payload: { message },
    };
  };
  export const getTechUpgrade = (token, course, tech) => {
    return {
      type: GET_TECH_UPGRADE,
      payload: { token, course, tech },
    };
  };
  
  export const getTechUpgradeSuccess = (values) => {
    return {
      type: GET_TECH_UPGRADE_SUCCESS,
      payload: { values },
    };
  };
  
  export const getTechUpgradeFail = (message) => {
    return {
      type: GET_TECH_UPGRADE_FAIL,
      payload: { message },
    };
  };

  export const getFeatureUpgrade = (token, course, tech) => {
    return {
      type: GET_FEATURE_UPGRADE,
      payload: { token, course, tech },
    };
  };
  
  export const getFeatureUpgradeSuccess = (values) => {
    return {
      type: GET_FEATURE_UPGRADE_SUCCESS,
      payload: { values },
    };
  };
  
  export const getFeatureUpgradeFail = (message) => {
    return {
      type: GET_FEATURE_UPGRADE_FAIL,
      payload: { message },
    };
  };

  export const getFeaturePurchase = (token, course, featureID) => {
    return {
      type: GET_FEATURE_PURCHASE,
      payload: { token, course, featureID },
    };
  };
  
  export const getFeaturePurchaseSuccess = (values) => {
    return {
      type: GET_FEATURE_PURCHASE_SUCCESS,
      payload: { values },
    };
  };
  
  export const getFeaturePurchaseFail = (message) => {
    return {
      type: GET_FEATURE_PURCHASE_FAIL,
      payload: { message },
    };
  };

  export const updateTechPurchase = (values, history, token, groupId, singleId, courseInfo, techID) => {
    return {
      type: UPDATE_TECH_PURCHASE,
      payload: { values, history, token, groupId, singleId, courseInfo, techID},
    };
  };
  export const updateTechPurchaseSuccess = (message) => {
    return {
      type: UPDATE_TECH_PURCHASE_SUCCESS,
      payload: { message },
    };
  };
  
  export const updateTechPurchaseFail = (message) => {
    return {
      type: UPDATE_TECH_PURCHASE_FAIL,
      payload: { message },
    };
  };
  export const updateTechUpgrade = (values, history, token, groupId, singleId, courseInfo, techID) => {
    return {
      type: UPDATE_TECH_UPGRADE,
      payload: { values, history, token, groupId, singleId, courseInfo, techID},
    };
  };
  export const updateTechUpgradeSuccess = (message) => {
    return {
      type: UPDATE_TECH_UPGRADE_SUCCESS,
      payload: { message },
    };
  };
  
  export const updateTechUpgradeFail = (message) => {
    return {
      type: UPDATE_TECH_UPGRADE_FAIL,
      payload: { message },
    };
  };
  export const updateFeaturePurchase = (values, history, token, groupId, singleId, courseInfo, featureID) => {
    return {
      type: UPDATE_FEATURE_PURCHASE,
      payload: { values, history, token, groupId, singleId, courseInfo, featureID},
    };
  };
  export const updateFeaturePurchaseSuccess = (message) => {
    return {
      type: UPDATE_FEATURE_PURCHASE_SUCCESS,
      payload: { message },
    };
  };
  
  export const updateFeaturePurchaseFail = (message) => {
    return {
      type: UPDATE_FEATURE_PURCHASE_FAIL,
      payload: { message },
    };
  };
  export const updateFeatureUpgrade = (values, history, token, groupId, singleId, courseInfo, techID) => {
    return {
      type: UPDATE_FEATURE_UPGRADE,
      payload: { values, history, token, groupId, singleId, courseInfo, techID},
    };
  };
  export const updateFeatureUpgradeSuccess = (message) => {
    return {
      type: UPDATE_FEATURE_UPGRADE_SUCCESS,
      payload: { message },
    };
  };
  
  export const updateFeatureUpgradeFail = (message) => {
    return {
      type: UPDATE_FEATURE_UPGRADE_FAIL,
      payload: { message },
    };
  };

  export const storeRnDData = (name, data) => ({
    type: STORE_RnD_DATA,
    payload: { name, data },
  });