
import { axiosGetPriceStrategy, axiosGetProductStrategy, axiosGetPromotionStrategy, axiosGetWeightedStrategy, axiosPriceStrategy, axiosProductStrategy, axiosPromotionStrategy, axiosWeightStrategy } from "helpers/api_helper";
import { toaster } from "helpers/custom/Toast";
import { getData, patchData } from "helpers/Custom_Helper";
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import { getPriceStrategy, getPriceStrategyFail, getPriceStrategySuccess, getProductStrategy, getProductStrategyFail, getProductStrategySuccess, getPromotionStrategy, getPromotionStrategyFail, getPromotionStrategySuccess, getWeightStrategy, getWeightStrategyFail, getWeightStrategySuccess, postPriceStrategyFail, postPriceStrategySuccess, postProductStrategyFail, postProductStrategySuccess, postPromotionStrategyFail, postPromotionStrategySuccess, postWeightStrategyFail, postWeightStrategySuccess, storeStrategyData, updatePriceStrategyFail, updatePriceStrategySuccess, updateProductStrategyFail, updateProductStrategySuccess, updatePromotionStrategyFail, updatePromotionStrategySuccess, updateWeightStrategyFail, updateWeightStrategySuccess } from "./actions";
import { GET_PRICE_STRATEGY, GET_PRODUCT_STRATEGY, GET_PROMOTION_STRATEGY, GET_WEIGHT_STRATEGY, POST_PRICE_STRATEGY, POST_PRODUCT_STRATEGY, POST_PROMOTION_STRATEGY, POST_WEIGHT_STRATEGY, UPDATE_PRICE_STRATEGY, UPDATE_PRODUCT_STRATEGY, UPDATE_PROMOTION_STRATEGY, UPDATE_WEIGHT_STRATEGY } from "./actionTypes";





//for main



function* onPostWeightStrategy({ payload: { values, history, token } }) {
  try {
    const response = yield call(axiosWeightStrategy, values, token)
    console.log("hi")
    console.log(response)
    yield put(postWeightStrategySuccess(response))
    toast("🦄 Weight strategy Successfully created!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Weight strategy Successfully created!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(postWeightStrategyFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}
function* onPostProductStrategy({ payload: { values, history, token } }) {
  try {
    const response = yield call(axiosProductStrategy, values, token)
    console.log("hi")
    console.log(response)
    yield put(postProductStrategySuccess(response))
    toast("🦄 product strategy Successfully created!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄  product strategy Successfully created!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(postProductStrategyFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}
function* onPostPromotionStrategy({ payload: { values, history, token } }) {
  try {
    const response = yield call(axiosPromotionStrategy, values, token)
    console.log("hi")
    console.log(response)
    yield put(postPromotionStrategySuccess(response))
    toast("🦄 promotion strategy Successfully created!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 promotion strategy Successfully created!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(postPromotionStrategyFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}

function* onPostPriceStrategy({ payload: { values, history, token } }) {
  try {
    const response = yield call(axiosPriceStrategy, values, token)
    console.log("hi")
    console.log(response)
    yield put(postPriceStrategySuccess(response))
    toast("🦄 price strategy Successfully created!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 price strategy Successfully created!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/details-product")
    } else {
      let message = error.response.data.message
      yield put(postPriceStrategyFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}


function* onGetWeightStrategy({ payload: { token, course, region, techID }, }) {
  try {
    const url = `weight-strategy/data-get/${course}/${region}/${techID}`;
    const response = yield call(getData, url, token);
    yield put(getWeightStrategySuccess(response?.getData));
    yield put(storeStrategyData("modifiedWeightStrategy",response?.getData[0]?.weightStrategy));
  } catch (error) {
    const message = error.response?.data?.message || "get banner failed";
    yield put(getWeightStrategyFail(message));
    toaster("error", message);
  }
}

function* onGetPriceStrategy({ payload: { token, course, region, techID }, }) {
  try {
    const url = `weight-price/data-get/${course}/${region}/${techID}`;
    const response = yield call(getData, url, token);
    yield put(getPriceStrategySuccess(response?.getData));
    yield put(storeStrategyData("modifiedPriceStrategy",response?.getData[0]?.weightPrice));
  } catch (error) {
    const message = error.response?.data?.message || "get banner failed";
    yield put(getPriceStrategyFail(message));
    toaster("error", message);
  }
}

function* onGetPromotionStrategy({ payload: { token, course, region, techID }, }) {
  try {
    const url = `weight-promotion/data-get/${course}/${region}/${techID}`;
    const response = yield call(getData, url, token);
    yield put(getPromotionStrategySuccess(response?.getData));
    yield put(storeStrategyData("modifiedPromotionStrategy",response?.getData[0]?.weightPromotion));
  } catch (error) {
    const message = error.response?.data?.message || "Action Failed";
    yield put(getPromotionStrategyFail(message));
    toaster("error", message);
  }
}

function* onGetProductStrategy({ payload: { token, course, region, techID }, }) {
  try {
    const url = `weight-product/data-get/${course}/${region}/${techID}`;
    const response = yield call(getData, url, token);
    yield put(getProductStrategySuccess(response?.getData));
    yield put(storeStrategyData("modifiedProductStrategy",response?.getData[0]?.weightProduct));
  } catch (error) {
    const message = error.response?.data?.message || "get banner failed";
    yield put(getProductStrategyFail(message));
    toaster("error", message);
  }
}
function* updateWeightStrategy({payload: { values, history, token, groupId, singleId, courseInfo, regionInfo, techID}}) {
  try {
    const url = `update-weight-strategy/${groupId}/${singleId}`;
    const response = yield call(patchData, url, values, token);
    yield put(updateWeightStrategySuccess(response?.getData));
    yield put(getWeightStrategy(token, courseInfo, regionInfo, techID));
    toaster("success", `Weight strategy Updated`);
    // history.push(`/`);
  } catch (error) {
    const message = error?.response?.data?.message || `Action failed`;
    toaster("error", message);
    yield put(updateWeightStrategyFail(message));
  }
}

function* updatePriceStrategy({payload: { values, history, token, groupId, singleId, courseInfo, regionInfo, techID}}) {
  try {
    const url = `update-weight-price/${groupId}/${singleId}`;
    const response = yield call(patchData, url, values, token);
    yield put(updatePriceStrategySuccess(response?.getData));
    yield put(getPriceStrategy(token, courseInfo, regionInfo, techID));
    toaster("success", `Price strategy Updated`);
    // history.push(`/`);
  } catch (error) {
    const message = error?.response?.data?.message || `Action failed`;
    toaster("error", message);
    yield put(updatePriceStrategyFail(message));
  }
}

function* updatePromotionStrategy({payload: { values, history, token, groupId, singleId, courseInfo, regionInfo, techID}}) {
  console.log('regionInfo', regionInfo, techID);

  try {
    const url = `update-weight-promotion/${groupId}/${singleId}`;
    const response = yield call(patchData, url, values, token);
    yield put(updatePromotionStrategySuccess(response?.getData));
    yield put(getPromotionStrategy(token, courseInfo, regionInfo, techID));
    toaster("success", `Promotion strategy Updated`);
    // history.push(`/`);
  } catch (error) {
    const message = error?.response?.data?.message || `Action failed`;
    toaster("error", message);
    yield put(updatePromotionStrategyFail(message));
  }
}

function* updateProductStrategy({payload: { values, history, token, groupId, singleId, courseInfo, regionInfo, techID}}) {
  try {
    const url = `update-weight-product/${groupId}/${singleId}`;
    const response = yield call(patchData, url, values, token);
    yield put(updateProductStrategySuccess(response?.getData));
    yield put(getProductStrategy(token, courseInfo, regionInfo, techID));
    toaster("success", `Product strategy Updated`);
    // history.push(`/`);
  } catch (error) {
    const message = error?.response?.data?.message || `Action failed`;
    toaster("error", message);
    yield put(updateProductStrategyFail(message));
  }
}
function* StrategySaga() {

  yield takeEvery(POST_WEIGHT_STRATEGY, onPostWeightStrategy);
  yield takeEvery(POST_PRODUCT_STRATEGY, onPostProductStrategy);
  yield takeEvery(POST_PROMOTION_STRATEGY, onPostPromotionStrategy);
  yield takeEvery(POST_PRICE_STRATEGY, onPostPriceStrategy);
  yield takeEvery(GET_WEIGHT_STRATEGY, onGetWeightStrategy);
  yield takeEvery(GET_PRODUCT_STRATEGY, onGetProductStrategy);
  yield takeEvery(GET_PROMOTION_STRATEGY, onGetPromotionStrategy);
  yield takeEvery(GET_PRICE_STRATEGY, onGetPriceStrategy);
  yield takeEvery(UPDATE_WEIGHT_STRATEGY, updateWeightStrategy);
  yield takeEvery(UPDATE_PRICE_STRATEGY, updatePriceStrategy);
  yield takeEvery(UPDATE_PROMOTION_STRATEGY, updatePromotionStrategy);
  yield takeEvery(UPDATE_PRODUCT_STRATEGY, updateProductStrategy);
}

export default StrategySaga;
