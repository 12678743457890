import {
  GET_TEAM_SUBMITTED_RESULT,
  GET_TEAM_SUBMITTED_RESULT_SUCCESS,
  EVUALATE_SUBMITTED_RESULT,
  EVUALATE_SUBMITTED_RESULT_FAIL,
  EVUALATE_SUBMITTED_RESULT_SUCCESS,
  GET_RESULT_SUMMARY,
  GET_RESULT_SUMMARY_SUCCESS,
  GET_UNIVERSE_ID,
  GET_UNIVERSE_ID_SUCCESS,
  SUBMIT_MARK_BY_TEACHER_FAIL,
  SUBMIT_MARK_BY_TEACHER_SUCCESS,
  SUBMIT_MARK_BY_TEACHER,
  SHOW_TEAM_MEMBER_NAME_WISE_DATA,
  SHOW_TEAM_MEMBER_NAME_WISE_DATA_SUCCESS,
} from "./actionTypes"

const initialState = {
  universeData: {},
  teamSubmitList: {},
  universeAlreadyEvaluate: {},
  resultSummary: [],
  teamName: {},
  totalRevenue: {},
  operationProfitAfter: {},
  operationProfitBefore: {},
  profitForThisYear: {},
  universeId: [],
  idLoading: false,
  message: "",
  error: "",
  loading: false,
  evulateLoading: false,
  objMarketShare: {},
  memberWiseData: [],
}

const resultEvaluationData = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAM_SUBMITTED_RESULT:
      state = { ...state, loading: true }
      break
    case GET_TEAM_SUBMITTED_RESULT_SUCCESS:
      state = {
        ...state,
        loading: false,
        universeData: action.payload.data.obj,
        teamSubmitList: action.payload.data.teamSubmit,
        universeAlreadyEvaluate: action.payload.data.universeRoundSubmit,
      }
      break
    case EVUALATE_SUBMITTED_RESULT:
      state = { ...state, evulateLoading: true }
      break
    case EVUALATE_SUBMITTED_RESULT_SUCCESS:
      state = {
        ...state,
        evulateLoading: false,
        message: action.payload.message,
      }
      break
    case EVUALATE_SUBMITTED_RESULT_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        evulateLoading: false,
      }
      break
    case GET_RESULT_SUMMARY:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_RESULT_SUMMARY_SUCCESS:
      state = {
        ...state,
        loading: false,
        teamName: action.payload.data.objTeamName,
        totalRevenue: action.payload.data.objTotalRevenue,
        operationProfitBefore: action.payload.data.objOperationProfitBefore,
        operationProfitAfter: action.payload.data.objOperationProfitAfter,
        profitForThisYear: action.payload.data.objProfitForThisYear,
        objMarketShare: action.payload.data.objMarketShare,
        objinitialBalance: action.payload.data.objinitialBalance,
        objinHouse: action.payload.data.objinHouse,
        objOutsource: action.payload.data.objOutsource,
        objTotalVariableCost: action.payload.data.objTotalVariableCost,
        objTransportation: action.payload.data.objTransportation,
        objTariffs: action.payload.data.objTariffs,
        objRanD: action.payload.data.objRanD,
        objPromotion: action.payload.data.objPromotion,
        objAdministrative: action.payload.data.objAdministrative,
        objTotalCost: action.payload.data.objTotalCost,
        objNetDepreciation: action.payload.data.objNetDepreciation,
        objIncomeTax: action.payload.data.objIncomeTax,
        objClosingBalance: action.payload.data.objClosingBalance,
      }
      break
    case GET_UNIVERSE_ID:
      state = {
        ...state,
        idLoading: true,
      }
      break
    case GET_UNIVERSE_ID_SUCCESS:
      state = {
        ...state,
        idLoading: false,
        universeId: action.payload.data,
      }
      break
    case SUBMIT_MARK_BY_TEACHER:
      state = {
        ...state,
        loading: true,
      }
      break
    case SUBMIT_MARK_BY_TEACHER_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case SUBMIT_MARK_BY_TEACHER_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case SHOW_TEAM_MEMBER_NAME_WISE_DATA:
      state = {
        ...state,
        loading: true,
      }
      break
    case SHOW_TEAM_MEMBER_NAME_WISE_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        memberWiseData: action.payload.data,
      }
      break
    default:
      state = state
      break
  }
  return state
}

export default resultEvaluationData
