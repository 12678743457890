import {
  GET_STUDENT_MARKET_SHARE_LAST_ROUND,
  GET_STUDENT_MARKET_SHARE_LAST_ROUND_SUCCESS,
  INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE,
  INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE_FAIL,
  INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE_SUCCESS,
  GET_DEMAND_FULL_DATA_SUCCESS,
  GET_DEMAND_FULL_DATA,
  GO_TO_PRODUCTION,
  GO_TO_PRODUCTION_SUCCESS,
  GO_TO_PRODUCTION_FAIL,
} from "./actionTypes"

const initial_State = {
  studentMarketShare: {},
  demandFullData: {},
  loading: false,
  message: "",
  error: "",
  check: false,
}

const studentMarketShareData = (state = initial_State, action) => {
  switch (action.type) {
    case GET_STUDENT_MARKET_SHARE_LAST_ROUND:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_STUDENT_MARKET_SHARE_LAST_ROUND_SUCCESS:
      state = {
        ...state,
        loading: false,
        studentMarketShare: action.payload.data,
      }
      break
    case INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE:
      state = {
        ...state,
        loading: true,
      }
      break
    case INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case GET_DEMAND_FULL_DATA:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_DEMAND_FULL_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        demandFullData: action.payload.data,
      }
      break
    case GO_TO_PRODUCTION:
      state = {
        ...state,
        loading: true,
      }
      break
    case GO_TO_PRODUCTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        check: action.payload.data,
      }
      break
    case GO_TO_PRODUCTION_FAIL:
      state = {
        ...state,
        loading: false,
        check: action.payload.data,
      }
      break
    default:
      state = state
      break
  }
  return state
}

export default studentMarketShareData
