import {
  ADD_FEATURE_BY_ADMIN,
  ADD_FEATURE_BY_ADMIN_FAIL,
  ADD_FEATURE_BY_ADMIN_SUCCESS,
  GET_FEATURE_BY_ADMIN,
  GET_FEATURE_BY_ADMIN_FAIL,
  GET_FEATURE_BY_ADMIN_SUCCESS,
  MAKE_FEATURE_AVAILABLE_BY_ADMIN,
  MAKE_FEATURE_AVAILABLE_BY_ADMIN_FAIL,
  MAKE_FEATURE_AVAILABLE_BY_ADMIN_SUCCESS,
} from "./actionTypes"

export const addFeatureByAdmin = (token, featureName, featurePrice) => {
  return {
    type: ADD_FEATURE_BY_ADMIN,
    payload: { token, featureName, featurePrice },
  }
}

export const addFeatureByAdminSuccess = message => {
  return {
    type: ADD_FEATURE_BY_ADMIN_SUCCESS,
    payload: { message },
  }
}

export const addFeatureByAdminFail = message => {
  return {
    type: ADD_FEATURE_BY_ADMIN_FAIL,
    payload: { message },
  }
}

export const getFeatureByAdmin = token => {
  return {
    type: GET_FEATURE_BY_ADMIN,
    payload: { token },
  }
}

export const getFeatureByAdminSuccess = data => {
  return {
    type: GET_FEATURE_BY_ADMIN_SUCCESS,
    payload: { data },
  }
}

export const makeFeatureAvailableByAdmin = (token, featureId) => {
  return {
    type: MAKE_FEATURE_AVAILABLE_BY_ADMIN,
    payload: { token, featureId },
  }
}

export const makeFeatureAvailableByAdminSuccess = message => {
  return {
    type: MAKE_FEATURE_AVAILABLE_BY_ADMIN_SUCCESS,
    payload: { message },
  }
}

export const makeFeatureAvailableByAdminFail = message => {
  return {
    type: MAKE_FEATURE_AVAILABLE_BY_ADMIN_FAIL,
    payload: { message },
  }
}
