import {
  ADD_TOTAL_DEMAND_LAST_ROUND,
  ADD_TOTAL_DEMAND_LAST_ROUND_FAIL,
  ADD_TOTAL_DEMAND_LAST_ROUND_SUCCESS,
  GET_TOTAL_DEMAND_LAST_ROUND,
  GET_TOTAL_DEMAND_LAST_ROUND_SUCCESS,
  INSERT_INITIAL_CAPITAL_FAIL,
  INSERT_INITIAL_CAPITAL_SUCCESS,
  INSERT_INITIAL_CAPITAL,
  GET_DEMAND_GRAPH,
  GET_DEMAND_GRAPH_SUCCESS,
  ADMIN_GET_TOTAL_DEMAND_LAST_ROUND_SUCCESS,
  ADMIN_GET_TOTAL_DEMAND_LAST_ROUND,
} from "./actionTypes"

const initialState = {
  totalDemands: [],
  demandGraph: {},
  adminTotalDemand: [],
  loading: false,
  message: "",
  error: "",
}

const totalDemandLastRoundData = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOTAL_DEMAND_LAST_ROUND:
      state = { ...state, loading: true }
      break
    case ADD_TOTAL_DEMAND_LAST_ROUND_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case ADD_TOTAL_DEMAND_LAST_ROUND_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case GET_TOTAL_DEMAND_LAST_ROUND:
      state = { ...state, loading: true }
      break
    case GET_TOTAL_DEMAND_LAST_ROUND_SUCCESS:
      state = { ...state, loading: false, totalDemands: action.payload.data }
      break
    case INSERT_INITIAL_CAPITAL:
      state = { ...state, loading: true }
      break
    case INSERT_INITIAL_CAPITAL_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case INSERT_INITIAL_CAPITAL_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case GET_DEMAND_GRAPH:
      state = { ...state, loading: true }
      break
    case GET_DEMAND_GRAPH_SUCCESS:
      state = {
        ...state,
        loading: false,
        demandGraph: action.payload.data,
      }
      break
    case ADMIN_GET_TOTAL_DEMAND_LAST_ROUND:
      state = { ...state, loading: true }
      break
    case ADMIN_GET_TOTAL_DEMAND_LAST_ROUND_SUCCESS:
      state = {
        ...state,
        loading: false,
        adminTotalDemand: action.payload.data,
      }
      break
    default:
      state = state
      break
  }
  return state
}

export default totalDemandLastRoundData
