import { call, put, takeEvery } from "redux-saga/effects"

import {
  ADD_MARKET_SHARE_LAST_ROUND,
  GET_MARKET_SHARE_LAST_ROUND,
  ADMIN_GET_MARKET_SHARE,
} from "./actionTypes"
import {
  addMarketShareLastRoundSuccess,
  addMarketShareLastRoundFail,
  getMarketShareLastRoundSuccess,
  adminGetMarketShareSuccess,
} from "./actions"
import { toast } from "react-toastify"
import {
  axiosInsertMarketShareData,
  axiosGetMarketShareData,
  axiosAdminGetMarketShareData,
} from "../../helpers/api_helper"

const asyncAdminGetMarketShare = async token => {
  try {
    const response = await axiosAdminGetMarketShareData(token, 0)
    return response.data
  } catch (error) {}
}

function* workerAdminGetMarketShare({ payload: { token } }) {
  try {
    const response = yield call(asyncAdminGetMarketShare, token)
    yield put(adminGetMarketShareSuccess(response.getDatas))
  } catch (error) {
    console.log("no data")
  }
}

const asyncGetMarketShareData = async (token, roundSerial) => {
  console.log("token from get all demand data", token, roundSerial)
  try {
    const response = await axiosGetMarketShareData(token, roundSerial)
    console.log("response from 22 market share", response.data)

    return response.data
  } catch (error) {
    console.log("error response", error.response.data)
    return error.response.data
  }
}
function* workerGetMarketShareLastRound({ payload: { token, value } }) {
  let { roundSerial } = value
  try {
    const response = yield call(asyncGetMarketShareData, token, roundSerial)
    console.log("response from 30", response)
    yield put(getMarketShareLastRoundSuccess(response.getDatas))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncAddMarketShareLastRound = async (
  regionId,
  techId,
  lastRound,
  token
) => {
  try {
    const response = await axiosInsertMarketShareData(
      token,
      regionId,
      techId,
      lastRound
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerAddMarketShareLastRound({
  payload: { regionId, techId, lastRound, token },
}) {
  try {
    const response = yield call(
      asyncAddMarketShareLastRound,
      regionId,
      techId,
      lastRound,
      token
    )
    if (response.status == "success") {
      yield put(addMarketShareLastRoundSuccess(response.message))
      toast.success(response.message)
    } else {
      toast.error(response.message)
      yield put(addMarketShareLastRoundFail(response.message))
    }
  } catch (error) {}
}
function* adminMarketShareSaga() {
  yield takeEvery(ADD_MARKET_SHARE_LAST_ROUND, workerAddMarketShareLastRound)
  yield takeEvery(GET_MARKET_SHARE_LAST_ROUND, workerGetMarketShareLastRound)
  yield takeEvery(ADMIN_GET_MARKET_SHARE, workerAdminGetMarketShare)
}
export default adminMarketShareSaga
