import {
  DEMAND_ROUND_VIDEO,
  DEMAND_ROUND_VIDEO_SHOW,
  INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT,
  INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT_FAIL,
  INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT_SUCCESS,
  UPDATE_VIDEO_DATA,
  UPDATE_VIDEO_DATA_SUCCESS,
  GET_DEMAND_ADMIN_LAST_ROUND_DATA,
  GET_DEMAND_ADMIN_LAST_ROUND_DATA_SUCCESS,
} from "./actionTypes"

const initial_State = {
  loading: false,
  message: "",
  error: "",
  marketShares: [],
  demandVideoData: {},
  demandLastRoundData: {},
}

const studentDemandData = (state = initial_State, action) => {
  switch (action.type) {
    case INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case DEMAND_ROUND_VIDEO:
      state = {
        ...state,
        loading: true,
      }
      break
    case DEMAND_ROUND_VIDEO_SHOW:
      state = {
        ...state,
        loading: false,
        demandVideoData: action.payload.data,
      }
      break

    case UPDATE_VIDEO_DATA:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_VIDEO_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.data,
      }
      break
    case GET_DEMAND_ADMIN_LAST_ROUND_DATA:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_DEMAND_ADMIN_LAST_ROUND_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        demandLastRoundData: action.payload.data,
      }
      break
    default:
      state = state
      break
  }
  return state
}
export default studentDemandData
