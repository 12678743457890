import {
  DEMAND_ROUND_VIDEO,
  DEMAND_ROUND_VIDEO_SHOW,
  INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT,
  INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT_FAIL,
  INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT_SUCCESS,
  UPDATE_VIDEO_DATA_SUCCESS,
  UPDATE_VIDEO_DATA,
  GET_DEMAND_ADMIN_LAST_ROUND_DATA,
  GET_DEMAND_ADMIN_LAST_ROUND_DATA_SUCCESS,
} from "./actionTypes"

//insert expected demand for this round
export const insertExpectedDemandForThisRound = (
  token,
  regionId,
  predictedMarketGrowth,
  totalDemandLastRound,
  roundSerial,
  universeId
) => ({
  type: INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT,
  payload: {
    token,
    regionId,
    predictedMarketGrowth,
    totalDemandLastRound,
    roundSerial,
    universeId,
  },
})

export const insertExpectedDemandForThisRoundSuccess = message => ({
  type: INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT_SUCCESS,
  payload: { message },
})

export const insertExpectedDemandForThisRoundFail = message => ({
  type: INSERT_EXPECTED_DEMAND_FOR_THIS_ROUND_STUDENT_FAIL,
  payload: { message },
})

export const demandRoundVideo = (token, roundSerial) => ({
  type: DEMAND_ROUND_VIDEO,
  payload: { token, roundSerial },
})
export const deamndRoundVideoShow = data => ({
  type: DEMAND_ROUND_VIDEO_SHOW,
  payload: { data },
})

export const updateVideoData = (token, roundSerial, bodyData) => ({
  type: UPDATE_VIDEO_DATA,
  payload: { token, roundSerial, bodyData },
})

export const updateVideoDataSuccess = data => ({
  type: UPDATE_VIDEO_DATA_SUCCESS,
  payload: { data },
})

//demand admin for last round
export const getDemandAdminLastRoundData = (token, universeId) => ({
  type: GET_DEMAND_ADMIN_LAST_ROUND_DATA,
  payload: { token, universeId },
})

export const getDemandAdminLastRoundDataSuccess = data => ({
  type: GET_DEMAND_ADMIN_LAST_ROUND_DATA_SUCCESS,
  payload: { data },
})
