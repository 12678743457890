import React from "react"

import { Route, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
// import "./assets/scss/theme.scss"
import HorizontalLayout from "../components/HorizontalLayout/"
import NonAuthLayout from "../components/NonAuthLayout"
// layouts Format
import VerticalLayout from "../components/VerticalLayout"
import LoadingToRedirect from "./LoadingToRedirect"

const AdminRoute = ({ children, ...rest }) => {
  const { token, email, userrole } = useSelector(state => ({ ...state.Login }))
  const { layoutType } = useSelector(state => ({ ...state.Layout }))

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (layoutType) {
      case "horizontal":
        layoutCls = VerticalLayout

        break
      default:
        layoutCls = HorizontalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  return token && userrole === "admin" ? (
    <Layout>
      <Route {...rest} render={() => children} />
    </Layout>
  ) : (
    <LoadingToRedirect />
  )
}

export default AdminRoute
