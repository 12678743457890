import {
  ACTIVE_ROUND_BY_TEACHER_SUCCESS,
  ACTIVE_ROUND_BY_TEACHER_FAIL,
  ACTIVE_ROUND_BY_TEACHER,
  DEACTIVATE_ROUND_BY_TEACHER,
  DEACTIVATE_ROUND_BY_TEACHER_SUCCESS,
  DEACTIVATE_ROUND_BY_TEACHER_SUCCESS_FAIL,
} from "./actionTypes"

const initialState = {
  loading: false,
  message: "",
  error: "",
}

const activeRoundByTeacherData = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_ROUND_BY_TEACHER:
      state = { ...state, loading: true }
      break
    case ACTIVE_ROUND_BY_TEACHER_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case ACTIVE_ROUND_BY_TEACHER_FAIL:
      state = { ...state, loading: false, error: action.payload.message }
      break
    case DEACTIVATE_ROUND_BY_TEACHER:
      state = { ...state, loading: true }
      break

    case DEACTIVATE_ROUND_BY_TEACHER_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message }
      break
    case DEACTIVATE_ROUND_BY_TEACHER_SUCCESS_FAIL:
      state = { ...state, loading: false, error: action.payload.error }
      break
    default:
      state = state
      break
  }
  return state
}
export default activeRoundByTeacherData
