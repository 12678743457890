import React, { useEffect } from "react"
import { Col, Container, Row, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { getMarketOutlooks } from "./../../store/actions"
const OutlookData = () => {
  let dispatch = useDispatch()
  const { token, loading, marketOutlooks, message } = useSelector(state => ({
    token: state.Login.token,
    loading: state.marketOutlookData.loading,
    marketOutlooks: state.marketOutlookData.marketOutlooks,
    message: state.marketOutlookData.message,
  }))
  useEffect(() => {
    dispatch(getMarketOutlooks(token))
  }, [message])
  return (
    <div className="fundamental-round-main-wrap">
      <Container>
        <div className="fundamental-items-main-area">
          <Row>
            <Col lg={12}>
              <div className="single-course-data fundamental outlook-page">
                <Table borderless hover striped>
                  <thead>
                    <tr>
                      <th>Round</th>
                      <th>Demand</th>
                      <th>Cost</th>
                      <th>Finance</th>
                      <th>
                        <b>View</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {marketOutlooks?.map((v, i) => (
                      <tr>
                        <td>
                          <b>{v.roundIndex}</b>
                        </td>
                        <td>{v.demand}</td>
                        <td>{v.cost}</td>
                        <td>{v.finance}</td>
                        <td>
                          <Link to="/">View</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default OutlookData
