import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu"
import { Container, Nav, Navbar } from "react-bootstrap"
import { useSelector } from "react-redux"
import { Link, NavLink, useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import logo from "../../../components/assets/image/main-logo2.png"
const HeaderMainNav = () => {
  let history = useHistory()
  const { token, userrole, loading, totalCapacity, totalRequirement } =
    useSelector(state => ({
      token: state.Login.token,
      userrole: state.Login.userrole,

      loading: state.studentProfileData.loading,
      totalCapacity: state.StudentCapacityData.totalCapacity,
      totalRequirement: state.StudentCapacityData.totalRequirement,
    }))

  const roundSerial = localStorage.getItem("roundSerial")
  console.log("hello round serial", localStorage.getItem("roundSerial"))

  const handleClick = e => {
    if (localStorage.getItem("roundSerial") === "no data") {
      e.preventDefault()
      toast.error("Currenty no round is active!")
    }
  }
  const handleDecisions = e => {
    if (localStorage.getItem("roundSerial") === "no data") {
      e.preventDefault()
      toast.error("Currenty no round is active!")
    }
    history.push("/decisions/tutorial")
    location.reload()
  }
  return (
    <div className="main-navbar-area-wrap">
      <Navbar expand="md">
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </Navbar.Brand>

          <Nav className="ms-auto logo-right-nav navbar-profile-link main-header-menu-bar">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto main-navbar-left main-navbar">
                {userrole == "teacher" && (
                  <Link className="nav-link" to="/teacherdashboard">
                    Home
                  </Link>
                )}
                {userrole == "teacher" && (
                  <Link className="nav-link" to="/result-evualation">
                    Result Evaluation
                  </Link>
                )}
                {userrole == "teacher" && (
                  <Link className="nav-link" to="/result-summary">
                    Result Summary
                  </Link>
                )}
                {userrole == "admin" && (
                  <Link className="nav-link" to="/allcourse">
                    Course
                  </Link>
                )}
                {userrole == "admin" && (
                  <Link className="nav-link" to="/teacherdata">
                    Teacher Data
                  </Link>
                )}
                {/* {userrole == "admin" && (
                  <Link className="nav-link" to="/tech-admin">
                    Tech
                  </Link>
                )} */}
                {userrole == "admin" && (
                  <a className="nav-link dropdoun-menu-item">
                    {" "}
                    Plant
                    <ul>
                      <li>
                        {" "}
                        <Link to="/admin-production-plant">Plant</Link>
                      </li>
                    </ul>
                  </a>
                )}
                {userrole == "admin" && (
                  <a className="nav-link dropdoun-menu-item">
                    {" "}
                    Production
                    <ul>
                      <li>
                        {" "}
                        <Link to="/Inhouse-Production">Inhouse Production</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/Contract-Production">
                          Contract Production
                        </Link>
                      </li>
                    </ul>
                  </a>
                )}
                {userrole == "admin" && (
                  <a className="nav-link dropdoun-menu-item">
                    {" "}
                    Tech
                    <ul>
                      <li>
                        {" "}
                        <Link to="/admin-tech">Tech Purchase</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/admin-tech-upgrade">Tech Upgrade</Link>
                      </li>
                    </ul>
                  </a>
                )}
                {userrole == "admin" && (
                  <a className="nav-link dropdoun-menu-item">
                    {" "}
                    Feature
                    <ul>
                      <li>
                        {" "}
                        <Link to="/feature-purchase">Feature Purchase</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/feature-upgrade">Feature Upgrade</Link>
                      </li>
                    </ul>
                  </a>
                )}
                {userrole == "admin" && (
                  <a className="nav-link dropdoun-menu-item">
                    {" "}
                    Strategy
                    <ul>
                      <li>
                        {" "}
                        <Link to="/strategy-weight">Weight</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/strategy-product">Product</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/strategy-promotion">Promotion</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/strategy-price">Price</Link>
                      </li>
                    </ul>
                  </a>
                )}
                {userrole == "admin" && (
                  <a className="nav-link dropdoun-menu-item">
                    Demand
                    <ul>
                      <li>
                        {" "}
                        <Link to="/admin-market-demand">Market Demand</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/admin-market-share">Market Share</Link>
                      </li>
                    </ul>
                  </a>
                )}{" "}
                {/* {userrole == "admin" && (
                  <a className="nav-link dropdoun-menu-item">
                    Fundamental info
                    <ul>
                      <li>
                        {" "}
                        <Link to="/admin-fundamental-round">Round</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/admin-fundamental-region">Region</Link>
                      </li>{" "}
                      <li>
                        {" "}
                        <Link to="/admin-fundamental-coverage">
                          Network Coverage
                        </Link>
                      </li>
                    </ul>
                  </a>
                )}{" "} */}
                {/* {userrole == "admin" && (
                  <a className="nav-link dropdoun-menu-item">
                    Market Outlook
                    <ul>
                      <li>
                        {" "}
                        <Link to="/admin-market-outlook">Market Demand</Link>
                      </li>
                      <li>
                      {" "}
                      <Link to="/admin-market-share">Market Share</Link>
                    </li>
                    </ul>
                  </a>
                )} */}
                {userrole == "student" && (
                  <Link className="nav-link" to="/studentdashboard">
                    Home
                  </Link>
                )}
                {userrole == "student" && (
                  <NavLink
                    exact
                    // isActive={localStorage.getItem("roundSerial") !== "no data"}
                    onClick={e => handleDecisions(e)}
                    activeClassName="navbar__link--active "
                    className="navbar__link nav-link main-header link-disable"
                    to="/decisions/tutorial"
                  >
                    Decisions
                  </NavLink>
                )}
                {userrole == "student" && (
                  <NavLink
                    exact
                    // isActive={localStorage.getItem("roundSerial") !== "no data"}
                    onClick={e => handleClick(e)}
                    activeClassName="navbar__link--active"
                    className="navbar__link nav-link main-header"
                    to="/material"
                  >
                    Material
                  </NavLink>
                )}
                {userrole == "teacher" && (
                  <NavLink
                    exact
                    // isActive={localStorage.getItem("roundSerial") !== "no data"}
                    onClick={e => handleClick(e)}
                    activeClassName="navbar__link--active"
                    className="navbar__link nav-link main-header"
                    to="/teacher-material"
                  >
                    Material
                  </NavLink>
                )}
                {userrole == "student" && (
                  <NavLink
                    exact
                    // isActive={localStorage.getItem("roundSerial") !== "no data"}
                    onClick={e => handleClick(e)}
                    activeClassName="navbar__link--active"
                    className="navbar__link nav-link main-header"
                    to="/requirement"
                  >
                    Requirement
                  </NavLink>
                )}{" "}
                {userrole == "teacher" && (
                  <NavLink
                    exact
                    // isActive={localStorage.getItem("roundSerial") !== "no data"}
                    onClick={e => handleClick(e)}
                    activeClassName="navbar__link--active"
                    className="navbar__link nav-link main-header"
                    to="/teacher-requirement"
                  >
                    Requirement
                  </NavLink>
                )}
                {/* {userrole == "teacher" && (
                <>
                  {" "}
                  <Link className="nav-link" to="/teachercourse">
                    Game Activation
                  </Link>
                </>
              )} */}
                {/* {userrole == "student" && (
                <NavLink
                  exact
                  activeClassName="navbar__link--active"
                  className="navbar__link nav-link main-header"
                  to="/schedule"
                >
                  Schedule
                </NavLink>
              )} */}
              </Nav>
            </Navbar.Collapse>
            <ProfileMenu />
          </Nav>
        </Container>
      </Navbar>
    </div>
  )
}

export default HeaderMainNav
