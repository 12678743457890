import { GET_CONTRACT_PRODUCTION, GET_CONTRACT_PRODUCTION_FAIL, GET_CONTRACT_PRODUCTION_SUCCESS, GET_IN_HOUSE_PRODUCTION, GET_IN_HOUSE_PRODUCTION_FAIL, GET_IN_HOUSE_PRODUCTION_SUCCESS, POST_CONTRACT_PRODUCTION, POST_CONTRACT_PRODUCTION_FAIL, POST_CONTRACT_PRODUCTION_SUCCESS, POST_IN_HOUSE_PRODUCTION, POST_IN_HOUSE_PRODUCTION_FAIL, POST_IN_HOUSE_PRODUCTION_SUCCESS, STORE_PRODUCTION_CAPACITY, UPDATE_CONTRACT_PRODUCTION, UPDATE_CONTRACT_PRODUCTION_FAIL, UPDATE_CONTRACT_PRODUCTION_SUCCESS, UPDATE_IN_HOUSE_PRODUCTION, UPDATE_IN_HOUSE_PRODUCTION_FAIL, UPDATE_IN_HOUSE_PRODUCTION_SUCCESS } from "./actionTypes";

  const initialState = {
    adding: false,
    message: "",
    error: "",
    contractProduction: [],
    modifiedInHouseProduction: [],
    modifiedContractProduction: [],
    inHouseProduction: [],
    contractProductionLoading:false,
    inHouseProductionLoading:false,
   
  };
  
  const productionCapacityReducer = (state = initialState, action) => {
    switch (action.type) {
    
        case POST_IN_HOUSE_PRODUCTION:
          state = {
            ...state,
            adding: true,
          };
          break;
        case POST_IN_HOUSE_PRODUCTION_SUCCESS:
          state = {
            ...state,
            message: action.payload.message,
            adding: false,
          };
          break;
        case POST_IN_HOUSE_PRODUCTION_FAIL:
          state = {
            ...state,
            error: action.payload.message,
            adding: false,
          };
          break;

          case UPDATE_IN_HOUSE_PRODUCTION:
            state = {
              ...state,
              adding: true,
            };
            break;
          case UPDATE_IN_HOUSE_PRODUCTION_SUCCESS:
            state = {
              ...state,
              message: action.payload.message,
              adding: false,
            };
            break;
          case UPDATE_IN_HOUSE_PRODUCTION_FAIL:
            state = {
              ...state,
              error: action.payload.message,
              adding: false,
            };
            break;

            case UPDATE_CONTRACT_PRODUCTION:
              state = {
                ...state,
                adding: true,
              };
              break;
            case UPDATE_CONTRACT_PRODUCTION_SUCCESS:
              state = {
                ...state,
                message: action.payload.message,
                adding: false,
              };
              break;
            case UPDATE_CONTRACT_PRODUCTION_FAIL:
              state = {
                ...state,
                error: action.payload.message,
                adding: false,
              };
              break;
          case POST_CONTRACT_PRODUCTION:
            state = {
              ...state,
              adding: true,
            };
            break;
          case POST_CONTRACT_PRODUCTION_SUCCESS:
            state = {
              ...state,
              message: action.payload.message,
              adding: false,
            };
            break;
          case POST_CONTRACT_PRODUCTION_FAIL:
            state = {
              ...state,
              error: action.payload.message,
              adding: false,
            };
            break;
            case GET_IN_HOUSE_PRODUCTION:
              state = {
                ...state,
                inHouseProductionLoading: true,
              };
              break;
            case GET_IN_HOUSE_PRODUCTION_SUCCESS:
              state = {
                ...state,
                inHouseProductionLoading: false,
                inHouseProduction: action.payload.values,
              };
              break;
            case GET_IN_HOUSE_PRODUCTION_FAIL:
              state = {
                ...state,
                inHouseProductionLoading: false,
                message: action.payload.message,
              };
              break;
              case GET_CONTRACT_PRODUCTION:
                state = {
                  ...state,
                  contractProductionLoading: true,
                };
                break;
              case GET_CONTRACT_PRODUCTION_SUCCESS:
                state = {
                  ...state,
                  contractProductionLoading: false,
                  contractProduction: action.payload.values,
                };
                break;
              case GET_CONTRACT_PRODUCTION_FAIL:
                state = {
                  ...state,
                  contractProductionLoading: false,
                  message: action.payload.message,
                };
                break;
                case STORE_PRODUCTION_CAPACITY:
                  return {
                    ...state,
                    [action.payload.name]: action.payload.data,
                  };
      
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default productionCapacityReducer;
  