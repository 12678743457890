export const GET_STUDENT_PROFILE_DATA = "GET_STUDENT_PROFILE_DATA"
export const GET_STUDENT_PROFILE_DATA_SUCCESS =
  "GET_STUDENT_PROFILE_DATA_SUCCESS"
export const GET_STUDENT_TEAM_DATA_SUCCESS = "GET_STUDENT_TEAM_DATA_SUCCESS"
//studentdemand
export const GET_STUDENT_DEMAND_DATA = "GET_STUDENT_DEMAND_DATA"
export const GET_STUDENT_DEMAND_DATA_SUCCESS = "GET_STUDENT_DEMAND_DATA_SUCCESS"
export const GET_STUDENT_DEMAND_DATA_FAIL = "GET_STUDENT_DEMAND_DATA_FAIL"

//update demand data
export const UPDATE_DEMAND_TABLE_ONE = "UPDATE_DEMAND_TABLE_ONE"
export const UPDATE_DEMAND_TABLE_ONE_SUCCESS = "UPDATE_DEMAND_TABLE_ONE_SUCCESS"
export const UPDATE_DEMAND_TABLE_ONE_FAIL = "UPDATE_DEMAND_TABLE_ONE_FAIL"

//expected demand
export const GET_EXPECTED_DEMAND_DATA = "GET_EXPECTED_DEMAND_DATA"
export const GET_EXPECTED_DEMAND_DATA_SUCCESS =
  "GET_EXPECTED_DEMAND_DATA_SUCCESS"
export const GET_EXPECTED_DEMAND_DATA_FAIL = "GET_EXPECTED_DEMAND_DATA_FAIL"

//market shares
export const GET_MARKET_SHARES_STUDENT = "GET_MARKET_SHARES_STUDENT"
export const GET_MARKET_SHARES_STUDENT_SUCCESS =
  "GET_MARKET_SHARES_STUDENT_SUCCESS"
export const GET_MARKET_SHARES_STUDENT_FAIL = "GET_MARKET_SHARES_STUDENT_FAIL"

//expected market demand
export const GET_EXPECTED_MARKET_DEMAND_STUDENT =
  "GET_EXPECTED_MARKET_DEMAND_STUDENT"
export const GET_EXPECTED_MARKET_DEMAND_STUDENT_SUCCESS =
  "GET_EXPECTED_MARKET_DEMAND_STUDENT_SUCCESS"
export const GET_EXPECTED_MARKET_DEMAND_STUDENT_FAIL =
  "GET_EXPECTED_MARKET_DEMAND_STUDENT_FAIL"
//market shares UPDATE
export const UPDATE_MARKET_SHARES_STUDENT = "UPDATE_MARKET_SHARES_STUDENT"
export const UPDATE_MARKET_SHARES_STUDENT_SUCCESS =
  "UPDATE_MARKET_SHARES_STUDENT_SUCCESS"
export const UPDATE_MARKET_SHARES_STUDENT_FAIL =
  "UPDATE_MARKET_SHARES_STUDENT_FAIL"

//Get Activity data
export const GET_ACTIVITY_DATA = "GET_ACTIVITY_DATA"
export const GET_ACTIVITY_DATA_SUCCESS = "GET_ACTIVITY_DATA_SUCCESS"
export const GET_ACTIVITY_DATA_FAIL = "GET_ACTIVITY_DATA_FAIL"

// Get Universe data
export const GET_UNIVERSE_DATA = "GET_UNIVERSE_DATA"
export const GET_UNIVERSE_DATA_SUCCESS = "GET_UNIVERSE_DATA_SUCCESS"
export const GET_UNIVERSE_DATA_FAIL = "GET_UNIVERSE_DATA_FAIL"

// Select Universe

export const SELECT_UNIVERSE_DATA = "SELECT_UNIVERSE_DATA"
export const SELECT_UNIVERSE_DATA_SUCCESS = "SELECT_UNIVERSE_DATA_SUCCESS"
export const SELECT_UNIVERSE_DATA_FAIL = "SELECT_UNIVERSE_DATA_FAIL"


export const STORE_UNIVERSE_DATA = "STORE_UNIVERSE_DATA"