import {
  ADD_MARKET_SHARE_LAST_ROUND,
  ADD_MARKET_SHARE_LAST_ROUND_SUCCESS,
  ADD_MARKET_SHARE_LAST_ROUND_FAIL,
  GET_MARKET_SHARE_LAST_ROUND,
  GET_MARKET_SHARE_LAST_ROUND_SUCCESS,
  ADMIN_GET_MARKET_SHARE,
  ADMIN_GET_MARKET_SHARE_SUCCESS,
} from "./actionTypes"

export const addMarketShareLastRound = (regionId, techId, lastRound, token) => {
  return {
    type: ADD_MARKET_SHARE_LAST_ROUND,
    payload: { regionId, techId, lastRound, token },
  }
}
export const addMarketShareLastRoundSuccess = message => {
  return {
    type: ADD_MARKET_SHARE_LAST_ROUND_SUCCESS,
    payload: { message },
  }
}
export const addMarketShareLastRoundFail = message => {
  return {
    type: ADD_MARKET_SHARE_LAST_ROUND_FAIL,
    payload: { message },
  }
}

export const getMarketShareLastRound = (token, value) => {
  return {
    type: GET_MARKET_SHARE_LAST_ROUND,
    payload: { token, value },
  }
}
export const getMarketShareLastRoundSuccess = data => {
  return {
    type: GET_MARKET_SHARE_LAST_ROUND_SUCCESS,
    payload: { data },
  }
}

export const adminGetMarketShare = token => {
  return {
    type: ADMIN_GET_MARKET_SHARE,
    payload: { token },
  }
}

export const adminGetMarketShareSuccess = data => {
  return {
    type: ADMIN_GET_MARKET_SHARE_SUCCESS,
    payload: { data },
  }
}
