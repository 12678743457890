import { ADD_TEACHER, ADD_TEACHER_SUCCESS, ADD_TEACHER_FAIL, GET_TEACHER, GET_TEACHER_SUCCESS } from "./actionTypes"

export const addTeacher = (values, history, token) => {
    return {
        type: ADD_TEACHER,
        payload: { values, history, token }
    }

}

export const addTeacherSuccess = (message) => {
    return {

        type: ADD_TEACHER_SUCCESS,
        payload: { message }
    }
}

export const addTeacherFail = (error) => ({
    type: ADD_TEACHER_FAIL,
    payload: { error },

})



export const getTeachers = (token) => ({
    type: GET_TEACHER,
    payload: token
})

export const getTeacherSuccess = (teachers) => ({
    type: GET_TEACHER_SUCCESS,
    payload: { teachers }
})