import {
  CANCLE_ENHANCEMENT,
  CANCLE_ENHANCEMENT_FAIL,
  CANCLE_ENHANCEMENT_SUCCESS,
  ENHANCE_STUDENT_CAPACITY,
  ENHANCE_STUDENT_CAPACITY_FAIL,
  ENHANCE_STUDENT_CAPACITY_SUCCESS,
  GO_TO_RAND_D,
  GO_TO_RAND_D_SUCCESS,
  IS_CAPACITY_IN_PROCESSING,
  IS_CAPACITY_IN_PROCESSING_SUCCESS,
} from "./actionTypes"

export const enhanceStudentCapacity = (
  token,
  teamId,
  roundSerial,
  regionId,
  capacityActive,
  totalProductionCapacity,
  totalCost,
  currentRound,
  universeId,
  setDevelopBtn
) => ({
  type: ENHANCE_STUDENT_CAPACITY,
  payload: {
    token,
    teamId,
    roundSerial,
    regionId,
    capacityActive,
    totalProductionCapacity,
    totalCost,
    currentRound,
    universeId,
    setDevelopBtn
  },
})

export const enhanceStudentCapacitySuccess = message => ({
  type: ENHANCE_STUDENT_CAPACITY_SUCCESS,
  payload: { message },
})
export const enhanceStudentCapacityFail = message => ({
  type: ENHANCE_STUDENT_CAPACITY_FAIL,
  payload: { message },
})

export const capacityInProcessing = (
  token,
  roundSerial,
  teamId,
  universeId
) => ({
  type: IS_CAPACITY_IN_PROCESSING,
  payload: { token, roundSerial, teamId, universeId },
})

export const capacityInProcessingSuccess = data => ({
  type: IS_CAPACITY_IN_PROCESSING_SUCCESS,
  payload: { data },
})

export const cancleEnahncement = (token, roundSerial, regionId, teamId) => ({
  type: CANCLE_ENHANCEMENT,
  payload: { token, roundSerial, regionId, teamId },
})

export const cancleEnhancementSuccess = message => ({
  type: CANCLE_ENHANCEMENT_SUCCESS,
  payload: { message },
})

export const cancleEnhancementFail = message => ({
  type: CANCLE_ENHANCEMENT_FAIL,
  payload: { message },
})

export const goToRANDD = (
  token,
  roundSerial,
  teamId,
  history,
  universeId,
  goNext = false,
  val
) => ({
  type: GO_TO_RAND_D,
  payload: { token, roundSerial, teamId, history, goNext, val, universeId },
})

export const goToRandDSuccess = data => ({
  type: GO_TO_RAND_D_SUCCESS,
  payload: { data },
})
