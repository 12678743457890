import { useEffect, useState } from "react"
import { Container, FormSelect } from "react-bootstrap"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"

import { MDBDataTable } from "mdbreact"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import AddCardComponent from "common/Layout/AddCardComponent"
import { useFormik } from "formik"
import { toaster } from "helpers/custom/Toast"
import { addUserSchema } from "pages/Schemas/AddUser.schema"
import Select from "react-select"
import {
  addTotalDemandLastRound,
  adminGetTotalDemandLastRound,
  getDemandData,
  getMarketShare,
  getRegions,
  postTotalDemand,
  storeData,
  updateTotalDemand,
} from "store/actions"
import addCircle from "../../assets/images/add-circle.png"
import closeCircle from "../../assets/images/close-circle.png"
import { getCourse } from "../../store/actions"
const initialValues = {}
const roundList = [
  {
    value: 0,
    _id: "64923741097",
  },
  {
    value: 1,
    _id: "64923748903",
  },
  {
    value: 2,
    _id: "64923744571",
  },
  {
    value: 3,
    _id: "64923709021",
  },
  {
    value: 4,
    _id: "64923701239",
  },
  {
    value: 5,
    _id: "64923782392",
  },
  {
    value: 6,
    _id: "64923785315",
  },
  {
    value: 7,
    _id: "64923787642",
  },
]
const UpdateMarketShare = ({ edit }) => {
  let dispatch = useDispatch()
  let history = useHistory()

  const [marketData, setMarketData] = useState()
  const [courseInfo, setCourseInfo] = useState("")
  const [regionInfo, setRegionInfo] = useState("")
  const [roundInfo, setRoundInfo] = useState({
    roundNum: "",
    roundId: "",
    totalDemand: "",
  })
  const [singleRound, setSingleRound] = useState([])
  // const [techData, setTechData] = useState()
  const {
    token,
    loading,
    regions,
    totalDemands,
    courses,
    totalDemandData,
    totalDemandLoading,
    availableTech,
    modifiedMarketShare,
  } = useSelector(state => ({
    token: state.Login.token,
    loading: state.regionData.loading,
    regions: state.regionData.regions,
    message: state.adminDemandData.message,
    totalDemands: state.adminDemandData.adminTotalDemand,
    totalDemandData: state.DemandReducer.totalDemandData,
    modifiedMarketShare: state.DemandReducer.modifiedMarketShare,
    totalDemandLoading: state.DemandReducer.totalDemandLoading,
    
    courses: state.courseAdmin.courses,
  }))
  console.log("modifiedMarketShare", modifiedMarketShare)
  console.log("totalDemandData", totalDemandData)
  useEffect(() => {
    dispatch(getRegions(token))
    dispatch(adminGetTotalDemandLastRound(token))
    // dispatch(getAvailableTechAdmin(token))
  }, [])
  useEffect(() => {
    dispatch(getCourse(token))
  }, [])




 
  const onSubmit = values => {
    console.log("modifiedDemand", modifiedDemand)
    const RoundArray = modifiedDemand?.map(data => ({
      demandData: data?.totalDemand,
      roundSerial: data?.demandData,
    }))
    let obj = {
      demandAndRound: RoundArray,
    }
    console.log("obj", obj)
    // dispatch(updateTotalDemand(obj, history, token, courseInfo, regionInfo))
  }

  const AddUser = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addUserSchema,
    onSubmit,
  })
 


  const handleCourse = data => {
    if (data) {
      setCourseInfo(data?._id)
    } else {
      setCourseInfo("")
    }
  }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      }
    },
  }
  const handleRegion = data => {
    if (data) {
      setRegionInfo(data?._id)
    } else {
      setRegionInfo("")
    }
  }


  useEffect(() => {
    console.log("courseInfo", courseInfo, regionInfo)
    if (courseInfo && regionInfo) {
      dispatch(getMarketShare(token, courseInfo, regionInfo))
    }
  }, [courseInfo, regionInfo])

  const handleChangeTarget = (e, idx) => {
    // setTargetValue(e.target.value, idx);

    const newData = kpiAchievedList.map((el, index) => {
      if (idx === index) {
        return {
          ...el,
          tillNow: e.target.value,
        }
      }
      return { ...el }
    })
    // dispatch(storeKpiSelection({ name: "kpiAchievedList", data: newData }));
  }
  const handleSubmitQtyEdit = (e, idx, data) => {
    const oldValue = [...modifiedDemand]
    console.log("oldValue", oldValue)

    oldValue[idx].demandData = e.target.value * 1
    dispatch(storeData("modifiedDemand", oldValue))
  }

  const handleUpdateData = (key, data) =>{
    console.log('data', data);
    let obj ={
      demandData: data?.demandData,
      roundSerial: data?.roundSerial,
    }
    dispatch(updateTotalDemand(obj, history, token, courseInfo, regionInfo, totalDemandData?._id, data?._id))


  }
  return (
    <div>
      <Container>
        <Row className="mt-4">
          <Col md="9" className="mx-auto">
            {/* add User */}
            <AddCardComponent className="">
            <h5 className="mb-4">Update Market Share</h5>
              <Form
                className="needs-validation col-md-12"
                onSubmit={AddUser.handleSubmit}
              >
                <Row>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Courses</Label>
                    <Select
                      name="course"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Course"
                      cacheOptions
                      getOptionLabel={e => e.courseName}
                      getOptionValue={e => e.value}
                      isClearable
                      options={courses}
                      styles={colourStyles}
                      onChange={handleCourse}
                    />
                  </Col>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Courses</Label>
                    <Select
                      name="course"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Region"
                      cacheOptions
                      getOptionLabel={e => e.regionName}
                      getOptionValue={e => e.value}
                      isClearable
                      options={regions}
                      styles={colourStyles}
                      onChange={handleRegion}
                    />
                  </Col>
                </Row>

                <Row>
                  <Table className="table table-hover mb-0 table-centered  mb-5">
                    <thead className="table-light">
                      <tr>
                        <th>No.</th>
                        <th>Round No</th>
                        <th>Tech</th>
                        <th>Market Share</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {courseInfo !== "" &&
                      regionInfo !== "" ?
                  (
                        <>
                      {totalDemandLoading ? (
                        <tr>
                          <td colSpan="5">
                            <h6 className="text-center">Loading...</h6>
                          </td>
                        </tr>
                      ) : (
                        (modifiedMarketShare || []).map((data, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>

                              <td>{data?.roundSerial}</td>
                              <td>{data?.techName}</td>
                              <td>
                                <Input
                                  type="number"
                                  style={{
                                    width: "100px",
                                    height: "38px",
                                  }}
                                  value={data?.marketShare}
                                  onChange={e =>
                                    handleSubmitQtyEdit(e, key, data)
                                  }
                                ></Input>
                              </td>
                              <td>
                                <Button
                                  onClick={() => handleUpdateData(key, data)}
                                >
                                  Update
                                </Button>
                              </td>
                            </tr>
                          )
                        })
                      )}
                           </>
                      ) : (
                        <p className="mt-3">
                          Please Select all required fields
                        </p>
                      )}
                    </tbody>
                  </Table>
                </Row>
              </Form>
            </AddCardComponent>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UpdateMarketShare
