import React from "react"
import { Container } from "react-bootstrap"

const AdminCourseView = () => {
  return (
    <div>
      <Container>
        <h3>Admin Course View Page</h3>
      </Container>
    </div>
  )
}

export default AdminCourseView
