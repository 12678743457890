import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import { getCourseForOneSuccess } from "store/actions"

import {
  axiosCreateCourse,
  axiosGetCourses,
  axiosGetCoursesForOne,
  axiosDeleteCourse,
} from "../../helpers/api_helper"
import {
  addCourseSuccess,
  addCourseFail,
  getCourseSuccess,
  deleteCourseSuccess,
  deleteCourseFail,
} from "./actions"
import {
  ADD_COURSE,
  GET_COURSE,
  GET_COURSE_FOR_ONE,
  DELETE_COURSE,
} from "./actionTypes"

const asyncCrateCourse = async (
  courseName,
  courseCode,
  studentLimit,
  courseLeader,
  token
) => {
  try {
    const response = await axiosCreateCourse(
      courseName,
      courseCode,
      studentLimit,
      courseLeader,
      token
    )

    console.log("respnse data for course Create", response.data)
    return response.data
  } catch (error) {
    console.log("error data", error.response.data)
    return error.response.data
  }
}

function* addCourseSaga(payload) {
  console.log("all data", payload)
  const { values, history, token } = payload.payload
  const { courseName, courseCode, studentLimit, courseLeader } = values
  try {
    const response = yield call(
      asyncCrateCourse,
      courseName,
      courseCode,
      studentLimit,
      courseLeader,
      token
    )
    console.log("response from hrer", response)
    if (response.status == "success") {
      yield put(addCourseSuccess(response.message))
      history.push("/allcourse")
      yield put(addCourseSuccess())
    } else {
      console.log("response 56", response)
      yield put(addCourseFail(response.message))
      console.log(console.log("56 response message", response.message))
      yield put(addCourseFail())
    }
  } catch (err) {
    yield put(addCourseFail())
  }
}

const asyncGetCourses = async token => {
  console.log("token data", token)
  try {
    const response = await axiosGetCourses(token)
    console.log("courses data", response.data)
    return response.data
  } catch (error) {
    console.log("error courses", error.response.data)
    return error.response.data
  }
}

function* getCourseSaga({ payload: token }) {
  console.log("Token data", token)
  try {
    const response = yield call(asyncGetCourses, token)
    console.log("response data", response)

    yield put(getCourseSuccess(response.getalldata))
  } catch (err) {
    console.log("fail data from here", err.data)
  }
}

const asyncGetCourseForOne = async token => {
  try {
    console.log("user get course from 86", token)
    const response = await axiosGetCoursesForOne(token)
    console.log("response data for one Course", response.data)
    return response.data
  } catch (err) {
    console.log("error data", err.response.data)
    return err.response.data
  }
}
function* workerGetCourseForOne({ payload: token }) {
  console.log("payload data", token)
  try {
    const response = yield call(asyncGetCourseForOne, token)
    console.log("response data", response)

    yield put(getCourseForOneSuccess(response.findAllCourseList))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncDeleteCourse = async (token, courseId) => {
  try {
    const response = await axiosDeleteCourse(token, courseId)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerDeleteCourse({ payload: { token, courseId } }) {
  try {
    const response = yield call(asyncDeleteCourse, token, courseId)

    if (response.status == "success") {
      yield put(deleteCourseSuccess(response.message))
      toast.success(response.message)
      const res = yield call(asyncGetCourses, token)

      yield put(getCourseSuccess(res.getalldata))
      yield put(deleteCourseSuccess())
    } else {
      yield put(deleteCourseFail(response.message))
      toast.error(response.message)
      yield put(deleteCourseFail())
    }
  } catch (error) {}
}

function* courseAdminSaga() {
  yield takeEvery(ADD_COURSE, addCourseSaga)
  yield takeEvery(GET_COURSE, getCourseSaga)
  yield takeEvery(GET_COURSE_FOR_ONE, workerGetCourseForOne)

  yield takeEvery(DELETE_COURSE, workerDeleteCourse)
}

export default courseAdminSaga
