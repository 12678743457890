import {
  GET_STUDENT_MARKET_SHARE_LAST_ROUND,
  GET_STUDENT_MARKET_SHARE_LAST_ROUND_SUCCESS,
  INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE,
  INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE_FAIL,
  INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE_SUCCESS,
  GET_DEMAND_FULL_DATA,
  GET_DEMAND_FULL_DATA_SUCCESS,
  GO_TO_PRODUCTION,
  GO_TO_PRODUCTION_SUCCESS,
  GO_TO_PRODUCTION_FAIL,
} from "./actionTypes"

//get market share last round
export const getStudentMarketShareLastRound = (
  token,

  roundSerial,
  teamId,
  universeId
) => ({
  type: GET_STUDENT_MARKET_SHARE_LAST_ROUND,
  payload: { token, roundSerial, teamId, universeId },
})

//get market share last round success
export const getStudentMarketShareLastRoundSuccess = data => ({
  type: GET_STUDENT_MARKET_SHARE_LAST_ROUND_SUCCESS,
  payload: { data },
})

//update last part of market share
export const insertOrUpdateLastPartOfMarketShare = (
  token,
  regionId,
  roundSerial,
  techId,
  estimateMarketShare,
  marketShareLastRound,
  teamId,
  universeId
) => ({
  type: INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE,
  payload: {
    token,
    regionId,
    roundSerial,
    techId,
    estimateMarketShare,
    marketShareLastRound,
    teamId,
    universeId,
  },
})

//success
export const insertOrUpdateLastPartOfMarketShareSuccess = message => ({
  type: INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE_SUCCESS,
  payload: { message },
})

//fail
// export const insertOrUpdate
export const insertOrUpdateLastPartOfMarketShareFail = message => ({
  type: INSERT_OR_UPDATE_LAST_PART_MARKETING_SHARE_FAIL,
  payload: { message },
})

//get demand data
export const getDemandFullData = (token, roundSerial, universeId) => ({
  type: GET_DEMAND_FULL_DATA,
  payload: { token, roundSerial, universeId },
})

export const getDemandFullDataSuccess = data => ({
  type: GET_DEMAND_FULL_DATA_SUCCESS,
  payload: { data },
})

export const goToProductionPage = (
  token,
  roundSerial,
  history,
  goNext = false,
  teamId,
  universeId
) => ({
  type: GO_TO_PRODUCTION,
  payload: { token, roundSerial, history, goNext, teamId, universeId },
})

export const goToProductionPageSuccess = data => ({
  type: GO_TO_PRODUCTION_SUCCESS,
  payload: { data },
})
export const goToProductionPageFail = data => ({
  type: GO_TO_PRODUCTION_FAIL,
  payload: { data },
})
