import {
  ADD_TECH_FROM_FILE,
  ADD_TECH_FROM_FILE_SUCCESS,
  ADD_TECH_FROM_FILE_FAIL,
  GET_TECHS,
  GET_TECHS_FAIL,
  GET_TECHS_SUCCESS,
  GET_AVAILABLE_TECH,
  GET_AVAILABLE_TECH_SUCCESS,
  GET_UNAVAILABLE_TECH,
  GET_UNAVAILABLE_TECH_SUCCESS,
  GET_AVAILABLE_TECH_FOR_ADMIN,
  GET_AVAILABLE_TECH_FOR_ADMIN_SUCCESS,
  GET_UNAVAILABLE_TECH_FOR_R_AND_D,
  GET_UNAVAILABLE_TECH_FOR_R_AND_D_SUCCESS,
  ANY_TECH_IN_PROCESSING,
  ANY_TECH_IN_PROCESSING_SUCCESS,
} from "./actionTypes"

export const addTechData = (value, token, history) => {
  return {
    type: ADD_TECH_FROM_FILE,
    payload: { value, token, history },
  }
}

export const addTechDataSuccess = message => {
  return {
    type: ADD_TECH_FROM_FILE_SUCCESS,
    payload: { message },
  }
}

export const addTechDataFail = message => {
  return {
    type: ADD_TECH_FROM_FILE_FAIL,
    payload: { message },
  }
}

export const getTechs = token => {
  return {
    type: GET_TECHS,
    payload: { token },
  }
}

export const getTechsSuccess = data => {
  return {
    type: GET_TECHS_SUCCESS,
    payload: { data },
  }
}

export const getAvailableTech = (token, teamId) => {
  return {
    type: GET_AVAILABLE_TECH,
    payload: { token, teamId },
  }
}

export const getAvailableTechSuccess = data => {
  return {
    type: GET_AVAILABLE_TECH_SUCCESS,
    payload: { data },
  }
}

export const getAvailableTechAdmin = token => {
  return {
    type: GET_AVAILABLE_TECH_FOR_ADMIN,
    payload: { token },
  }
}

export const getAvailableTechAdminSuccess = data => {
  return {
    type: GET_AVAILABLE_TECH_FOR_ADMIN_SUCCESS,
    payload: { data },
  }
}

export const getUnavailableTech = token => {
  return {
    type: GET_UNAVAILABLE_TECH,
    payload: { token },
  }
}

export const getUnavailableTechSuccess = data => {
  return {
    type: GET_UNAVAILABLE_TECH_SUCCESS,
    payload: { data },
  }
}

export const getUnavailableTechForRandD = (token, teamId) => ({
  type: GET_UNAVAILABLE_TECH_FOR_R_AND_D,
  payload: { token, teamId },
})

export const getUnavailableTechForRandDSuccess = data => ({
  type: GET_UNAVAILABLE_TECH_FOR_R_AND_D_SUCCESS,
  payload: { data },
})

export const anyTechInProcessing = (
  token,
  teamId,
  roundSerial,
  universeId
) => ({
  type: ANY_TECH_IN_PROCESSING,
  payload: { token, teamId, roundSerial, universeId },
})

export const anyTechInProcesingSuccess = data => ({
  type: ANY_TECH_IN_PROCESSING_SUCCESS,
  payload: { data },
})
