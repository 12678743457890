import React, { useState, useEffect } from "react"
import { Container, FormSelect } from "react-bootstrap"
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  Row,
  Form,
  Label,
  Table,
  Input,
  Button,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  getRegions,
  addMarketShareLastRound,
  adminGetMarketShare,
  getAvailableTechAdmin,
  postTotalDemand,
  getOnlyProductionRegion,
} from "store/actions"
import { MDBDataTable } from "mdbreact"
import addCircle from "../../assets/images/add-circle.png"
import closeCircle from "../../assets/images/close-circle.png"
import CustomInput from "common/CustomFilter/CustomInput"
import AddCardComponent from "common/Layout/AddCardComponent"
import { useFormik } from "formik"
import { addUserSchema } from "pages/Schemas/AddUser.schema"
import { getCourse } from "../../store/actions"
import Select from "react-select"
import { toaster } from "helpers/custom/Toast"
import {
  getPlantUpgrade,
  postPlantUpgrade,
  storePlantUpgradeData,
  updatePlantUpgrade,
} from "store/PlantUpgrade/actions"
const initialValues = {}
const roundList = [
  {
    value: 0,
    _id: "64923741097",
  },
  {
    value: 1,
    _id: "64923748903",
  },
  {
    value: 2,
    _id: "64923744571",
  },
  {
    value: 3,
    _id: "64923709021",
  },
  {
    value: 4,
    _id: "64923701239",
  },
  {
    value: 5,
    _id: "64923782392",
  },
  {
    value: 6,
    _id: "64923785315",
  },
  {
    value: 7,
    _id: "64923787642",
  },
]
const UpdatePlantUpgrade = ({ edit }) => {
  const [regionData, setRegionData] = useState({})
  const [initialCapital, setInitialCapital] = useState()
  const [marketData, setMarketData] = useState()
  const [techData, setTechData] = useState()
  const [regionInfo, setRegionInfo] = useState("")
  const [courseInfo, setCourseInfo] = useState("")
  const [roundInfo, setRoundInfo] = useState({
    roundNum: "",
    roundId: "",
    plantPrice: "",
    secondRound: "",
    thirdRound: "",
  })
  const [singleRound, setSingleRound] = useState([])
  const {
    token,

    regions,
    plantUpgradeLoading,
    courses,
    modifiedPlant,
    plantUpgradeData,
    adding,
  } = useSelector(state => ({
    token: state.Login.token,
    plantUpgradeData: state.PlantUpgradeReducer.plantUpgradeData,
    regions: state.regionData.productionRegionAdmin,
    message: state.adminDemandData.message,

    courses: state.courseAdmin.courses,
    modifiedPlant: state.PlantUpgradeReducer.modifiedPlant,
    plantUpgradeLoading: state.PlantUpgradeReducer.plantUpgradeLoading,
    adding: state.PlantUpgradeReducer.adding,
  }))
  console.log("plantUpgradeData", plantUpgradeData)
  const history = useHistory()
  const dispatch = useDispatch()
  let value = {}
  value.roundSerial = 0
  useEffect(() => {
    dispatch(getOnlyProductionRegion())
    dispatch(getAvailableTechAdmin(token))
  }, [])
  useEffect(() => {
    dispatch(adminGetMarketShare(token))
  }, [])

  useEffect(() => {
    dispatch(getCourse(token))
  }, [])

  useEffect(() => {
    console.log("courseInfo", courseInfo, regionInfo)
    if (courseInfo && regionInfo) {
      dispatch(getPlantUpgrade(token, courseInfo, regionInfo))
    }
  }, [courseInfo, regionInfo])

  const onSubmit = values => {
    console.log("modifiedPlant", modifiedPlant)
  }

  const AddUser = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addUserSchema,
    onSubmit,
  })

  const handleCourse = data => {
    console.log("data", data)
    if (data) {
      setCourseInfo(data?._id)
    } else {
      setCourseInfo("")
    }
  }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      }
    },
  }
  const handleRegion = data => {
    console.log("data", data)
    if (data) {
      setRegionInfo(data?._id)
    } else {
      setRegionInfo("")
    }
  }
  const handleSubmitSecondEdit = (e, idx, data) => {
    const oldValue = [...modifiedPlant]
    console.log("oldValue", oldValue)

    oldValue[idx].second = e.target.value
    dispatch(storePlantUpgradeData("modifiedPlant", oldValue))
  }
  const handleSubmitThirdEdit = (e, idx, data) => {
    const oldValue = [...modifiedPlant]
    console.log("oldValue", oldValue)

    oldValue[idx].third = e.target.value
    dispatch(storePlantUpgradeData("modifiedPlant", oldValue))
  }
  const handleSubmitPlantEdit = (e, idx, data) => {
    const oldValue = [...modifiedPlant]
    console.log("oldValue", oldValue)

    oldValue[idx].plantSize = e.target.value
    dispatch(storePlantUpgradeData("modifiedPlant", oldValue))
  }
  const handleUpdateData = (key, data) => {
    console.log("data", data)
    let obj = {
      roundSerial: data?.roundSerial,
      plantSize: data?.plantSize * 1,
      second: data?.second * 1,
      third: data?.third * 1,
    }
    console.log("obj", obj)
    dispatch(
      updatePlantUpgrade(
        obj,
        history,
        token,
        plantUpgradeData?._id,
        data?._id,
        courseInfo,
        regionInfo
      )
    )
  }
  return (
    <div>
      <Container>
      
        <Row className="mt-4">
          <Col md="9" className="mx-auto">
            {/* add User */}
            <div className="text-end mb-3">
              <Button
                color="info"
                className="btn btn-primary me-end"
                onClick={() => history.push("/admin-production-plant")}
              >
                Add Plant upgrade
              </Button>
            </div>
            <AddCardComponent className="">
              <Form
                className="needs-validation col-md-12"
                onSubmit={AddUser.handleSubmit}
              >
                <Row>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Courses</Label>
                    <Select
                      name="course"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Course"
                      cacheOptions
                      getOptionLabel={e => e.courseName}
                      getOptionValue={e => e.value}
                      isClearable
                      options={courses}
                      styles={colourStyles}
                      onChange={handleCourse}
                    />
                  </Col>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Region</Label>
                    <Select
                      name="course"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Region"
                      cacheOptions
                      getOptionLabel={e => e.regionName}
                      getOptionValue={e => e.value}
                      isClearable
                      options={regions}
                      styles={colourStyles}
                      onChange={handleRegion}
                    />
                  </Col>
                </Row>

                <Row>
                  <Table className="table table-hover mb-0 table-centered  mb-5">
                    <thead className="table-light">
                      <tr>
                        <th>No.</th>
                        <th>Round No</th>
                        <th>second round</th>
                        <th>third round</th>
                        <th>Plant size</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseInfo !== "" && regionInfo !== "" ? (
                        <>
                          {plantUpgradeLoading ? (
                            <tr>
                              <td colSpan="5">
                                <h6 className="text-center">Loading...</h6>
                              </td>
                            </tr>
                          ) : (
                            (modifiedPlant || []).map((data, key) => {
                              return (
                                <tr key={key}>
                                  <td>{key + 1}</td>

                                  <td>{data?.roundSerial}</td>
                                  <td>
                                    {" "}
                                    <Input
                                      type="number"
                                      style={{
                                        width: "100px",
                                        height: "38px",
                                      }}
                                      value={data?.second}
                                      onChange={e =>
                                        handleSubmitSecondEdit(e, key, data)
                                      }
                                    ></Input>
                                  </td>

                                  <td>
                                    {" "}
                                    <Input
                                      type="number"
                                      style={{
                                        width: "100px",
                                        height: "38px",
                                      }}
                                      value={data?.third}
                                      onChange={e =>
                                        handleSubmitThirdEdit(e, key, data)
                                      }
                                    ></Input>
                                  </td>
                                  <td>
                                    <Input
                                      type="number"
                                      style={{
                                        width: "100px",
                                        height: "38px",
                                      }}
                                      value={data?.plantSize}
                                      onChange={e =>
                                        handleSubmitPlantEdit(e, key, data)
                                      }
                                    ></Input>
                                  </td>
                                  <td>
                                    <Button
                                      color="warning"
                                      outline
                                      disabled={adding}
                                      onClick={() =>
                                        handleUpdateData(key, data)
                                      }
                                    >
                                      {adding ? "Submitting" : "Update"}
                                    </Button>
                                  </td>
                                </tr>
                              )
                            })
                          )}
                        </>
                      ) : (
                        <p className="mt-3">
                          Please Select all required fields
                        </p>
                      )}
                    </tbody>
                  </Table>
                </Row>
              </Form>
            </AddCardComponent>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UpdatePlantUpgrade
