import {
  ADD_UNIVERSE,
  ADD_UNIVERSE_SUCCESS,
  ADD_UNIVERSE_FAIL,
  GET_UNIVERSES,
  GET_UNIVERSES_SUCCESS,
  GET_SINGLE_UNIVERSE,
  GET_SINGLE_UNIVERSE_SUCCESS,
  GET_SINGLE_UNIVERSE_FAIL,
} from "./actionTypes"

const initialState = {
  universes: [],
  universe: {},
  loading: false,
  message: "",
  error: "",
}

const universeTeacher = (state = initialState, action) => {
  switch (action.type) {
    case ADD_UNIVERSE:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_UNIVERSE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case ADD_UNIVERSE_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case GET_UNIVERSES:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_UNIVERSES_SUCCESS:
      state = {
        ...state,
        loading: false,
        universes: action.payload.universe,
      }
      break
    case GET_SINGLE_UNIVERSE:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_SINGLE_UNIVERSE_SUCCESS:
      state = {
        ...state,
        loading: false,
        universe: action.payload.universe,
      }
      break
    default:
      state = state
      break
  }
  return state
}
export default universeTeacher
