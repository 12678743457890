import { Container, Table } from "react-bootstrap"
import { MetaTags } from "react-meta-tags"
import "./requirement.scss"
const TeacherRequirement = () => {
  return (
    <Container>
      <MetaTags>
        <title>Teacher | Teacher-equirement</title>
      </MetaTags>
      <div className="requirement-table-main-area-card-wrap">
        <div className="estimate-markate-demand-wrap requirement-table-main-area">
          <div className="estimate-markate-demand-table">
            <Table className="table table-striped ">
              <thead>
                <tr className="estimate-table-header">
                  <th colSpan={2}>
                    <h2>Requirement</h2>
                  </th>
                </tr>
              </thead>

              <tbody className="requirement-table-body-content">
                <tr>
                  <td>Storage</td>
                  <td>Minimum 2 GB RAM for smoothly run our project</td>
                </tr>
                <tr>
                  <td>Browser</td>
                  <td>
                    Any browser. Ex: Google Chrome, safari, Microsoft Edge
                  </td>
                </tr>
                <tr>
                  <td>Operatiing System</td>
                  <td>OS : Windows 7, 8.1 , 10 , 11</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default TeacherRequirement
