import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_TEAM_SUBMITTED_RESULT,
  EVUALATE_SUBMITTED_RESULT,
  GET_RESULT_SUMMARY,
  GET_UNIVERSE_ID,
  SUBMIT_MARK_BY_TEACHER,
  SHOW_TEAM_MEMBER_NAME_WISE_DATA,
} from "./actionTypes"

import {
  getTeamSubmittedResultSuccess,
  evualateSubmittedResultFail,
  evualateSubmittedResultSuccess,
  getResultSummarySuccess,
  getUniverseIdSuccess,
  getResultSummary,
  submitMarkByTeacherFail,
  submitMarkByTeacherSuccess,
  showTeamMemberNameWiseDataSuccess,
} from "./actions"
import {
  axiosGetTeamSubmittedData,
  axiosEvualateSubmitResult,
  axiosGetResultSummarySuccess,
  axiosGetUniverseId,
  axiosSubmitMark,
  axiosShowTeamMemberNameWiseData,
} from "../../helpers/api_helper"
import { toast } from "react-toastify"

const asyncSubmitMark = async (token, mark, teamId, roundSerial) => {
  try {
    const response = await axiosSubmitMark(token, teamId, roundSerial, mark)
    return response.data
  } catch (error) {}
}
function* workerSubmitMarkByTeacher({
  payload: { token, mark, teamId, roundSerial },
}) {
  try {
    const response = yield call(
      asyncSubmitMark,
      token,
      mark,
      teamId,
      roundSerial
    )
    console.log("hello data 46", response)
    if (response.status == "success") {
      yield put(submitMarkByTeacherSuccess(response.message))
      toast.success(response.message)
      yield put(submitMarkByTeacherSuccess())
    } else {
      yield put(submitMarkByTeacherFail(response.message))
      toast.error(response.message)
      yield put(submitMarkByTeacherFail())
    }
  } catch (error) {}
}

const asyncGetUniverseId = async token => {
  try {
    const response = await axiosGetUniverseId(token)
    return response.data
  } catch (error) {}
}

function* workerGetUniverseId({ payload: { token } }) {
  try {
    const response = yield call(asyncGetUniverseId, token)
    console.log("hello response data 34", response.getUniverseData)
    yield put(getUniverseIdSuccess(response.getUniverseData))
  } catch (error) {}
}
const asyncGetResultSubmit = async (token, roundSerial, universeId) => {
  try {
    const response = await axiosGetResultSummarySuccess(
      token,
      roundSerial,
      universeId
    )
    return response.data
  } catch (error) {}
}
function* workerGetResultSubmit({
  payload: { token, roundSerial, universeId },
}) {
  try {
    const response = yield call(
      asyncGetResultSubmit,
      token,
      roundSerial,
      universeId
    )

    yield put(getResultSummarySuccess(response.resultSummary))
  } catch (error) {}
}

const asyncEvulateSubmittedResult = async (
  token,
  teamId,
  universeId,
  roundSerial
) => {
  try {
    const response = await axiosEvualateSubmitResult(
      token,
      teamId,
      universeId,
      roundSerial
    )
    return response.data
  } catch (error) {}
}

function* workerEvualateSubmittedResult({
  payload: { token, teamId, universeId, history, roundSerial },
}) {
  try {
    const response = yield call(
      asyncEvulateSubmittedResult,
      token,
      teamId,
      universeId,
      roundSerial
    )
    if (response.status == "success") {
      toast.success(response.message)
      let res = yield call(asyncGetTeamSubmittedData, token, roundSerial)
      yield put(evualateSubmittedResultSuccess(res.objData))
      yield put(getTeamSubmittedResultSuccess(res.objData))
    } else {
      toast.error(response.message)
    }
    // console.log("hello data here", response)
  } catch (error) {}
}

const asyncGetTeamSubmittedData = async (token, roundSerial) => {
  try {
    const response = await axiosGetTeamSubmittedData(token, roundSerial)
    return response.data
  } catch (error) {}
}
function* workerGetTeamSubmittedResult({ payload: { token, roundSerial } }) {
  try {
    const response = yield call(asyncGetTeamSubmittedData, token, roundSerial)
    console.log("hello res 17 sdfas", response.objData)
    yield put(getTeamSubmittedResultSuccess(response.objData))
  } catch (error) {}
}
//show team member name wise data
const asyncShowTeamMemberNameWiseData = async (token, teamId, roundSerial) => {
  try {
    const response = await axiosShowTeamMemberNameWiseData(
      token,
      teamId,
      roundSerial
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerShowTeamMemberNameWiseData({
  payload: { token, teamId, roundSerial },
}) {
  try {
    const response = yield call(
      asyncShowTeamMemberNameWiseData,
      token,
      teamId,
      roundSerial
    )
    console.log("res obj", response.getData)
    yield put(showTeamMemberNameWiseDataSuccess(response.getData))
  } catch (error) {
    console.log("error data")
  }
}

function* resultEvaluationSaga() {
  yield takeEvery(GET_TEAM_SUBMITTED_RESULT, workerGetTeamSubmittedResult)
  yield takeEvery(EVUALATE_SUBMITTED_RESULT, workerEvualateSubmittedResult)
  yield takeEvery(GET_RESULT_SUMMARY, workerGetResultSubmit)
  yield takeEvery(GET_UNIVERSE_ID, workerGetUniverseId)
  yield takeEvery(SUBMIT_MARK_BY_TEACHER, workerSubmitMarkByTeacher)
  yield takeEvery(
    SHOW_TEAM_MEMBER_NAME_WISE_DATA,
    workerShowTeamMemberNameWiseData
  )
}

export default resultEvaluationSaga
