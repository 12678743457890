import { call, put, takeEvery } from "redux-saga/effects"

import {
  ADD_FEATURE_BY_ADMIN,
  GET_FEATURE_BY_ADMIN,
  MAKE_FEATURE_AVAILABLE_BY_ADMIN,
} from "./actionTypes"

import {
  addFeatureByAdminFail,
  addFeatureByAdminSuccess,
  getFeatureByAdminSuccess,
  makeFeatureAvailableByAdminFail,
  makeFeatureAvailableByAdminSuccess,
} from "./actions"

import { toast } from "react-toastify"
import {
  axiosAddFeature,
  axiosGetFeature,
  axiosMakeFeatureAvailable,
} from "../../helpers/api_helper"

const asyncMakeFeatureAvailableByAdmin = async (token, featureId) => {
  try {
    const response = await axiosMakeFeatureAvailable(token, featureId)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerMakeFeatureAvailableByAdmin({ payload: { token, featureId } }) {
  try {
    const response = yield call(
      asyncMakeFeatureAvailableByAdmin,
      token,
      featureId
    )
    if (response.status == "success") {
      yield put(makeFeatureAvailableByAdminSuccess(response.message))
      yield put(makeFeatureAvailableByAdminSuccess())
    } else {
      toast.error(response.message)
      yield put(makeFeatureAvailableByAdminFail(response.message))
    }
  } catch (error) {}
}

const asyncGetFeatureByAdmin = async token => {
  try {
    const response = await axiosGetFeature(token)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetFeatureByAdmin({ payload: { token } }) {
  try {
    console.log("token data 25 line", token)
    const response = yield call(asyncGetFeatureByAdmin, token)
    console.log("daat hello 26", response)
    yield put(getFeatureByAdminSuccess(response.getFeaturesData))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncAddFeatureByAdmin = async (token, featureName, featurePrice) => {
  try {
    const response = await axiosAddFeature(token, featureName, featurePrice)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerAddFeatureByAdmin({
  payload: { token, featureName, featurePrice },
}) {
  try {
    const response = yield call(
      asyncAddFeatureByAdmin,
      token,
      featureName,
      featurePrice
    )
    if (response.status == "success") {
      yield put(addFeatureByAdminSuccess(response.message))
      toast.success(response.message)
      yield put(addFeatureByAdminSuccess())
    } else {
      yield put(addFeatureByAdminFail(response.message))
      toast.error(response.message)
      yield put(addFeatureByAdminFail())
    }
  } catch (error) {
    console.log("error data", error)
  }
}

function* adminFeatureSaga() {
  yield takeEvery(ADD_FEATURE_BY_ADMIN, workerAddFeatureByAdmin)
  yield takeEvery(GET_FEATURE_BY_ADMIN, workerGetFeatureByAdmin)
  yield takeEvery(
    MAKE_FEATURE_AVAILABLE_BY_ADMIN,
    workerMakeFeatureAvailableByAdmin
  )
}

export default adminFeatureSaga
