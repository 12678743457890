import { useEffect, useState } from "react"
import { Container, FormSelect } from "react-bootstrap"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"

import { MDBDataTable } from "mdbreact"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import AddCardComponent from "common/Layout/AddCardComponent"
import { useFormik } from "formik"
import { toaster } from "helpers/custom/Toast"
import { addUserSchema } from "pages/Schemas/AddUser.schema"
import Select from "react-select"
import {
  addTotalDemandLastRound,
  adminGetTotalDemandLastRound,
  getDemandData,
  getRegions,
  postTotalDemand,
} from "store/actions"
import addCircle from "../../assets/images/add-circle.png"
import closeCircle from "../../assets/images/close-circle.png"
import { getCourse } from "../../store/actions"
const initialValues = {}
const roundList = [
  {
    value: 0,
    _id: "64923741097",
  },
  {
    value: 1,
    _id: "64923748903",
  },
  {
    value: 2,
    _id: "64923744571",
  },
  {
    value: 3,
    _id: "64923709021",
  },
  {
    value: 4,
    _id: "64923701239",
  },
  {
    value: 5,
    _id: "64923782392",
  },
  {
    value: 6,
    _id: "64923785315",
  },
  {
    value: 7,
    _id: "64923787642",
  },
]
const MarketDemand = ({ edit }) => {
  let dispatch = useDispatch()
  let history = useHistory()

  const [marketData, setMarketData] = useState()
  const [courseInfo, setCourseInfo] = useState("")
  const [regionInfo, setRegionInfo] = useState("")
  const [roundInfo, setRoundInfo] = useState({
    roundNum: "",
    roundId: "",
    totalDemand: "",
  })
  const [singleRound, setSingleRound] = useState([])
  // const [techData, setTechData] = useState()
  const {
    token,
    loading,
    regions,
    totalDemands,
    courses,
    adding,
    modifiedDemand,
  } = useSelector(state => ({
    token: state.Login.token,
    loading: state.regionData.loading,
    regions: state.regionData.regions,
    message: state.adminDemandData.message,
    totalDemands: state.adminDemandData.adminTotalDemand,
    courses: state.courseAdmin.courses,
    modifiedDemand: state.DemandReducer.modifiedDemand,
    adding: state.DemandReducer.adding,
  }))
  console.log("modifiedDemand", modifiedDemand)
  const roundFilter = data => {
    let result = data
    if (modifiedDemand) {
      const addedRound = modifiedDemand.map(data => data?.roundSerial)
      console.log("addedRound", addedRound)
      result = data?.filter(el => !addedRound.includes(el?.value))
    }
    console.log("result", result)
    if (addedStock.length) {
      result = result.filter(data => !addedStock.includes(data?.value))
    }
    console.log("result43", result)
    return result
  }
  useEffect(() => {
    dispatch(getRegions(token))
    dispatch(adminGetTotalDemandLastRound(token))
    // dispatch(getAvailableTechAdmin(token))
  }, [])
  useEffect(() => {
    dispatch(getCourse(token))
  }, [])
  useEffect(() => {
    console.log("courseInfo", courseInfo, regionInfo)
    if (courseInfo && regionInfo) {
      dispatch(getDemandData(token, courseInfo, regionInfo))
    }
  }, [courseInfo, regionInfo])

  const handleKeyPress = e => {
    const keyCode = e.keyCode || e.which
    // console.log("keycode print")

    if (keyCode === 13) {
      // console.log("print value", marketData, e.target.value)
      dispatch(addTotalDemandLastRound(marketData, e.target.value, token))
    }
  }

  const tableData = totalDemands?.map((market, index) => {
    return {
      ...market,
      sl: index + 1,
      heading1: market?.region.regionName,
      heading2: market?.roundIndex,
      heading3: market?.totalDemandLastRound,
    }
  })

  const userdata = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },
      ,
      {
        label: "Region Name",
        field: "heading1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Round Serial",
        field: "heading2",
        sort: "asc",
        width: 150,
      },

      {
        label: "Total Demand last round",
        field: "heading3",
        sort: "asc",
        width: 200,
      },

      // {
      //   label: "Manage",
      //   field: "manage",
      // },
    ],
    rows: tableData,
  }
  const onSubmit = values => {
    if (courseInfo === "" || regionInfo === "") {
      return toaster("warning", "Please fill all the fields")
    }
    const RoundArray = singleRound.map(data => ({
      demandData: data?.totalDemand,
      roundSerial: data?.roundNum,
    }))
    let obj = {
      course: courseInfo,
      region: regionInfo,
      demandAndRound: RoundArray,
    }
    if(obj?.demandAndRound?.length ===0){
      return toaster("warning", "Please fill all the fields")
    }
    else{
      dispatch(postTotalDemand(obj, history, token))
    }
   
  }

  const AddUser = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addUserSchema,
    onSubmit,
  })
  const addedStock = singleRound.map(data => data?.roundNum)
  console.log("addedStock", addedStock)

  const handleDemandData = e => {
    setRoundInfo({
      ...roundInfo,
      totalDemand: e.target.value,
    })
  }
  const handleAddProductInput = () => {
    if (roundInfo.totalDemand === "" || !!roundInfo.roundNum <-1) {
      return toaster("warning", "Please fill all the fields")
    }
    if (roundInfo.totalDemand * 1 <= 0) {
      return toaster("warning", "Stock Can not be negative or zero")
    }
    let data = {
      totalDemand: roundInfo?.totalDemand * 1,
      roundNum: roundInfo?.roundNum * 1,
      roundId: roundInfo?.roundId,
    }
    console.log("data", data)
    setSingleRound([...singleRound, data])
    setRoundInfo({
      totalDemand: "",
      roundNum: "",
    })
  }
  const handleRemoveProduct = data => {
    console.log("remove", data)
    const removeProd = singleRound.filter(
      prod => data?.roundId !== prod?.roundId
    )
    console.log("removeProd", removeProd)
    setSingleRound(removeProd)
  }
  const handleCourse = data => {
    if (data) {
      setCourseInfo(data?._id)
    } else {
      setCourseInfo("")
    }
  }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      }
    },
  }
  const handleRegion = data => {
    console.log("data", data)
    if (data) {
      setRegionInfo(data?._id)
    } else {
      setRegionInfo("")
    }
  }

  return (
    <div>
      <Container>
        <Row className="mt-4">
          <Col md="9" className="mx-auto">
            {/* add User */}
            <div className="text-end">
              <Button
                color="info"
                className="btn  mb-3"
                onClick={() => history.push("/admin-market-demand-update")}
              >
                Update Demand
              </Button>
            </div>
            <AddCardComponent className="">
              <Form
                className="needs-validation col-md-12"
                onSubmit={AddUser.handleSubmit}
              >
                <Row>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Courses</Label>
                    <Select
                      name="course"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Course"
                      cacheOptions
                      getOptionLabel={e => e.courseName}
                      getOptionValue={e => e.value}
                      isClearable
                      options={courses}
                      styles={colourStyles}
                      onChange={handleCourse}
                    />
                  </Col>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Region</Label>
                    <Select
                      name="course"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Region"
                      cacheOptions
                      getOptionLabel={e => e.regionName}
                      getOptionValue={e => e.value}
                      isClearable
                      options={regions}
                      styles={colourStyles}
                      onChange={handleRegion}
                    />
                  </Col>
                </Row>
                <Row>
                  <h6 className="mt-3 mb-4 fw-bold">Add</h6>
                  <div className="">
                    <Table className="table table-hover mb-0 table-centered  mb-3">
                      <thead className="table-light">
                        <tr>
                          <th>No.</th>
                          <th>Round</th>
                          <th>QTY</th>

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>

                          <td
                            style={{
                              width: "300px",
                            }}
                          >
                            <Select
                              name=""
                              className="mb-2"
                              classNamePrefix="select2-selection"
                              placeholder="Select Round"
                              cacheOptions
                              getOptionLabel={e => e.value}
                              getOptionValue={e => e.value}
                              value={
                                roundList?.find(
                                  data => roundInfo?.roundId === data?._id
                                ) || {}
                              }
                              // options={roundList.filter(
                              //   data => !addedStock.includes(data?.value)
                              // )}
                              options={roundFilter(roundList)}
                              onChange={e => {
                                setRoundInfo({
                                  ...roundInfo,
                                  roundNum: e?.value || "",
                                  roundId: e?._id || "",
                                })
                              }}
                              isClearable
                            />
                          </td>

                          <td>
                            <Input
                              type="number"
                              style={{
                                width: "100px",
                                height: "38px",
                              }}
                              value={roundInfo?.totalDemand}
                              onChange={e => {
                                handleDemandData(e)
                              }}
                            ></Input>
                          </td>

                          <td>
                            <img
                              src={addCircle}
                              alt=""
                              style={{ cursor: "pointer" }}
                              className="img-fluid"
                              onClick={() => handleAddProductInput()}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Row>
                <Row>
                  <Table className="table table-hover mb-0 table-centered  mb-5">
                    <thead className="table-light">
                      <tr>
                        <th>No.</th>
                        <th>Round No</th>
                        <th>Total Demand</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {false ? (
                        <tr>
                          <td colSpan="5">
                            <h6 className="text-center">Loading...</h6>
                          </td>
                        </tr>
                      ) : (
                        (singleRound || []).map((data, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>

                              <td>{data?.roundNum}</td>
                              <td>{data?.totalDemand}</td>

                              <td>
                                <img
                                  src={closeCircle}
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                  className="img-fluid"
                                  onClick={() => handleRemoveProduct(data)}
                                />
                              </td>
                            </tr>
                          )
                        })
                      )}
                    </tbody>
                  </Table>
                </Row>
                {false ? (
                  <div className="d-flex justify-content-end mt-3">
                    <Spinner className="ms-2" color="primary" />
                  </div>
                ) : (
                  <div className="d-flex justify-content-end mt-3">
                    <input
                      type="submit"
                      disabled={adding}
                      value={adding ? "Submitting" : "Add Demand"}
                      className="btn btn-primary "
                    />
                  </div>
                )}
              </Form>
            </AddCardComponent>
          </Col>
        </Row>
        <div className="inventory-main-area">
          <div className="inventory-title">
            <h4>Market Demand</h4>
          </div>
          <div className="inventody-item-main-area-wrap-admin">
            <div className="inventory-item-area-admin">
              <div className="inventory-single-item-admin-1">
                <p>Total demand last round, in lac</p>
              </div>
              <div className="inventory-single-item-admin-2">
                <FormSelect
                  name="USA"
                  id="hdb"
                  onClick={e => setMarketData(e.target.value)}
                >
                  <option value="select">Select a region</option>
                  {regions?.map((region, i) => (
                    <option value={region._id}>{region?.regionName}</option>
                  ))}
                </FormSelect>
              </div>
              <div className="inventory-single-item-admin-3">
                <p>
                  {" "}
                  <input type="Number" onKeyPress={handleKeyPress} />
                </p>
              </div>
            </div>{" "}
          </div>
          <div>
            <Row>
              <Col className="col-10">
                <Card>
                  <CardTitle className="h4">
                    Estimate the total market Share Last Round
                  </CardTitle>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      noBottomColumns
                      striped
                      data={userdata}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default MarketDemand
