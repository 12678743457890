export const GET_TECH_PURCHASE = "GET_TECH_PURCHASE";
export const GET_TECH_PURCHASE_SUCCESS = "GET_TECH_PURCHASE_SUCCESS";
export const GET_TECH_PURCHASE_FAIL = "GET_TECH_PURCHASE_FAIL";

export const GET_TECH_UPGRADE = "GET_TECH_UPGRADE";
export const GET_TECH_UPGRADE_SUCCESS = "GET_TECH_UPGRADE_SUCCESS";
export const GET_TECH_UPGRADE_FAIL = "GET_TECH_UPGRADE_FAIL";


export const GET_FEATURE_PURCHASE = "GET_FEATURE_PURCHASE";
export const GET_FEATURE_PURCHASE_SUCCESS = "GET_FEATURE_PURCHASE_SUCCESS";
export const GET_FEATURE_PURCHASE_FAIL = "GET_FEATURE_PURCHASE_FAIL";

export const GET_FEATURE_UPGRADE = "GET_FEATURE_UPGRADE";
export const GET_FEATURE_UPGRADE_SUCCESS = "GET_FEATURE_UPGRADE_SUCCESS";
export const GET_FEATURE_UPGRADE_FAIL = "GET_FEATURE_UPGRADE_FAIL";


export const GET_CONTRACT_PRODUCTION = "GET_CONTRACT_PRODUCTION";
export const GET_CONTRACT_PRODUCTION_SUCCESS = "GET_CONTRACT_PRODUCTION_SUCCESS";
export const GET_CONTRACT_PRODUCTION_FAIL = "GET_CONTRACT_PRODUCTION_FAIL";

export const POST_TECH_UPGRADE = "POST_TECH_UPGRADE";
export const POST_TECH_UPGRADE_SUCCESS = "POST_TECH_UPGRADE_SUCCESS";
export const POST_TECH_UPGRADE_FAIL = "POST_TECH_UPGRADE_FAIL";

export const POST_TECH_PURCHASE = "POST_TECH_PURCHASE";
export const POST_TECH_PURCHASE_SUCCESS = "POST_TECH_PURCHASE_SUCCESS";
export const POST_TECH_PURCHASE_FAIL = "POST_TECH_PURCHASE_FAIL";

export const POST_FEATURE_UPGRADE = "POST_FEATURE_UPGRADE";
export const POST_FEATURE_UPGRADE_SUCCESS = "POST_FEATURE_UPGRADE_SUCCESS";
export const POST_FEATURE_UPGRADE_FAIL = "POST_FEATURE_UPGRADE_FAIL";


export const POST_FEATURE_PURCHASE = "POST_FEATURE_PURCHASE";
export const POST_FEATURE_PURCHASE_SUCCESS = "POST_FEATURE_PURCHASE_SUCCESS";
export const POST_FEATURE_PURCHASE_FAIL = "POST_FEATURE_PURCHASE_FAIL";

export const UPDATE_TECH_PURCHASE = "UPDATE_TECH_PURCHASE";
export const UPDATE_TECH_PURCHASE_SUCCESS = "UPDATE_TECH_PURCHASE_SUCCESS";
export const UPDATE_TECH_PURCHASE_FAIL = "UPDATE_TECH_PURCHASE_FAIL";

export const UPDATE_TECH_UPGRADE = "UPDATE_TECH_UPGRADE";
export const UPDATE_TECH_UPGRADE_SUCCESS = "UPDATE_TECH_UPGRADE_SUCCESS";
export const UPDATE_TECH_UPGRADE_FAIL = "UPDATE_TECH_UPGRADE_FAIL";

export const UPDATE_FEATURE_PURCHASE = "UPDATE_FEATURE_PURCHASE";
export const UPDATE_FEATURE_PURCHASE_SUCCESS = "UPDATE_FEATURE_PURCHASE_SUCCESS";
export const UPDATE_FEATURE_PURCHASE_FAIL = "UPDATE_FEATURE_PURCHASE_FAIL";

export const UPDATE_FEATURE_UPGRADE = "UPDATE_FEATURE_UPGRADE";
export const UPDATE_FEATURE_UPGRADE_SUCCESS = "UPDATE_FEATURE_UPGRADE_SUCCESS";
export const UPDATE_FEATURE_UPGRADE_FAIL = "UPDATE_FEATURE_UPGRADE_FAIL";

export const STORE_RnD_DATA = "STORE_RnD_DATA";