import {
  ADD_OR_UPDATE_TILL_SET_PRICE,
  ADD_OR_UPDATE_TILL_SET_PRICE_FAIL,
  ADD_OR_UPDATE_TILL_SET_PRICE_SUCCESS,
  GET_TILL_PRICE,
  GET_TILL_PRICE_SUCCESS,
  GET_STUDENT_MARKET_DATA_ON_TECH,
  GET_STUDENT_MARKET_DATA_ON_TECH_SUCCESS,
  GET_MARKETING_PRIORITIES_DATA,
  GET_MARKETING_PRIORITIES_DATA_SUCCESS,
  SET_PRIORITIES_FOR_MARKETING,
  SET_PRIORITIES_FOR_MARKETING_FAIL,
  SET_PRIORITIES_FOR_MARKETING_SUCCESS,
  GET_TAXION_DATA,
  GET_TAXION_DATA_SUCCESS,
  GET_TAXION_DATA_FAIL,
  CREATE_OR_UPDATE_MARKET_DATA_LAST_PART,
  CREATE_OR_UPDATE_MARKET_DATA_LAST_PART_FAIL,
  CREATE_OR_UPDATE_MARKET_DATA_LAST_PART_SUCCESS,
  GET_MARKETING_REGIONS_IN_INCOME_STATEMENT,
  GET_MARKETING_REGIONS_IN_INCOME_STATEMENT_SUCCESS,
  GO_TO_DECISION_CHECKLIST_PAGE_SUCCESS,
  GO_TO_DECISION_CHECKLIST_PAGE,
  GET_MARKETING_PRIORITIES_INDEX_SUCCESS,
  GET_MARKETING_PRIORITIES_INDEX,
  GO_TO_INCOME_STATEMENT,
  GO_TO_INCOME_STATEMENT_SUCCESS,
  DISABLE_TECH_BUTTON,
  DISABLE_TECH_BUTTON_SUCCESS,
  GET_MARKET_SHARE_OF_EACH,
  GET_MARKET_SHARE_OF_EACH_SUCCESS,
  NEW_SET_PRIORITIES_FOR_MARKETING,
  NEW_SET_PRIORITIES_FOR_MARKETING_SUCCESS,
  NEW_SET_PRIORITIES_FOR_MARKETING_FAIL,
  GET_PRIORITIES_PERCENTAGE_DATA_SUCCESS,
  GET_PRIORITIES_PERCENTAGE_DATA,
  GET_PRICING_MATRIX,
  GET_PRICING_MATRIX_SUCCESS,
} from "./actionTypes"

//addOrUpdateTillSetPrice
export const addOrUpdateTillSetPrice = (
  token,
  techId,
  setPrice,
  productionCostPerUnit,
  regionId,
  roundSerial,
  marketingStrategy,
  marketSize
) => ({
  type: ADD_OR_UPDATE_TILL_SET_PRICE,
  payload: {
    token,
    techId,
    setPrice,
    productionCostPerUnit,
    regionId,
    roundSerial,
    marketingStrategy,
    marketSize,
  },
})

//addOrUpdateTillSetPriceSuccess
export const addOrUpdateTillSetPriceSuccess = message => ({
  type: ADD_OR_UPDATE_TILL_SET_PRICE_SUCCESS,
  payload: { message },
})

//addOrUpdateTillSetPriceFail
export const addOrUpdateTillSetPriceFail = message => ({
  type: ADD_OR_UPDATE_TILL_SET_PRICE_FAIL,
  payload: { message },
})

//get till price
export const getTillPrice = (token, roundSerial) => ({
  type: GET_TILL_PRICE,
  payload: { token, roundSerial },
})

export const getTillPriceSuccess = data => ({
  type: GET_TILL_PRICE_SUCCESS,
  payload: { data },
})

export const getStudentMarketDataOnTech = (
  token,
  roundSerial,
  techId,
  universeId
) => ({
  type: GET_STUDENT_MARKET_DATA_ON_TECH,
  payload: { token, roundSerial, techId, universeId },
})

export const getStudentMarketDataOnTechSuccess = data => ({
  type: GET_STUDENT_MARKET_DATA_ON_TECH_SUCCESS,
  payload: { data },
})

export const getMarketingPrioritiesData = (
  token,
  roundSerial,
  teamId,
  universeId
) => ({
  type: GET_MARKETING_PRIORITIES_DATA,
  payload: { token, roundSerial, teamId, universeId },
})

export const getMarketingPrioritiesDataSuccess = data => ({
  type: GET_MARKETING_PRIORITIES_DATA_SUCCESS,
  payload: { data },
})

export const setPrioritiesForMarketing = (
  token,
  teamId,
  regionId,
  regionName,
  techId,
  techName,
  totalProduction,
  priorities,
  productionCostPerUnit,
  roundSerial,
  inventoryProduction,
  inventoryProductionCost,
  contractProduction,
  contractProductionCost
) => ({
  type: SET_PRIORITIES_FOR_MARKETING,
  payload: {
    token,
    teamId,
    regionId,
    regionName,
    techId,
    techName,
    totalProduction,
    priorities,
    productionCostPerUnit,
    roundSerial,
    inventoryProduction,
    inventoryProductionCost,
    contractProduction,
    contractProductionCost,
  },
})

export const setPrioritiesForMarketingSuccess = message => ({
  type: SET_PRIORITIES_FOR_MARKETING_SUCCESS,
  payload: { message },
})

export const setPrioritiesForMarketingFail = message => ({
  type: SET_PRIORITIES_FOR_MARKETING_FAIL,
  payload: { message },
})

//get taxion data
export const getTaxionData = (token, roundSerial, universeId) => ({
  type: GET_TAXION_DATA,
  payload: { token, roundSerial, universeId },
})
//get taxion data success
export const getTaxionDataSuccess = data => ({
  type: GET_TAXION_DATA_SUCCESS,
  payload: { data },
})

export const createOrUpdateMarketDataLastPart = (
  token,
  roundSerial,
  techId,
  regionName,
  setPrice,
  promotion,
  estimatedSalesInUnit,
  marketingStrategyType,
  strategyPoint,
  universeId
) => ({
  type: CREATE_OR_UPDATE_MARKET_DATA_LAST_PART,
  payload: {
    token,
    roundSerial,
    techId,
    regionName,
    setPrice,
    promotion,
    estimatedSalesInUnit,
    marketingStrategyType,
    strategyPoint,
    universeId,
  },
})
export const createOrUpdateMarketDataLastPartSuccess = message => ({
  type: CREATE_OR_UPDATE_MARKET_DATA_LAST_PART_SUCCESS,
  payload: { message },
})
export const createOrUpdateMarketDataLastPartFail = message => ({
  type: CREATE_OR_UPDATE_MARKET_DATA_LAST_PART_FAIL,
  payload: { message },
})

export const getMarketingStatementDataInIncomeStatement = (
  token,
  roundSerial,
  teamId,
  regionId,
  universeId
) => ({
  type: GET_MARKETING_REGIONS_IN_INCOME_STATEMENT,
  payload: { token, roundSerial, teamId, regionId, universeId },
})

export const getMarketingStatementDataInIncomeStatementSuccess = data => ({
  type: GET_MARKETING_REGIONS_IN_INCOME_STATEMENT_SUCCESS,
  payload: { data },
})

export const goToDecisionCheckListPage = (
  token,
  roundSerial,
  teamId,
  history,
  universeId,
  goNext = 1
) => ({
  type: GO_TO_DECISION_CHECKLIST_PAGE,
  payload: { token, roundSerial, teamId, history, universeId, goNext },
})

export const goToDecisionCheckListPageSuccess = data => ({
  type: GO_TO_DECISION_CHECKLIST_PAGE_SUCCESS,
  payload: { data },
})

export const getMarketingPrioritiesIndex = (
  token,
  roundSerial,
  universeId
) => ({
  type: GET_MARKETING_PRIORITIES_INDEX,
  payload: { token, roundSerial, universeId },
})

export const getMarketingPrioritiesIndexSuccess = data => ({
  type: GET_MARKETING_PRIORITIES_INDEX_SUCCESS,
  payload: { data },
})

export const goToIncomeStatement = (
  token,
  roundSerial,
  teamId,
  universeId,
  history,
  goNext = false
) => ({
  type: GO_TO_INCOME_STATEMENT,
  payload: { token, roundSerial, teamId, universeId, history, goNext },
})

export const goToIncomeStatementSuccess = data => ({
  type: GO_TO_INCOME_STATEMENT_SUCCESS,
  payload: { data },
})

//disable tech button
export const disableTechButton = (token, roundSerial, teamId) => ({
  type: DISABLE_TECH_BUTTON,
  payload: { token, roundSerial, teamId },
})

export const disableTechButtonSuccess = data => ({
  type: DISABLE_TECH_BUTTON_SUCCESS,
  payload: { data },
})

export const getMarketShareOfEach = (token, teamId, roundSerial, techId) => ({
  type: GET_MARKET_SHARE_OF_EACH,
  payload: { token, roundSerial, teamId, techId },
})

export const getMarketShareOfEachSuccess = data => ({
  type: GET_MARKET_SHARE_OF_EACH_SUCCESS,
  payload: { data },
})

export const getPricingMatrix = (token, universeId, roundSerial, techId) => ({
  type: GET_PRICING_MATRIX,
  payload: { token, universeId, roundSerial, techId },
})

export const getPricingMatrixSuccess = data => ({
  type: GET_PRICING_MATRIX_SUCCESS,
  payload: { data },
})
export const newSetPrioritiesForMarketing = (
  token,
  teamId,
  regionId,
  regionName,
  techId,
  techName,
  totalProduction,
  priorities,
  productionCostPerUnit,
  roundSerial,
  inventoryProduction,
  inventoryProductionCost,
  contractProduction,
  contractProductionCost,
  marketingRegion,
  universeId
) => ({
  type: NEW_SET_PRIORITIES_FOR_MARKETING,
  payload: {
    token,
    teamId,
    regionId,
    regionName,
    techId,
    techName,
    totalProduction,
    priorities,
    productionCostPerUnit,
    roundSerial,
    inventoryProduction,
    inventoryProductionCost,
    contractProduction,
    contractProductionCost,
    marketingRegion,
    universeId,
  },
})

export const newSetPrioritiesForMarketingSuccess = message => ({
  type: NEW_SET_PRIORITIES_FOR_MARKETING_SUCCESS,
  payload: { message },
})

export const newSetPrioritiesForMarketingFail = message => ({
  type: NEW_SET_PRIORITIES_FOR_MARKETING_FAIL,
  payload: { message },
})

export const getPrioritiesPercentageData = (
  token,
  teamId,
  roundSerial,
  universeId
) => ({
  type: GET_PRIORITIES_PERCENTAGE_DATA,
  payload: { token, teamId, roundSerial, universeId },
})

export const getPrioritiesPercentageDataSuccess = data => ({
  type: GET_PRIORITIES_PERCENTAGE_DATA_SUCCESS,
  payload: { data },
})
