import { call, put, takeEvery } from "redux-saga/effects"

import { axiosCreateTeacher, axiosGetTeachers } from "../../helpers/api_helper"
import { addTeacherSuccess, addTeacherFail, getTeacherSuccess } from "./actions"
import { ADD_TEACHER, GET_TEACHER } from "./actionTypes"

const asyncAddTeacehr = async (email, token) => {
  try {
    const response = await axiosCreateTeacher(
      email,

      token
    )

    console.log("geeting data", response.data)
    return response.data
  } catch (err) {
    console.log("error data", err.response.data)
    return err.response.data
  }
}

function* teachersaga({ payload: { values, history, token } }) {
  const { email } = values
  try {
    const response = yield call(
      asyncAddTeacehr,

      email,

      token
    )

    if (response.status == "success") {
      yield put(addTeacherSuccess(response.message))
      history.push("/teacherdata")
      yield put(addTeacherSuccess(""))
    } else {
      yield put(addTeacherFail(response.message))
      yield put(addTeacherFail(""))
    }
  } catch (error) {
    yield put(addTeacherFail(response.message))
  }
}

//axios
const asyncGetTeachers = async token => {
  try {
    const response = await axiosGetTeachers(token)
    console.log("response data", response.data)
    return response.data
  } catch (error) {
    console.log("error from fetch", error)
  }
}

//get teacherworker
function* getTeachersaga({ payload: token }) {
  console.log("token data", token)
  try {
    const getTeachers = yield call(asyncGetTeachers, token)
    console.log("get teachers us", getTeachers)

    yield put(getTeacherSuccess(getTeachers.getListOfDatas))
  } catch (err) {}
}

function* teacherAdminSaga() {
  yield takeEvery(ADD_TEACHER, teachersaga)
  yield takeEvery(GET_TEACHER, getTeachersaga)
}

export default teacherAdminSaga
