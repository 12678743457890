import {
  FEATURE_REMOVE_FROM_LIST,
  FEATURE_REMOVE_FROM_LIST_SUCCESS,
  GET_FEATURES_FOR_R_AND_D,
  GET_FEATURES_FOR_R_AND_D_SUCCESS,
  GET_FEATURE_ALREADY_IN_UPGRADE,
  GET_FEATURE_ALREADY_IN_UPGRADE_SUCCESS,
  GET_FEATURE_ENABLE,
  GET_FEATURE_ENABLE_SUCCESS,
  GET_IS_TECH_ENABLE,
  GET_IS_TECH_ENABLE_SUCCESS,
  GET_LATEST_TECH,
  GET_LATEST_TECH_SUCCESS,
  GET_PURCHASED_FEATURE,
  GET_PURCHASED_FEATURE_SUCCESS,
  PURCHASE_FEATURE,
  PURCHASE_FEATURE_BY_STUDENT,
  PURCHASE_FEATURE_BY_STUDENT_FAIL,
  PURCHASE_FEATURE_BY_STUDENT_SUCCESS,
  PURCHASE_FEATURE_FAIL,
  PURCHASE_FEATURE_SUCCESS,
  PURCHASE_TECH,
  PURCHASE_TECH_FAIL,
  PURCHASE_TECH_SUCCESS,
  UPGRADE_FEATURE,
  UPGRADE_FEATURE_BY_STUDENT,
  UPGRADE_FEATURE_BY_STUDENT_FAIL,
  UPGRADE_FEATURE_BY_STUDENT_SUCCESS,
  UPGRADE_FEATURE_FAIL,
  UPGRADE_FEATURE_SUCCESS,
  UPGRADE_TECH,
  UPGRADE_TECH_FAIL,
  UPGRADE_TECH_SUCCESS,
} from "./actionTypes"
const Initial_State = {
  latestTech: {},
  featuresRND: [],
  isTechEnable: true,
  featureEnableList: {},
  upgradedFeatures: [],
  purchasedFeatures: [],
  featureProcessing: 0,
  loading: false,
  message: "",
  error: "",
}
const studentRND = (state = Initial_State, action) => {
  switch (action.type) {
    case GET_FEATURES_FOR_R_AND_D:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_FEATURES_FOR_R_AND_D_SUCCESS:
      state = {
        ...state,
        featuresRND: action.payload.data,
      }
      break
    case UPGRADE_FEATURE_BY_STUDENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPGRADE_FEATURE_BY_STUDENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case UPGRADE_FEATURE_BY_STUDENT_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case GET_FEATURE_ALREADY_IN_UPGRADE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_FEATURE_ALREADY_IN_UPGRADE_SUCCESS:
      state = {
        ...state,
        loading: false,
        upgradedFeatures: action.payload.data,
      }
      break
    case PURCHASE_FEATURE_BY_STUDENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case PURCHASE_FEATURE_BY_STUDENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case PURCHASE_FEATURE_BY_STUDENT_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case GET_PURCHASED_FEATURE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_PURCHASED_FEATURE_SUCCESS:
      state = {
        ...state,
        loading: false,
        purchasedFeatures: action.payload.data,
      }
      break
    case GET_LATEST_TECH:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_LATEST_TECH_SUCCESS:
      state = {
        ...state,
        loading: false,
        latestTech: action.payload.data,
      }
      break
    case GET_IS_TECH_ENABLE:
      state = {
        ...state,
        loading: false,
      }
      break
    case GET_IS_TECH_ENABLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        isTechEnable: action.payload.data,
      }
      break
    case UPGRADE_TECH:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPGRADE_TECH_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case UPGRADE_TECH_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      }
      break
    case PURCHASE_TECH:
      state = {
        ...state,
        loading: true,
      }
      break
    case PURCHASE_TECH_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case PURCHASE_TECH_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case GET_FEATURE_ENABLE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_FEATURE_ENABLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        featureEnableList: action.payload.data,
        featureProcessing: action.payload.check,
      }
      break
    case UPGRADE_FEATURE:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPGRADE_FEATURE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case UPGRADE_FEATURE_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case PURCHASE_FEATURE:
      state = {
        ...state,
        loading: true,
      }
      break
    case PURCHASE_FEATURE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case PURCHASE_FEATURE_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case FEATURE_REMOVE_FROM_LIST:
      state = {
        ...state,
        loading: true,
      }
      break
    case FEATURE_REMOVE_FROM_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    default:
      state = state
      break
  }
  return state
}
export default studentRND
