import {
  GET_CAPACITY_BY_STUDENT,
  GET_CAPACITY_BY_STUDENT_SUCCESS,
  ADD_IN_HOUSE_MANUFACTURE,
  ADD_IN_HOUSE_MANUFACTURE_SUCCESS,
  ADD_IN_HOUSE_MANUFACTURE_FAIL,
  GET_IN_HOUSE_MANUFACTURE,
  GET_IN_HOUSE_MANUFACTURE_FAIL,
  GET_IN_HOUSE_MANUFACTURE_SUCCESS,
  ADD_CONTRACT_MANUFACTURE,
  ADD_CONTRACT_MANUFACTURE_FAIL,
  ADD_CONTRACT_MANUFACTURE_SUCCESS,
  GET_CONTRACT_MANUFACTURE_LAST_PART,
  GET_CONTRACT_MANUFACTURE_LAST_PART_SUCCESS,
  GET_PRODUCTION_REQUIREMENT,
  GET_PRODUCTION_REQUIREMENT_SUCCESS,
  GET_CAPACITY_MANUFACTURE,
  GET_CAPACITY_MANUFACTURE_FAIL,
  GET_CAPACITY_MANUFACTURE_SUCCESS,
  GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART,
  GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART_SUCCESS,
  GET_STUDENT_INVENTORY,
  GET_STUDENT_INVENOTRY_SUCCESS,
  GET_STUDENT_INVENTORY_FAIL,
} from "./actionTypes"

//getCapacityByStudent
export const getCapacityByStudent = (
  token,
  roundSerial,
  teamId,
  universeId
) => ({
  type: GET_CAPACITY_BY_STUDENT,
  payload: { token, roundSerial, teamId, universeId },
})

export const getCapacityByStudentSuccess = data => ({
  type: GET_CAPACITY_BY_STUDENT_SUCCESS,
  payload: { data },
})

//inhouse manufacture
export const addInhouseManufacture = (
  token,
  regionId,
  techId,
  techName,
  regionName,
  teamId,
  roundSerial,
  capacityAllocation,
  productionLevel,
  universeId
) => ({
  type: ADD_IN_HOUSE_MANUFACTURE,
  payload: {
    token,
    regionId,
    techId,
    techName,
    regionName,
    teamId,
    roundSerial,
    capacityAllocation,
    productionLevel,
    universeId,
  },
})

export const addInHouseManufactureSuccess = message => ({
  type: ADD_IN_HOUSE_MANUFACTURE_SUCCESS,
  payload: { message },
})

export const addInHouseManufactureFail = error => ({
  type: ADD_IN_HOUSE_MANUFACTURE_FAIL,
  payload: { error },
})

export const getInHouseManufactureData = (teamId, roundSerial, token) => ({
  type: GET_IN_HOUSE_MANUFACTURE,
  payload: { teamId, roundSerial, token },
})

export const getInHouseManufactureDataSuccess = data => ({
  type: GET_IN_HOUSE_MANUFACTURE_SUCCESS,
  payload: { data },
})

//addContractManufacture
export const addContractManufacture = (
  token,
  regionId,
  techId,
  techName,
  regionName,
  teamId,
  roundSerial,
  capacityAllocation,
  totalProductionCapacity,
  universeId
) => ({
  type: ADD_CONTRACT_MANUFACTURE,
  payload: {
    token,
    regionId,
    techId,
    techName,
    regionName,
    teamId,
    roundSerial,
    capacityAllocation,
    totalProductionCapacity,
    universeId,
  },
})

//add Contract Manufacture Success
export const addContractManufactureSuccess = message => ({
  type: ADD_CONTRACT_MANUFACTURE_SUCCESS,
  payload: { message },
})

export const addContractManufactureFail = message => ({
  type: ADD_CONTRACT_MANUFACTURE_FAIL,
  payload: { message },
})

export const getContractManufacture = (token, regionId, roundSerial) => ({
  type: GET_CONTRACT_MANUFACTURE,
  payload: { token, regionId, roundSerial },
})

export const getContractManufactureSuccess = data => ({
  type: GET_CONTRACT_MANUFACTURE_SUCCESS,
  payload: { data },
})

export const getProductionRequirement = (
  token,
  teamId,
  roundSerial,
  regionId,
  universeId
) => ({
  type: GET_PRODUCTION_REQUIREMENT,
  payload: { token, teamId, roundSerial, regionId, universeId },
})

export const getProductionRequirementSuccess = data => ({
  type: GET_PRODUCTION_REQUIREMENT_SUCCESS,
  payload: { data },
})

export const getCapacityManufacture = (token, roundSerial) => ({
  type: GET_CAPACITY_MANUFACTURE,
  payload: { token, roundSerial },
})

export const getCapacityManufactureSuccess = data => ({
  type: GET_CAPACITY_MANUFACTURE_SUCCESS,
  payload: { data },
})

export const getInHouseManufactureDataLastPart = (
  token,

  roundSerial,
  universeId
) => ({
  type: GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART,
  payload: { token, roundSerial, universeId },
})

export const getInHouseManufactureDataLastPartSuccess = data => ({
  type: GET_IN_HOUSE_MANUFACTURE_DATA_LAST_PART_SUCCESS,
  payload: { data },
})

export const getContractManufactureDataLastPart = (
  token,

  roundSerial,
  universeId
) => ({
  type: GET_CONTRACT_MANUFACTURE_LAST_PART,
  payload: { token, roundSerial, universeId },
})

export const getContractManufactureDataLastPartSuccess = data => ({
  type: GET_CONTRACT_MANUFACTURE_LAST_PART_SUCCESS,
  payload: { data },
})

export const getStudentInventory = (token, roundSerial, teamId) => ({
  type: GET_STUDENT_INVENTORY,
  payload: { token, roundSerial, teamId },
})

export const getStudentInventorySuccess = data => ({
  type: GET_STUDENT_INVENOTRY_SUCCESS,
  payload: { data },
})
