 import { call, put, takeEvery } from "redux-saga/effects"

 import { addUniverstiyNameError, addUniverstiyNameSuccess, getUniverstiyNameSuccess } from "./actions"

 import { ADD_UNIVERSITY_NAME, GET_UNIVERSITY_NAME } from "./actionTypes"

 import { axiosCreateUniversity, axiosGetUniversityName } from "../../helpers/api_helper"

 const asyncUniversityNameCreate = async(universityName, token) => {
     try {

         const response = await axiosCreateUniversity(universityName, token)
         console.log("response data", response.data)
         return response.data

     } catch (err) {
         console.log(" Error response data", err.response.data)
         return err.response.data

     }

 }

 function* universtiyNameSaga({ payload }) {
     const { universityName } = payload.values
     const { token } = payload
     try {

         const response = yield call(asyncUniversityNameCreate, universityName, token)

         console.log("data", response.message)
         if (response.status == "success") {
             yield put(addUniverstiyNameSuccess(response.message))

             yield put(addUniverstiyNameSuccess(""))
         } else {
             console.log("response message", response.message)
             yield put(addUniverstiyNameError(response.message))
             yield put(addUniverstiyNameError(""))
         }

     } catch (error) {
         yield put(addUniverstiyNameError(error))

     }


 }

 const asyncUniversityNameGet = async(token) => {
     try {
         const response = await axiosGetUniversityName(token)
         console.log("hello universkity")
         console.log("response data", response.data)

         return response.data
     } catch (error) {
         console.log(error.resposne.data)
     }

 }

 function* getUniversityNameSaga({ payload }) {

     try {
         const response = yield call(asyncUniversityNameGet, payload)

         console.log("ubi", response.listOfUniversityNames)
         if (response.status == "success") {
             yield put(getUniverstiyNameSuccess(response.listOfUniversityNames))
         }

     } catch (err) {

         console.log("some thing error")
     }
 }

 function* UniversitySaga() {
     yield takeEvery(ADD_UNIVERSITY_NAME, universtiyNameSaga)
     yield takeEvery(GET_UNIVERSITY_NAME, getUniversityNameSaga)
 }

 export default UniversitySaga