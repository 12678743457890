import {
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_FAIL,
  ADD_NEW_USER,
  GET_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  LOADING_ADD_USER,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  user: {},
  error: {},
  loading: false,
  isLoading: false,
}

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: true,
      }
    case ADD_NEW_USER: {
      return {
        ...state,
        error: {},
        isLoading: true,
      }
    }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      }

    case GET_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        //change here
        loading: false,
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        isLoading: false,
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: true,
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          product => product.id.toString() !== action.payload.id.toString()
        ),
        loading: true,
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case LOADING_ADD_USER:
      return {
        ...state,
        isLoading: action.payload,
      }

    default:
      return state
  }
}

export default UserReducer
