import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { CardBody } from "reactstrap"
import inputBackground from "../img/OBJECTS 1.png"
import { getRoundData } from "./../../../store/roundAdmin/actions"
import RoundEmptyPage from "./RoundEmptyPage"
import RoundHeader from "./RoundHeader"

const FundamentalRound = () => {
  const { token, loading, rounds, message } = useSelector(state => ({
    token: state.Login.token,
    loading: state.roundData.loading,
    rounds: state.roundData.rounds,
    message: state.roundData.message,
  }))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getRoundData(token))
  }, [])
  return (
    <div className="fundamental-round-main-wrap">
      <Container>
        <RoundHeader />
        {!rounds && (
          <Row>
            <RoundEmptyPage />
          </Row>
        )}

        <Row>
          <Col className="col-12">
            <div
              className="input-tech-data-area"
              // style={{ backgroundImage: `url(${})` }}
              //   style={{ backgroundImage: `url(${inputBackground})` }}
            >
              <CardBody className="p-4 ">
                <div className="p-3">
                  <label for="myfile">
                    <img src={inputBackground} alt="" />
                  </label>
                  <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    id="myfile"
                    name="UploadTechdata"
                    // onChange={techDataUpload}
                  />
                </div>
              </CardBody>
            </div>
          </Col>
        </Row>
        <div className="fundamental-items-main-area">
          <Row>
            <Col lg={12}>
              <div className="single-course-data fundamental">
                {/* {console.log(item)} */}
                {rounds?.map((v, i) => (
                  <div className="technology-data-single-item-area fundamental">
                    <div className="technology-data-single-item-area-left fundamental">
                      <p>Round </p>
                    </div>
                    <div className="technology-data-single-item-area-right fundamental">
                      <p>{v.roundName}</p>
                    </div>{" "}
                    <div className="technology-data-single-item-area-right-edit-btn fundamental">
                      <NavLink to="/admin-edit-technology">
                        <p>
                          <i className="bx bx-edit-alt"></i>
                        </p>
                      </NavLink>
                    </div>
                  </div>
                ))}{" "}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default FundamentalRound
