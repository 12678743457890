import { GET_CONTRACT_PRODUCTION, GET_CONTRACT_PRODUCTION_FAIL, GET_CONTRACT_PRODUCTION_SUCCESS, GET_IN_HOUSE_PRODUCTION, GET_IN_HOUSE_PRODUCTION_FAIL, GET_IN_HOUSE_PRODUCTION_SUCCESS, POST_CONTRACT_PRODUCTION, POST_CONTRACT_PRODUCTION_FAIL, POST_CONTRACT_PRODUCTION_SUCCESS, POST_IN_HOUSE_PRODUCTION, POST_IN_HOUSE_PRODUCTION_FAIL, POST_IN_HOUSE_PRODUCTION_SUCCESS, STORE_PRODUCTION_CAPACITY, UPDATE_CONTRACT_PRODUCTION, UPDATE_CONTRACT_PRODUCTION_FAIL, UPDATE_CONTRACT_PRODUCTION_SUCCESS, UPDATE_IN_HOUSE_PRODUCTION, UPDATE_IN_HOUSE_PRODUCTION_FAIL, UPDATE_IN_HOUSE_PRODUCTION_SUCCESS } from "./actionTypes";

export const postInHouseProduction = (values, history, token) => {
    return {
      type: POST_IN_HOUSE_PRODUCTION,
      payload: { values, history, token},
    };
  };
  export const postInHouseProductionSuccess = (message) => {
    return {
      type: POST_IN_HOUSE_PRODUCTION_SUCCESS,
      payload: { message },
    };
  };
  
  export const postInHouseProductionFail = (message) => {
    return {
      type: POST_IN_HOUSE_PRODUCTION_FAIL,
      payload: { message },
    };
  };

  export const postContractProduction = (values, history, token) => {
    return {
      type: POST_CONTRACT_PRODUCTION,
      payload: { values, history, token},
    };
  };
  export const postContractProductionSuccess = (message) => {
    return {
      type: POST_CONTRACT_PRODUCTION_SUCCESS,
      payload: { message },
    };
  };
  
  export const postContractProductionFail = (message) => {
    return {
      type: POST_CONTRACT_PRODUCTION_FAIL,
      payload: { message },
    };
  };
  export const updateInHouseProduction = (values, history, token, groupId, singleId, courseInfo, regionInfo, techID) => {
    return {
      type: UPDATE_IN_HOUSE_PRODUCTION,
      payload: { values, history, token, groupId, singleId, courseInfo, regionInfo, techID},
    };
  };
  export const updateInHouseProductionSuccess = (message) => {
    return {
      type: UPDATE_IN_HOUSE_PRODUCTION_SUCCESS,
      payload: { message },
    };
  };
  
  export const updateInHouseProductionFail = (message) => {
    return {
      type: UPDATE_IN_HOUSE_PRODUCTION_FAIL,
      payload: { message },
    };
  };

  export const updateContractProduction = (values, history, token, groupId, singleId, courseInfo, regionInfo, techID) => {
    return {
      type: UPDATE_CONTRACT_PRODUCTION,
      payload: { values, history, token, groupId, singleId, courseInfo, regionInfo, techID},
    };
  };
  export const updateContractProductionSuccess = (message) => {
    return {
      type: UPDATE_CONTRACT_PRODUCTION_SUCCESS,
      payload: { message },
    };
  };
  
  export const updateContractProductionFail = (message) => {
    return {
      type: UPDATE_CONTRACT_PRODUCTION_FAIL,
      payload: { message },
    };
  };


  export const getInHouseProduction = (token, course, region, tech) => {
    return {
      type: GET_IN_HOUSE_PRODUCTION,
      payload: { token, course, region, tech },
    };
  };
  
  export const getInHouseProductionSuccess = (values) => {
    return {
      type: GET_IN_HOUSE_PRODUCTION_SUCCESS,
      payload: { values },
    };
  };
  
  export const getInHouseProductionFail = (message) => {
    return {
      type: GET_IN_HOUSE_PRODUCTION_FAIL,
      payload: { message },
    };
  };
  export const getContractProduction = (token, course, region, tech) => {
    return {
      type: GET_CONTRACT_PRODUCTION,
      payload: { token, course, region, tech },
    };
  };
  
  export const getContractProductionSuccess = (values) => {
    return {
      type: GET_CONTRACT_PRODUCTION_SUCCESS,
      payload: { values },
    };
  };
  
  export const getContractProductionFail = (message) => {
    return {
      type: GET_CONTRACT_PRODUCTION_FAIL,
      payload: { message },
    };
  };


  export const storeProductionCapacity = (name, data) => ({
    type: STORE_PRODUCTION_CAPACITY,
    payload: { name, data },
  });