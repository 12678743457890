import {
  all,
  call,
  delay,
  put,
  takeLatest,
  takeEvery,
} from "redux-saga/effects"

import { ADD_ROUND_DATA, GET_ROUND } from "./actionTypes"
import {
  addRoundDataFail,
  addRoundDataSuccess,
  getRoundDataSuccess,
} from "./actions"

import { axiosAddRoundData, axiosGetRounds } from "../../helpers/api_helper"
import { toast } from "react-toastify"

//asyncRoundData
const asyncGetRoundData = async token => {
  try {
    const response = await axiosGetRounds(token)
    console.log("response data from 24 all round", response.data)
    return response.data
  } catch (error) {
    console.log("error data", error.response.data)
    return error.response.data
  }
}
//get Round data
export function* workerGetRoundData({ payload: { token } }) {
  console.log("token data", token)
  try {
    const response = yield call(asyncGetRoundData, token)
    yield put(getRoundDataSuccess(response.getDatas))
    
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncAddRoundData = async (token, roundName) => {
  try {
    const response = await axiosAddRoundData(token, roundName)
    console.log("round result", response.data)
    return response.data
  } catch (error) {
    console.log("error data", error.response.data)
    return error.response.data
  }
}
export function* workerAddRoundData({ payload: { value, token } }) {
  console.log("add round data", value, token)
  const { roundName } = value
  try {
    const response = yield call(asyncAddRoundData, token, roundName)

    console.log("round data from here", response)
    if (response.status == "success") {
      yield put(addRoundDataSuccess(response.message))
      toast.success(response.message)
      // yield takeEvery(GET_ROUND, workerGetRoundData)
    } else {
      yield put(addRoundDataFail(response.message))
      toast.error(response.message)
    }
  } catch (error) {}
}

function* roundAdminSaga() {
  yield takeEvery(ADD_ROUND_DATA, workerAddRoundData)
  yield takeEvery(GET_ROUND, workerGetRoundData)
}

export default roundAdminSaga
