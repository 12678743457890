import React, { useState, useEffect } from "react"
import { Container, FormSelect } from "react-bootstrap"
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  Row,
  Form,
  Label,
  Table,
  Input,
  Button,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  getRegions,
  addMarketShareLastRound,
  adminGetMarketShare,
  getAvailableTechAdmin,
  postTotalDemand,
} from "store/actions"
import { MDBDataTable } from "mdbreact"
import addCircle from "../../assets/images/add-circle.png"
import closeCircle from "../../assets/images/close-circle.png"
import CustomInput from "common/CustomFilter/CustomInput"
import AddCardComponent from "common/Layout/AddCardComponent"
import { useFormik } from "formik"
import { addUserSchema } from "pages/Schemas/AddUser.schema"
import { getCourse } from "../../store/actions"
import Select from "react-select"
import { toaster } from "helpers/custom/Toast"
import {
  getContractProduction,
  postContractProduction,
  postInHouseProduction,
} from "store/ProductionCapacity/actions"
const initialValues = {}
const roundList = [
  {
    value: 0,
    _id: "64923741097",
  },
  {
    value: 1,
    _id: "64923748903",
  },
  {
    value: 2,
    _id: "64923744571",
  },
  {
    value: 3,
    _id: "64923709021",
  },
  {
    value: 4,
    _id: "64923701239",
  },
  {
    value: 5,
    _id: "64923782392",
  },
  {
    value: 6,
    _id: "64923785315",
  },
  {
    value: 7,
    _id: "64923787642",
  },
]
const ContractProduction = ({ edit }) => {
  const [regionData, setRegionData] = useState({})
  const [initialCapital, setInitialCapital] = useState()
  const [marketData, setMarketData] = useState()
  const [techData, setTechData] = useState()
  const [regionInfo, setRegionInfo] = useState("")
  const [courseInfo, setCourseInfo] = useState("")
  const [techName, setTechName] = useState("")
  const [techID, setTechID] = useState("")
  const [roundInfo, setRoundInfo] = useState({
    roundNum: "",
    roundId: "",
    productionCost: "",
    techName: "",
    techID: "",
  })
  const [singleRound, setSingleRound] = useState([])
  const {
    token,

    regions,

    techs,

    modifiedContractProduction,
    courses,
    adding
  } = useSelector(state => ({
    token: state.Login.token,

    regions: state.regionData.regions,
    message: state.adminDemandData.message,

    techs: state.techData.adminAvailableTech,
    modifiedContractProduction:
      state.productionCapacityReducer.modifiedContractProduction,
    courses: state.courseAdmin.courses,
    adding:state.productionCapacityReducer.adding,
  }))
  console.log("roundInfo", roundInfo)
  const history = useHistory()
  const dispatch = useDispatch()
  let value = {}
  value.roundSerial = 0
  useEffect(() => {
    dispatch(getRegions(token))
    dispatch(getAvailableTechAdmin(token))
  }, [])
  useEffect(() => {
    dispatch(adminGetMarketShare(token))
  }, [])

  useEffect(() => {
    dispatch(getCourse(token))
  }, [])

  const onSubmit = values => {
    if (courseInfo === "" || regionInfo === "" || techID === "") {
      return toaster("warning", "Please fill all the fields")
    }
    const RoundArray = singleRound.map(data => ({
      productionCost: data?.productionCost,
      roundSerial: data?.roundNum,
    }))
    let obj = {
      techName: techName,
      tech: techID,
      course: courseInfo,
      region: regionInfo,
      productionCostArr: RoundArray,
    }
    console.log("obj", obj)
    if (obj?.productionCostArr?.length === 0) {
      return toaster("warning", "Please fill all the fields")
    } else {
      dispatch(postContractProduction(obj, history, token))
    }
   
  }

  const AddUser = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addUserSchema,
    onSubmit,
  })
  const addedStock = singleRound.map(data => data?.roundNum)
  const handleDemandData = e => {
    console.log("e", e.target.value)
    setRoundInfo({
      ...roundInfo,
      productionCost: e.target.value,
    })
  }
  const handleAddProductInput = () => {
    if (roundInfo.productionCost === "" || roundInfo.roundNum < -1) {
      return toaster("warning", "Please fill all the fields")
    }
    if (roundInfo.productionCost * 1 <= 0) {
      return toaster("warning", "Stock Can not be negative or zero")
    }
    let data = {
      productionCost: roundInfo?.productionCost * 1,
      roundNum: roundInfo?.roundNum * 1,
      roundId: roundInfo?.roundId,
    }
    console.log("data", data)
    setSingleRound([...singleRound, data])
    setRoundInfo({
      productionCost: "",
      roundNum: "",
    })
  }
  const handleRemoveProduct = data => {
    console.log("remove", data)
    const removeProd = singleRound.filter(
      prod => data?.roundId !== prod?.roundId
    )
    console.log("removeProd", removeProd)
    setSingleRound(removeProd)
  }
  const handleCourse = data => {
    console.log("data", data)
    if (data) {
      setCourseInfo(data?._id)
    } else {
      setCourseInfo("")
    }
  }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      }
    },
  }
  const handleRegion = data => {
    console.log("data", data)
    if (data) {
      setRegionInfo(data?._id)
    } else {
      setRegionInfo("")
    }
  }
  const handletech = data => {
    console.log("data", data)
    if (data) {
      setTechID(data?._id)
      setTechName(data?.techName)
    }
  }
  useEffect(() => {
    if (regionInfo && courseInfo && techID) {
      dispatch(getContractProduction(token, courseInfo, regionInfo, techID))
    }
  }, [courseInfo, regionInfo, techID])
  const roundFilter = data => {
    let result = data
    if (modifiedContractProduction) {
      const addedRound = modifiedContractProduction.map(
        data => data?.roundSerial
      )

      result = data?.filter(el => !addedRound.includes(el?.value))
    }
    if (addedStock.length) {
      result = result.filter(data => !addedStock.includes(data?.value))
    }

    return result
  }
  return (
    <div>
      <Container>
        <Row className="mt-4">
          <Col md="9" className="mx-auto">
            {/* add User */}

            <div className="text-end mb-3">
              <Button
                color="info"
                onClick={() => history.push("/update-contract-production")}
              >
                Update Contract Production
              </Button>
            </div>
            <AddCardComponent className="">
              <h5>Contract production</h5>
              <Form
                className="needs-validation col-md-12"
                onSubmit={AddUser.handleSubmit}
              >
                <Row>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Courses</Label>
                    <Select
                      name="course"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Course"
                      cacheOptions
                      getOptionLabel={e => e.courseName}
                      getOptionValue={e => e.value}
                      isClearable
                      options={courses}
                      styles={colourStyles}
                      onChange={handleCourse}
                    />
                  </Col>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Region</Label>
                    <Select
                      name="course"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Region"
                      cacheOptions
                      getOptionLabel={e => e.regionName}
                      getOptionValue={e => e.value}
                      isClearable
                      options={regions}
                      styles={colourStyles}
                      onChange={handleRegion}
                    />
                  </Col>

                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Tech</Label>
                    <Select
                      name="tech"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Tech"
                      cacheOptions
                      getOptionLabel={e => e.techName}
                      getOptionValue={e => e.value}
                      options={techs}
                      isClearable
                      styles={colourStyles}
                      onChange={handletech}
                    />
                  </Col>
                </Row>
                <Row>
                  <h6 className="mt-3 mb-4 fw-bold">Add</h6>
                  <div className="">
                    <Table className="table table-hover mb-0 table-centered  mb-3">
                      <thead className="table-light">
                        <tr>
                          <th>No.</th>
                          <th>Round</th>

                          <th>QTY</th>

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>

                          <td
                            style={{
                              width: "300px",
                            }}
                          >
                            <Select
                              name=""
                              className="mb-2"
                              classNamePrefix="select2-selection"
                              placeholder="Select Round"
                              cacheOptions
                              getOptionLabel={e => e.value}
                              getOptionValue={e => e.value}
                              value={
                                roundList?.find(
                                  data => roundInfo?.roundId === data?._id
                                ) || {}
                              }
                              options={roundFilter(roundList)}
                              onChange={e => {
                                setRoundInfo({
                                  ...roundInfo,
                                  roundNum: e?.value || "",
                                  roundId: e?._id || "",
                                })
                              }}
                              isClearable
                            />
                          </td>

                          <td>
                            <Input
                              type="number"
                              style={{
                                width: "100px",
                                height: "38px",
                              }}
                              value={roundInfo?.productionCost}
                              onChange={e => {
                                handleDemandData(e)
                              }}
                            ></Input>
                          </td>

                          <td>
                            <img
                              src={addCircle}
                              alt=""
                              style={{ cursor: "pointer" }}
                              className="img-fluid"
                              onClick={() => handleAddProductInput()}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Row>
                <Row>
                  <Table className="table table-hover mb-0 table-centered  mb-5">
                    <thead className="table-light">
                      <tr>
                        <th>No.</th>
                        <th>Round No</th>
                        <th>Tech</th>
                        <th>Total Demand</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {false ? (
                        <tr>
                          <td colSpan="5">
                            <h6 className="text-center">Loading...</h6>
                          </td>
                        </tr>
                      ) : (
                        (singleRound || []).map((data, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>

                              <td>{data?.roundNum}</td>
                              <td>{data?.techName}</td>
                              <td>{data?.productionCost}</td>

                              <td>
                                <img
                                  src={closeCircle}
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                  className="img-fluid"
                                  onClick={() => handleRemoveProduct(data)}
                                />
                              </td>
                            </tr>
                          )
                        })
                      )}
                    </tbody>
                  </Table>
                </Row>
                {false ? (
                  <div className="d-flex justify-content-end mt-3">
                    <Spinner className="ms-2" color="primary" />
                  </div>
                ) : (
                  <div className="d-flex justify-content-end mt-3">
                    <input
                    disabled={adding}
                      type="submit"
                      value={adding ?"Submitting" :"Add Contract production"}
                      className="btn btn-success "
                    />
                  </div>
                )}
              </Form>
            </AddCardComponent>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ContractProduction
