import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_MARKET_OUTLOOK,
  GET_MARKET_OUTLOOKS,
  GET_SINGLE_MARKET_OUTLOOK,
  UPDATE_MARKET_OUTLOOK,
  STUDENT_GET_SINGLE_MARKET_OUTLOOK,
} from "./actionTypes"

import {
  addMarketOutlookSuccess,
  addMarketOutlookFail,
  getMarketOutlooksSuccess,
  getSingleMarketOutlookSuccess,
  getSingleMarketOutlookFail,
  studentGetSingleMarketOutlookSuccess,
} from "./actions"
import { toast } from "react-toastify"
import {
  axiosAddMarketOutlook,
  axiosGetMarketOutlooks,
  axiosGetSingleMarketOutlook,
  axiosUpdateMarketOutlook,
  axiosStudentGetSingleMarketOutlook,
} from "../../helpers/api_helper"

const asyncStudentGetSingleMarketOutlook = async (roundIndex, token) => {
  try {
    const response = await axiosStudentGetSingleMarketOutlook(token, roundIndex)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerStudentGetSingleMarketOutlook({
  payload: { roundIndex, token },
}) {
  try {
    const response = yield call(
      asyncStudentGetSingleMarketOutlook,
      roundIndex,
      token
    )
    yield put(
      studentGetSingleMarketOutlookSuccess(response.getSingleMarketOutlookData)
    )
  } catch (error) {}
}

const asyncGetSingleMarketOutlook = async (marketOutlokId, token) => {
  try {
    const response = await axiosGetSingleMarketOutlook(token, marketOutlokId)
    console.log("response data", response.data)
    return response.data
  } catch (error) {
    console.log("error data", error.response.data)
    return error.response.data
  }
}

function* workerGetSingleMarketOutlook({ payload: { marketOutlokId, token } }) {
  try {
    const response = yield call(
      asyncGetSingleMarketOutlook,
      marketOutlokId,
      token
    )
    if (response.status == "success") {
      yield put(getSingleMarketOutlookSuccess(response.getMarketOutlookData))
    } else {
      yield put(getSingleMarketOutlookFail(response.message))
      toast.error(response.message)
    }
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncGetMarketOutlooks = async token => {
  console.log("token data")
  try {
    const response = await axiosGetMarketOutlooks(token)
    console.log("respnse data", response.data)
    return response.data
  } catch (error) {
    console.log("error response", error.response.data)
    return error.response.data
  }
}
function* workerGetMarketOutlooks({ payload }) {
  console.log("token data from 24", payload)
  try {
    const response = yield call(asyncGetMarketOutlooks, payload)
    console.log("log from 68", response)
    yield put(getMarketOutlooksSuccess(response.getMarketOutlookDatas))
  } catch (error) {
    // console.log("error data print", error)
  }
}
const asyncAddMarketOutlook = async (value, token) => {
  try {
    const { demand, finance, cost, roundIndex } = value
    const response = await axiosAddMarketOutlook(
      token,
      demand,
      finance,
      cost,
      roundIndex
    )
    console.log("response data print", response.data)
    return response.data
  } catch (error) {
    console.log("error response data", error.response.data)
    return error.response.data
  }
}
function* workerAddMarketOutlook({ payload: { value, token } }) {
  console.log("values data from 890 no line,", value, token)
  try {
    const response = yield call(asyncAddMarketOutlook, value, token)
    if (response.status == "success") {
      yield put(addMarketOutlookSuccess(response.message))
      toast.success(response.message)
      toast.success()
    } else {
      yield put(addMarketOutlookFail(response.message))
      toast.error(response.message)
    }
  } catch (error) {
    console.log("error data", error)
  }
}
function* marketOutlookSaga() {
  yield takeEvery(ADD_MARKET_OUTLOOK, workerAddMarketOutlook)
  yield takeEvery(GET_MARKET_OUTLOOKS, workerGetMarketOutlooks)
  yield takeEvery(GET_SINGLE_MARKET_OUTLOOK, workerGetSingleMarketOutlook)
  yield takeEvery(
    STUDENT_GET_SINGLE_MARKET_OUTLOOK,
    workerStudentGetSingleMarketOutlook
  )
}
export default marketOutlookSaga
