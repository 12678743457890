import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"

import {
  axiosGetCapacity,
  axiosAddOrUpdateCapacity,
  axiosAddOrUpdateInventory,
  axiosGetInventory,
  axiosGetOnlyProductionRegion,
  axiosAddCapacityWithTech,
} from "../../helpers/api_helper"
import {
  ADD_OR_UPDATE_CAPACITY,
  GET_CAPACITY,
  GET_CAPACITY_SUCCESS,
  ADD_OR_UPDATE_INVENTORY,
  GET_INVENTORY,
  GET_ONLY_PRODUCTION_REGION,
  ADD_CAPACITY_WITH_TECH,
} from "./actionTypes"
import {
  addOrUpdateCapacityFail,
  addOrUpdateCapacitySuccess,
  getCapacitySuccess,
  addOrUpdateInventorySuccess,
  addOrUpdateInventoryFail,
  getInventorySuccess,
  getOnlyProductionRegionSuccess,
  addCapacityWIthTechSuccess,
  addCapacityWithTechFail,
} from "./actions"

const asyncAddCapacityWithTech = async (token, techId, capacityId) => {
  try {
    const response = await axiosAddCapacityWithTech(token, capacityId, techId)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerAddCapacityWithTech({
  payload: { token, techId, capacityId },
}) {
  try {
    const response = yield call(
      asyncAddCapacityWithTech,
      token,
      techId,
      capacityId
    )
    if (response.status == "success") {
      toast.success(response.message)
      yield put(addCapacityWIthTechSuccess(response.message))
    } else {
      toast.error(response.message)
      yield put(addCapacityWithTechFail(response.message))
    }
  } catch (error) {
    console.log("error ", error.response.message)
  }
}

const asyncGetOnlyProductionregion = async token => {
  try {
    const response = await axiosGetOnlyProductionRegion(token, 0)
    console.log("response data from 67", response)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetOnlyProductionRegion({ payload: { token } }) {
  try {
    const response = yield call(asyncGetOnlyProductionregion, token)
    console.log("response data 41", response)
    yield put(getOnlyProductionRegionSuccess(response.getRegionData))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncGetInventory = async token => {
  try {
    const response = await axiosGetInventory(token)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerGetInventory({ payload: { token } }) {
  try {
    const response = yield call(asyncGetInventory, token)
    yield put(getInventorySuccess(response.getAll))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncAddOrUpdateInventory = async (
  token,
  techId,
  regionId,
  roundSerial,
  amount
) => {
  try {
    const response = await axiosAddOrUpdateInventory(
      token,
      techId,
      regionId,
      roundSerial,
      amount
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerAddOrUpdateInventory({
  payload: { token, techId, regionId, roundSerial, amount, history },
}) {
  try {
    const response = yield call(
      asyncAddOrUpdateInventory,
      token,
      techId,
      regionId,
      roundSerial,
      amount
    )

    if (response.status == "success") {
      yield put(addOrUpdateInventorySuccess(response.message))
      toast.success(response.message)
      history.push("/admin-production")
    } else {
      yield put(addOrUpdateInventoryFail(response.message))
    }
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncGetCapacity = async token => {
  try {
    const response = await axiosGetCapacity(token)
    console.log("response data", response)
    return response.data
  } catch (error) {
    console.log("error data")
    return error.response.data
  }
}

function* workerGetCapacity({ payload: { token } }) {
  try {
    const response = yield call(asyncGetCapacity, token)
    console.log("resposne data", response.getData)
    yield put(getCapacitySuccess(response.getData))
  } catch (error) {
    console.log("error data")
  }
}

//call axios addorupdatecapacity
const asyncAddOrUpdateCapacity = async (
  token,
  regionId,
  totalProductionCapacity
) => {
  try {
    console.log("region id from 142", regionId)
    const response = await axiosAddOrUpdateCapacity(
      token,
      regionId,
      totalProductionCapacity
    )

    console.log("responseof production", response.data)
    return response.data
  } catch (error) {
    console.log("error data", error.response.data)
    return error.response.data
  }
}

function* workerAddOrUpdateCapacity({
  payload: { token, regionId, totalProductionCapacity, history },
}) {
  try {
    console.log("hello region data", regionId)
    const response = yield call(
      asyncAddOrUpdateCapacity,
      token,
      regionId,
      totalProductionCapacity
    )

    if (response.status == "success") {
      yield put(addOrUpdateCapacitySuccess(response.message))
      history.push("/admin-production")
    } else {
      yield put(addOrUpdateCapacityFail(response.message))
    }
  } catch (error) {
    console.log("error data", error)
  }
}

function* AdminProductionSaga() {
  yield takeEvery(ADD_OR_UPDATE_CAPACITY, workerAddOrUpdateCapacity)
  yield takeEvery(GET_CAPACITY, workerGetCapacity)
  yield takeEvery(ADD_OR_UPDATE_INVENTORY, workerAddOrUpdateInventory)
  yield takeEvery(GET_INVENTORY, workerGetInventory)
  yield takeEvery(GET_ONLY_PRODUCTION_REGION, workerGetOnlyProductionRegion)
  yield takeEvery(ADD_CAPACITY_WITH_TECH, workerAddCapacityWithTech)
}

export default AdminProductionSaga
