import React, { useState, useEffect } from "react"
import { Container, FormSelect } from "react-bootstrap"
import { Col, Card, CardBody, CardTitle, Row, Form,  Label,
  Table,
  Input,
  Button, } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  getRegions,
  addMarketShareLastRound,
  adminGetMarketShare,
  getAvailableTechAdmin,
  postTotalDemand,
  getFeatureForRandD,
} from "store/actions"
import { MDBDataTable } from "mdbreact"
import addCircle from "../../assets/images/add-circle.png"
import closeCircle from "../../assets/images/close-circle.png"
import CustomInput from "common/CustomFilter/CustomInput"
import AddCardComponent from "common/Layout/AddCardComponent"
import { useFormik } from "formik"
import { addUserSchema } from "pages/Schemas/AddUser.schema"
import { getCourse } from "../../store/actions"
import Select from "react-select"
import { toaster } from "helpers/custom/Toast"
import { postPlantUpgrade } from "store/PlantUpgrade/actions"
import { getFeaturePurchase, getFeatureUpgrade, getTechPurchase, postTechPurchase, storeRnDData, updateFeatureUpgrade } from "store/AdminR&D/actions"
const initialValues = {}
const roundList = [
  {
    value: 0,
    _id: "64923741097",
  },
  {
    value: 1,
    _id: "64923748903",
  },
  {
    value: 2,
    _id: "64923744571",
  },
  {
    value: 3,
    _id: "64923709021",
  },
  {
    value: 4,
    _id: "64923701239",
  },
  {
    value: 5,
    _id: "64923782392",
  },
  {
    value: 6,
    _id: "64923785315",
  },
  {
    value: 7,
    _id: "64923787642",
  },
]
const UpdateFeatureUpgrade = ({edit}) => {

  const [techName, setTechName] = useState("")
  const [techID, setTechID] = useState("")
  const [featureName, setFeatureName] = useState("")
  const [featureID, setFeatureID] = useState("")
  const [courseInfo, setCourseInfo] = useState("")
  const [roundInfo, setRoundInfo] = useState({
    roundNum: "",
    roundId: "",
    secondRound: "",
    thirdRound: "",
  })
  const [singleRound, setSingleRound] = useState([])
  const {
    token,
   
    techs,
    modifiedTechPurchase,
    courses,
    featuresRND,
    modifiedFeatureUpgrade,
    adding, 
    featureUpgrade,
    featureUpgradeLoading
  } = useSelector(state => ({
    token: state.Login.token,
   
    message: state.adminDemandData.message,
    
    techs: state.techData.adminAvailableTech,
    featuresRND: state.studentRND.featuresRND,
    courses: state.courseAdmin.courses,
    modifiedFeatureUpgrade:  state.RnDReducer.modifiedFeatureUpgrade,
    adding: state.RnDReducer.adding,
    featureUpgrade: state.RnDReducer.featureUpgrade,
    featureUpgradeLoading: state.RnDReducer.featureUpgradeLoading,
  }))
  console.log('featureUpgrade', featureUpgrade);
  const history = useHistory()
  const dispatch = useDispatch()
  let value = {}
  value.roundSerial = 0
  useEffect(() => {
    dispatch(getRegions(token))
    dispatch(getAvailableTechAdmin(token))
  }, [])
  useEffect(() => {
    dispatch(adminGetMarketShare(token))
  }, [])

  useEffect(() => {
    dispatch(getCourse(token))
  }, [])



  const onSubmit = values => {
    console.log("values", values)
    
  }

  const AddUser = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addUserSchema,
    onSubmit,
  })



 

  const handleCourse = data => {
    console.log("data", data)
    if (data) {
      setCourseInfo(data?._id)
    } else {
      setCourseInfo("")
    }
  }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {

      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      };
    },
  };


  useEffect(() => {
  
    dispatch(getFeatureForRandD(token))
  }, [])
  useEffect(() => {
 
    if (featureID && courseInfo) {
      dispatch(getFeatureUpgrade(token, courseInfo, featureID))
    }
  }, [courseInfo, featureID])

  const handleFeature = data => {
    console.log("data", data)
    if (data) {
      setFeatureID(data?._id)
      setFeatureName(data?.featureName)
    }
  }
  const handleSubmitSecondEdit = (e, idx, data) => {
    const oldValue = [...modifiedFeatureUpgrade]
    console.log("oldValue", oldValue)

    oldValue[idx].second = e.target.value
    dispatch(storeRnDData("modifiedFeatureUpgrade", oldValue))
  }
  const handleSubmitThirdEdit = (e, idx, data) => {
    const oldValue = [...modifiedFeatureUpgrade]
    console.log("oldValue", oldValue)

    oldValue[idx].third = e.target.value
    dispatch(storeRnDData("modifiedFeatureUpgrade", oldValue))
  }

  const handleUpdateData = (key, data) => {
    console.log("data", data)
    let obj = {
      roundSerial: data?.roundSerial,
      second: data?.second * 1,
      third: data?.third * 1,
    }
    console.log("obj", obj)
    dispatch(
      updateFeatureUpgrade(
        obj,
        history,
        token,
        featureUpgrade?._id,
        data?._id,
        courseInfo,
        featureID
      )
    )
  }
  return (
    <div>
      <Container>
      <Row className="mt-4">
          <Col md="9" className="mx-auto">
          <div className="text-end mb-3">
              <Button
                color="info"
                onClick={() => history.push("/feature-upgrade")}
              >
                Add feature upgrade
              </Button>
            </div>
            <AddCardComponent className="">
           
            <h5 className="mb-5">Update Feature Upgrade</h5>
              <Form
                className="needs-validation col-md-12"
                onSubmit={AddUser.handleSubmit}
              >
                <Row>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Courses</Label>
                    <Select
                      name="course"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Course"
                      cacheOptions
                      getOptionLabel={e => e.courseName}
                      getOptionValue={e => e.value}
                      isClearable
                      options={courses}
                      styles={colourStyles}
                      onChange={handleCourse}
                    />
                  </Col>
                  <Col md="6 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Tech</Label>
                    <Select
                      name="tech"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Feature"
                      cacheOptions
                      getOptionLabel={e => e.featureName}
                      getOptionValue={e => e.value}
                      options={featuresRND}
                      isClearable
                      styles={colourStyles}
                      onChange={handleFeature}
                    />
                  </Col>
                </Row>
              
                <Row>
                  <Table className="table table-hover mb-0 table-centered  mb-5">
                    <thead className="table-light">
                      <tr>
                        <th>No.</th>
                        <th>Round No</th>
                        <th>second round</th>
                        <th>third round</th>
                    

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {courseInfo !== "" && featureID !== "" ? (
                        <>
                      {featureUpgradeLoading ? (
                        <tr>
                          <td colSpan="5">
                            <h6 className="text-center">Loading...</h6>
                          </td>
                        </tr>
                      ) : (
                        (modifiedFeatureUpgrade || []).map((data, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>

                              <td>{data?.roundSerial}</td>
                              <td>
                                    {" "}
                                    <Input
                                      type="number"
                                      style={{
                                        width: "100px",
                                        height: "38px",
                                      }}
                                      value={data?.second}
                                      onChange={e =>
                                        handleSubmitSecondEdit(e, key, data)
                                      }
                                    ></Input>
                                  </td>

                                  <td>
                                    {" "}
                                    <Input
                                      type="number"
                                      style={{
                                        width: "100px",
                                        height: "38px",
                                      }}
                                      value={data?.third}
                                      onChange={e =>
                                        handleSubmitThirdEdit(e, key, data)
                                      }
                                    ></Input>
                                  </td>
                            

                                  <td>
                                    <Button
                                    color="warning" outline
                                      disabled={adding}
                                      onClick={() =>
                                        handleUpdateData(key, data)
                                      }
                                    >
                                      {adding ? "Submitting" : "Update"}
                                    </Button>
                                  </td>
                            </tr>
                          )
                        })
                      )}
                         </>
                      ) : (
                        <p className="mt-5">Please Select all required fields</p>
                      )}
                    </tbody>
                  </Table>
                </Row>
               
              </Form>
            </AddCardComponent>
          </Col>
        </Row>
    
      </Container>
    </div>
  )
}

export default UpdateFeatureUpgrade
