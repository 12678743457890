//for all login user
export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

//for all
export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR"

export const STORE_LOGIN_DATA = "STORE_LOGIN_DATA"
