export const GET_UNAVAILABLE_FEATURE = "GET_UNAVAILABLE_FEATURE"
export const GET_UNAVAILABLE_FEATURE_SUCCESS = "GET_UNAVAILABLE_FEATURE_SUCCESS"
export const GET_UNAVAILABLE_FEATURE_FAIL = "GET_UNAVAILABLE_FEATURE_FAIL"

export const GET_TOTAL_OWN_FEATURE_NUMBER = "GET_TOTAL_OWN_FEATURE_NUMBER"
export const GET_TOTAL_OWN_FEATURE_NUMBER_SUCCESS =
  "GET_TOTAL_OWN_FEATURE_NUMBER_SUCCESS"

export const GET_TECH_PURCHASE_PRICE = "GET_TECH_PURCHASE_PRICE"
export const GET_TECH_PURCHASE_PRICE_SUCCESS = "GET_TECH_PURCHASE_PRICE_SUCCESS"
export const GET_TECH_UPGRADE_PRICE = "GET_TECH_UPGRADE_PRICE"
export const GET_TECH_UPGRADE_PRICE_SUCCESS = "GET_TECH_UPGRADE_PRICE_SUCCESS"

export const GET_FEATURE_PURCHASE_PRICE = "GET_FEATURE_PURCHASE_PRICE"
export const GET_FEATURE_UPGRADE_PRICE = "GET_FEATURE_UPGRADE_PRICE"

export const GET_FEATURE_UPGRADE_PRICE_SUCCESS =
  "GET_FEATURE_UPGRADE_PRICE_SUCCESS"
export const GET_FEATURE_PURCHASE_PRICE_SUCCESS =
  "GET_FEATURE_PURCHSE_PRICE_SUCCESS"
