//add market outlook
export const ADD_MARKET_OUTLOOK = "ADD_MARKET_OUTLOOK"
export const ADD_MARKET_OUTLOOK_SUCCESS = "ADD_MARKET_OUTLOOK_SUCCESS"
export const ADD_MARKET_OUTLOOK_FAIL = "ADD_MARKET_OUTLOOK_FAIL"

//get market outlooks
export const GET_MARKET_OUTLOOKS = "GET_MARKET_OUTLOOKS"
export const GET_MARKET_OUTLOOKS_SUCCESS = "GET_MARKET_OUTLOOKS_SUCCESS"

//get single market outlook
export const GET_SINGLE_MARKET_OUTLOOK = "GET_SINGLE_MARKET_OUTLOOK"
export const GET_SINGLE_MARKET_OUTLOOK_SUCCESS =
  "GET_SINGLE_MARKET_OUTLOOK_SUCCESS"
export const GET_SINGLE_MARKET_OUTLOOK_FAIL = "GET_SINGLE_MARKET_OUTLOOK_FAIL"

//update market outlook
export const UPDATE_MARKET_OUTLOOK = "UPDATE_MARKET_OUTLOOK"
export const UPDATE_MARKET_OUTLOOK_SUCCESS = "UPDATE_MARKET_OUTLOOK_SUCCESS"
export const UPDATE_MARKET_OUTLOOK_FAIL = "UPDATE_MARKET_OUTLOOK_FAIL"

//student get single market outlook
export const STUDENT_GET_SINGLE_MARKET_OUTLOOK =
  "STUDENT_GET_SINGLE_MARKET_OUTLOOK"
export const STUDENT_GET_SINGLE_MARKET_OUTLOOK_SUCCESS =
  "STUDENT_GET_SINGLE_MARKET_OUTLOOK_SUCCESS"
export const STUDENT_GET_SINGLE_MARKET_OUTLOOK_FAIL =
  "STUDENT_GET_SINGLE_MARKET_OUTLOOK_FAIL"
