import { ADD_UNIVERSITY_NAME, ADD_UNIVERSITY_NAME_SUCCESS, ADD_UNIVERSITY_NAME_ERROR, GET_UNIVERSITY_NAME, GET_UNIVERSITY_NAME_SUCCESS } from "./actionTypes"

export const addUniversityName = (values, token) => ({
    type: ADD_UNIVERSITY_NAME,
    payload: { values, token }
})

export const addUniverstiyNameSuccess = (message) => ({
    type: ADD_UNIVERSITY_NAME_SUCCESS,
    payload: { message }
})

export const addUniverstiyNameError = (error) => ({
    type: ADD_UNIVERSITY_NAME_ERROR,
    payload: { error }
})


export const getUniverstiyName = (token) => ({

    type: GET_UNIVERSITY_NAME,
    payload: token
})

export const getUniverstiyNameSuccess = (universityNames) => ({

    type: GET_UNIVERSITY_NAME_SUCCESS,
    payload: { universityNames }
})