import { call, put, takeEvery } from "redux-saga/effects"
import { postLogin } from "../../../helpers/api_helper"
import { loginUserError, loginSuccess } from "./actions"
import { UserError } from "../loginError/actions"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER, UPDATE_PASSWORD } from "./actionTypes"

//3rd call async login
const loginWithEmailPasswordAsync = async (email, password) => {
  try {
    const response = await postLogin(email, password)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* loginUser({ payload: { user, history } }) {
  const { email, password } = user

  try {
    localStorage.clear()
    sessionStorage.clear()
    localStorage.removeItem("lastUniverse")
    localStorage.removeItem("teamId")
    localStorage.removeItem("universeId")
    localStorage.removeItem("persist")

    const loginUserResponse = yield call(
      loginWithEmailPasswordAsync,
      email,
      password
    )

    if (loginUserResponse.status === "success") {
      yield put(
        loginSuccess(
          loginUserResponse.data.firstName,
          loginUserResponse.token,
          loginUserResponse.data.email,
          loginUserResponse.data.role,
          loginUserResponse.data?.roundLimit
            ? loginUserResponse.data?.roundLimit
            : 7,
          loginUserResponse?.data?.lastUniverse
            ? loginUserResponse?.data?.lastUniverse
            : undefined
        )
      )
      // history.pushState(null, null, location.href)
      // window.onpopstate = function (event) {
      //   history.go(`/${loginUserResponse.data.role}dashboard`)
      // }
      //  history.push("/studentdashboard")
      // window.location.href = `/${loginUserResponse.data.role}dashboard`
      history.push(`/${loginUserResponse.data.role}dashboard`)
    } else {
      yield put(loginUserError())
      yield put(UserError(loginUserResponse.message))
    }
  } catch (error) {
    console.log("error data", error.response)
    yield put(UserError(error))
    yield put(loginUserError())
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    yield put(loginSuccess())
    localStorage.clear()
    sessionStorage.clear()
    // localStorage.clear()
    window.location.href = "/login"
    // location.replace("/login")
    // history.push("/login")
    // window.location.href = "/login"
    // location.reload()
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
