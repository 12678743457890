export const GET_PLANT_UPGRADE = "GET_PLANT_UPGRADE";
export const GET_PLANT_UPGRADE_SUCCESS = "GET_PLANT_UPGRADE_SUCCESS";
export const GET_PLANT_UPGRADE_FAIL = "GET_PLANT_UPGRADE_FAIL";

export const POST_PLANT_UPGRADE = "POST_PLANT_UPGRADE";
export const POST_PLANT_UPGRADE_SUCCESS = "POST_PLANT_UPGRADE_SUCCESS";
export const POST_PLANT_UPGRADE_FAIL = "POST_PLANT_UPGRADE_FAIL";



export const UPDATE_PLANT_UPGRADE = "UPDATE_PLANT_UPGRADE";
export const UPDATE_PLANT_UPGRADE_SUCCESS = "UPDATE_PLANT_UPGRADE_SUCCESS";
export const UPDATE_PLANT_UPGRADE_FAIL = "UPDATE_PLANT_UPGRADE_FAIL";

export const STORE_PLANT_DATA = "STORE_PLANT_DATA";