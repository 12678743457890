import { combineReducers } from "redux"
import ForgetPassword from "./auth/forgetpwd/reducer"
// Authentication
import Login from "./auth/login/reducer"
import loginError from "./auth/loginError/reducer"
import Profile from "./auth/profile/reducer"
//Calendar
import calendar from "./calendar/reducer"
// import CompetitiveProduct from "./competitiveProduct/reducers"
// import dropdownnamesReducer from "./dropdownnames/reducers"
// Front
import Layout from "./layout/reducer"
// import Productsreducer from "./Products/reducers"
// import SalesReducer from "./sales/reducers"
// import SalestargetsReducer from "./SalesTarget/reducers"
// import StoreReducer from "./Stores/reducers"
// import SurveyReducer from "./survey/reducers"
import UserReducer from "./users/reducers"
// import AttandanceReducer from "./attandance/reducers"
// import NoticeReducer from "./noticeBoard/reducers"

//adminTeacher
import TeacherAdmin from "./teachersAdmin/reducers"

//universityREducuer
import university from "./university/reducers"

//studentAdmin
import StudentAdmin from "./studentsAdmin/reducers"

//reset Password
import ResetPass from "./auth/resetPassword/reducer"

//courseAdmin
import courseAdmin from "./courseAdmin/reducer"

//courseunderteacher
import courseUnderTeacher from "./teacher/teacherCourse/reducers"

//universeTeacher reducer
import universeTeacher from "./universeTeacher/reducers"

//teamTeacher reducer
import teamTeacher from "./teamTeacher/reducers"
import dataAdmin from "./networkCoverage/reducer"
//import region data
import regionData from "./regionData/reducer"
import techData from "./tech/reducer"
import marketOutlookData from "./marketOutlook/reducer"
import adminDemandData from "./AdminDemand/reducer"
import AdminMarketShares from "./adminMarketShare/reducer"
import roundData from "./roundAdmin/reducer"
import roundTeacher from "./roundTeacher/reducer"
import studentProfileData from "./StudentProfile/reducers"
import AdminProductionReducer from "./AdminProduction/reducer"
import StudentCapacityData from "./studentPlanning/reducers"
import StudentMarketing from "./studentMarketing/reducers"
import adminFeature from "./AdminFeature/reducer"
import studentRND from "./studentRandD/reducers"
import distributionDataStudent from "./DistributionAndLogistic/reducers"
import studentDemandData from "./studentDemand/reducers"
import studentMarketShareData from "./studentMarketShare/reducers"
import studentInitialCapitalData from "./studentInitialCapital/reducer"

import studentEnhanceCapacity from "./studentCapacity/reducers"
import featureData from "./feature/reducer"
import financialOutComeData from "./financialOutcome/reducer"
import resultEvaluationData from "./ResultEvaluation/reducer"
import DemandReducer from "./Demand/reducers"
import PlantUpgradeReducer from "./PlantUpgrade/reducers"
import productionCapacityReducer from "./ProductionCapacity/reducers"
import RnDReducer from "./AdminR&D/reducers"
import StrategyReducer from "./Strategy/reducers"
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  loginError,
  ForgetPassword,
  Profile,
  calendar,

  UserReducer,

  TeacherAdmin,
  university,
  StudentAdmin,
  ResetPass,
  courseAdmin,
  courseUnderTeacher,
  universeTeacher,
  teamTeacher,
  dataAdmin,
  regionData,
  techData,
  marketOutlookData,
  adminDemandData,
  AdminMarketShares,
  roundData,
  roundTeacher,
  studentProfileData,
  AdminProductionReducer,
  StudentCapacityData,
  StudentMarketing,
  adminFeature,
  studentRND,
  distributionDataStudent,
  studentDemandData,
  studentMarketShareData,
  studentInitialCapitalData,
  studentEnhanceCapacity,
  featureData,
  financialOutComeData,
  resultEvaluationData,
  DemandReducer,
  PlantUpgradeReducer,
  productionCapacityReducer,
  RnDReducer,
  StrategyReducer
})

export default rootReducer
