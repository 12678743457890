import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RECOVER_ACCOUNT,
  RECOVER_ACCOUNT_FAIL,
  RECOVER_ACCOUNT_SUCCESS,
} from "./actionTypes"

export const userForgetPassword = (user, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history },
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}

export const recoverAccount = (email, history) => {
  return {
    type: RECOVER_ACCOUNT,
    payload: { email, history },
  }
}

export const recoverAccountSuccess = message => {
  return {
    type: RECOVER_ACCOUNT_SUCCESS,
    payload: { message },
  }
}

export const recoverAccountFail = error => {
  return {
    type: RECOVER_ACCOUNT_FAIL,
    payload: { error },
  }
}
