import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"

const RoundHeader = () => {
  return (
    <div>
      <div className="admin-all-page-header-top-main-title-search-container">
        <Row>
          <Col className="col-12">
            <div className="admin-all-page-header-top-search">
              <span>
                {" "}
                <h4>Round </h4>
              </span>
              <span>
                <span>
                  {" "}
                  <input type="search" />
                  <button>
                    <i className="bx bx-search-alt-2"></i>
                  </button>
                </span>
                <Link
                  to="/admin-create-round"
                  className="float-end waves-effect"
                >
                  <Button
                    outline
                    // className="btn-lg  waves-effect waves-light"
                  >
                    Create Round
                  </Button>
                </Link>
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default RoundHeader
