import {
  GET_ROUNDS_BY_TEACHER,
  GET_ROUNDS_BY_TEACHER_FAIL,
  GET_ROUNDS_BY_TEACHER_SUCCESS,
  ACTIVE_ROUND_BY_TEACHER,
  ACTIVE_ROUND_BY_TEACHER_FAIL,
  ACTIVE_ROUND_BY_TEACHER_SUCCESS,
  DEACTIVATE_ROUND_BY_TEACHER,
  DEACTIVATE_ROUND_BY_TEACHER_SUCCESS_FAIL,
  DEACTIVATE_ROUND_BY_TEACHER_SUCCESS,
  UPDATE_ROUND_BY_TEACHER,
  UPDATE_ROUND_BY_TEACHER_SUCCESS,
  UPDATE_ROUND_BY_TEACHER_FAIL
} from "./actionTypes"

export const getRoundsByTeacher = token => {
  return {
    type: GET_ROUNDS_BY_TEACHER,
    payload: { token },
  }
}

export const getRoundsByTeacherSuccess = data => {
  return {
    type: GET_ROUNDS_BY_TEACHER_SUCCESS,
    payload: { data },
  }
}

export const activeRoundByTeacher = (
  roundSerial,
  teamIds,
  examEndingTime,
  universeId,
  token
) => {
  return {
    type: ACTIVE_ROUND_BY_TEACHER,
    payload: { roundSerial, teamIds, examEndingTime, universeId, token },
  }
}

export const activeRoundByTeacherSuccess = message => {
  return {
    type: ACTIVE_ROUND_BY_TEACHER_SUCCESS,
    payload: { message },
  }
}

export const activeRoundByTeacherFail = message => {
  return {
    type: ACTIVE_ROUND_BY_TEACHER_FAIL,
    payload: { message },
  }
}

export const deactivateRoundByTeacher = (
  token,
  universeId,
  roundSerial,
  teamIds
) => {
  return {
    type: DEACTIVATE_ROUND_BY_TEACHER,
    payload: { token, universeId, roundSerial, teamIds },
  }
}

export const deactivateRoundByTeacherSuccess = message => {
  return {
    type: DEACTIVATE_ROUND_BY_TEACHER_SUCCESS,
    payload: { message },
  }
}

export const deactivateRoundByTeacherFail = message => {
  return {
    type: DEACTIVATE_ROUND_BY_TEACHER_SUCCESS_FAIL,
    payload: { message },
  }
}

export const updateRoundByTeacher = (
  roundSerial,
  teamIds,
  examEndingTime,
  universeId,
  token
) => {
  return {
    type: UPDATE_ROUND_BY_TEACHER,
    payload: { roundSerial, teamIds, examEndingTime, universeId, token },
  }
}

export const updateRoundByTeacherSuccess = message => {
  return {
    type: UPDATE_ROUND_BY_TEACHER_SUCCESS,
    payload: { message },
  }
}

export const updateRoundByTeacherFail = message => {
  return {
    type: UPDATE_ROUND_BY_TEACHER_FAIL,
    payload: { message },
  }
}
