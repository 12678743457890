import { GET_INITIAL_CAPITAL, GET_INITIAL_CAPITAL_SUCCESS } from "./actionTypes"

const initial_state = {
  initialCapital: {},
  loading: false,
  message: "",
  error: "",
}

const studentInitialCapitalData = (state = initial_state, action) => {
  switch (action.type) {
    case GET_INITIAL_CAPITAL:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_INITIAL_CAPITAL_SUCCESS:
      state = {
        ...state,
        loading: false,
        initialCapital: action.payload.data,
      }
    default:
      state = state
      break
  }
  return state
}

export default studentInitialCapitalData
