import {
  GET_TEAMS,
  GET_SINGLE_TEAM,
  GET_SINGLE_TEAM_FAIL,
  ADD_TEAM,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FAIL,
  GET_TEAMS_SUCCESS,
  GET_SINGLE_TEAM_SUCCESS,
  ADD_STUDENT_INTO_TEAM_SUCCESS,
  ADD_STUDENT_INTO_TEAM,
  ADD_STUDENT_INTO_TEAM_FAIL,
  REMOVE_STUDENT_FROM_TEAM,
  REMOVE_STUDENT_FROM_TEAM_SUCCESS,
  REMOVE_STUDENT_FROM_TEAM_FAIL,
  MAKE_CAPTAIN,
  MAKE_CAPTAIN_FAIL,
  MAKE_CAPTAIN_SUCCESS,
} from "./actionTypes"

//addTeam
export const addTeam = (values, token) => {
  return {
    type: ADD_TEAM,
    payload: { values, token },
  }
}
export const addTeamSuccess = message => {
  return {
    type: ADD_TEAM_SUCCESS,
    payload: { message },
  }
}

export const addTeamFail = message => {
  return {
    type: ADD_TEAM_FAIL,
    payload: { message },
  }
}
export const getTeams = (universeId, token) => {
  return {
    type: GET_TEAMS,
    payload: { universeId, token },
  }
}
export const getTeamsSuccess = teams => {
  return {
    type: GET_TEAMS_SUCCESS,
    payload: { teams },
  }
}

export const getSingleTeam = (universeId, teamId, token) => {
  return {
    type: GET_SINGLE_TEAM,
    payload: { universeId, teamId, token },
  }
}

export const getSingleTeamSuccess = team => {
  return {
    type: GET_SINGLE_TEAM_SUCCESS,
    payload: { team },
  }
}

export const getSingleTeamFail = message => {
  return {
    type: GET_SINGLE_TEAM_FAIL,
    payload: { message },
  }
}

export const addStudent = (universeId, teamId, token, email) => {
  return {
    type: ADD_STUDENT_INTO_TEAM,
    payload: { universeId, teamId, token, email },
  }
}

export const addStudentSuccess = message => {
  return {
    type: ADD_STUDENT_INTO_TEAM_SUCCESS,
    payload: { message },
  }
}
export const addStudentFail = message => {
  return {
    type: ADD_STUDENT_INTO_TEAM_FAIL,
    payload: { message },
  }
}

export const removeStudentFromTeam = (token, teamId, universeId, studentId) => {
  return {
    type: REMOVE_STUDENT_FROM_TEAM,
    payload: { token, teamId, universeId, studentId },
  }
}

export const removeStudentFromTeamSuccess = message => {
  return {
    type: REMOVE_STUDENT_FROM_TEAM_SUCCESS,
    payload: { message },
  }
}

export const removeStudentFromTeamFail = message => {
  return {
    type: REMOVE_STUDENT_FROM_TEAM_FAIL,
    payload: { message },
  }
}

export const makeCaptain = (token, teamId, universeId, userId) => {
  return {
    type: MAKE_CAPTAIN,
    payload: { token, teamId, universeId, userId },
  }
}

export const makeCaptainSuccess = message => {
  return {
    type: MAKE_CAPTAIN_SUCCESS,
    payload: { message },
  }
}

export const makeCaptainFail = error => {
  return {
    type: MAKE_CAPTAIN_FAIL,
    payload: { error },
  }
}
