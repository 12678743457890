import React, { useEffect, useState } from "react"
import { Col, Container, Form, Row, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { addMarketOutlook, getMarketOutlooks } from "./../../store/actions"
const CreateOutLook = () => {
  const { token, loading, marketOutlooks, message } = useSelector(state => ({
    token: state.Login.token,
    loading: state.marketOutlookData.loading,
    marketOutlooks: state.marketOutlookData.marketOutlooks,
    message: state.marketOutlookData.message,
  }))

  const dispatch = useDispatch()
  let history = useHistory()
  const [roundIndex, setRoundIndex] = useState("")
  const [demand, setDemand] = useState("")
  const [cost, setCost] = useState("")
  const [finance, setFinance] = useState("")
  useEffect(() => {
    dispatch(getMarketOutlooks(token))
  }, [message])
  function handleValidSubmit(e) {
    e.preventDefault()
    if (roundIndex == "select") {
      toast.error("Please, Select an valid Round index")
    } else {
      console.log("round index", roundIndex, demand, cost, finance)
      let values = {}
      values.roundIndex = roundIndex
      values.demand = demand
      values.cost = cost
      values.finance = finance

      dispatch(addMarketOutlook(values, token))
    }
  }
  return (
    <div>
      <Container>
        <div className="create-outlook-single-data-wrap">
          <div className="create-outlook-inner-item-wrap">
            <div className="create-outlook-header">
              <div className="outlook-left-title">
                <h4>Market Outlook</h4>
              </div>
              <div className="outlook-single-delete-button">
                <h6>Delete</h6>
              </div>
            </div>
            <div className="create-outlook-body-main-wrap">
              <Form.Select
                value={roundIndex}
                onChange={e => setRoundIndex(e.target.value)}
                t
                aria-label="Default select example"
              >
                <option value="select">Select Round Serial</option>
                <option value={1}> One</option>
                <option value={2}>Two</option>
                <option value={3}>Three</option>
                <option value={4}>Four</option>
                <option value={5}>Five</option>
                <option value={6}>Six</option>
                <option value={7}>Seven</option>
              </Form.Select>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Demand</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={demand}
                  onChange={e => setDemand(e.target.value)}
                />
              </Form.Group>{" "}
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Cost</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={cost}
                  onChange={e => setCost(e.target.value)}
                />
              </Form.Group>{" "}
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Finance</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={finance}
                  onChange={e => setFinance(e.target.value)}
                />
              </Form.Group>
            </div>
            <Row>
              <Col lg="6">
                <div className="outlook-single-button-single-wrap">
                  {loading ? (
                    <Spinner />
                  ) : (
                    <button onClick={e => handleValidSubmit(e)}>
                      Create Market Outlook
                    </button>
                  )}
                </div>
              </Col>{" "}
            </Row>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default CreateOutLook
