export const ADD_UNIVERSE = "ADD_UNIVERSE"
export const ADD_UNIVERSE_SUCCESS = "ADD_UNIVERSE_SUCCESS"
export const ADD_UNIVERSE_FAIL = "ADD_UNIVERSE_FAIL"

export const GET_UNIVERSES = "GET_UNIVERSES"
export const GET_UNIVERSES_SUCCESS = "GET_UNIVERES_SUCCESS"

export const GET_SINGLE_UNIVERSE = "GET_SINGLE_UNIVERSE"
export const GET_SINGLE_UNIVERSE_SUCCESS = "GET_SINGLE_UNIVERSE_SUCCESS"
export const GET_SINGLE_UNIVERSE_FAIL = "GET_SINGLE_UNIVERSE_FAIL"
