import {
  CANCLE_ENHANCEMENT,
  ENHANCE_STUDENT_CAPACITY,
  GO_TO_RAND_D,
  IS_CAPACITY_IN_PROCESSING,
} from "./actionTypes"

import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"

import {
  cancleEnhancementFail,
  cancleEnhancementSuccess,
  capacityInProcessingSuccess,
  enhanceStudentCapacityFail,
  enhanceStudentCapacitySuccess,
  goToRandDSuccess,
} from "../actions"

import {
  axiosCancleEnhancement,
  axiosCapacityEnhancement,
  axiosGoToRANDD,
  axiosIsCapacityInProcessing,
} from "../../helpers/api_helper"

const asyncEnhanceStudentCapacity = async (
  token,
  teamId,
  roundSerial,
  regionId,
  capacityActive,
  totalProductionCapacity,
  totalCost,
  currentRound,
  universeId
) => {
  try {
    const response = await axiosCapacityEnhancement(
      token,
      teamId,
      roundSerial,
      regionId,
      capacityActive,
      totalProductionCapacity,
      totalCost,
      currentRound,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
//enhancement caapcity data
function* workerEnhanceStudentCapacity({
  payload: {
    token,
    teamId,
    roundSerial,
    regionId,
    capacityActive,
    totalProductionCapacity,
    totalCost,
    currentRound,
    universeId,
    setDevelopBtn
  },
}) {
  try {
    console.log("universe id ", universeId)
    const response = yield call(
      asyncEnhanceStudentCapacity,
      token,
      teamId,
      roundSerial,
      regionId,
      capacityActive,
      totalProductionCapacity,
      totalCost,
      currentRound,
      universeId
    )

    if (response.status == "success") {
      setDevelopBtn(false)
      yield put(enhanceStudentCapacitySuccess(response.message))
      toast.success(response.message)
      const res = yield call(
        asyncIsCapacityInProcessing,
        token,
        roundSerial,
        teamId,
        universeId
      )

      yield put(capacityInProcessingSuccess(res.getData))
      yield put(enhanceStudentCapacitySuccess())
    } else {
      yield put(enhanceStudentCapacityFail(response.message))
      toast.error(response.message)
      yield put(enhanceStudentCapacityFail())
    }
  } catch (error) {}
}

const asyncIsCapacityInProcessing = async (
  token,
  roundSerial,
  teamId,
  universeId
) => {
  try {
    const response = await axiosIsCapacityInProcessing(
      token,
      roundSerial,
      teamId,
      universeId
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

function* workerIsCapacityIsProcessing({
  payload: { token, roundSerial, teamId, universeId },
}) {
  try {
    console.log("universe Id here", universeId)
    const response = yield call(
      asyncIsCapacityInProcessing,
      token,
      roundSerial,
      teamId,
      universeId
    )
    yield put(capacityInProcessingSuccess(response.getData))
  } catch (error) {
    console.log("error data", error)
  }
}

const asyncCancleEnhacnement = async (token, roundSerial, regionId, teamId) => {
  try {
    const response = await axiosCancleEnhancement(
      token,
      roundSerial,
      regionId,
      teamId
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}
function* workerCancleEnhancement({
  payload: { token, roundSerial, regionId, teamId },
}) {
  const response = yield call(
    asyncCancleEnhacnement,
    token,
    roundSerial,
    regionId,
    teamId
  )
  if (response.status == "success") {
    yield put(cancleEnhancementSuccess(response.message))
    toast.success(response.message)

    const res = yield call(
      asyncIsCapacityInProcessing,
      token,
      roundSerial,
      teamId
    )
    yield put(capacityInProcessingSuccess(res.getData))

    yield put(cancleEnhancementSuccess())
  } else {
    yield put(cancleEnhancementFail(response.message))
    toast.error(response.message)
    yield put(cancleEnhancementFail())
  }
}
//asyncGoTORandD
const asyncGoTORAndD = async (token, roundSerial, teamId, universeId) => {
  try {
    console.log("call 176 line", universeId)
    const response = await axiosGoToRANDD(
      token,
      roundSerial,
      teamId,
      universeId
    )
    console.log("response here", response)
    return response.data
  } catch (error) {
    return error.resposne.data
  }
}

function* workerGoToRandD({
  payload: { token, roundSerial, teamId, history, goNext, val, universeId },
}) {
  try {
    console.log("call this page", universeId)
    const response = yield call(
      asyncGoTORAndD,
      token,
      roundSerial,
      teamId,
      universeId
    )
    console.log("response here check", response.check)
    if (response.check == false) {
      toast.error("Please fill all the input field first")
      console.log("history data", goNext)
      if (goNext == true) {
        history.goBack("/decisions/demand")
      }
    } else {
      console.log("history call go to rand d", val)
      if (val == 1) {
        history.push("/decisions/demand")
      } else if (val == 3) {
        history.push("/decisions/marketing")
      } else {
        history.push("/decisions/r-and-d")
      }
    }
    yield put(goToRandDSuccess(response.check))
  } catch (error) {}
}
function* capacityEnhancementSaga() {
  yield takeEvery(ENHANCE_STUDENT_CAPACITY, workerEnhanceStudentCapacity)
  yield takeEvery(IS_CAPACITY_IN_PROCESSING, workerIsCapacityIsProcessing)
  yield takeEvery(CANCLE_ENHANCEMENT, workerCancleEnhancement)
  yield takeEvery(GO_TO_RAND_D, workerGoToRandD)
}
export default capacityEnhancementSaga
