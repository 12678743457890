import {
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  ADD_NEW_USER,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  LOADING_ADD_USER,
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = data => ({
  type: GET_USERS_SUCCESS,
  payload: data,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})
export const getUser = id => ({
  type: GET_USER,
  payload: id,
})

export const LoadingUserAdd = isLoading => ({
  type: LOADING_ADD_USER,
  payload: isLoading,
})

export const getUserSuccess = data => ({
  type: GET_USER_SUCCESS,
  payload: data,
})

export const getUserFail = error => ({
  type: GET_USER_FAIL,
  payload: error,
})

export const addNewUser = (data, history) => ({
  type: ADD_NEW_USER,
  payload: { data, history },
})

export const addUserSuccess = data => ({
  type: ADD_USER_SUCCESS,
  payload: data,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const updateUser = (id, data) => ({
  type: UPDATE_USER,
  payload: { id, data },
})

export const updateUserSuccess = (id, data) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { id, data },
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = data => ({
  type: DELETE_USER,
  payload: data,
})

export const deleteUserSuccess = data => ({
  type: DELETE_USER_SUCCESS,
  payload: data,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})
